/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default InfoIcon;
