/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.125 29.375a4.583 4.583 0 0 1-4.583 4.583h-27.5l-9.167 9.167V6.458a4.583 4.583 0 0 1 4.583-4.583h32.084a4.584 4.584 0 0 1 4.583 4.583v22.917Z"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default SvgComponent;
