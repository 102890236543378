import {
  CardContent,
  Typography,
  Divider,
  CardActionArea,
  Box,
} from '@mui/material';
import moment from 'moment';
import { useAppContext } from 'context/AppContext';
import AnnouncementCardModal from 'components/Announcements/AnnouncementCardModal';
import CustomChip from 'components/Common/CustomChip';
import MotionCard from 'components/MotionCard';

const AnnouncementCard = ({
  name,
  date,
  content,
  space,
}: AnnouncementProps) => {
  const { openModal } = useAppContext();

  const parsedDate = moment(date).format('DD/MM/YYYY');

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        overflow: 'visible',
        height: '100%',
      }}
    >
      <CardActionArea
        onClick={() =>
          openModal(
            <AnnouncementCardModal
              name={name}
              date={parsedDate.toString()}
              content={content}
              space={space}
            />,
          )
        }
        sx={{
          height: '100%',
        }}
      >
        <CardContent
          sx={{
            padding: '14px 16px 22px 16px',
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ fontSize: 'smallFontSize', opacity: 0.65 }}
              color="text.content"
            >
              {parsedDate.toString()}
            </Typography>
            <CustomChip
              label={space.replace('Work.Life ', '')}
              variant="outlined"
              color="secondary"
              sx={{
                fontSize: 'smallFontSize',
                fontWeight: 'fontWeightRegular',
                cursor: 'inherit',
              }}
            />
          </Box>
          <Divider sx={{ mt: '16px', mb: '14px' }} />
          <Typography
            variant="h4"
            sx={{ fontWeight: 'fontWeightBold', mb: '12px' }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              lineHeight: '17px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '5',
              WebkitBoxOrient: 'vertical',
            }}
          >
            <Box
              sx={{
                a: {
                  color: 'inherit',
                },
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </Typography>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

export default AnnouncementCard;
