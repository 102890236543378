export const LABELS = ['09', '11', '13', '15', '17'];

export const OPTIONS = {
  scales: {
    y: {
      suggestedMin: -0.05,
      suggestedMax: 1,
      ticks: { display: false, stepSize: 0.2 },
      grid: {
        drawBorder: false,
        tickColor: 'transparent',
        lineWidth: (ctx: { index: number }) =>
          ctx.index > 1 && ctx.index < 6 ? 1 : 0,
      },
    },
    x: {
      position: { y: -0.05 },
      grid: {
        display: false,
        borderColor: '#1D1954',
      },
      ticks: {
        color: '#1D1954',
        font: {
          family: '"Antebas", "Roboto", sans-serif',
          size: 13,
          weight: '700',
        },
      },
    },
  },
  maintainAspectRatio: false,
};

export const REFETCH_INTERVAL = 30 * 60 * 1000;
