import {
  Box,
  IconButton,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import CopyIcon from 'assets/icons/CopyIcon';
import WifiIcon from 'assets/icons/WifiIcon';
import { useAuth } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';
import { MENU_TYPES } from 'constants/common';

export type PincodeProps = {
  lightBackground?: boolean;
};

const PincodeInput = ({ lightBackground }: PincodeProps) => {
  const { user } = useAuth();
  const pincode = user?.wifiPin || '';
  const theme = useTheme();
  const { anchorEl, setAnchorEl } = useAppContext();
  const { snack, setSnack } = useToast();

  return (
    <>
      <OutlinedInput
        value={pincode}
        sx={{
          maxWidth: '327px',
          height: '52px',
          px: '0px',
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '8px',
          overflow: 'hidden',
          cursor: 'default',
          color: '#1D1954',
          '& .MuiInputBase-input': {
            padding: '17px 16px 17px 14px',
            fontSize: '20px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        startAdornment={
          <Box
            sx={{
              height: '52px',
              minWidth: '48px',
              width: '48px',
              backgroundColor: theme.palette.secondary.main,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WifiIcon
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
          </Box>
        }
        endAdornment={
          <IconButton
            aria-label="copy"
            sx={{
              marginRight: '16px',
              marginLeft: '18px',
              color: theme.palette.text.content,
            }}
            onClick={() => {
              setAnchorEl({ ...anchorEl, [MENU_TYPES.WIFI]: false });
              navigator.clipboard.writeText(pincode);
              setSnack({
                ...snack,
                message: 'Wifi pincode copied!',
                open: true,
                autoHideDuration: 1500,
              });
            }}
          >
            <CopyIcon
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
          </IconButton>
        }
        readOnly
      />
      <Typography
        sx={{
          fontSize: 'smallFontSize',
          marginTop: '8px',
          color: lightBackground === true ? '#1D1954' : '#FFFFFF',
          opacity: lightBackground === true ? 0.6 : 1,
        }}
      >
        Use this code for wifi, check in and printing
      </Typography>
    </>
  );
};
PincodeInput.defaultProps = {
  lightBackground: false,
};

export default PincodeInput;
