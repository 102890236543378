import { Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { NavigationButton } from 'components/Slider/style';
import NavigationIcon from 'assets/icons/NavigationIcon';

export type DateNavigationProps = {
  currentDate: moment.Moment;
  setCurrentDate: (date: moment.Moment) => void;
};

const DateNavigation = ({
  currentDate,
  setCurrentDate,
}: DateNavigationProps) => {
  const theme = useTheme();
  const today = moment();

  return (
    <>
      <NavigationButton
        onClick={() => setCurrentDate(moment(currentDate).subtract(1, 'day'))}
      >
        <NavigationIcon
          sx={{
            padding: '7px',
          }}
        />
      </NavigationButton>
      <Typography
        fontWeight={theme.typography.fontWeightBold}
        width="80px"
        textAlign="center"
        marginX="8px"
      >
        {currentDate.isSame(today, 'day')
          ? 'Today'
          : currentDate.format('DD/MM/YY')}
      </Typography>
      <NavigationButton
        onClick={() => setCurrentDate(moment(currentDate).add(1, 'day'))}
      >
        <NavigationIcon
          sx={{
            padding: '7px',
            transform: 'rotate(180deg)',
          }}
        />
      </NavigationButton>
    </>
  );
};

export default DateNavigation;
