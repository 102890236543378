import { Stack, Typography, useTheme } from '@mui/material';
import DatePickerController from 'components/Form/DatePickerController';
import SelectField from 'components/Form/SelectField';
import Section from 'components/Section';
import { OPTIONS } from 'constants/signUp';

const InductionForm = ({
  spaces,
  bankHolidays,
}: {
  spaces: SpaceProps[];
  bankHolidays: string[];
}) => {
  const theme = useTheme();
  const spaceOptions = spaces?.map((space) => ({
    value: space.id,
    label: space.name,
  }));

  return (
    <Section title="Book your induction">
      <Stack spacing={2}>
        <Typography
          fontWeight={theme.typography.fontWeightBold}
          marginBottom="16px"
        >
          Before you can become a fully-fledged Work.Life member, you&apos;ll
          need to book an induction. We&apos;ll show you around the space, get
          you set up on the Wifi, and talk you through our amenities. After your
          induction, you&apos;ll be given your access card.
        </Typography>
        <SelectField
          label="Select the space you'd like to your induction"
          name="inductionSpaceId"
          options={spaceOptions}
          required
        />
        <DatePickerController
          name="inductionDate"
          label="Pick your induction date"
          required
          disableWeekends
          disablePast
          bankHolidays={bankHolidays}
        />
        <SelectField
          label="Available times"
          name="inductionTime"
          options={OPTIONS.inductionTime}
        />
      </Stack>
    </Section>
  );
};

export default InductionForm;
