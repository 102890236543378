import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';
import routes from 'utils/routes';

import MetricsChart from 'components/Metrics/MetricsChart';
import Loader from 'components/Loader';

import { API, APIRoutes } from 'utils/api';

import AccountCard from './AccountCard';

type CardMetricsProps = {
  label: string;
};

const fetchMetrics = async () => {
  const { data } = await API.get(APIRoutes.user.metrics);
  return data;
};

const CardMetrics = ({ label }: CardMetricsProps) => {
  const { data, isSuccess, isLoading } = useQuery<any>('metrics', () =>
    fetchMetrics(),
  );

  const metricData = data?.data.slice(data.data.length - 6, data.data.length);

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <AccountCard
        label={label}
        value={routes.main.account.metrics}
        elements={
          <>
            <Typography fontWeight="700" mb="30px">
              Payments in last 6 months
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: '28px',
                right: '28px',
                '&:before': {
                  content: '" "',
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  background: '#1D1954',
                  borderRadius: '4px',
                  marginRight: '8px',
                },
              }}
            >
              <Typography fontSize="16px">Me</Typography>
            </Box>
            <Box width="100%" height="70%">
              <MetricsChart data={metricData} />
            </Box>
          </>
        }
      />
    );
  }

  return null;
};

export default CardMetrics;
