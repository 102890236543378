/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';

import CardItem from 'components/Common/CardItem';
import { API, APIRoutes } from 'utils/api';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import ScrollTopButton from 'components/Common/ScrollTopButton';

const getBookedEvents = async () => {
  const {
    data: { data: events },
  } = await API.get(APIRoutes.events.booked);
  return events;
};

const BookedEvents = () => {
  const { isLoading, isFetching, data, isSuccess } = useQuery<EventProps[]>(
    'events-booked',
    () => getBookedEvents(),
  );

  return (
    <Box>
      {isLoading || isFetching ? (
        <LoadingSpinner minHeight="500px" />
      ) : isSuccess ? (
        isEmpty(data) ? (
          <Box sx={{ opacity: 0.6, textAlign: 'center' }}>
            You currently don&apos;t have any events
          </Box>
        ) : (
          <>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fill,minmax(312px, 1fr))"
              gap={2}
            >
              {data.map((event: EventProps) => (
                <Box key={`booked-event-${event.id}`}>
                  <CardItem
                    type="events"
                    summary={event.shortDescription}
                    title={event.title}
                    id={event.eventId}
                    created={event.startDate}
                    imageUrl={event.imageUrl}
                    location={event.address}
                    price={event.price}
                    createdAt={event.createdAt}
                  />
                </Box>
              ))}
            </Box>
            <Box textAlign="center" mt="27px">
              <ScrollTopButton />
            </Box>
          </>
        )
      ) : null}
    </Box>
  );
};

export default BookedEvents;
