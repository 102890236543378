import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiDrawer: {
    styleOverrides: {
      root: {
        transition: `width ${theme.transitions.duration.standard}ms`,
        gridArea: 'side-menu',
        '& .MuiDrawer-paper': {
          borderRight: 'none',
          position: 'static',
          backgroundColor: theme.palette.background.main,
          color: theme.palette.text.main,
          overflowY: 'visible',
          '& .MuiListItem-root': {
            height: '40px',
            marginBottom: '4px',
            borderRadius: '100px',
            '& .MuiListItemIcon-root': {
              marginRight: '0px',
              minWidth: 'auto',
              color: 'inherit',
              position: 'relative',
            },
            '& .MuiListItemText-root': {
              marginLeft: '8px',
            },
            '& .Mui-selected': {
              height: '40px',
              backgroundColor: theme.palette.primary.dark,
              borderRadius: '100px',
              overflowY: 'visible',
              '& .MuiTypography-root': {
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.fontWeightBold,
              },
              '& .MuiListItemIcon-root': {
                color: theme.palette.secondary.main,
              },
            },
            '& .MuiSvgIcon-root:first-of-type': {
              width: '24px',
              height: '24px',
            },
          },
          '& .MuiListItem-root:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.main,
            borderRadius: '100px',
            '& .MuiButtonBase-root:hover': {
              backgroundColor: 'unset',
              '& span': {
                fontWeight: theme.typography.fontWeightBold,
              },
            },
          },
        },
      },
    },
  },
});
