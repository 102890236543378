import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import ImageIcon from 'assets/icons/ImageIcon';
import Section from 'components/Section';
import { useAuth } from 'context/AuthContext';
import { IMAGE_PICKER_CONFIG } from 'constants/profile';

const PictureForm = () => {
  const [removePhoto, setRemovePhoto] = useState(false);
  const [pictureName, setPictureName] = useState('');

  const { setValue, register, resetField } = useFormContext();
  const { user } = useAuth();
  const theme = useTheme();

  const onDrop = (selectedImages: any) => {
    const file = selectedImages[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setValue('base64avatar', reader.result?.toString(), {
        shouldDirty: true,
      });
    setPictureName(file?.name);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...IMAGE_PICKER_CONFIG,
  });

  useEffect(() => {
    if (removePhoto) {
      setValue('base64avatar', null, {
        shouldDirty: true,
      });
      setPictureName('');
    } else {
      resetField('base64avatar');
    }
  }, [removePhoto]);

  return (
    <Section title="Your profile" id="yourprofile">
      <Box display="flex" alignItems="flex-end" gap="16px">
        <Avatar
          src={user?.avatar || ''}
          sx={{ width: '192px', height: '192px' }}
        />
        <Box maxWidth="512px">
          <Box display="flex" alignItems="center" marginBottom="8px">
            <ImageIcon
              sx={{
                marginRight: '8px',
              }}
            />
            <Typography
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
            >
              Your profile picture
            </Typography>
          </Box>
          <Typography marginBottom="16px" lineHeight="18px">
            This image will be shown in the members directory and your profile
            page if you choose to share it with other members. It will also help
            us recognize you! File smaller than 10MB and at least 400px by
            400px.
          </Typography>
          <Box display="flex" gap="16px">
            <Box textAlign="center" {...getRootProps()}>
              <input {...getInputProps()} {...register('base64avatar')} />
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  width: '144px',
                  height: '44px',
                  justifyContent: 'center',
                  marginBottom: '8px',
                }}
                disabled={removePhoto}
              >
                Browse
              </Button>
              <Typography fontSize={theme.typography.smallFontSize}>
                {pictureName || 'No file selected'}
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={removePhoto}
                  onChange={() => setRemovePhoto((isChecked) => !isChecked)}
                />
              }
              label={
                <Typography fontWeight={theme.typography.fontWeightBold}>
                  Remove my photo
                </Typography>
              }
              sx={{
                marginBottom: '32px',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default PictureForm;
