import { useMutation } from 'react-query';
import { useToast } from 'context/ToastContext';
import LoadingButton from 'components/Common/LoadingButton';
import { API, APIRoutes } from 'utils/api';

interface DownloadInvoiceButtonProps {
  invoice?: SecurityDepositInvoice;
}

const DownloadInvoiceButton = ({ invoice }: DownloadInvoiceButtonProps) => {
  const { snack, setSnack } = useToast();

  const { mutate: download, isLoading } = useMutation(
    () =>
      API.get(APIRoutes.invoices.byId(invoice?.id), {
        responseType: 'blob',
      }),
    {
      onSuccess: (response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', `${invoice?.name}.pdf`);
        link.click();
        link.remove();
      },
      onError: () => {
        setSnack({
          ...snack,
          message: 'Unable to download invoice',
          open: true,
          type: 'error',
        });
      },
    },
  );

  return (
    <LoadingButton
      isLoading={isLoading}
      disabled={!invoice}
      variant="contained"
      color="secondary"
      fullWidth
      sx={{
        justifyContent: 'center',
        height: '100%',
      }}
      onClick={() => download()}
    >
      Download
    </LoadingButton>
  );
};

DownloadInvoiceButton.defaultProps = {
  invoice: {},
};

export default DownloadInvoiceButton;
