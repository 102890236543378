import { useState } from 'react';
import { getItem, KEYS, setItem } from 'utils/cache';
import { incompatibleBrowser } from 'utils/browserDetection';

import LayoutBanner from './LayoutBanner';

const UnsupportedBrowserBanner = () => {
  const [isVisible, setIsVisible] = useState(
    incompatibleBrowser() && !getItem(KEYS.IGNORE_UNSUPPORTED_BROWSER),
  );

  const ignoreIncompatibleBrowser = () => {
    setItem(KEYS.IGNORE_UNSUPPORTED_BROWSER, 'true');
    setIsVisible(false);
  };

  if (isVisible) {
    return (
      <LayoutBanner
        onClick={ignoreIncompatibleBrowser}
        text="You are using an outdated browser. Upgrade your browser for
        better experience and security."
      />
    );
  }

  return null;
};

export default UnsupportedBrowserBanner;
