import { Tab, Tabs, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import Section from 'components/Section';
import { useAuth } from 'context/AuthContext';

export type TabProps = {
  value: string;
  label: string;
};

const CustomTabs = ({
  tabsConfig,
  scrollable,
}: {
  tabsConfig: TabProps[];
  scrollable?: boolean;
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const isScrollable = useMediaQuery('(max-width:1300px)');

  const teamTabs = user?.isTeamLeader
    ? tabsConfig
    : tabsConfig.filter((tab) => tab.label !== 'My team');

  const tabs = user?.allowCreditCardPayment
    ? teamTabs
    : teamTabs.filter((tab) => tab.label !== 'Card Payments');

  return (
    <Section
      sx={{
        // eslint-disable-next-line no-nested-ternary
        padding: scrollable ? (isScrollable ? '0 20px' : '0 40px') : '0 40px',
        background: theme.palette.background.content,
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Tabs
        value={
          window.location.pathname !== '/' ? window.location.pathname : false
        }
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{ minHeight: '0' }}
        variant={scrollable ? 'scrollable' : 'standard'}
      >
        {tabs.map((tab: TabProps, index) => (
          <Tab
            // eslint-disable-next-line react/no-array-index-key
            key={`tab-${index}`}
            value={tab.value}
            label={tab.label}
            component={Link}
            to={tab.value}
            sx={{ marginTop: '0' }}
          />
        ))}
      </Tabs>
    </Section>
  );
};

CustomTabs.defaultProps = {
  scrollable: false,
};

export default CustomTabs;
