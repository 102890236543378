import { useMemo } from 'react';
import {
  CardMedia,
  CardContent,
  Typography,
  Divider,
  CardActionArea,
  Box,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import MotionCard from 'components/MotionCard';
import CustomChip from 'components/Common/CustomChip';
import routes from 'utils/routes';

export type CardItemProps = {
  type?: string;
  title: string;
  summary: string;
  created: string;
  imageUrl: string;
  id: number;
  location?: string;
  price?: number;
  createdAt?: string;
};

const CardItem = ({
  type,
  title,
  summary,
  created,
  imageUrl,
  id,
  location,
  price,
  createdAt,
}: CardItemProps) => {
  const date = moment(created).format('DD/MM/YYYY');
  const today = moment().format('DD/MM/YYYY');
  const { pathname } = useLocation();

  const noBookedBadgeRoutes = [routes.main.dashboard, routes.main.events];

  const showBookedBadge = useMemo(() => {
    if (
      type === 'events' &&
      !noBookedBadgeRoutes.includes(pathname) &&
      moment(moment().utc()).diff(createdAt, 'minutes') < 3
    ) {
      return true;
    }
    return false;
  }, [type, noBookedBadgeRoutes]);

  return (
    <MotionCard
      sx={{
        overflow: 'visible',
        height: '100%',
        ...(showBookedBadge && {
          backgroundColor: 'rgba(104, 202, 61, 0.3)',
        }),
      }}
      whileHover={{ scale: 1.025 }}
    >
      {type === 'events' && (
        <Box
          display="flex"
          sx={{
            position: 'absolute',
            right: '16px',
            top: '-10px',
          }}
        >
          {price === 0 && (
            <CustomChip label="Free!" variant="filled" color="secondary" />
          )}
          {date === today && (
            <CustomChip label="Today!" variant="filled" color="secondary" />
          )}
          {showBookedBadge && (
            <CustomChip label="Booked!" variant="filled" color="success" />
          )}
        </Box>
      )}
      <CardActionArea
        component={Link}
        to={`/${type}/${id}${
          pathname === routes.main.eventsBooked ? '?booked=true' : ''
        }`}
      >
        <CardContent
          sx={{
            padding: '16px 16px 24px 16px',
          }}
        >
          <CardMedia
            component="img"
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.src = '/assets/images/placeholder.png';
            }}
            height="176"
            image={imageUrl}
            alt={title}
            sx={{ borderRadius: '4px', mb: 2 }}
          />
          <Typography variant="h4" sx={{ fontWeight: 'fontWeightBold' }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: 'smallFontSize',
              lineHeight: '16.25px',
              opacity: 0.65,
            }}
            color="text.content"
          >
            {date.toString() + (location && ` - ${location}`)}
          </Typography>
          <Divider sx={{ my: '10px' }} />
          <Typography
            variant="body2"
            sx={{
              lineHeight: '18px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '5',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {summary}
          </Typography>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

CardItem.defaultProps = {
  type: 'event',
  location: '',
  price: 0,
  createdAt: '',
};

export default CardItem;
