/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="52px"
    height="52px"
    fill="none"
    viewBox="0 0 52 52"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect x={10} y={10} width={32} height={32} rx={16} fill="#fff" />
      <g clipPath="url(#b)">
        <path
          d="M18 26.278v-.017l.004-.068c0-.015 0-.03.002-.045l.003-.024c0-.01.008-.054.008-.054l.006-.039.002-.017.012-.05.006-.024c.003-.012.005-.024.01-.037l.001-.01.017-.048.008-.024a.887.887 0 0 1 .015-.042l.003-.009.02-.043.01-.02c.005-.014.01-.028.017-.04l.005-.009.02-.036.015-.027.023-.038.006-.01.03-.043.016-.02a.28.28 0 0 1 .017-.025l.007-.01a1.15 1.15 0 0 1 .016-.018l.011-.013.036-.042 6.145-6.338a1.198 1.198 0 0 1 1.748.017c.232.247.358.574.355.921a1.32 1.32 0 0 1-.37.915l-3.97 4.092 10.512.02c.681.001 1.235.584 1.234 1.3-.001.714-.555 1.296-1.235 1.296l-10.538-.02 4.005 4.205c.233.245.361.57.361.918 0 .347-.128.673-.36.918a1.2 1.2 0 0 1-.875.38 1.2 1.2 0 0 1-.874-.38l-6.115-6.418-.018-.02-.017-.021-.018-.02a.769.769 0 0 1-.033-.038l-.014-.018-.023-.035-.014-.019-.025-.037-.005-.007-.025-.044-.013-.023a.5.5 0 0 1-.018-.031l-.007-.015-.02-.046-.01-.02a.592.592 0 0 1-.014-.034l-.005-.014a.992.992 0 0 1-.017-.048l-.007-.018-.012-.04-.006-.018a1.518 1.518 0 0 1-.01-.043l-.005-.021-.009-.04-.002-.012a.482.482 0 0 1-.007-.049l-.003-.025-.005-.037-.002-.015a.754.754 0 0 1-.002-.052l-.001-.026a.56.56 0 0 1-.002-.038Z"
          fill="#1D1954"
        />
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" transform="rotate(-180 17 17)" d="M0 0h16v16H0z" />
      </clipPath>
      <filter
        id="a"
        x={0}
        y={0}
        width={52}
        height={52}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_626_50145"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_626_50145"
          result="shape"
        />
      </filter>
    </defs>
  </SvgIcon>
);

export default SvgComponent;
