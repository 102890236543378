import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useFormContext, get, Controller } from 'react-hook-form';
import theme from 'styles/theme';

export type CheckBoxProps = {
  label: string | React.ReactNode;
  name: string;
  disabled?: boolean;
  fontBold?: boolean;
};

const CheckBox = ({ label, name, disabled, fontBold }: CheckBoxProps) => {
  const {
    control,
    formState: { errors, isSubmitted },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Checkbox
                onBlur={onBlur}
                onChange={onChange}
                checked={!!value}
                inputRef={ref}
                disabled={disabled}
              />
            )}
          />
        }
        label={
          <Typography
            fontWeight={
              fontBold
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular
            }
          >
            {label}
          </Typography>
        }
        disabled={disabled}
      />
      {isSubmitted && errorMessage && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
    </>
  );
};

CheckBox.defaultProps = {
  fontBold: false,
  disabled: false,
};

export default CheckBox;
