import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import ScrollSpy from 'react-ui-scrollspy';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation, useQuery } from 'react-query';
import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import FormBanner from 'components/Profile/FormBanner';
import AddressForm from 'components/Profile/AddressForm';
import DirectDebit from 'components/Profile/DirectDebit';
import BillingForm from 'components/Profile/BillingForm';
import SocialForm from 'components/Profile/SocialForm';
import PictureForm from 'components/Profile/PictureForm';
import DetailsForm from 'components/Profile/DetailsForm';
import ChangePasswordForm from 'components/Profile/ChangePasswordForm';
import NotificationsForm from 'components/Profile/NotificationsForm';
import { TABS_PROFILE } from 'constants/tabs/tabsProfile';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import { USER_PROFILE } from 'constants/profile';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';

const breadcrumbsConfig = () => [{ label: 'Profile' }];

const schema = yup.object({
  avatar: yup.string(),
  base64avatar: yup.string().nullable(true),
  fullName: yup.string().nullable().required('Full name is required'),
  mobilePhone: yup
    .string()
    .nullable()
    .required('Phone is required')
    .matches(/^(?!\s)/, 'Cannot start with a blank space'),
  email: yup.string().email('Invalid e-mail').required('E-mail is required'),
  dateOfBirth: yup.date().nullable().typeError('Please provide full date'),
  salutation: yup
    .string()
    .nullable()
    .required(
      "What should we call you? You can type 'John' or 'Dr. White', for example",
    )
    .matches(/^(?!\s)/, 'Cannot start with a blank space'),
  address: yup
    .string()
    .nullable()
    .required('Address is required')
    .matches(/^(?!\s)/, 'Cannot start with a blank space'),
  postCode: yup
    .string()
    .nullable()
    .required('Postcode is required')
    .matches(/^(?!\s)/, 'Cannot start with a blank space'),
  countryId: yup.number().required().nullable(true),
  companyName: yup.string().nullable(true),
  cityName: yup
    .string()
    .nullable()
    .required('City is required')
    .matches(/^(?!\s)/, 'Cannot start with a blank space'),

  billingAddress: yup.string().nullable(true),
  billingPostCode: yup.string().nullable(true),
  billingCountryId: yup.number().nullable(true),
  billingEmail: yup.string().email('Invalid e-mail').nullable(true),
  billingCityName: yup.string().nullable(true),

  newPassword: yup
    .string()
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must have at least one uppercase letter (A-Z), one lowercase letter (a-z), a number (0-9) and a symbol (!@#$%^&*).',
    )
    .notRequired(),

  repeatNewPassword: yup.string().when('newPassword', {
    is: (val: string) => !!(val && val.length > 0),
    then: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('newPassword'), null], 'Your passwords do not match.'),
  }),
});

type FormData = yup.InferType<typeof schema>;

const getProfileData = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.user.account);
  return data;
};

const Profile = () => {
  const { setUser, fetchUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { snack, setSnack } = useToast();
  const [showButton, setShowButton] = useState<boolean>(false);

  const smallerResolution = useMediaQuery('(max-width: 1150px)');

  const theme = useTheme();

  const {
    isSuccess,
    isLoading,
    refetch,
    data: profile,
  } = useQuery<ProfileProps>(['profile'], () => getProfileData());

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: USER_PROFILE,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  const { mutate: updateProfile, isLoading: isUpdating } = useMutation(
    (payload: any) => API.put(APIRoutes.user.account, payload),
    {
      onSuccess: async () => {
        refetch();
        setUser(await fetchUser());
      },
      onSettled: (_, error: any) => {
        setSnack({
          ...snack,
          message: error
            ? `Unable to update profile${error.message && `: ${error.message}`}`
            : 'Your changes have been saved',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(
        location.hash.slice(1).split('?')[0],
      );
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location, isSuccess]);

  useEffect(() => {
    if (profile && isSuccess) {
      reset({ ...profile });
    }
  }, [profile, isSuccess]);

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    const payload = {
      ...data,
      avatar: dirtyFields?.base64avatar ? data?.base64avatar : null,
      removePhoto: data?.removePhoto,
    };
    updateProfile(payload);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <SectionHeader
          title="My profile"
          path={breadcrumbsConfig()}
          tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
          elements={
            <Box width="250px">
              <PincodeInput lightBackground />
            </Box>
          }
          backLink={routes.main.account.dashboard}
        />
        <Box
          sx={{
            position: 'sticky',
            top: '0px',
            zIndex: '9',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgb(241, 241, 241)',
          }}
        >
          <Tabs
            variant="scrollable"
            sx={{
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
                height: '100%',
                gap: '24px',
              },
            }}
            value={false}
          >
            {TABS_PROFILE.map((option: any) => (
              <Tab
                key={option.label}
                data-to-scrollspy-id={option.value}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`${routes.main.account.profile}#${option.value}`);
                }}
                label={option.label}
                sx={{
                  all: 'unset',
                  '&.active-scroll-spy': {
                    fontWeight: theme.typography.fontWeightBold,
                    borderBottom: `2px solid ${theme.palette.secondary.main}`,
                  },
                  height: '56px',
                  lineHeight: '56px',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Tabs>
          {showButton && (
            <Box
              position={smallerResolution ? 'static' : 'absolute'}
              sx={{
                right: smallerResolution ? 0 : '40px',
                top: smallerResolution ? 0 : '-5px',
              }}
            >
              <ScrollTopButton hideLabel />
            </Box>
          )}
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ScrollSpy
              offsetBottom={56}
              onUpdateCallback={(id) =>
                setShowButton(id !== TABS_PROFILE[0].value)
              }
            >
              <PictureForm />
              <DetailsForm />
              <AddressForm />
              <DirectDebit />
              <BillingForm />
              <SocialForm />
              <ChangePasswordForm />
              <NotificationsForm />
            </ScrollSpy>
            {isDirty && <FormBanner isLoading={isUpdating} />}
          </form>
        </FormProvider>
      </>
    );
  }
  return null;
};

export default Profile;
