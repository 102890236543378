// eslint-disable-next-line max-len
import { SvgIcon, SvgIconProps } from '@mui/material';

const InstagramIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default InstagramIcon;
