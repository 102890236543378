/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const MeetingRoomsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M5 18.6V1L13 5.8V23L5 18.6Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 19H19V1H11.4615H5"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12V14"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default MeetingRoomsIcon;
