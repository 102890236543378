import { SvgIcon, SvgIconProps } from '@mui/material';

const AvatarIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="116"
    height="116"
    viewBox="0 0 116 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="116" height="116" rx="58" fill="#F1F1F1" />
    <path
      // eslint-disable-next-line max-len
      d="M84.6654 88V81.3333C84.6654 77.7971 83.2606 74.4057 80.7601 71.9052C78.2596 69.4048 74.8683 68 71.332 68H44.6654C41.1291 68 37.7378 69.4048 35.2373 71.9052C32.7368 74.4057 31.332 77.7971 31.332 81.3333V88M71.332 41.3333C71.332 48.6971 65.3625 54.6667 57.9987 54.6667C50.6349 54.6667 44.6654 48.6971 44.6654 41.3333C44.6654 33.9695 50.6349 28 57.9987 28C65.3625 28 71.332 33.9695 71.332 41.3333Z"
      stroke="#1D1954"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#F1F1F1"
    />
  </SvgIcon>
);

export default AvatarIcon;
