import React from 'react';
import ChevronDownIcon from 'assets/icons/ChevronDownIcon';

export const SelectIcon = () => (
  <ChevronDownIcon
    sx={{
      position: 'absolute',
      right: '7px',
      width: '24px',
      height: '24px',
      color: '#1D1954',
      pointerEvents: 'none',
    }}
  />
);
