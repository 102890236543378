import routes from 'utils/routes';

export const TABS_ACCOUNT = [
  {
    value: routes.main.account.dashboard,
    label: 'Dashboard',
  },
  {
    value: routes.main.account.profile,
    label: 'Profile',
  },
  {
    value: routes.main.account.creditCard,
    label: 'Card Payments',
  },
  {
    value: routes.main.account.invoices,
    label: 'Invoices',
  },
  {
    value: routes.main.account.plans,
    label: 'Plans & Benefits',
  },
  {
    value: routes.main.account.metrics,
    label: 'Metrics',
  },
  {
    value: routes.main.account.team,
    label: 'My team',
  },
];
