import { Box, Button, Typography, useTheme } from '@mui/material';
import PlusWithCircleIcon from 'assets/icons/PlusWithCircleIcon';

import EditIcon from 'assets/icons/EditIcon';

export type EmptySectionProps = {
  title?: string;
  missing: string;
  label?: string;
  helperText?: string;
  onClick?: () => void;
  icon: JSX.Element;
  isEdit?: boolean;
};

const EmptySection = ({
  title,
  missing,
  label,
  helperText,
  onClick,
  icon,
  isEdit,
}: EmptySectionProps) => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
      color={theme.palette.text.content}
    >
      <Box sx={{ svg: { width: '100%', height: '100%' } }} mt="70px">
        {icon}
      </Box>
      <Typography fontWeight="bold" fontSize="26px" mt={2}>
        {title}
      </Typography>
      <Typography
        fontWeight="400"
        fontSize="20px"
        mt={1}
        lineHeight="20px"
        maxWidth="270px"
      >
        Looks as though {missing}
      </Typography>
      {helperText && (
        <Typography fontWeight="400" fontSize="16px" my={2}>
          {helperText}
        </Typography>
      )}
      {label &&
        (label === 'Add new visitor' ? (
          <Button
            onClick={onClick}
            variant="contained"
            color="secondary"
            endIcon={<PlusWithCircleIcon />}
            sx={{
              padding: '10px 16px',
              color: theme.palette.blue[900],
              width: '200px',
              height: '44px',
            }}
          >
            {label}
          </Button>
        ) : (
          <Button
            onClick={onClick}
            variant="contained"
            sx={{
              justifyContent: 'center',
              backgroundColor: isEdit
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
              color: isEdit ? 'inherit' : '#fff',
              '&:hover': {
                backgroundColor: isEdit
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              },
              width: 'auto',
              height: '44px',
            }}
            endIcon={
              isEdit ? (
                <EditIcon
                  sx={{
                    marginRight: 1,
                    width: '24px',
                    height: '24px',
                  }}
                />
              ) : null
            }
          >
            {label}
          </Button>
        ))}
    </Box>
  );
};

EmptySection.defaultProps = {
  title: 'Uh oh!',
  label: '',
  helperText: '',
  onClick: () => null,
  isEdit: false,
};

export default EmptySection;
