import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BasketIcon from 'assets/icons/BasketIcon';
import ButtonContainer from 'components/ButtonContainer';
import routes from 'utils/routes';

export type ButtonViewBasketProps = {
  hasNotification?: boolean;
};

const ButtonViewBasket = ({ hasNotification }: ButtonViewBasketProps) => {
  const navigate = useNavigate();

  return (
    <ButtonContainer>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          width: '200px',
          paddingRight: '20px',
        }}
        endIcon={
          <Box position="relative" top="-2px" width="24px" height="24px">
            <BasketIcon
              hasNotification={hasNotification}
              buttonViewBasket
              sx={{
                width: '22px',
                height: '21px',
              }}
            />
          </Box>
        }
        onClick={() => {
          navigate(routes.main.basket);
        }}
      >
        View basket
      </Button>
    </ButtonContainer>
  );
};

ButtonViewBasket.defaultProps = {
  hasNotification: false,
};

export default ButtonViewBasket;
