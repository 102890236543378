import { useMutation } from 'react-query';
import { Typography, useTheme, Box, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

import BasketIcon from 'assets/icons/BasketIcon';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';
import { QUANTITY_LIMITS } from 'constants/store';
import { QuantityButton, QuantityInput } from './style';

type CardActionsProps = {
  productId: number;
  regularCharge: boolean;
};

const CardActions = ({ productId, regularCharge }: CardActionsProps) => {
  const theme = useTheme();
  const { snack, setSnack } = useToast();
  const { getQuantity, updateQuantity, isOpen, closeModal } = useAppContext();
  const { isBasketEmpty, setIsBasketEmpty, user } = useAuth();
  const quantity = getQuantity(productId);
  const isActionDisabled = !user?.canPurchaseProducts;

  const { mutate: addProduct, isLoading } = useMutation(
    () =>
      API.patch(APIRoutes.basket.patch(productId), {
        quantity,
        regularCharge,
      }),
    {
      onSuccess: () => {
        if (isBasketEmpty) {
          setIsBasketEmpty(false);
        }
        updateQuantity(productId, -(quantity - 1));
      },
      onSettled: (_, error) => {
        if (isOpen) {
          closeModal();
        }
        setSnack({
          ...snack,
          message: error
            ? 'Unable to add item to basket'
            : 'Item added to basket',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      gap="26px"
    >
      <Box width="100%">
        <Tooltip
          title={
            isActionDisabled
              ? 'You cannot purchase products. Contact with your Team Leader.'
              : null
          }
        >
          <span>
            <LoadingButton
              isLoading={isLoading}
              disabled={isActionDisabled}
              onClick={() => addProduct()}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '200px',
                height: '44px',
                padding: '10px 16px',
              }}
              endIcon={
                <BasketIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              }
            >
              Add to basket
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>
      <Box textAlign="center">
        <Typography fontSize={theme.typography.smallFontSize}>Qty</Typography>
        <QuantityInput
          disabled={isActionDisabled}
          readOnly
          value={quantity}
          startAdornment={
            <QuantityButton
              variant="contained"
              disabled={isActionDisabled}
              onClick={() => {
                if (quantity > QUANTITY_LIMITS.min) {
                  updateQuantity(productId, -1);
                }
              }}
            >
              <RemoveRoundedIcon />
            </QuantityButton>
          }
          endAdornment={
            <QuantityButton
              variant="contained"
              disabled={isActionDisabled}
              onClick={() => {
                if (quantity < QUANTITY_LIMITS.max) {
                  updateQuantity(productId, 1);
                }
              }}
            >
              <AddRoundedIcon />
            </QuantityButton>
          }
        />
      </Box>
    </Box>
  );
};

export default CardActions;
