import styled from '@emotion/styled';
import { Box } from '@mui/material';
import theme from 'styles/theme';

export const MessageBox = styled(Box, {
  shouldForwardProp: (prop: string) => !['isExternal'].includes(prop as string),
})<{ isExternal: boolean }>(({ isExternal }) => ({
  width: 'fit-content',
  maxWidth: '50%',
  marginLeft: isExternal ? '0px' : 'auto',
  textAlign: isExternal ? 'right' : 'left',
  marginBottom: '8px',
  '& > div': {
    padding: '12px 16px',
    backgroundColor: isExternal ? '#ffffff' : theme.palette.secondary.main,
    textAlign: 'left',
    border: isExternal ? 'solid 1px #D9DFEF' : 'none',
    borderRadius: '12px',
    minWidth: '255px',
    lineHeight: '18px',
    color: '#1D1954',
  },
  '& > p': {
    opacity: 0.65,
    fontSize: theme.typography.smallFontSize,
    marginLeft: isExternal ? '0px' : '15px',
    marginRight: isExternal ? '15px' : '0px',
  },
}));
