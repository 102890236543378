import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Box, Typography } from '@mui/material';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import LoadingButton from 'components/Common/LoadingButton';

import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';

const Summary = () => {
  const [searchParams] = useSearchParams();
  const finalize = searchParams.get('finalize');
  const { snack, setSnack } = useToast();

  const { mutate: setUpGoCardless, isLoading } = useMutation(
    () =>
      API.post(APIRoutes.auth.goCardless, {
        redirectUri: `${window.location.origin}/account/profile#payment?finalize=true`,
        exitUri: window.location.href,
      }),
    {
      mutationKey: 'sign-up-plan',
      onSuccess: (data) => {
        const { data: resData } = data;
        window.location.href = resData?.url;
      },
      onError: () => {
        setSnack({
          ...snack,
          message: 'Something went wrong. Please try again.',
          open: true,
          type: 'error',
        });
      },
    },
  );

  const { mutate: goCardlessFinallSetup, isLoading: isFinalSetupLoading } =
    useMutation(
      () =>
        API.post(APIRoutes.auth.goCardlessFinalize, {
          status: 'success',
        }),
      {
        mutationKey: 'sign-up-plan',
        onSuccess: () => {
          window.location.href = `${window.location.origin}/dashboard`;
        },
        onError: () => {
          setSnack({
            ...snack,
            message: 'Something went wrong. Please try again.',
            open: true,
            type: 'error',
          });
        },
      },
    );

  useEffect(() => {
    const goCardlessFinalizeSetup = async () => {
      await goCardlessFinallSetup();
    };

    if (finalize) {
      goCardlessFinalizeSetup();
    }
  }, [finalize]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={3}
      mt="55px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
        sx={{ width: '600px', textAlign: 'center' }}
      >
        <Typography
          variant="h1"
          sx={{
            lineHeight: '26px',
          }}
        >
          {!finalize ? 'Complete setup' : 'Thank You!'}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'fontWeightBold',
            overflowWrap: 'break-word',
          }}
        >
          {finalize
            ? 'Thank you for submitting your information, you will be redirected shortly.'
            : 'Thanks for completing payment for your first month!'}
        </Typography>
        {!finalize && (
          <>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'fontWeightBold',
                overflowWrap: 'break-word',
              }}
            >
              You&apos;re almost good to go, all that’s left to do is to
              complete set up by adding Direct Debit for ongoing hassle-free
              invoicing.
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'fontWeightBold',
                overflowWrap: 'break-word',
              }}
            >
              We can’t wait to welcome you to our community.
            </Typography>
          </>
        )}

        {isFinalSetupLoading && <LoadingSpinner />}
        {!finalize && (
          <LoadingButton
            isLoading={isLoading}
            variant="contained"
            color="secondary"
            sx={{
              minWidth: '173px',
              height: '44px',
              justifyContent: 'center',
            }}
            onClick={() => setUpGoCardless()}
          >
            Set up Direct Debit
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};

export default Summary;
