import React from 'react';
import {
  Box,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { SelectIcon } from 'components/Common/SelectIcon';

export const FilterSelect = ({
  options,
  userFilter,
  setUserFilter,
  setSearchParams,
}: {
  options: {
    label: string;
    value: string;
  }[];
  userFilter: string;
  setUserFilter: React.Dispatch<React.SetStateAction<string>>;
  setSearchParams: any;
}) => (
  <FormControl
    sx={{
      width: '208px',
      marginRight: '18px',
    }}
  >
    <Select
      value={userFilter as string}
      onChange={(event: SelectChangeEvent) => {
        setUserFilter(event.target.value);
        setSearchParams((searchParams: any) => {
          searchParams.set('filter', event.target.value);
          return searchParams;
        });
      }}
      IconComponent={SelectIcon}
      sx={{
        height: '44px',
        borderRadius: '10px',
        background: '#FFFFFF',
        '& .MuiInputBase-input': {
          padding: '8px 8px 8px 16px',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      {options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          <>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                background: '#1D1954',
                borderRadius: '4px',
                marginRight: '8px',
              }}
            />
            <Typography fontSize="16px"> {option.label}</Typography>
          </>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
