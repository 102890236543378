import { Grid } from '@mui/material';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import ChartCard from 'components/Desks/ChartCard';
import Loader from 'components/Loader';
import { API, APIRoutes } from 'utils/api';
import { FILTERS } from 'constants/desks';

type ChartsProps = {
  filter: string;
  favourites: number[];
  setFavourites: React.Dispatch<React.SetStateAction<number[]>>;
};

const Charts = ({ filter, favourites, setFavourites }: ChartsProps) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (filter && filter !== FILTERS.ALL) {
      setSearchParams({ filter });
    }
  }, [filter]);

  const getCapacities = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.infogrid.capacities(moment().toISOString(), filter),
    );
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<CapacityProps[]>(
    ['capacity', filter],
    () => getCapacities(),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    let availableSpaces = [...data];
    if (filter === FILTERS.FAVOURITES) {
      availableSpaces = availableSpaces.filter((space: CapacityProps) =>
        favourites?.includes(space.spaceId),
      );
    }

    return (
      <Grid container spacing={2}>
        {availableSpaces.map((space) => (
          <Grid item xs={12} xl={6} key={space.spaceId}>
            <ChartCard
              spaceId={space.spaceId}
              favourites={favourites}
              setFavourites={setFavourites}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
  return null;
};

export default Charts;
