import { Box, Icon, SvgIconProps, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

import { StyledChip } from 'components/Rooms/style';

export type BookingOptionsProps = {
  icon: (props: SvgIconProps) => JSX.Element;
  title: string;
  options: string[];
  name: string;
  colors?: string[];
  disableRest?: boolean;
};

const SelectableSection = ({
  icon,
  title,
  options,
  name,
  colors,
  disableRest,
}: BookingOptionsProps) => {
  const theme = useTheme();
  const { setValue, watch, register, resetField } = useFormContext();
  const selected = watch(name);

  return (
    <Box marginBottom="50px">
      <Box display="flex" alignItems="center" marginBottom="30px">
        <Icon
          component={icon}
          sx={{
            color: theme.palette.secondary.main,
            fill: 'none',
            width: '24px',
            height: '24px',
            marginRight: '8px',
          }}
        />
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {title}
        </Typography>
      </Box>

      <Box display="flex" gap="16px" flexWrap="wrap" {...register(name)}>
        {options.map((option, idx) => (
          <StyledChip
            key={option}
            label={option}
            variant={selected === option.toString() ? 'filled' : 'outlined'}
            color="secondary"
            disabled={
              disableRest
                ? !isEmpty(selected) && selected !== option.toString()
                : false
            }
            sx={{
              minWidth: '160px',
              color: theme.palette.blue[900],
              width: 'auto',
              padding: '15px 8px 15px 8px',
              fontWeight:
                selected === option.toString()
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular,
              '&.MuiChip-outlined': {
                background: '#FFFFFF',
                borderColor: colors ? colors[idx] : 'secondary',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },
              },
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              '&.MuiChip-filled': {
                backgroundColor: colors ? colors[idx] : 'secondary',
              },
            }}
            onClick={() => {
              if (selected === option.toString()) {
                resetField(name);
              } else {
                setValue(name, option.toString(), { shouldDirty: true });
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

SelectableSection.defaultProps = {
  colors: '',
  disableRest: false,
};

export default SelectableSection;
