import { ACCOUNT_TYPES } from 'constants/account';

export const getPlanAvailability = (plan: string) => {
  let start = {
    hour: 8,
    minute: 30,
  };
  let end = {
    hour: 17,
    minute: 30,
  };

  if (
    [
      ACCOUNT_TYPES.RES_TEAM,
      ACCOUNT_TYPES.RESIDENT_ACTIVE,
      ACCOUNT_TYPES.RESIDENT_PREACTIVE,
      ACCOUNT_TYPES.UNLIMITED,
    ].includes(plan)
  ) {
    start = {
      hour: 0,
      minute: 0,
    };
    end = {
      hour: 24,
      minute: 0,
    };
  } else if (plan === ACCOUNT_TYPES.FLEX) {
    start = {
      hour: 8,
      minute: 30,
    };
    end = {
      hour: 20,
      minute: 30,
    };
  }

  return {
    start,
    end,
  };
};
