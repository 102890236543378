import { Typography } from '@mui/material';
import WifiIcon from 'assets/icons/WifiIcon';
import Menu from 'components/Common/HeaderMenu';
import PincodeInput from 'components/Common/PincodeInput';
import { MENU_TYPES } from 'constants/common';

const WifiMenu = () => (
  <Menu
    label="Wifi pin"
    icon={<WifiIcon />}
    color="primary"
    type={MENU_TYPES.WIFI}
  >
    <Typography
      variant="h2"
      sx={{
        marginBottom: '24px',
      }}
    >
      Wifi pincode
    </Typography>
    <PincodeInput />
  </Menu>
);

export default WifiMenu;
