import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiPickersPopper: {
    styleOverrides: {
      root: {
        paddingTop: '4px',
        '& .MuiPaper-root': {
          border: `solid 1px ${theme.palette.secondary.main}`,
          borderRadius: '8px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          color: theme.palette.text.primary,
          '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected, .PrivatePickersYear-yearButton.Mui-selected':
            {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightBold,
            },
          '& .MuiDayPicker-weekDayLabel, .MuiPickersCalendarHeader-label': {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
          },
          '& .MuiPickersArrowSwitcher-button:not(.Mui-disabled), .MuiPickersCalendarHeader-switchViewButton':
            {
              color: theme.palette.text.primary,
            },
        },
      },
    },
  },
});
