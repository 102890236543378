import { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';

import Section from 'components/Section';
import TextInput from 'components/Form/TextInput';

const ChangePasswordForm = () => {
  const theme = useTheme();
  const {
    formState,
    formState: { errors },
    getFieldState,
    control,
    trigger,
  } = useFormContext();

  const newPassword = useWatch({
    control,
    name: 'newPassword',
  });

  useEffect(() => {
    if (newPassword && newPassword.length === 1 && !errors?.repeatNewPassword) {
      trigger('repeatNewPassword');
    }
  }, [newPassword]);

  return (
    <Section title="Password" id="password">
      <Typography
        fontWeight={theme.typography.fontWeightBold}
        marginBottom="32px"
      >
        To change your password, type your current password:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Current password"
            name="currentPassword"
            type="password"
          />
          {getFieldState('currentPassword', formState).isDirty && (
            <>
              <TextInput
                label="New password"
                name="newPassword"
                type="password"
              />
              <TextInput
                label="Repeat new password"
                name="repeatNewPassword"
                type="password"
              />
            </>
          )}
        </Grid>
      </Grid>
    </Section>
  );
};

export default ChangePasswordForm;
