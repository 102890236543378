import routes from 'utils/routes';

export const TABS_HELP = [
  {
    value: routes.main.visitors,
    label: 'Visitors',
  },
  {
    value: routes.main.deliveries,
    label: 'Deliveries',
  },
];
