import { Swiper } from 'swiper/react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const StyledSwiper = styled(Swiper)`
  padding: 20px 10px;
  margin-left: -10px;
  margin-top: -20px;
  margin-bottom: -20px;

  .swiper-slide {
    height: auto;
  }
`;

export const NavigationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.orange[800]}`,
  width: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  '.MuiSvgIcon-root': {
    color: 'transparent',
  },
}));
