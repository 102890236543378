import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: theme.palette.text.main,
      },
      separator: {
        color: theme.palette.text.content,
        fontWeight: theme.typography.fontWeightRegular,
      },
      li: {
        color: theme.palette.text.content,
        a: {
          fontWeight: theme.typography.fontWeightRegular,
          '&:hover': {
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'none',
          },
        },
        p: {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.content,
        },
      },
    },
  },
});
