export const FORM_OPTIONS = {
  atmosphere: ['Focus', 'Social', 'Relaxing', 'Collab'],
  location: ['Central', 'North', 'East', 'South', 'West'],
  type: ['Favourites'],
};

export const DEFAULT_VALUES = { deskAvailabilityFilter: '' };

export enum FILTERS {
  ALL = 'All',
  FAVOURITES = 'Favourites',
}
