/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const AcceptIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32px"
    height="32px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#FABE79" />
    <path
      d="M23.468 10.4 13.202 20.667 8.535 16"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default AcceptIcon;
