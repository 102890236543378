import SelectField from 'components/Form/SelectField';
import Section from 'components/Section';
import { OPTIONS } from 'constants/signUp';

const TellUsMoreForm = () => (
  <Section title="Tell us more">
    <SelectField
      name="tellUsMore"
      label="How did you hear about Work.Life?"
      options={OPTIONS.tellUsMore}
    />
  </Section>
);

export default TellUsMoreForm;
