import { Box, Typography, useTheme } from '@mui/material';
import { ChipLive } from './style';

const CapacityLabel = ({ isAnimated }: { isAnimated?: boolean }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <ChipLive
        label="Live"
        variant="filled"
        color="error"
        isAnimated={isAnimated}
      />
      <Typography fontWeight={theme.typography.fontWeightBold} marginLeft="4px">
        Capacity
      </Typography>
    </Box>
  );
};

CapacityLabel.defaultProps = {
  isAnimated: false,
};

export default CapacityLabel;
