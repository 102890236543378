import { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';
import SelectField from 'components/Form/SelectField';
import { countries } from 'components/Profile/helpers';
import { ADDRESS_FIELDS } from './AddressForm';

const BILING_FIELDS = [
  'billingAddress',
  'billingPostCode',
  'billingCountryId',
  'billingEmail',
  'billingCityName',
];

const BillingForm = () => {
  const theme = useTheme();
  const { setValue, resetField, control } = useFormContext();
  const [copyAddress, setCopyAddress] = useState(false);

  const addressValues = useWatch({ control, name: ADDRESS_FIELDS });

  const handleChange = () => {
    BILING_FIELDS.map((field) => resetField(field));
    setCopyAddress((isChecked) => !isChecked);
  };

  useEffect(() => {
    if (copyAddress) {
      const values = [...addressValues.slice(0, 3), ...addressValues.slice(4)];

      BILING_FIELDS.filter((el) => el !== 'billingEmail').map((field, index) =>
        setValue(field, values[index], {
          shouldDirty: true,
        }),
      );
    }
  }, [copyAddress, ...addressValues]);

  return (
    <Section title="Billing address" id="billing">
      <FormControlLabel
        control={<Checkbox checked={copyAddress} onChange={handleChange} />}
        label={
          <Typography fontWeight={theme.typography.fontWeightBold}>
            Same as address
          </Typography>
        }
        sx={{
          marginBottom: '32px',
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Address"
            name={BILING_FIELDS[0]}
            type="text"
            disabled={copyAddress}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Postcode"
            name={BILING_FIELDS[1]}
            type="text"
            disabled={copyAddress}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectField
            name={BILING_FIELDS[2]}
            label="Country"
            options={countries}
            disabled={copyAddress}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Send invoices to"
            name={BILING_FIELDS[3]}
            type="text"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="City"
            name={BILING_FIELDS[4]}
            type="text"
            disabled={copyAddress}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default BillingForm;
