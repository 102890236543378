import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useQuery } from 'react-query';

import LoadingSpinner from 'components/Common/LoadingSpinner';
import DateNavigation from 'components/Desks/DateNavigation';
import { mapAvailability } from 'components/Desks/helpers';
import CapacityLabel from 'components/Desks/CapacityLabel';
import { Link } from 'components/Link';
import routes from 'utils/routes';
import { useAppContext } from 'context/AppContext';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

import { API, APIRoutes } from 'utils/api';
import { useAuth } from 'context/AuthContext';
import Favourite from 'components/Desks/Favourite';
import { FILTERS } from 'constants/desks';
import { AvailibilityCircle } from './style';

const Cell = ({
  children,
  width,
  align,
  item,
}: {
  children: React.ReactNode;
  width: number;
  align: string;
  item?: boolean;
}) => (
  <Box
    sx={{
      flexBasis: `${width}%`,
      textAlign: align,
      fontWeight: 700,
      py: item ? '20px' : 0,
      borderRight: item ? '1.5px solid #D9DFEF' : 'none',
      height: item ? '87px' : 'none',
      display: item ? 'flex' : 'block',
      alignItems: item ? 'center' : 'unset',
      position: 'relative',

      '&:after': {
        content: item ? "''" : 'none',
        position: item ? 'absolute' : 'none',
        width: item ? '90%' : 'none',
        height: item ? '1.5px' : 'none',
        backgroundColor: item ? '#D9DFEF' : 'none',
        left: item ? '50%' : 'none',
        bottom: '0px',
        transform: item ? 'translateX(-50%)' : 'none',
      },
    }}
  >
    {children}
  </Box>
);

Cell.defaultProps = {
  item: false,
};

type TableProps = {
  filter: string;
  favourites: number[];
  setFavourites: React.Dispatch<React.SetStateAction<number[]>>;
};

const Table = ({ filter, favourites, setFavourites }: TableProps) => {
  const { openModal } = useAppContext();
  const theme = useTheme();
  const currentTime = moment();

  const [date, setDate] = useState<moment.Moment>(currentTime);

  const [searchParams, setSearchParams] = useSearchParams();

  const { isFromLondon } = useAuth();

  useEffect(() => {
    const paramDate = moment(searchParams.get('date'), 'DD-MM-YYYY');
    setDate(paramDate.isValid() ? paramDate : currentTime);
  }, []);

  useEffect(() => {
    if (date) {
      setSearchParams(
        { filter, date: date.format('DD-MM-YYYY') },
        { replace: true },
      );
    }
  }, [date, filter]);

  const getCapacities = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.infogrid.capacities(date.toISOString(), filter),
    );
    return data;
  };

  const { isSuccess, data: capacities } = useQuery<CapacityProps[]>(
    ['capacity', filter, date],
    () => getCapacities(),
  );

  const renderMainSection = useMemo(() => {
    if (isSuccess) {
      let availableSpaces = [...capacities];
      if (filter === FILTERS.FAVOURITES) {
        availableSpaces = availableSpaces.filter((space: CapacityProps) =>
          favourites.includes(space.spaceId),
        );
      }

      return availableSpaces.map((data) => (
        <Box
          key={data.space}
          display="flex"
          alignItems="center"
          sx={{
            '&:last-of-type': { '& div:after ': { all: 'unset' } },
          }}
        >
          <Cell width={19.2} align="left" item>
            <Box display="flex" alignItems="center" gap="8px">
              {isFromLondon && (
                <Favourite
                  id={data.spaceId}
                  favourites={favourites}
                  setFavourites={setFavourites}
                />
              )}
              {data.name.replace('Work.Life ', '')}
            </Box>
          </Cell>
          <Cell width={16.9} align="center" item>
            <AvailibilityCircle
              color={mapAvailability(data.current).color}
              margin="auto"
            />
          </Cell>
          <Cell width={16.9} align="center" item>
            <AvailibilityCircle
              color={mapAvailability(data.peak).color}
              margin="auto"
            />
          </Cell>
          <Cell width={16.9} align="center" item>
            <AvailibilityCircle
              color={mapAvailability(data.weekAvg).color}
              margin="auto"
            />
          </Cell>
          <Cell width={30.2} align="right" item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="42px"
              sx={{
                '& p': { fontWeight: 700 },
              }}
              width="100%"
            >
              <Link
                to={`${
                  routes.main.promotions
                }?space=${data.spaceId.toString()}`}
                color="#1D1954"
                fontWeight={theme.typography.fontWeightBold}
              >
                See perks
              </Link>
              <Button
                onClick={() => {
                  openModal(
                    <MeetingRoomModal
                      initialValues={{
                        location: data.spaceId.toString(),
                      }}
                    />,
                  );
                }}
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  textDecoration: 'none',
                  px: '24px',
                  maxHeight: '60px',
                  minHeight: '46px',
                  lineHeight: '16px',
                }}
              >
                Book a room
              </Button>
            </Box>
          </Cell>
        </Box>
      ));
    }

    return <LoadingSpinner minHeight="276px" />;
  }, [isSuccess, date, filter, favourites]);

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <Box display="flex" alignItems="center" px="24px" py="16px">
        <Cell width={19.2} align="left">
          Workspace
        </Cell>
        <Cell width={16.9} align="center">
          {moment(date).isSame(currentTime, 'date') ? (
            <Box display="flex" justifyContent="center">
              <CapacityLabel />
            </Box>
          ) : (
            <Typography fontWeight={700}>This time</Typography>
          )}
        </Cell>
        <Cell width={16.9} align="center">
          Peak daily
        </Cell>
        <Cell width={16.9} align="center">
          Average weekly
        </Cell>
        <Cell width={30.2} align="right">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="12px"
          >
            <DateNavigation currentDate={date} setCurrentDate={setDate} />
          </Box>
        </Cell>
      </Box>
      <Box
        borderTop="1.5px solid #D9DFEF"
        mx="24px"
        sx={{
          '& div:last-of-type': {
            borderRight: 'none',

            '&:after': {
              right: 0,
              width: '96%',
              transform: 'translate(-49%)',
            },
          },

          '& div:first-of-type': {
            '&:after': {
              left: 0,
              width: '96%',
              transform: 'translate(0%)',
            },
          },
        }}
        pb="24px"
      >
        {renderMainSection}
      </Box>
    </Box>
  );
};

export default Table;
