import React from 'react';
import { Grid, Box } from '@mui/material';
import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import Section from 'components/Section';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import { useAuth } from 'context/AuthContext';
import CardYourProfile from './DashboardCards/CardYourProfile';
import CardInvoices from './DashboardCards/CardInvoices';
import CardPlans from './DashboardCards/CardPlans';
import CardMetrics from './DashboardCards/CardMetrics';
import Profile from './Profile';
import CardCreditDebitCard from './CardCreditDebitCard';
import Invoices from './Invoices';
import Plans from './Plans';
import Metrics from './Metrics';
import CardMyTeam from './DashboardCards/CardMyTeam';
import MyTeam from './Team/MyTeam';

const breadcrumbsConfig = () => [{ label: 'Account dashboard' }];

type AccountProps = {
  tab: string;
};

const AccountDashboard = ({ tab }: AccountProps) => {
  const { user } = useAuth();

  return (
    <>
      {tab === 'Dashboard' && (
        <>
          <SectionHeader
            title="Account"
            hideBackButton
            path={breadcrumbsConfig()}
            tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
            elements={
              <Box width="250px">
                <PincodeInput lightBackground />
              </Box>
            }
          />
          <Section>
            <Grid container spacing={2} mt="27px">
              <CardYourProfile label="Your profile" />
              <CardInvoices label="Your invoices" />
              <CardPlans label="Plans & Benefits" />
              <CardMetrics label="Metrics" />
              {user?.isTeamLeader && <CardMyTeam label="Your team" wide />}
            </Grid>
          </Section>
        </>
      )}

      {tab === 'Profile' && <Profile />}
      {tab === 'Invoices' && <Invoices />}
      {tab === 'Card Payments' && user?.allowCreditCardPayment && (
        <CardCreditDebitCard />
      )}
      {tab === 'Plans & Benefits' && <Plans />}
      {tab === 'Metrics' && <Metrics />}
      {tab === 'My team' && user?.isTeamLeader && <MyTeam />}
      <Box textAlign="center" marginBottom={5}>
        <ScrollTopButton />
      </Box>
    </>
  );
};
export default AccountDashboard;
