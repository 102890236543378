import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from 'react-query';
import moment from 'moment';

import { API, APIRoutes } from 'utils/api';
import { Link } from 'components/Link';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import routes from 'utils/routes';
import { REFETCH_INTERVAL } from 'constants/chart';
import { AvailibilityCircle } from 'pages/Desks/style';
import { useAppContext } from 'context/AppContext';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';
import { useAuth } from 'context/AuthContext';
import Chart from './Chart';
import { mapAvailability } from './helpers';
import DateNavigation from './DateNavigation';
import CapacityLabel from './CapacityLabel';
import Favourite from './Favourite';

export type ChartCardProps = {
  spaceId: number;
  favourites: number[];
  setFavourites: React.Dispatch<React.SetStateAction<number[]>>;
};

const ChartCard = ({ spaceId, favourites, setFavourites }: ChartCardProps) => {
  const { openModal } = useAppContext();
  const today = moment();
  const [currentDate, setCurrentDate] = useState(today);
  const theme = useTheme();
  const { spaces, isFromLondon } = useAuth();
  const spaceName = spaces.find((space) => space.id === spaceId)?.shortName;

  const getCapacity = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.infogrid.capacity(
        spaceId.toString(),
        currentDate.toISOString(),
      ),
    );
    return data;
  };

  const { data: availability, isSuccess } = useQuery<CapacityProps>(
    [`availability-${spaceId}`, currentDate],
    getCapacity,
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  const oddHour = useMemo(() => {
    if (today.hour() >= 18) {
      return 17;
    }
    if (today.hour() <= 8) {
      return 9;
    }
    return today.hour() + (today.hour() % 2 ? 0 : 1);
  }, [today]);

  const renderMainSection = useMemo(() => {
    if (isSuccess) {
      const { hourly: data } = availability;
      const currentHour = data.find(
        (item: { date: string }) =>
          moment(item.date).parseZone().get('hour') === oddHour,
      );
      const currentHourAvailability = mapAvailability(currentHour.occupancy);
      const peakAvailability = mapAvailability(availability.peak);

      return (
        <Box display="flex" padding="24px" justifyContent="space-between">
          <Box
            width="100%"
            height="276px"
            sx={{ '& canvas': { width: '100% !important' } }}
          >
            <Chart data={data} color={currentHourAvailability.color} />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginLeft: '24px',
              marginRight: '20px',
            }}
          />
          <Box>
            <Box marginBottom="15px">
              {currentDate.isSame(today, 'day') ? (
                <CapacityLabel />
              ) : (
                <Typography fontWeight={theme.typography.fontWeightBold}>
                  This time
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center" marginBottom="32px">
              <AvailibilityCircle color={currentHourAvailability.color} />
              <Typography
                fontSize="smallFontSize"
                fontWeight={theme.typography.fontWeightBold}
                marginLeft="8px"
              >
                {currentHourAvailability.message}
              </Typography>
            </Box>
            <Typography
              fontWeight={theme.typography.fontWeightBold}
              marginBottom="15px"
            >
              Peak capacity
            </Typography>
            <Box display="flex" alignItems="center" marginBottom="32px">
              <AvailibilityCircle color={peakAvailability.color} />
              <Typography
                fontSize="smallFontSize"
                fontWeight={theme.typography.fontWeightBold}
                marginLeft="8px"
              >
                {peakAvailability.message}
              </Typography>
            </Box>
            <Button
              onClick={() => {
                openModal(
                  <MeetingRoomModal
                    initialValues={{
                      location: spaceId.toString(),
                    }}
                  />,
                );
              }}
              variant="contained"
              sx={{
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.main,
                width: '144px',
                height: '44px',
                marginBottom: '17px',
                '&:hover': {
                  color: '#FFFFFF',
                },
              }}
            >
              Book a room
            </Button>
            <Box>
              <Link
                to={`${
                  routes.main.promotions
                }?space=${availability.spaceId.toString()}`}
                color="#1D1954"
                fontWeight={theme.typography.fontWeightBold}
                fontSize={theme.typography.smallFontSize.toString()}
              >
                See perks
              </Link>
            </Box>
          </Box>
        </Box>
      );
    }

    return <LoadingSpinner minHeight="276px" />;
  }, [isSuccess, currentDate]);

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box display="flex" alignItems="center" padding="24px" gap="8px">
        {isFromLondon && spaceName !== undefined && (
          <Favourite
            id={spaceId}
            favourites={favourites}
            setFavourites={setFavourites}
          />
        )}
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {spaceName}
        </Typography>
        <Box marginLeft="auto" display="flex" alignItems="center">
          <DateNavigation
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
        </Box>
      </Box>
      <Divider />
      {renderMainSection}
    </Card>
  );
};

export default ChartCard;
