/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  Fade,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import theme from 'styles/theme';

interface DiscountInputProps {
  discountCode: string;
  setDiscountCode: (value: string) => void;
  discount: Discount;
}

function DiscountInput({
  discountCode,
  setDiscountCode,
  discount,
}: DiscountInputProps) {
  const [isOpen, setIsOpen] = useState(!!discountCode);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (discountCode) {
      setValue('');
    }
  }, [discountCode]);

  return (
    <Stack spacing={1}>
      <Link onClick={() => setIsOpen((wasOpen) => !wasOpen)}>
        Enter discount code
      </Link>
      <Fade in={isOpen} unmountOnExit>
        <Stack spacing={1}>
          <Box display="flex" gap={2} alignItems="center">
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type="text"
              variant="filled"
              name="discountCode"
              label="Discount code"
              fullWidth
            />
            <Button
              disabled={!value}
              sx={{
                width: 'fit-content',
                borderRadius: '10px',
                padding: '13px 15px',
                maxHeight: '56px',
                backgroundColor: theme.palette.primary.main,
                flexShrink: 0,
              }}
              variant="contained"
              onClick={() => {
                setDiscountCode(value);
              }}
            >
              Apply
            </Button>
          </Box>
          {discount && (
            <Typography
              fontWeight={700}
              color={discount.isCodeValid ? 'success.main' : 'error.main'}
            >
              {discount.isCodeValid
                ? `Discount code applied${
                    discount?.description && ` (${discount.description})`
                  }`
                : `Invalid discount code (${discountCode})`}
            </Typography>
          )}
        </Stack>
      </Fade>
    </Stack>
  );
}

export default DiscountInput;
