import { MenuItem, Typography, useTheme } from '@mui/material';
import PinIcon from 'assets/icons/PinIcon';
import DropdownMenu from './DropdownMenu';

export type LocationDropdownProps = {
  items: SpaceProps[];
  onSelect: (id: number) => void;
  currentId: number;
};

const LocationDropdown = ({
  items,
  onSelect,
  currentId,
}: LocationDropdownProps) => {
  const theme = useTheme();
  const currentLocation = items.find((item) => item.id === currentId);

  return (
    <DropdownMenu
      type="location"
      label={currentLocation?.shortName || 'Filter location'}
      color="secondary"
      icon={
        <PinIcon
          sx={{
            width: '16px',
            height: '16px',
            fill: 'rgba(247, 102, 102, 0.3)',
          }}
        />
      }
      items={items.map((item: SpaceProps) => (
        <MenuItem key={item.id} onClick={() => onSelect(item.id)}>
          <Typography
            sx={{
              color: theme.palette.blue[900],
              fontWeight:
                item.id === currentId ? 'fontWeightBold' : 'fontWeightRegular',
            }}
          >
            {item.id === currentId && (
              <PinIcon
                sx={{
                  fill: theme.palette.success.main,
                  height: '16px',
                  width: '16px',
                  marginRight: '6px',
                }}
              />
            )}
            {item.shortName}
          </Typography>
        </MenuItem>
      ))}
    />
  );
};

export default LocationDropdown;
