/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

import CardItem from 'components/Common/CardItem';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import { useAuth } from 'context/AuthContext';
import LoadingSpinner from 'components/Common/LoadingSpinner';

const EventsListing = ({
  categories,
  isSuccessCategories,
  categoryId,
  setCategoryId,
}: {
  categories: any;
  isSuccessCategories: boolean;
  categoryId: number;
  setCategoryId: (id: number) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentSpaceId, setUserSpaceId, isValidSpaceId } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  const getEvents = async ({ pageParam = 1 }) => {
    const { data } = await API.get(
      APIRoutes.events.index(
        LIMITS.LISTING,
        pageParam,
        currentSpaceId,
        categoryId,
      ),
    );
    return data;
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isSuccess, isFetching } =
    useInfiniteQuery(
      ['events-listing', currentSpaceId, categoryId],
      getEvents,
      {
        getNextPageParam: (lastPage, pages) =>
          pages.length < lastPage?.totalPages ? pages.length + 1 : undefined,
        enabled: isInitialized,
      },
    );

  useEffect(() => {
    const paramSpace = searchParams.get('space');
    if (paramSpace && isValidSpaceId(paramSpace)) {
      setUserSpaceId(+paramSpace);
    }
    const paramCategory = searchParams.get('category');
    if (
      paramCategory &&
      categories?.some(
        (category: { id: number }) => category.id.toString() === paramCategory,
      )
    ) {
      setCategoryId(+paramCategory);
    }
    setIsInitialized(true);
  }, [isSuccessCategories]);

  useEffect(() => {
    setSearchParams(
      `?${currentSpaceId ? `space=${currentSpaceId}&` : ''}${
        categoryId ? `category=${categoryId}&` : ''
      }`,
      { replace: true },
    );
  }, [currentSpaceId, categoryId]);

  if (isSuccessCategories) {
    return (
      <Box>
        {isLoading ? (
          <LoadingSpinner minHeight="500px" />
        ) : isSuccess ? (
          isEmpty(data.pages[0]?.data) ? (
            <Box sx={{ opacity: 0.6, textAlign: 'center' }}>
              No events available
            </Box>
          ) : (
            <>
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill,minmax(312px, 1fr))"
                gap={2}
              >
                {data.pages.map((group) =>
                  group.data.map((event: EventProps) => (
                    <Box key={`event-${event.id}`}>
                      <CardItem
                        type="events"
                        summary={event.shortDescription}
                        title={event.title}
                        id={event.id}
                        created={event.startDate}
                        imageUrl={event.imageUrl}
                        location={event.address}
                        price={event.price}
                      />
                    </Box>
                  )),
                )}
              </Box>
              <LoadMoreButton
                hasNextPage={hasNextPage}
                onClick={fetchNextPage}
                isFetching={isFetching}
              />
            </>
          )
        ) : null}
      </Box>
    );
  }
  return null;
};

export default EventsListing;
