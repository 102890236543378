import { Stack, Typography, useTheme } from '@mui/material';
import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';

const TeamForm = () => {
  const theme = useTheme();

  return (
    <Section title="Team leader details">
      <Stack spacing={2}>
        <Typography fontWeight={theme.typography.fontWeightBold}>
          We consider the Team Leader to be the active member in charge on a day
          to day basis in the space. This may well be you (use your own details)
          but if not, please nominate the correct person.
        </Typography>
        <TextInput label="Team leader name" name="teamLeaderName" type="text" />
        <TextInput
          label="Team leader email address"
          name="teamLeaderEmail"
          type="text"
        />
        <TextInput
          label="Team leader phone number"
          name="teamLeaderPhone"
          type="text"
        />
      </Stack>
    </Section>
  );
};

export default TeamForm;
