import React from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import HelpAccordion from 'components/Help/HelpAccordion';

type FAQ = {
  q: string;
  a: string;
  aa: string;
};

const FAQSection = () => {
  const getFAQs = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.help.faq);
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery(['faqs'], () => getFAQs());

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    const FAQs: FAQ[] = [];
    Object.keys(data).forEach((key) => FAQs.push(...data[key]));
    return (
      <Box>
        <Typography variant="h2" marginBottom="32px">
          Frequently asked questions
        </Typography>
        <Box display="flex" flexDirection="column" gap="16px">
          {FAQs.map((faq, index) => (
            <HelpAccordion
              // eslint-disable-next-line react/no-array-index-key
              key={`faq-${index}`}
              summary={faq.q}
              details={faq.a}
              fullDetails={faq.aa}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

export default FAQSection;
