/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="53px"
    height="43px"
    viewBox="0 0 53 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.958 43.125v-4.583a9.166 9.166 0 0 0-9.167-9.167H10.458a9.167 9.167 0 0 0-9.167 9.167v4.583m50.417 0v-4.583a9.166 9.166 0 0 0-6.875-8.87m-9.167-27.5a9.166 9.166 0 0 1 0 17.761m-6.875-8.891a9.167 9.167 0 1 1-18.333 0 9.167 9.167 0 1 1 18.333 0Z"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default SvgComponent;
