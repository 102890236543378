import React, { ReactNode } from 'react';
import { Backdrop, Box, Button, ButtonProps, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseMenuIcon from 'assets/icons/CloseMenuIcon';
import { useAppContext } from 'context/AppContext';
import MotionBox from 'components/MotionBox';

export type HeaderMenuProps = {
  label: string;
  icon: JSX.Element;
  color: ButtonProps['color'];
  children: ReactNode;
  type: string;
};

const HeaderMenu = ({
  label,
  type,
  icon,
  color,
  children,
}: HeaderMenuProps) => {
  const theme = useTheme();
  const { anchorEl, setAnchorEl } = useAppContext();

  const open = anchorEl[`${type}`];
  const isWifiMenu = label === 'Wifi pin';

  const variants = {
    closed: {
      x: '100%',
      y: '-100%',
    },
    open: {
      x: '125px',
      y: isWifiMenu ? '-333px' : '-103px',
      transition: { stiffness: 1000 },
    },
  };

  const handleClick = (elType: string) => {
    setAnchorEl({ ...anchorEl, [elType]: true });
  };

  const handleClose = (elType: string) => {
    setAnchorEl({ ...anchorEl, [elType]: false });
  };

  return (
    <>
      <Button
        id="dropdown-button"
        aria-controls={open ? 'dropdown-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => handleClick(type)}
        variant="contained"
        color={color}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          ...(color === 'primary' && {
            backgroundColor: theme.palette.blue[600],
          }),
        }}
        startIcon={icon}
      >
        {label}
      </Button>
      <Backdrop open={open}>
        <MotionBox
          initial={false}
          animate={open ? 'open' : 'closed'}
          variants={variants}
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            paddingRight: '125px',
            paddingTop: isWifiMenu ? '333px' : '103px',
            width: '685px',
            height: '685px',
            backgroundColor: `${color}.main`,
            color: `${color}.contrastText`,
            borderRadius: '50%',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              paddingTop: '89px',
              paddingRight: '92px',
              paddingLeft: '141px',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => handleClose(type)}
              sx={{
                width: '40px',
                height: '40px',
                position: 'absolute',
                top: '32px',
                right: '40px',
                background: '#FFFFFF',
                border: `solid 1px ${
                  color === 'primary'
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main
                }`,
                '&:hover': {
                  background: '#FFFFFF',
                },
              }}
            >
              <CloseMenuIcon />
            </IconButton>
            {children}
          </Box>
        </MotionBox>
      </Backdrop>
    </>
  );
};

export default HeaderMenu;
