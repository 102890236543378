import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvailibilityCircle = styled(Box, {
  shouldForwardProp: (prop: string) => !['color'].includes(prop as string),
})<{
  color: string;
}>(({ color }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: color,
}));
