/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo } from 'react';
import {
  useForm,
  SubmitHandler,
  FormProvider,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid, Link } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import DetailsForm from 'components/Profile/DetailsForm';
import AddressForm from 'components/Profile/AddressForm';
import CompanyForm from 'components/Register/CompanyForm';
import TeamForm from 'components/Register/TeamForm';
import TellUsMoreForm from 'components/Register/TellUsMoreForm';
import InductionForm from 'components/Register/InductionForm';
import PasswordForm from 'components/Register/PasswordForm';
import routes from 'utils/routes';
import BoxWithPlan from 'components/SignUp/BoxWithPlan';
import { OPTIONS } from 'constants/signUp';
import CheckBox from 'components/Form/CheckBox';
import { API, APIRoutes, setAuthenticationToken } from 'utils/api';
import Loader from 'components/Loader';
import { KEYS, setItem } from 'utils/cache';
import { PLAN } from 'constants/plans';
import { useToast } from 'context/ToastContext';

type FormData = {
  fullName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  dateOfBirth: Date | undefined;
  salutation: string | undefined;
  address: string | undefined;
  postCode: string | undefined;
  countryId: string | undefined;
  companyName: string | undefined;
  cityName: string | undefined;
  onlyBusiness: boolean | undefined;
  companyRegistrationNumber: string | undefined;
  teamLeaderName: string | undefined;
  teamLeaderEmail: string | undefined;
  teamLeaderPhone: string | undefined;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  tellUsMore: string | undefined;
  inductionSpaceId: number | undefined;
  inductionDate: string | undefined;
  inductionTime: string | undefined;
  generalTermsAcceptedOnline: boolean | undefined;
};

const wrapperSchema = (bankHolidays: string[] | undefined) =>
  yup
    .object({
      fullName: yup
        .string()
        .required('Full name is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      phone: yup
        .string()
        .required('Phone is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      email: yup
        .string()
        .email('Invalid e-mail')
        .required('E-mail is required'),
      dateOfBirth: yup.date().nullable().typeError('Please provide full date'),
      salutation: yup
        .string()
        .required(
          'What should we call you? You can type ‘John’ or ‘Dr. White’, for example.',
        )
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      address: yup
        .string()
        .required('Address is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      postCode: yup
        .string()
        .required('Postcode is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      countryId: yup.string(),
      companyName: yup.string(),
      cityName: yup
        .string()
        .required('City is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      onlyBusiness: yup.boolean(),
      companyRegistrationNumber: yup.string(),
      teamLeaderName: yup.string(),
      teamLeaderEmail: yup.string(),
      teamLeaderPhone: yup.string(),
      password: yup
        .string()
        .matches(
          /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Invalid password',
        )
        .required('Password is required'),
      passwordConfirmation: yup
        .string()
        .required('Password confirmation is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
      tellUsMore: yup.string(),
      inductionSpaceId: yup.number().required('Induction space is required'),
      inductionDate: yup
        .string()
        .test(
          'cant-above-3months',
          'Induction date cannot be more than 3 months on advance,',
          (d) => {
            const threeMonths = moment().add(3, 'months');
            return !moment(d).isAfter(threeMonths);
          },
        )
        .test(
          'cant-be-weekend',
          'Induction date cannot be set on Saturday on Sunday.',
          (d) => {
            const weekday = moment(d).isoWeekday();

            return !(weekday === 6 || weekday === 7);
          },
        )
        .test(
          'cant-be-before-borough-launch',
          'Borough is not available before 8th July.',
          (d, context) => {
            const spaceId = context.parent.inductionSpaceId;
            const inductionDate = moment(d);

            if (
              spaceId === 1415144936 &&
              inductionDate.isBefore(moment('2024-07-08', 'YYYY-MM-DD'))
            )
              return false;

            return true;
          },
        )
        .test(
          'cant-be-today',
          'Induction date cannot be set to today.',
          (d) => {
            const isTodayBlocked =
              moment(d).isSame(moment(), 'day') &&
              moment().isSameOrAfter(moment({ hour: 15, minute: 0 }));
            return !isTodayBlocked;
          },
        )
        .test('cant-be-past', 'Induction date cannot be in the past.', (d) => {
          const isInThePast = moment(d).isBefore(moment(), 'day');
          return !isInThePast;
        })
        .test(
          'cant-be-bank',
          'Induction date cannot be on bank holiday.',
          (d) => {
            const isBankHoliday = bankHolidays?.includes(
              moment(d).format('YYYY-MM-DD'),
            );
            return !isBankHoliday;
          },
        )
        .test('must-be-full-date', 'Please provide full date', (d) => {
          const isNotFullDate = d === 'Invalid date';
          return !isNotFullDate;
        })
        .typeError('Induction date is required')
        .required(),
      inductionTime: yup.string().test({
        name: 'induction-time-check',
        test: (value, ctx) => {
          const { inductionDate } = ctx.parent;
          const isToday = moment(inductionDate).isSame(moment(), 'day');

          if (isToday) {
            if (
              (moment().isSameOrAfter(moment({ hour: 9, minute: 0 })) &&
                value === '9:00am') ||
              (moment().isSameOrAfter(moment({ hour: 15, minute: 0 })) &&
                value === '3:00pm')
            ) {
              return ctx.createError({
                message:
                  'Selected time is in the past. Change either time or date.',
                path: 'inductionTime',
              });
            }
            return true;
          }
          return true;
        },
      }),
      generalTermsAcceptedOnline: yup
        .boolean()
        .oneOf([true], 'Acceptance of terms and conditions is required'),
    })
    .required();

const getSpaces = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.spaces);
  return data;
};
const getBankHolidays = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bankHolidays);
  return data;
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { snack, setSnack } = useToast();
  const tariffGuid = searchParams.get('tariff_guid') || Object.keys(PLAN)[0];
  const { spaceId } = PLAN[tariffGuid];

  const {
    isLoading,
    isSuccess,
    data: spaces,
  } = useQuery<SpaceProps[]>('spaces', () => getSpaces());

  const {
    isLoading: isLoadingBankHolidays,
    isSuccess: bankHolidaysSuccess,
    data: bankHolidays,
  } = useQuery<string[]>('bankHolidays', () => getBankHolidays());

  const { mutate: signUp } = useMutation(
    (payload: any) => API.post(APIRoutes.auth.signUp, payload),
    {
      mutationKey: 'sign-up',
      onSuccess: ({ data }, payload) => {
        setItem(KEYS.INDUCTION_DATE, payload?.inductionDate);
        setItem(KEYS.INDUCTION_TIME, payload?.inductionTime);

        setAuthenticationToken(data?.token);
        setItem(KEYS.REFRESH_TOKEN, data?.refreshToken);

        navigate(routes.auth.signUp.plan({ tariff_guid: tariffGuid }));
      },
      onError: (err: { message: string }) => {
        setSnack({
          ...snack,
          message: `Unable to sign up ${
            err?.message.toLocaleLowerCase() || ''
          }`,
          color: 'white',
          open: true,
          type: 'error',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{
                color: 'white',
                width: '20px',
                height: '20px',
              }}
            />
          ),
        });
      },
    },
  );

  const initDate = useMemo(() => {
    const today = moment.utc().startOf('day');
    let daysRemaining = 1;

    while (daysRemaining > 0) {
      today.add(1, 'day');
      if (!(today.isoWeekday() === 6 || today.isoWeekday() === 7)) {
        daysRemaining -= 1;
      }
    }
    return today.toISOString();
  }, []);

  const params = Object.fromEntries(new URLSearchParams(searchParams));

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(wrapperSchema(bankHolidays)),
    defaultValues: {
      ...params,
      countryId: '1220',
      inductionDate: initDate,
      inductionTime: OPTIONS.inductionTime[0].value,
      generalTermsAcceptedOnline: false,
    },
  });
  const { handleSubmit, resetField, trigger, control } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      ...data,
      tariffGuid,
      dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
      inductionDate: moment(data?.inductionDate).toISOString(),
    };
    signUp(payload);
  };

  useEffect(() => {
    if (spaceId) {
      resetField('inductionSpaceId', {
        defaultValue: spaces?.find((space) => space.id === spaceId)?.id,
      });
    }
  }, [spaces]);
  const inductionDate = useWatch({
    control,
    name: 'inductionDate',
  });

  useEffect(() => {
    trigger('inductionTime');
  }, [inductionDate]);

  if (isLoading || isLoadingBankHolidays) {
    return <Loader />;
  }

  if (isSuccess && bankHolidaysSuccess) {
    return (
      <Grid container spacing={4} justifyContent="space-between">
        <Grid
          item
          xs={12}
          lg={7}
          order={{ xs: 2, lg: 1 }}
          sx={{
            '& section': {
              background: 'unset',
              padding: '20px 0px',
            },
          }}
        >
          <FormProvider {...methods}>
            <form id="step-1" onSubmit={handleSubmit(onSubmit)} noValidate>
              <DetailsForm isSignUp />
              <AddressForm isSignUp />
              <CompanyForm />
              <TeamForm />
              <PasswordForm />
              <TellUsMoreForm />
              <InductionForm spaces={spaces} bankHolidays={bankHolidays} />
              <CheckBox
                label={
                  <>
                    Check this box to indicate you agree with our&nbsp;
                    <span onClick={(e) => e.stopPropagation()}>
                      <Link
                        href="https://work.life/memberterms/"
                        target="_blank"
                      >
                        terms and conditions
                      </Link>
                    </span>
                    .
                  </>
                }
                name="generalTermsAcceptedOnline"
              />
            </form>
          </FormProvider>
        </Grid>
        <Grid item xs={12} lg={5} order={{ xs: 1, lg: 2 }}>
          <BoxWithPlan tariffGuid={tariffGuid} />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default RegisterForm;
