import { Box, Card, CardContent, CardMedia } from '@mui/material';
import BookingSlotsSlider, { State } from './BookingSlotsSlider';
import RoomDetails from './RoomDetails';

const CalendarRow = ({
  index,
  room,
  selected,
  setSelected,
  isResultsView,
  isExternal,
  timeIndex,
  setTimeIndex,
  isPartTimeBooking,
}: {
  index: number;
  room: Room;
  selected: State;
  setSelected: React.Dispatch<React.SetStateAction<State>>;
  isResultsView: boolean;
  isExternal?: boolean;
  timeIndex: number;
  setTimeIndex: (index: number) => void;
  isPartTimeBooking: boolean;
}) => (
  <Box>
    <Card sx={{ overflow: 'visible' }}>
      <CardContent
        sx={{
          padding: '12px',
          height: '120px',
          maxHeight: '120px',
          display: 'flex',
          '&:last-child': {
            paddingBottom: '12px',
          },
        }}
      >
        <CardMedia
          component="img"
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.src = '/assets/images/placeholder.png';
          }}
          height="88"
          image={room.photo.icon.url}
          alt="Room image"
          sx={{ borderRadius: '4px', width: '88px', alignSelf: 'center' }}
        />
        <RoomDetails {...room} capacity={room?.additionalInfo?.capacity} />
        <BookingSlotsSlider
          showLabels={index === 0}
          room={room}
          selected={selected}
          setSelected={setSelected}
          isResultsView={isResultsView}
          isExternal={isExternal}
          timeIndex={timeIndex}
          setTimeIndex={setTimeIndex}
          isPartTimeBooking={isPartTimeBooking}
        />
      </CardContent>
    </Card>
  </Box>
);

CalendarRow.defaultProps = { isExternal: false };

export default CalendarRow;
