export const TABS_PROFILE = [
  {
    value: 'yourprofile',
    label: 'Your Profile',
  },
  {
    value: 'details',
    label: 'Personal details',
  },
  {
    value: 'address',
    label: 'Address',
  },
  {
    value: 'payment',
    label: 'Payment',
  },
  {
    value: 'billing',
    label: 'Billing',
  },
  {
    value: 'social',
    label: 'Social',
  },
  {
    value: 'password',
    label: 'Password',
  },
  {
    value: 'notifications',
    label: 'Notifications',
  },
];
