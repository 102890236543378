import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, FormHelperText } from '@mui/material';
import { useState } from 'react';
import ButtonContainer from 'components/ButtonContainer';
import ArrowIcon from 'assets/icons/ArrowIcon';
import TextInput from 'components/Form/TextInput';
import { useAuth } from 'context/AuthContext';
import CheckBox from 'components/Form/CheckBox';
import LoadingButton from 'components/Common/LoadingButton';

type FormData = {
  email: string;
  password: string;
  keep_logged_in: boolean;
};

const schema = yup
  .object({
    email: yup
      .string()
      .required('This field is required')
      .email('Incorrect e-mail'),
    password: yup.string().required('Password is required'),
  })
  .required();

export default function LoginForm() {
  const [apiError, setApiError] = useState<string>();

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { email: '', password: '', keep_logged_in: false },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, getFieldState, formState } = methods;
  const { signIn, signInIsLoading } = useAuth();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await signIn(data);
    } catch (error) {
      setApiError((error as Error).message ?? 'Invalid email or password');
    }
  };

  const inputsDirty =
    getFieldState('email', formState).isDirty &&
    getFieldState('password', formState).isDirty;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput label="Email" name="email" type="text" />
        <TextInput label="Password" name="password" type="password" />
        <Box display="flex" justifyContent="center">
          <CheckBox label="Keep me logged in" name="keep_logged_in" />
        </Box>

        <FormHelperText error sx={{ textAlign: 'center' }}>
          {apiError}
        </FormHelperText>

        <ButtonContainer justifyContent="center">
          <LoadingButton
            isLoading={signInIsLoading}
            sx={{
              justifyContent: 'space-between',
              height: '46px',
              width: '200px',
              mt: 2,
            }}
            endIcon={
              <ArrowIcon
                sx={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
            }
            variant="contained"
            type="submit"
            disabled={!inputsDirty}
          >
            Login
          </LoadingButton>
        </ButtonContainer>
      </form>
    </FormProvider>
  );
}
