import React from 'react';
import { Box, BoxProps } from '@mui/material';

function HintBox({ children, ...props }: BoxProps) {
  return (
    <Box
      sx={{
        padding: 4,
        borderRadius: '10px',
        background: (theme) => theme.palette.section.main,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export default HintBox;
