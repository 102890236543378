import {
  CardContent,
  CardActionArea,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CustomChip from 'components/Common/CustomChip';
import MotionCard from 'components/MotionCard';
import routes from 'utils/routes';

const BookingItem = ({
  _id,
  checkOut,
  resource,
  checkIn,
  nonClickable = false,
  isPartTimeBooking,
}: BookingProps) => {
  const parsedFrom = moment(checkIn);
  const parsedTo = moment(checkOut);

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        overflow: 'visible',
      }}
    >
      {parsedFrom.isSame(moment(), 'day') && (
        <CustomChip
          label="Today!"
          variant="filled"
          color="secondary"
          sx={{
            position: 'absolute',
            right: '11px',
            top: '-10px',
          }}
        />
      )}
      <CardActionArea
        component={nonClickable ? Box : Link}
        to={(isPartTimeBooking
          ? routes.main.upcomingOfficeDayBooking
          : routes.upcomingBooking)(_id)}
      >
        <CardContent
          sx={{
            padding: '14px 16px 14px 16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: '48px',
              textAlign: 'center',
              fontWeight: 'fontWeightBold',
              lineHeight: '22px',
            }}
          >
            {parsedFrom.format('ddd DD')}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: 'orange.800',
              mx: '8px',
            }}
          />
          <Box
            sx={{
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'fontWeightBold',
                lineHeight: '17px',
              }}
              noWrap
            >
              {resource.name}
            </Typography>
            <Typography sx={{ lineHeight: '17px' }}>
              {parsedFrom.format('HH:mm')} - {parsedTo.format('HH:mm')}
              {resource?.capacity &&
                ` | ${resource.capacity.toString()} ${
                  resource.capacity > 1 ? 'people' : 'person'
                }`}
            </Typography>
            {resource?.bookedBy && (
              <Typography
                sx={{
                  fontSize: 'smallFontSize',
                  lineHeight: '16.25px',
                  opacity: 0.65,
                }}
                color="text.content"
              >
                Booked by:&nbsp;{resource.bookedBy}
              </Typography>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

BookingItem.defaultProps = {};

export default BookingItem;
