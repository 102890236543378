import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { isEmpty } from 'lodash-es';

import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';
import { ACTIONS } from 'constants/help';
import ActionCard from 'components/Help/ActionCard';
import TicketCard from 'components/Help/TicketCard';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import EmptySection from 'components/Common/EmptySection';
import { useAppContext } from 'context/AppContext';
import { breakpoint } from 'styles/breakpoints';
import { LIMITS } from 'constants/common';
import LoadMoreButton from 'components/Common/LoadMoreButton';

const getTickets = async ({ pageParam = 1 }) => {
  const { data } = await API.get(
    APIRoutes.help.tickets(LIMITS.TICKETS, pageParam),
  );
  return data;
};

const getClosedTickets = async ({ pageParam = 1 }) => {
  const { data } = await API.get(
    APIRoutes.help.closedTickets(LIMITS.TICKETS, pageParam),
  );
  return data;
};

const TicketsSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isOpen } = useAppContext();

  const { refetch, isSuccess, data, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery(['tickets'], getTickets, {
      getNextPageParam: (lastPage, pages) =>
        pages.length < lastPage.totalPages ? pages.length + 1 : undefined,
    });

  const {
    refetch: refetchArchive,
    isSuccess: isSuccessArchive,
    data: archive,
    hasNextPage: hasNextPageArchive,
    fetchNextPage: fetchNextPageArchive,
    isFetching: isFetchingArchive,
  } = useInfiniteQuery(['closedTickets'], getClosedTickets, {
    getNextPageParam: (lastPage, pages) =>
      pages.length < lastPage.totalPages ? pages.length + 1 : undefined,
  });
  // refetch chat messages after modal was closed
  useEffect(() => {
    if (!isOpen) {
      refetch();
      refetchArchive();
    }
  }, [isOpen]);

  const renderTicketsList = useMemo(() => {
    if (isSuccess) {
      const totalUnread = data?.pages?.[0]?.totalUnread;

      return (
        <>
          <Badge badgeContent={totalUnread} color="error">
            <Typography
              variant="h2"
              fontWeight={theme.typography.fontWeightBold}
              marginBottom="32px"
              marginRight="8px"
            >
              Your open tickets
            </Typography>
          </Badge>

          {isEmpty(data.pages?.[0]?.data) ? (
            <EmptySection
              onClick={() => navigate(routes.main.help.raiseTicket)}
              missing="you have no open tickets"
              helperText="Need help?"
              label="Raise a ticket"
              icon={<img src="/assets/images/NoTickets.svg" alt="No tickets" />}
            />
          ) : (
            <Box display="flex" flexDirection="column" gap="16px">
              {data.pages.map((group) =>
                group.data.map((ticket: TicketProps) => (
                  <TicketCard {...ticket} key={`ticket-${ticket._id}`} />
                )),
              )}
            </Box>
          )}
        </>
      );
    }
    return <LoadingSpinner minHeight="300px" />;
  }, [data]);

  const renderClosedTickets = useMemo(() => {
    if (isSuccessArchive) {
      const totalUnread = archive?.pages?.[0]?.totalUnread;

      return (
        <div style={{ marginTop: 32 }}>
          <Badge badgeContent={totalUnread} color="error">
            <Typography
              variant="h2"
              fontWeight={theme.typography.fontWeightBold}
              marginBottom="32px"
              marginRight="8px"
            >
              Your closed tickets
            </Typography>
          </Badge>

          {isEmpty(archive.pages?.[0]?.data) ? (
            <EmptySection
              title="Yay!"
              missing="you have no closed tickets"
              icon={<img src="/assets/images/NoTickets.svg" alt="No tickets" />}
            />
          ) : (
            <Box display="flex" flexDirection="column" gap="16px">
              {archive.pages.map((group) =>
                group.data.map((ticket: TicketProps) => (
                  <TicketCard {...ticket} key={`ticket-${ticket._id}`} />
                )),
              )}
            </Box>
          )}
        </div>
      );
    }
    return <LoadingSpinner minHeight="300px" />;
  }, [archive]);

  const isSmallerResolution = useMediaQuery(breakpoint.xl);

  return (
    <Box
      display="flex"
      gap="28px"
      sx={{ flexDirection: isSmallerResolution ? 'row' : 'column' }}
    >
      <Box width="100%">
        <Typography
          variant="h2"
          fontWeight={theme.typography.fontWeightBold}
          marginBottom="32px"
        >
          Help
        </Typography>
        {ACTIONS.map((action) => (
          <ActionCard key={action.id} {...action} />
        ))}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box width="100%" maxWidth="312px">
        {renderTicketsList}
        <LoadMoreButton
          hasNextPage={hasNextPage}
          onClick={fetchNextPage}
          isFetching={isFetching}
          hideScrollToTopButton
          marginTop="32px"
        />
        {renderClosedTickets}
        <LoadMoreButton
          hasNextPage={hasNextPageArchive}
          onClick={fetchNextPageArchive}
          isFetching={isFetchingArchive}
          hideScrollToTopButton
          marginTop="32px"
        />
      </Box>
    </Box>
  );
};

export default TicketsSection;
