import { Box, Button, Grid, Icon, Typography, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery, useMutation } from 'react-query';
import { useEffect } from 'react';
import { map, compact } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import FormBanner from 'components/Profile/FormBanner';
import Section from 'components/Section';
import ImageIcon from 'assets/icons/ImageIcon';
import { useAppContext } from 'context/AppContext';
import AddTeamMemberModal from 'components/Team/AddTeamMemberModal';
import CircleWithPlus from 'assets/icons/PlusWithCircleIcon';
import Loader from 'components/Loader';
import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import CompanyForm from './CompanyForm';
import PictureForm from './PictureForm';
import MemberForm from './MemberForm';

type FormData = {
  logo: string;
  name: string;
  website: string;
  avatar: string;
  removePhoto: boolean;
  teamMembers: MemberProps[];
};

const breadcrumbsConfig = () => [{ label: 'My team' }];

const getTeamDetails = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.teams.index);
  return data;
};

const MyTeam = () => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  const { snack, setSnack } = useToast();
  const { state } = useLocation();
  const { restoreProfile } = useAuth();

  const shouldOpenModal = state?.shouldOpenModal;

  const {
    isSuccess,
    isLoading,
    refetch,
    data: team,
  } = useQuery<TeamProps>(['team'], () => getTeamDetails());

  const defaultValues = {
    name: '',
    website: '',
    avatar: '',
    teamMembers: [],
    removePhoto: false,
    id: -1,
  };

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  const { mutate: updateTeam, isLoading: isUpdating } = useMutation(
    (payload: any) => API.put(APIRoutes.teams.byId(team?.id || 0), payload),
    {
      onSuccess: () => {
        refetch();
        restoreProfile();
      },
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error
            ? 'Unable to update team'
            : 'Your changes have been saved',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    const { teamMembers } = dirtyFields;

    const dirtyTeamMembers = compact(
      map(teamMembers, (field, index) =>
        field ? data?.teamMembers[index] : null,
      ),
    );

    const payload = {
      website: data?.website,
      teamMembers: dirtyTeamMembers,
      removePhoto: data?.removePhoto,
      ...(dirtyFields.avatar && { base64teamLogo: data?.avatar }),
    };

    updateTeam(payload);
  };

  useEffect(() => {
    if (team) {
      const { name, avatar, website, teamMembers } = team;
      const values = {
        name,
        avatar,
        website,
        teamMembers,
        removePhoto: false,
      };
      reset({ ...values }, { keepDirtyValues: false, keepValues: false });
    }
  }, [team]);

  const openAddTeamMemberModal = (teamId: number) =>
    openModal(<AddTeamMemberModal teamId={teamId} />);

  useEffect(() => {
    if (team && shouldOpenModal) {
      openAddTeamMemberModal(team.id);
    }
  }, [isSuccess, shouldOpenModal]);

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <SectionHeader
          title="My profile"
          path={breadcrumbsConfig()}
          tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
          elements={
            <Box width="250px">
              <PincodeInput lightBackground />
            </Box>
          }
          backLink={routes.main.account.dashboard}
        />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <Typography variant="h3">Team details</Typography>

              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                mt="32px"
                mb="16px"
              >
                <ImageIcon />
                <Typography
                  variant="h4"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  Company logo
                </Typography>
              </Box>

              <Grid container spacing={16}>
                <PictureForm />
                <CompanyForm />
              </Grid>
            </Section>

            <Section>
              <Box display="flex" justifyContent="space-between" mb="28px">
                <Typography variant="h3">Team members</Typography>
                <Button
                  onClick={() => openAddTeamMemberModal(team.id)}
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: '200px',
                    height: '46px',
                    backgroundColor: theme.palette.secondary.main,
                  }}
                  endIcon={
                    <Icon
                      component={CircleWithPlus}
                      sx={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  }
                >
                  Add team member
                </Button>
              </Box>

              <Grid container spacing="16px">
                {team?.teamMembers?.map((teamMemberDetails, index) => (
                  <Grid item xs={12} lg={6} key={teamMemberDetails.id}>
                    <MemberForm
                      index={index}
                      teamId={team?.id}
                      {...teamMemberDetails}
                    />
                  </Grid>
                ))}
              </Grid>
            </Section>

            {isDirty && <FormBanner isLoading={isUpdating} />}
          </form>
        </FormProvider>
      </>
    );
  }
  return null;
};
export default MyTeam;
