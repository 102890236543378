import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BANNER } from 'constants/banner';
import Banner from './Common/Banner';

const LABEL = 'office';

const OfficeBanner = () => {
  const theme = useTheme();

  return (
    <Banner radius="156px" color={theme.palette.primary.main} label={LABEL}>
      <Box height="256px" margin="-34px 0px -40px -33px">
        <img
          src="/assets/images/style-office.svg"
          alt={LABEL}
          height="259px"
          width="497px"
        />
      </Box>
      <Box marginLeft="auto">
        <Typography variant="h1" marginBottom="12px">
          {BANNER.OFFICE.title}
        </Typography>
        <Typography variant="h4" marginBottom="40px" marginRight="70px">
          {BANNER.OFFICE.text}
        </Typography>
        <Button
          onClick={() => {
            window.open(BANNER.OFFICE.link, '_blank');
          }}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: '200px',
            justifyContent: 'center',
          }}
        >
          {BANNER.OFFICE.label}
        </Button>
      </Box>
    </Banner>
  );
};

export default OfficeBanner;
