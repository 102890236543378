import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FindMore = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();

  return (
    <Box textAlign="center" mt="47px">
      <Typography
        variant="h2"
        fontWeight={theme.typography.fontWeightBold}
        color={theme.palette.secondary.contrastText}
        mx="auto"
      >
        Can&apos;t find what
        <br /> you&apos;re looking for?
      </Typography>
      <Button
        onClick={onClick}
        variant="contained"
        sx={{
          justifyContent: 'center',
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
          width: '168px',
          height: '44px',
          marginTop: '27px',
        }}
      >
        Search
      </Button>
    </Box>
  );
};

export default FindMore;
