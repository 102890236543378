import { SvgIcon, SvgIconProps } from '@mui/material';

const TeamMemberIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="62" height="62" rx="31" fill="#F1F1F1" />
    <path
      // eslint-disable-next-line max-len
      d="M45.2518 47.0338V43.4706C45.2518 41.5805 44.501 39.7679 43.1646 38.4314C41.8281 37.095 40.0155 36.3442 38.1254 36.3442H23.8725C21.9825 36.3442 20.1698 37.095 18.8334 38.4314C17.4969 39.7679 16.7461 41.5805 16.7461 43.4706V47.0338M38.1254 22.0913C38.1254 26.0271 34.9348 29.2177 30.999 29.2177C27.0631 29.2177 23.8725 26.0271 23.8725 22.0913C23.8725 18.1555 27.0631 14.9648 30.999 14.9648C34.9348 14.9648 38.1254 18.1555 38.1254 22.0913Z"
      stroke="#1D1954"
      strokeWidth="2.13793"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default TeamMemberIcon;
