import { Button, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

function Footer({ submitStep }: BookingStepFooterProps) {
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <>
      <Typography
        sx={{
          fontSize: 'smallFontSize',
          opacity: 0.65,
        }}
        color="text.content"
      >
        Work.Life needs the contact information you provide to us to contact you
        about our products and services. You may unsubscribe from these
        communications at any time.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        disabled={!isValid}
        onClick={submitStep}
        sx={{
          width: '196px',
          justifyContent: 'center',
          height: '44px',
          flexShrink: 0,
        }}
      >
        Agree and proceed
      </Button>
    </>
  );
}

export default Footer;
