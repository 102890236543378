import HomeIcon from 'assets/icons/HomeIcon';
import EventsIcon from 'assets/icons/EventsIcon';
import PerksIcon from 'assets/icons/PerksIcon';
import MeetingRoomsIcon from 'assets/icons/MeetingRoomsIcon';
import HelpIcon from 'assets/icons/HelpIcon';
import VisitorsIcon from 'assets/icons/VisitorsIcon';
import StoreIcon from 'assets/icons/StoreIcon';
import BasketIcon from 'assets/icons/BasketIcon';
import AlertsIcon from 'assets/icons/AlertsIcon';
import AccountIcon from 'assets/icons/AccountIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import ImpersonateIcon from 'assets/icons/ImpersonateIcon';
import routes from 'utils/routes';

export const NAVIGATION_LIST = {
  firstSection: [
    {
      icon: <HomeIcon />,
      label: 'Home',
      href: routes.main.dashboard,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <MeetingRoomsIcon />,
      label: 'Meeting rooms',
      href: routes.main.meetingsRoomsCalendar(),
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <MeetingRoomsIcon />,
      label: 'Meeting rooms',
      href: routes.main.externalBookingsGallery(),
      authenticated: false,
      anonymous: true,
    },
    {
      icon: <MeetingRoomsIcon />,
      label: 'Office Day Bookings',
      href: routes.main.officeDayBookingsGallery(),
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <MeetingRoomsIcon />,
      label: 'Office Day Bookings',
      href: routes.main.externalOfficeDayBookingsGallery(),
      authenticated: false,
      anonymous: true,
    },
    {
      icon: <PerksIcon />,
      label: 'Perks',
      href: routes.main.promotions,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <EventsIcon />,
      label: 'Events',
      href: routes.main.events,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <HelpIcon />,
      label: 'Help/FAQs',
      href: routes.main.help.faq,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <VisitorsIcon />,
      label: 'Visitors/Deliveries',
      href: routes.main.visitors,
      subHref: routes.main.deliveries,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <StoreIcon />,
      label: 'Store',
      href: routes.main.store,
      authenticated: true,
      anonymous: false,
    },
  ],
  secondSection: [
    {
      icon: <AccountIcon />,
      label: 'Account',
      href: routes.main.account.dashboard,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <BasketIcon />,
      label: 'Basket',
      href: routes.main.basket,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <AlertsIcon />,
      label: 'Alerts',
      href: routes.main.alerts,
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <LogoutIcon />,
      label: 'Logout',
      href: '/#',
      authenticated: true,
      anonymous: false,
    },
    {
      icon: <ImpersonateIcon />,
      label: 'Mirror member',
      href: '/#',
      authenticated: true,
      anonymous: false,
    },
  ],
};
