import { useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import Switch from 'components/Common/Switch';
import routes from 'utils/routes';
import breakpoints from 'styles/theme/breakpoints';
import HeaderSection from 'pages/MeetingRooms/HeaderSection';
import ExternalGalleryView from './ExternalGalleryView';
import ExternalCalendarView from './ExternalCalendarView';

type MeetingRoomsTypes = 'calendar' | 'gallery';

interface MeetingRoomsProps {
  type: MeetingRoomsTypes;
  isPartTimeBooking?: boolean;
}

const ExternalMeetingRooms = ({
  type,
  isPartTimeBooking = false,
}: MeetingRoomsProps) => {
  const { currentSpaceId, user, isValidSpaceId, spaces } = useAuth();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const roomId = searchParams.get('roomId');
  const extendedSearch = searchParams.get('capacity');

  const [extendedGrid, setExtendedGrid] = useState(!!extendedSearch);
  const [params, setParams] = useState({});
  const [localization, setLocalization] = useState<number | undefined>(
    parseInt(searchParams.get('spaceId') as string, 10) ||
      currentSpaceId ||
      user?.spaceId,
  );

  const handleChangeLocalization = (loc: number | undefined) => {
    // so when we change space - we clear roomId as other space has different rooms
    if (!extendedSearch) {
      setSearchParams(`?spaceId=${localization}`, { replace: true });
    }
    setLocalization(loc as number | undefined);
  };

  useEffect(() => {
    if (isEmpty(spaces)) return;
    const paramSpaceId = searchParams.get('spaceId') as string;

    if (extendedSearch === 'null' || extendedSearch === null) {
      if (paramSpaceId === currentSpaceId.toString()) {
        handleChangeLocalization(currentSpaceId);
      } else {
        handleChangeLocalization(
          paramSpaceId && isValidSpaceId(paramSpaceId)
            ? +paramSpaceId
            : currentSpaceId,
        );
      }
    } else {
      handleChangeLocalization(+paramSpaceId);
    }
  }, [currentSpaceId, spaces]);

  useEffect(() => {
    if (!extendedSearch) {
      setSearchParams(
        `${localization ? `&spaceId=${localization}` : ''}${
          roomId ? `&roomId=${roomId}` : ''
        }`,
        { replace: true },
      );
    } else {
      const urlParam = (
        capacity: string | null,
        from: string | null,
        to: string | null,
        loc: any,
      ) => `?capacity=${capacity}&from=${from}&spaceId=${loc}&to=${to}`;

      setSearchParams(
        urlParam(
          searchParams.get('capacity'),
          searchParams.get('from'),
          searchParams.get('to'),
          localization || searchParams.get('spaceId'),
        ),
        { replace: true },
      );
    }
  }, [localization]);

  useEffect(() => {
    if (parseInt(searchParams.get('spaceId') as string, 10) === 0)
      setLocalization(user?.spaceId);
  }, [searchParams.get('spaceId')]);

  useEffect(() => {
    if (searchParams.get('capacity') === null) {
      setParams({
        space: searchParams.get('spaceId'),
      });
    }
    if (searchParams.get('capacity') !== null)
      setParams({
        spaceId: searchParams.get('spaceId'),
        capacity: searchParams.get('capacity'),
        from: searchParams.get('from'),
        to: searchParams.get('to'),
      });
  }, [location]);

  const getParams = useMemo(() => {
    if (searchParams.get('capacity') !== null) return params;
    return {
      spaceId: Number.isNaN(localization) ? user?.spaceId : localization,
      ...(roomId && { roomId }),
    };
  }, [params, location]);

  return (
    <>
      <HeaderSection
        isSearch={!!extendedSearch}
        setLocalization={handleChangeLocalization}
        isPartTimeBooking={isPartTimeBooking}
      />

      <Box position="relative" height="100%">
        <Box
          sx={{
            position: 'absolute',
            right: 40,
            top: 32,
            zIndex: 9,

            [`@media (max-width: ${breakpoints.values.md}px)`]: {
              right: 'auto',
              left: 40,
            },
          }}
        >
          <Link
            to={
              type === 'gallery'
                ? (isPartTimeBooking
                    ? routes.main.externalOfficeDayBookingsCalendar
                    : routes.main.externalBookingsCalendar)(getParams)
                : (isPartTimeBooking
                    ? routes.main.externalOfficeDayBookingsGallery
                    : routes.main.externalBookingsGallery)(getParams)
            }
            style={{ textDecoration: 'none' }}
          >
            <Switch items={['gallery', 'calendar']} activeItem={type} />
          </Link>
        </Box>

        {type === 'calendar' && (
          <ExternalCalendarView
            changeLoc={handleChangeLocalization}
            loc={localization}
            extendedGrid={extendedGrid}
            setExtendedGrid={setExtendedGrid}
            isPartTimeBooking={isPartTimeBooking}
          />
        )}
        {type === 'gallery' && (
          <ExternalGalleryView
            changeLoc={handleChangeLocalization}
            loc={localization}
            extendedGrid={extendedGrid}
            setExtendedGrid={setExtendedGrid}
            isPartTimeBooking={isPartTimeBooking}
          />
        )}
      </Box>
    </>
  );
};

ExternalMeetingRooms.defaultProps = {
  isPartTimeBooking: false,
};

export default ExternalMeetingRooms;
