import { Box, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { useFormContext, get, Controller } from 'react-hook-form';

export type TextInputProps = TextFieldProps & {
  label: string;
  name: string;
  type: string;
  onlyDigits?: boolean;
};

const TextInput = ({
  label,
  name,
  type,
  onlyDigits,
  ...props
}: TextInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <TextField
            label={label}
            type={type}
            value={value || ''}
            onChange={(e) => {
              const newValue = e.target.value;

              if (onlyDigits) {
                onChange(newValue.replace(/[^0-9]/g, ''));
              } else {
                onChange(newValue);
              }
            }}
            {...rest}
            sx={{
              width: '100%',
              mb: 1,
            }}
            {...props}
          />
        )}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
};

TextInput.defaultProps = {
  onlyDigits: false,
};

export default TextInput;
