import {
  CardContent,
  Typography,
  CardActionArea,
  Box,
  CardMedia,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';
import AccountIcon from 'assets/icons/AccountIcon';
import MotionCard from 'components/MotionCard';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';

import { useToast } from 'context/ToastContext';

interface BookingCard {
  card: Room;
  to?: string;
  from?: string;
  extendedGrid?: boolean;
  bookingInterval: {
    from: string;
    to: string;
  };
  space: any;
  isPartTimeBooking: boolean;
}

const confirmBooking = async (data: NewBookingProps | undefined) => {
  await API.post(APIRoutes.bookings.postBooking, data);
};

const BookingCard = ({
  card,
  from,
  to,
  bookingInterval,
  extendedGrid,
  space,
  isPartTimeBooking,
}: BookingCard) => {
  const [searchParams] = useSearchParams();
  const { user } = useAuth();

  const theme = useTheme();

  const queryClient = useQueryClient();
  const { snack, setSnack } = useToast();
  const navigate = useNavigate();

  const handleSuccess = () => {
    queryClient.invalidateQueries(['bookings', { isPartTimeBooking }]);
    queryClient.invalidateQueries(['rooms', { isPartTimeBooking }]);
    setSnack({
      ...snack,
      message: 'Booking confirmed',
      open: true,
      type: 'success',
    });

    setTimeout(
      () =>
        navigate(
          (isPartTimeBooking
            ? routes.main.officeDayBookingsGallery
            : routes.main.meetingsRoomsGallery)({
            spaceId: space.toString(),
          }),
        ),
      1000,
    );
  };

  const { isLoading: isLoadingBookingRoom, mutate: bookRoom } = useMutation(
    'confirm-booking',
    () =>
      confirmBooking({
        from: bookingInterval.from,
        to: bookingInterval.to,
        resourceId: card?.id,
        title: card?.name,
        isPartTimeBooking,
      }),
    {
      onSuccess: () => handleSuccess(),
      retry: 0,
      onError: (error: any) =>
        setSnack({
          ...snack,
          message: error.message,
          open: true,
          type: 'error',
        }),
    },
  );

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        height: '100%',
      }}
    >
      <CardActionArea
        component={Link}
        to={(isPartTimeBooking
          ? routes.main.officeDayBookings
          : routes.bookings)(
          card.id,
          bookingInterval?.from,
          bookingInterval?.to,
        )}
        state={{
          capacity: searchParams.get('capacity'),
          returnToDefaultView: !extendedGrid,
        }}
        sx={{ height: '100%' }}
      >
        <CardContent
          sx={{
            padding: '16px 16px 24px 16px',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Box mb="auto" width="100%">
            <CardMedia
              component="img"
              height="176"
              src={card.photo?.default.url}
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>,
              ) => {
                event.currentTarget.src = '/assets/images/placeholder.png';
              }}
              alt={card.name}
              sx={{ borderRadius: '4px', mb: 2 }}
            />
            <Box display="flex" justifyContent="space-between" gap="8px">
              <Box>
                <Typography
                  variant="h4"
                  fontWeight="fontWeightBold"
                  lineHeight="18px"
                  marginBottom="5px"
                >
                  {card.name}
                </Typography>
                <Typography
                  fontSize="smallFontSize"
                  sx={{
                    opacity: 0.65,
                  }}
                >
                  {card.district}
                </Typography>
              </Box>
              {card?.additionalInfo?.capacity && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <AccountIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                  <Typography
                    fontWeight="fontWeightBold"
                    lineHeight="18px"
                    textAlign="center"
                  >
                    {card.additionalInfo.capacity}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Typography typography="18px">
            {from} - {to}
          </Typography>
          <Tooltip
            title={
              user?.canMakeBookings
                ? null
                : 'You cannot make bookings. Contact with your Team Leader.'
            }
          >
            <Box component="span" sx={{ alignSelf: 'end' }}>
              <LoadingButton
                isLoading={isLoadingBookingRoom}
                variant="contained"
                disabled={!user?.canPurchaseEvents}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: '48px',
                  padding: '12px 34px',
                  width: '164px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  bookRoom?.();
                }}
              >
                Instant book
              </LoadingButton>
            </Box>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

BookingCard.defaultProps = {
  to: '',
  from: '',
  extendedGrid: false,
};

export default BookingCard;
