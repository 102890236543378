import routes from 'utils/routes';

export const TABS_HELP = [
  {
    value: routes.main.help.faq,
    label: 'FAQs',
  },
  {
    value: routes.main.help.ticket,
    label: 'Help/Tickets',
  },
];
