/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="168px"
    height="132px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 168 132"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={51} cy={60} r={51} fill="#D9DFEF" />
      <rect
        x={33.48}
        y={16.709}
        width={114.589}
        height={85.215}
        rx={12}
        fill="#fff"
      />
      <path
        d="M75.802 44.873h5.79c.438 0 .859-.176 1.17-.489a1.675 1.675 0 0 0 0-2.359 1.647 1.647 0 0 0-1.17-.488h-5.79c-.438 0-.859.175-1.17.488a1.676 1.676 0 0 0 0 2.36c.311.312.732.488 1.17.488ZM52.646 44.873h5.79c.438 0 .859-.176 1.169-.489a1.675 1.675 0 0 0 0-2.359 1.647 1.647 0 0 0-1.17-.488h-5.789c-.438 0-.86.175-1.17.488a1.676 1.676 0 0 0 0 2.36c.31.312.732.488 1.17.488ZM98.959 44.873h5.789c.438 0 .859-.176 1.169-.489.311-.312.485-.737.485-1.18 0-.441-.174-.866-.485-1.179a1.647 1.647 0 0 0-1.169-.488h-5.79c-.438 0-.859.175-1.169.488a1.676 1.676 0 0 0 0 2.36c.31.312.731.488 1.17.488ZM122.115 44.873h5.789c.439 0 .859-.176 1.17-.489a1.676 1.676 0 0 0 0-2.359 1.649 1.649 0 0 0-1.17-.488h-5.789c-.439 0-.859.175-1.17.488a1.676 1.676 0 0 0 0 2.36c.311.312.731.488 1.17.488ZM75.802 64.39h5.79c.438 0 .859-.175 1.17-.488a1.676 1.676 0 0 0 0-2.36 1.647 1.647 0 0 0-1.17-.488h-5.79c-.438 0-.859.176-1.17.488a1.676 1.676 0 0 0 0 2.36c.311.313.732.488 1.17.488ZM52.646 64.39h5.79c.438 0 .859-.175 1.169-.488a1.676 1.676 0 0 0 0-2.36 1.647 1.647 0 0 0-1.17-.488h-5.789c-.438 0-.86.176-1.17.488a1.676 1.676 0 0 0 0 2.36c.31.313.732.488 1.17.488ZM75.802 83.74h5.79c.438 0 .859-.175 1.17-.488a1.676 1.676 0 0 0 0-2.359 1.647 1.647 0 0 0-1.17-.489h-5.79c-.438 0-.859.176-1.17.489a1.675 1.675 0 0 0 0 2.36c.311.312.732.488 1.17.488ZM52.646 83.74h5.79c.438 0 .859-.175 1.169-.488a1.676 1.676 0 0 0 0-2.359 1.647 1.647 0 0 0-1.17-.489h-5.789c-.438 0-.86.176-1.17.489a1.676 1.676 0 0 0 0 2.36c.31.312.732.488 1.17.488Z"
        fill="#1D1954"
      />
      <rect
        x={33.75}
        y={14.25}
        width={114}
        height={88.5}
        rx={12.75}
        fill="#fff"
        stroke="#1D1954"
        strokeWidth={4.5}
      />
      <path d="M35.25 33.75H147" stroke="#1D1954" strokeWidth={4.5} />
      <ellipse
        cx={128.144}
        cy={91.899}
        rx={33.214}
        ry={33.418}
        fill="#FEDA8F"
      />
      <circle cx={115.5} cy={84} r={4.5} fill="#1D1954" />
      <circle cx={139.5} cy={84} r={4.5} fill="#1D1954" />
      <circle cx={48.75} cy={24.75} r={3.75} fill="#1D1954" />
      <circle cx={59.25} cy={24.75} r={3.75} fill="#1D1954" />
      <circle cx={69.75} cy={24.75} r={3.75} fill="#1D1954" />
      <circle
        cx={127.5}
        cy={91.5}
        r={34.875}
        stroke="#1D1954"
        strokeWidth={4.65}
        fill="transparent"
      />
      <path
        d="M110.836 108.938c4.095-4.757 10.047-7.751 16.672-7.751 6.624 0 12.576 2.994 16.672 7.751"
        stroke="#1D1954"
        strokeWidth={4.65}
        strokeLinecap="round"
        fill="transparent"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h168v132H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SvgComponent;
