/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const CircleWithPlus = (props: SvgIconProps) => (
  <SvgIcon
    width="22px"
    height="22px"
    fill="none"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 7v8m-4-4h8m6 0c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CircleWithPlus;
