import { browserVersion, browserName, isIE } from 'react-device-detect';

export function incompatibleBrowser() {
  if (browserName === 'Safari' && parseInt(browserVersion as string, 10) <= 14)
    return true;

  if (browserName === 'Chrome' && parseInt(browserVersion as string, 10) <= 105)
    return true;

  if (
    browserName === 'Firefox' &&
    parseInt(browserVersion as string, 10) <= 104
  )
    return true;

  if (browserName === 'Opera' && parseInt(browserVersion as string, 10) <= 89)
    return true;

  if (isIE) return true;

  if (browserName === 'Edge' && parseInt(browserVersion as string, 10) <= 104)
    return true;

  return false;
}
