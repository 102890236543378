import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import * as smoothscroll from 'smoothscroll-polyfill';
import { AppContextProvider } from 'context/AppContext';
import { ToastContextProvider } from 'context/ToastContext';
import { AuthProvider } from 'context/AuthContext';
import App from 'App';
import GlobalStyle from 'styles/globalStyles';
import theme from 'styles/theme';

// kick off the polyfill!
smoothscroll.polyfill();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient} contextSharing>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <BrowserRouter>
          <ToastContextProvider>
            <AuthProvider>
              <AppContextProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <App />
                </LocalizationProvider>
              </AppContextProvider>
            </AuthProvider>
          </ToastContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
