import { Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';

interface SwitchProps {
  items: [string, string];
  activeItem: string;
}

const Switch = ({ items, activeItem }: SwitchProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        color: theme.palette.text.content,
        borderRadius: '56px',
        backgroundColor: '#fff',
        padding: '7px',
        width: 'unset',
        border: `1px solid ${theme.palette.secondary.main}`,
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '50%',
          backgroundColor: theme.palette.secondary.main,
          display: 'block',
          height: '100%',
          borderRadius: '56px',
          top: 0,
          left: activeItem === items[1] ? '50%' : 0,
          transition: 'left 0.2s',
        },
      }}
    >
      <Box
        width="50%"
        zIndex={1}
        px="16px"
        fontWeight={activeItem === items[0] ? 'bold' : '400'}
        letterSpacing="0.3px"
      >
        {`${items[0].charAt(0).toUpperCase() + items[0].slice(1)}`}
      </Box>
      <Box
        width="50%"
        zIndex={1}
        px="16px"
        textAlign="right"
        fontWeight={activeItem === items[1] ? 'bold' : '400'}
        letterSpacing="0.3px"
      >
        {`${items[1].charAt(0).toUpperCase() + items[1].slice(1)}`}
      </Box>
    </Box>
  );
};

export default Switch;
