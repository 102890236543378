import { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { Title } from 'components/Title';

export type SectionProps = BoxProps & {
  children: ReactNode;
  title?: ReactNode;
};

const Section = ({ children, title, ...rest }: SectionProps) => (
  <Box
    component="section"
    position="relative"
    sx={{
      padding: '32px 40px 64px 40px',
      background: `linear-gradient(180deg, #F1F1F1 0%, rgba(241, 241, 241, 0) 100%) `,
    }}
    {...rest}
  >
    {title && (
      <Box display="flex" alignItems="center" mb={6}>
        <Title variant="h2">{title}</Title>
      </Box>
    )}
    {children}
  </Box>
);

Section.defaultProps = {
  title: '',
};

export default Section;
