import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { useMemo } from 'react';
import filter from 'lodash-es/filter';
import { useQuery } from 'react-query';
import { omit } from 'lodash-es';
import CloseIcon from 'assets/icons/CloseIcon';
import theme from 'styles/theme';
import { useAppContext } from 'context/AppContext';
import TasksList from 'components/Home/OnboardingModal/TasksList';
import { API, APIRoutes } from 'utils/api';
import LoadingSpinner from 'components/Common/LoadingSpinner';

const fetchTasks = async () => {
  const { data } = await API.get(APIRoutes.onboarding.tasks);
  return data;
};

const OnboardingModal = () => {
  const { closeModal } = useAppContext();
  const { data: { data = {} } = {}, isFetching } = useQuery(
    'tasks',
    fetchTasks,
  );

  const tasks = useMemo(() => omit(data, 'isInductionBooked'), [data]);

  const progress = useMemo(() => {
    if (!tasks) return 0;

    const numberOfCompletedTasks = filter(tasks, { completed: true }).length;
    const numberOfTasks = Object.keys(tasks).length;

    return Math.floor((numberOfCompletedTasks / numberOfTasks) * 100);
  }, [tasks]);

  return (
    <Card
      component={Container}
      maxWidth="xl"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.background.content,
        padding: '32px',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box position="sticky" top="0px" zIndex="101">
        <CardHeader
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                fontSize="26px"
                fontWeight={theme.typography.fontWeightBold}
                color={theme.palette.blue[900]}
              >
                Welcome to Work Life. Before we begin...
              </Typography>
              <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                  width: '20px',
                  height: '20px',
                  background: theme.palette.secondary.main,
                  '&:hover': {
                    background: theme.palette.secondary.main,
                  },
                }}
              >
                <CloseIcon
                  sx={{
                    width: '14px',
                    height: '14px',
                    color: theme.palette.blue[800],
                  }}
                />
              </IconButton>
            </Stack>
          }
          sx={{
            padding: 0,
            marginBottom: '40px',
          }}
        />
      </Box>
      <CardContent
        sx={{
          padding: 0,
          overflow: 'auto',
        }}
      >
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            <TasksList tasks={tasks} closeModal={closeModal} />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop="40px"
            >
              <Stack direction="row" gap="12px" alignItems="center">
                <Box position="relative">
                  <CircularProgress
                    variant="determinate"
                    size={48}
                    thickness={4}
                    value={100}
                    sx={{
                      color: theme.palette.success.light,
                    }}
                  />
                  <CircularProgress
                    variant="determinate"
                    color="success"
                    size={48}
                    thickness={4}
                    value={progress}
                    sx={{
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                    }}
                  />
                </Box>
                <Stack gap="4px">
                  <Typography
                    fontSize="smallFontSize"
                    lineHeight="16.25px"
                    sx={{
                      opacity: 0.65,
                    }}
                  >
                    Your progress
                  </Typography>
                  <Typography
                    fontWeight={theme.typography.fontWeightBold}
                    color="text.content"
                  >
                    {progress}% complete
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                onClick={closeModal}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                Remind me later
              </Button>
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default OnboardingModal;
