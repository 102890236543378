import { MenuItem, Typography, useTheme } from '@mui/material';
import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import DropdownMenu from './DropdownMenu';

export type CategoryDropdownProps = {
  items: CategoryProps[];
  onSelect: (id: number) => void;
  currentId: number | null;
};

const ALL_CATEGORY = { id: 0, title: 'All' };

const CategoryDropdown = ({
  items,
  onSelect,
  currentId,
}: CategoryDropdownProps) => {
  const theme = useTheme();
  const currentCategory = items.find(
    (item) => item.id.toString() === currentId?.toString(),
  );
  items = [ALL_CATEGORY, ...items];
  return (
    <DropdownMenu
      type="category"
      label={currentCategory?.title || 'Category'}
      color="primary"
      icon={
        <ChevronDownIcon
          sx={{
            width: '20px',
            height: '20px',
          }}
        />
      }
      items={items.map((item: CategoryProps) => (
        <MenuItem key={item.id} onClick={() => onSelect(item.id)}>
          <Typography
            sx={{
              color: theme.palette.blue[900],
              fontWeight:
                item.id === currentId ? 'fontWeightBold' : 'fontWeightRegular',
            }}
          >
            {item.title}
          </Typography>
        </MenuItem>
      ))}
    />
  );
};

export default CategoryDropdown;
