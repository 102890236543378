import React from 'react';
import { Box, Typography } from '@mui/material';

import CardItem from 'components/Common/CardItem';

type relatedItemProps = {
  type: string;
  relatedItems: EventProps[] | PerkProps[];
};

const RelatedItemsSection = ({ type, relatedItems }: relatedItemProps) => (
  <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h2">Related {type}</Typography>
    </Box>

    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill,minmax(312px, 1fr))"
      gap={2}
      my={5}
    >
      {relatedItems.map((e: EventProps | PerkProps) => (
        <CardItem
          type={type}
          key={`event-${e.id}`}
          summary={e.shortDescription}
          title={e.title}
          id={e.id}
          created={e.startDate}
          imageUrl={e.imageUrl}
          location={e.address}
        />
      ))}
    </Box>
  </>
);

export default RelatedItemsSection;
