export const USER_PROFILE = {
  avatar: '',
  base64avatar: '',
  fullName: '',
  mobilePhone: '',
  email: '',
  salutation: '',
  address: '',
  postCode: '',
  countryId: 0,
  companyName: '',
  cityName: '',
  billingAddress: '',
  billingPostCode: '',
  billingCountryId: 0,
  billingEmail: '',
  billingCityName: '',
  twitter: null,
  facebook: null,
  instagram: null,
  linkedin: null,
  skype: null,
  google: null,
  github: null,
  flickr: null,
  vimeo: null,
  blogger: null,
  tumblr: null,
  onNewWallPost: false,
  onNewBlogComment: false,
  onNewEventComment: false,
  receiveCommunityDigest: false,
  receiveEveryMessage: false,
};

export const IMAGE_PICKER_CONFIG = {
  maxFiles: 1,
  multiple: false,
  maxSize: 10485760,
  accept: {
    'image/jpg': [],
    'image/jpeg': [],
    'image/png': [],
  },
};

export const NOTIFICATIONS = {
  firstSection: {
    title: 'Select when you would like to recieve notifications:',
    options: [
      {
        label: 'When a new message is posted in the home page wall.',
        name: 'onNewWallPost',
      },
      {
        label: 'When a new comment is posted in the blog.',
        name: 'onNewBlogComment',
      },
      {
        label: 'When a new comment is posted in an event.',
        name: 'onNewEventComment',
      },
    ],
  },
  secondSection: {
    title:
      'How and when should we alert you about conversations in the community board?',
    options: [
      {
        label:
          'Send me an update in the morning if there are new messages (~8am).',
        name: 'receiveCommunityDigest',
      },
      {
        label:
          'Send me a notification shortly after every message. You can still mute individual conversations.',
        name: 'receiveEveryMessage',
      },
    ],
  },
};
