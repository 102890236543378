/* eslint-disable max-len */
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import Slider from 'components/Slider';
import Section from 'components/Section';
import AnnouncementCard from 'components/Announcements/AnnouncementCard';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import { useAuth } from 'context/AuthContext';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import { ACCOUNT_TYPES } from 'constants/account';
import OfficeBanner from 'components/OfficeBanner';

const AnnouncementsSection = () => {
  const { currentSpaceId, userType } = useAuth();

  const getAnnouncements = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.announcements.index(LIMITS.DASHBOARD_SECTION, currentSpaceId),
    );
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<AnnouncementProps[]>(
    ['announcements', currentSpaceId],
    () => getAnnouncements(),
  );

  if (isLoading) {
    return (
      <Section>
        <LoadingSpinner minHeight="334px" />
      </Section>
    );
  }

  if (isSuccess && !isEmpty(data)) {
    return (
      <Section>
        <>
          <Slider
            id="announcements"
            title="Announcements"
            type="announcements"
            emptyComponent
            hideViewAll
            items={data.map((an) => (
              <AnnouncementCard key={`announcement-${an._id}`} {...an} />
            ))}
          />
          {[ACCOUNT_TYPES.RESIDENT_PREACTIVE].includes(userType) && (
            <OfficeBanner />
          )}
        </>
      </Section>
    );
  }
  return null;
};

export default AnnouncementsSection;
