import React, { useState } from 'react';
import { useQuery } from 'react-query';
import BookedEvents from 'pages/Events/BookedEvents';
import CustomTabs from 'components/Common/Tabs';
import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import EventsUpcoming from 'pages/Events/UpcomingEvents';
import { TABS_HELP } from 'constants/tabs/tabsEvents';
import LocationDropdown from 'components/Common/LocationDropdown';
import CategoryDropdown from 'components/Common/CategoryDropdown';
import { API, APIRoutes } from 'utils/api';

import Loader from 'components/Loader';

import { useAuth } from 'context/AuthContext';

const breadcrumbsConfig = () => [{ label: 'Events' }];

type EventsProps = {
  tab: string;
};

const getCategories = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.events.categories);
  return data;
};

const Events = ({ tab }: EventsProps) => {
  const { currentSpaceId, setUserSpaceId, spaces } = useAuth();
  const [categoryId, setCategoryId] = useState(0);

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isSuccess: isSuccessCategories,
  } = useQuery(['categories'], () => getCategories(), {
    enabled: tab === 'events',
  });

  if (isLoadingCategories) {
    return <Loader />;
  }

  return (
    <>
      <SectionHeader
        title="Events"
        hideBackButton
        path={breadcrumbsConfig()}
        tabs={<CustomTabs tabsConfig={TABS_HELP} />}
        elements={
          tab === 'events' && (
            <>
              <LocationDropdown
                items={spaces}
                currentId={currentSpaceId}
                onSelect={setUserSpaceId}
              />
              <CategoryDropdown
                items={categories}
                currentId={categoryId}
                onSelect={setCategoryId}
              />
            </>
          )
        }
      />
      <Section>
        {tab === 'events' ? (
          <EventsUpcoming
            categories={categories}
            isSuccessCategories={isSuccessCategories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
        ) : (
          <BookedEvents />
        )}
      </Section>
    </>
  );
};

export default Events;
