import { Box, Button, Typography, useTheme } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ArrowIcon from 'assets/icons/ArrowIcon';
import { State } from 'components/Rooms/BookingSlotsSlider';
import MotionBox from 'components/MotionBox';
import { DEFAULT_STATE } from 'constants/rooms';
import routes from 'utils/routes';

interface ConfimationBannerProps {
  selected: State;
  setSelected: React.Dispatch<React.SetStateAction<State>>;
  isPartTimeBooking: boolean;
}

const ConfimationBanner = ({
  selected,
  setSelected,
  isPartTimeBooking,
}: ConfimationBannerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const from = moment(selected?.from);
  const to = moment(selected?.to);

  return (
    <AnimatePresence>
      {selected?.id && (
        <MotionBox
          position="sticky"
          bottom="0"
          left="0"
          width="100%"
          py="24px"
          pr="20px"
          pl="30px"
          zIndex="101"
          sx={{
            borderRadius: '20px 20px 0 48px',
            boxShadow: '15px 0px 35px -15px rgba(66, 68, 90, 1)',
            backgroundColor: '#fff',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Box display="flex" alignItems="center">
            <Typography fontSize="26px" fontWeight="700" mr="24px">
              {selected?.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography fontWeight="700" mr="8px">
                {from.format('dddd Do MMMM YYYY')}
              </Typography>
              {from.format('HH:mm')} -&nbsp;
              {!to.isSame(from, 'day') && (
                <Typography fontWeight="700" mr="8px">
                  {to.format('dddd Do MMMM YYYY')}
                </Typography>
              )}
              {to.format('HH:mm')}
            </Box>
            <Box flex={1} />
            <Typography
              component="button"
              fontWeight={600}
              mr="24px"
              sx={{
                textDecoration: 'underline',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => setSelected(DEFAULT_STATE)}
            >
              Cancel
            </Typography>
            <Button
              disabled={!selected?.name || !selected?.from || !selected?.to}
              onClick={() => {
                navigate(
                  (isPartTimeBooking
                    ? routes.main.officeDayBookings
                    : routes.bookings)(selected.id, selected.from, selected.to),
                  {
                    state: {
                      capacity: 1,
                      returnToDefaultView: true,
                    },
                  },
                );
              }}
              sx={{
                width: '200px',
                backgroundColor: theme.palette.primary.main,
              }}
              endIcon={
                <ArrowIcon
                  sx={{ width: '16px', height: '16px', marginRight: '5px' }}
                />
              }
              variant="contained"
              type="submit"
            >
              Confirm booking
            </Button>
          </Box>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default ConfimationBanner;
