/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const Trash = (props: SvgIconProps) => (
  <SvgIcon
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5h2m0 0h16M3 5v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5H3Zm3 0V3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Trash;
