import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import ButtonContainer from 'components/ButtonContainer';
import ArrowIcon from 'assets/icons/ArrowIcon';
import TextInput from 'components/Form/TextInput';
import routes from 'utils/routes';
import HiddenInput from 'components/Form/HiddenInput';
import { PASSWORD_CHANGE_SCHEMA } from 'constants/signUp';
import LoadingButton from 'components/Common/LoadingButton';

type FormData = {
  newPassword: string;
  repeatNewPassword: string;
  token: string;
};

const schema = yup.object(PASSWORD_CHANGE_SCHEMA).required();

export default function ActivationForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const resetPasswordLink = searchParams.get('link');
  const url = resetPasswordLink ? new URL(resetPasswordLink) : null;
  const token = url ? url.searchParams.get('token') : null;

  const { snack, setSnack } = useToast();
  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      repeatNewPassword: '',
      token: token || '',
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;
  const { activate } = useAuth();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await activate(data);
      setSnack({
        ...snack,
        message: 'Your password has been changed. You may log in now',
        open: true,
        type: 'success',
      });
      navigate(routes.auth.signIn);
    } catch (e) {
      setSnack({
        ...snack,
        message:
          'We failed to change your password. The reset link could have expired - please request a new one',
        open: true,
        type: 'error',
      });
      navigate(routes.auth.resetPassword);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HiddenInput name="token" />
        <TextInput label="New password" name="newPassword" type="password" />
        <TextInput
          label="Repeat new password"
          name="repeatNewPassword"
          type="password"
        />
        <ButtonContainer justifyContent="center">
          <LoadingButton
            isLoading={isSubmitting}
            sx={{ height: '44px', width: '200px', mt: 2 }}
            endIcon={
              <ArrowIcon
                sx={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
            }
            variant="contained"
            type="submit"
            disabled={!isDirty}
          >
            Continue
          </LoadingButton>
        </ButtonContainer>
      </form>
    </FormProvider>
  );
}
