import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { LABELS, OPTIONS } from 'constants/chart';

export type ChartProps = {
  data: {
    occupancy: number;
    date: string;
    hour: number;
    avg: boolean;
  }[];
  color: string;
};

ChartJS.register(CategoryScale, LinearScale, BarElement);

const options: any = {
  ...OPTIONS,
  plugins: { tooltip: false },
};

const Chart = ({ data, color }: ChartProps) => {
  const now = moment().get('hour');
  const oddHour = now + (now % 2 ? 0 : 1);
  const dataset = {
    labels: LABELS,
    datasets: [
      {
        data: LABELS.map(
          (label) =>
            data.find(
              (item) => moment(item.date).parseZone().get('hour') === +label,
            )?.occupancy,
        ),
        backgroundColor: LABELS.map((label) =>
          oddHour === +label ? color : '#1D1954',
        ),
        borderRadius: 24,
        borderSkipped: false,
        barThickness: 32,
      },
    ],
  };

  return <Bar options={options} data={dataset} />;
};

export default Chart;
