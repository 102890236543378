import { useMemo } from 'react';
import { Avatar, Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from 'react-query';

import TeamMemberIcon from 'assets/icons/TeamMemberIcon';
import CheckBox from 'components/Form/CheckBox';
import { useAppContext } from 'context/AppContext';
import TrashIcon from 'assets/icons/Trash';
import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';
import LoadingIconButton from 'components/Common/LoadingIconButton';
import { useAuth } from 'context/AuthContext';
import CancellationDialog from 'components/CancellationDialog';

const MemberForm = ({
  teamId,
  index,
  id,
  avatar,
  fullName,
  tariff,
}: {
  teamId: number;
  index: number;
  id: number;
  avatar: string;
  fullName: string;
  tariff: string;
}) => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  const { snack, setSnack } = useToast();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const matches = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  const isTeamAdministrator = useMemo(() => id === user?.id, [user]);

  const { mutate: removeTeamMember, isLoading } = useMutation(
    (query: { id: string; notes: string; survey: boolean }) =>
      API.delete(
        APIRoutes.teams.remove(
          teamId,
          id,
          encodeURIComponent(`${query.id}\n${query.notes}`),
        ),
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('team');
      },
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error
            ? 'Unable to remove this member'
            : 'Team member removed',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '8px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #D9DFEF"
        padding="3px 4px 3px 24px"
      >
        <Typography fontSize="20px" fontWeight="bold">
          {fullName}&nbsp;-&nbsp;{tariff}
        </Typography>
        <LoadingIconButton
          isLoading={isLoading}
          disabled={isTeamAdministrator}
          onClick={() =>
            openModal(
              <CancellationDialog
                text="By confirming this you will immediately
                      cancel this persons contract, remove their access 
                      to the portal, remove them from your team and de-activate their access card - do you wish to continue?"
                confirmationText="Yes, I'm sure"
                cancellationText="No, take me back"
                onClick={removeTeamMember}
              />,
            )
          }
          sx={{
            width: '40px',
            height: '40px',
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
            margin: '13px 24px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            '&:disabled': {
              opacity: 0.65,
            },
          }}
        >
          <TrashIcon
            sx={{
              width: '24px',
              height: '24px',
              color: theme.palette.primary.main,
            }}
          />
        </LoadingIconButton>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        padding="27px 24px 32px"
        gap="28px"
        flexDirection={matches ? 'column' : 'row'}
      >
        <Box>
          {avatar ? (
            <Avatar src={avatar} sx={{ width: 176, height: 176 }} />
          ) : (
            <TeamMemberIcon sx={{ width: 176, height: 176 }} />
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display="flex" flexDirection="column">
          <CheckBox
            name={`teamMembers.${index}.teamAdministrator`}
            label="Team administrator"
            disabled={isTeamAdministrator}
            fontBold
          />
          <CheckBox
            name={`teamMembers.${index}.canMakeBookings`}
            label="Can make bookings"
            disabled={isTeamAdministrator}
            fontBold
          />
          <CheckBox
            name={`teamMembers.${index}.canPurchaseProducts`}
            label="Can purchase products"
            disabled={isTeamAdministrator}
            fontBold
          />
          <CheckBox
            name={`teamMembers.${index}.canPurchaseEvents`}
            label="Can purchase events"
            disabled={isTeamAdministrator}
            fontBold
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MemberForm;
