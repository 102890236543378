import { Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import SelectField from 'components/Form/SelectField';
import { API, APIRoutes } from 'utils/api';
import TextInput from 'components/Form/TextInput';
import DatePickerController from 'components/Form/DatePickerController';
import Loader from 'components/Loader';
import TitleWithIcon from 'components/TourBooking/TitleWithIcon';
import PinIcon from 'assets/icons/PinIcon';
import InfoIcon from 'assets/icons/InfoIcon';

const getSpaces = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.tourBoking.spaces);
  return data;
};

function Form() {
  const { isFetching, data: spaces = [] } = useQuery<SpaceProps[]>(
    'bookingSpaces',
    getSpaces,
    {
      staleTime: 60 * 1000,
    },
  );

  const spaceOptions = useMemo(
    () =>
      spaces?.map((space) => ({
        value: space.id,
        label: space.shortName,
      })),
    [spaces],
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <TitleWithIcon title="Where?" icon={PinIcon} />
        <SelectField
          label="Select location"
          name="location"
          options={spaceOptions}
          required
        />
      </Stack>
      <Stack spacing={2}>
        <TitleWithIcon title="Tell us about you" icon={InfoIcon} />
        <TextInput label="First name" name="firstName" type="text" required />
        <TextInput label="Last name" name="lastName" type="text" required />
        <TextInput label="Phone" name="phone" type="text" required />
        <TextInput label="Work email" name="email" type="text" required />
        <TextInput
          label="How many desks do you need?"
          name="desks"
          type="text"
          onlyDigits
          required
        />
        <DatePickerController
          name="moveDate"
          label="When do you want to start?"
          required
          disablePast
        />
      </Stack>
    </Stack>
  );
}

export default Form;
