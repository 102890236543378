import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardContent, Typography, CardActionArea, Grid } from '@mui/material';
import MotionCard from 'components/MotionCard';

type AccountCardProps = {
  label: string;
  value: string;
  wide?: boolean;
  elements: ReactNode;
};

const AccountCard = ({ label, value, elements, wide }: AccountCardProps) => {
  const navigate = useNavigate();
  return (
    <Grid item lg={wide ? 12 : 6} md={12} width="100%">
      <MotionCard
        whileHover={{ scale: 1.025 }}
        sx={{
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <CardActionArea onClick={() => navigate(value)} sx={{ height: '100%' }}>
          <CardContent sx={{ height: '100%', padding: '24px' }}>
            <Typography variant="h2" fontSize="20px" mb="20px">
              {label}
            </Typography>
            {elements}
          </CardContent>
        </CardActionArea>
      </MotionCard>
    </Grid>
  );
};

AccountCard.defaultProps = {
  wide: false,
};
export default AccountCard;
