import { useState } from 'react';
import moment, { Moment } from 'moment';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';

import DatePickerIcon from 'assets/icons/DatePickerIcon';

type MonthFilterProps = {
  options: {
    label: string;
    value: any;
  }[];
  onSelect: (option: string | Moment) => void;
  selectedValue: string | Moment;
};

const MonthFilter = ({
  options,
  onSelect,
  selectedValue,
}: MonthFilterProps) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          width: '40px',
          height: '40px',
          border: `solid 1px ${theme.palette.secondary.main}`,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: open ? theme.palette.secondary.main : '#FFFFFF',
        }}
        onClick={handleClick}
      >
        <DatePickerIcon
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        BackdropProps={{
          sx: {
            background: 'transparent',
            backdropFilter: 'none',
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            borderColor: theme.palette.secondary.main,
          },
        }}
      >
        {options
          .filter((x) => x.label !== null)
          .map((option) => (
            <MenuItem
              key={option?.value}
              onClick={() => {
                handleClose();
                onSelect(option?.value);
              }}
              sx={{
                paddingX: '0px',
                fontWeight:
                  moment(selectedValue).format('MMMM YYYY') ===
                  moment(option?.value).format('MMMM YYYY')
                    ? 'bold'
                    : 'normal',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default MonthFilter;
