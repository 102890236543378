import { Box, Stack, Typography, useTheme } from '@mui/material';
import DatePickerController from 'components/Form/DatePickerController';
import SelectField from 'components/Form/SelectField';
import Section from 'components/Section';
import { OPTIONS } from 'constants/signUp';

const InductionForm = ({
  spaces,
  bankHolidays,
}: {
  spaces: SpaceProps[];
  bankHolidays: string[];
}) => {
  const theme = useTheme();
  const spaceOptions = spaces
    ?.filter((space) => space.id !== 0)
    ?.map((space) => ({
      value: space.id,
      label: space.name,
    }));

  return (
    <Section sx={{ margin: 0 }}>
      <Stack spacing={2}>
        <Typography
          fontWeight={theme.typography.fontWeightRegular}
          marginBottom="8px"
          marginTop="8px"
        >
          Before they can become a fully-fledged Work.Life member, you&apos;ll
          need to book an induction. We&apos;ll show them around the space, get
          them set up on the Wifi, and talk through our amenities. After your
          induction, you&apos;ll be given your access card.
        </Typography>
        <SelectField
          label="Select the space you'd like to your induction"
          name="inductionSpaceId"
          options={spaceOptions}
          required
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <DatePickerController
            name="inductionDate"
            label="Pick your induction date"
            required
            disableWeekends
            disablePast
            bankHolidays={bankHolidays}
            sx={{ width: '48%' }}
          />
          <Box sx={{ width: '48%' }}>
            <SelectField
              label="Available times"
              name="inductionTime"
              options={OPTIONS.inductionTime}
            />
          </Box>
        </Box>
      </Stack>
    </Section>
  );
};

export default InductionForm;
