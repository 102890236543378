import { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { isMobile, isBrowser } from 'react-device-detect';

import { Link } from '@mui/material';
import { getItem, KEYS, setItem } from 'utils/cache';

import LayoutBanner from './LayoutBanner';

const UnsupportedResolutionBanner = () => {
  const smallerResolution = useMediaQuery('(max-width:820px)');

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(
      (isMobile || (isBrowser && smallerResolution)) &&
        !getItem(KEYS.IGNORE_UNSUPPORTED_RESOLUTION),
    );
  }, [smallerResolution]);

  const ignoreUnsupportedResolution = () => {
    setItem(KEYS.IGNORE_UNSUPPORTED_RESOLUTION, 'true');
    setIsVisible(false);
  };

  if (isVisible) {
    return (
      <LayoutBanner
        onClick={ignoreUnsupportedResolution}
        text={
          <>
            The screen resolution is too small to display the content properly,
            we recommend downloading the Work.Life app for mobile devices on{' '}
            <Link href="https://apps.apple.com/pl/app/work-life/id1541199437">
              App Store
            </Link>{' '}
            or{' '}
            <Link href="https://play.google.com/store/apps/details?id=life.work.app">
              Google Play
            </Link>
          </>
        }
      />
    );
  }

  return null;
};

export default UnsupportedResolutionBanner;
