import { Dispatch, SetStateAction } from 'react';
import { Box, Chip, IconButton, OutlinedInput, useTheme } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';

import ArrowIcon from 'assets/icons/ArrowIcon';
import { API, APIRoutes } from 'utils/api';
import { IMAGE_PICKER_CONFIG } from 'constants/help';
import LoadingIconButton from 'components/Common/LoadingIconButton';

type MessageForm = {
  message: string;
  image: {
    name: string;
    src: string;
  };
};

const MessageInput = ({
  chatId,
  setMessages,
}: {
  chatId: string;
  setMessages: Dispatch<SetStateAction<Message[]>>;
}) => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { isDirty, isValid },
    watch,
  } = useForm<MessageForm>({
    mode: 'onChange',
    defaultValues: {
      message: '',
      image: { name: '', src: '' },
    },
  });

  const { mutate: sendMessage, isLoading } = useMutation(
    (payload: { message?: string; photo?: string }) =>
      API.post(APIRoutes.help.sendMessage(chatId), payload),
    {
      onSettled: (response, error) => {
        resetField('message');
        resetField('image');
        if (error) return;
        setMessages((prevMessages) => [...prevMessages, response?.data?.data]);
      },
    },
  );

  const onDrop = (selectedImages: any) => {
    const file = selectedImages[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setValue(
        'image',
        {
          name: file.name,
          src: reader.result?.toString() || '',
        },
        { shouldDirty: true },
      );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...IMAGE_PICKER_CONFIG,
  });

  const onSubmit: SubmitHandler<MessageForm> = async (data) => {
    const payload = {
      ...(data.message && { message: data.message }),
      ...(data.image.src && { photo: data.image.src }),
    };
    sendMessage(payload);
  };

  const selectedImage = watch('image');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OutlinedInput
        placeholder="Write message"
        fullWidth
        sx={{
          height: '56px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          '& .MuiInputBase-input': {
            padding: '20px 16px',
          },
          '& > fieldset': {
            border: 'none',
          },
        }}
        startAdornment={
          selectedImage.name && (
            <Chip
              label={selectedImage.name}
              onDelete={() => resetField('image')}
              sx={{
                width: '150px',
                maxWidth: '150px',
              }}
            />
          )
        }
        endAdornment={
          <Box display="flex" gap="8px">
            <Box>
              <input {...getInputProps()} {...register('image')} />
              <IconButton
                {...getRootProps()}
                disabled={!!selectedImage.src}
                sx={{
                  width: '40px',
                  height: '40px',
                  border: `solid 1px ${theme.palette.secondary.main}`,
                  color: theme.palette.blue[900],
                  '&:disabled': {
                    opacity: 0.65,
                  },
                }}
              >
                <CameraAltOutlinedIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </IconButton>
            </Box>
            <LoadingIconButton
              sx={{
                width: '40px',
                height: '40px',
                border: `solid 1px ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
                '&:disabled': {
                  color: theme.palette.primary.main,
                },
              }}
              type="submit"
              disabled={!isDirty || !isValid}
              isLoading={isLoading}
            >
              <ArrowIcon
                sx={{
                  width: '18px',
                  height: '18px',
                  transform: 'rotate(-90deg)',
                }}
              />
            </LoadingIconButton>
          </Box>
        }
        {...register('message', {
          validate: (value: string) => !value || !!value.trim(),
        })}
      />
    </form>
  );
};

export default MessageInput;
