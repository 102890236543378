import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';

import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';

import ChosenPlanIcon from 'assets/icons/ChosenPlanIcon';

import { useAuth } from 'context/AuthContext';
import { ACCOUNT_TYPES } from 'constants/account';
import AccountCard from './AccountCard';

type CardPlansProps = {
  label: string;
};

const fetchPlans = async () => {
  const { data } = await API.get(APIRoutes.user.plans);
  return data;
};

const CardPlans = ({ label }: CardPlansProps) => {
  const { data, isSuccess } = useQuery('plans', () => fetchPlans());

  const { userType } = useAuth();

  if (isSuccess) {
    return (
      <AccountCard
        label={label}
        value={routes.main.account.plans}
        elements={
          <Box
            mt="30px"
            mb="0"
            p="22px"
            sx={{
              borderRadius: '8px',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              pb="20px"
              sx={{ borderBottom: '1px solid #D9DFEF' }}
            >
              <Typography fontSize="20px" fontWeight="700">
                Your chosen plan:
              </Typography>
              {userType === ACCOUNT_TYPES.FLEX ? (
                <img
                  src="/assets/images/FlexPlanIcon.png"
                  alt="flex"
                  width="auto"
                  height="44px"
                />
              ) : (
                <ChosenPlanIcon width="52px" height="44px" />
              )}
            </Box>
            <Typography pt="24px" fontSize="20px" fontWeight="700">
              {data.data.activeContracts.length > 0
                ? data.data.activeContracts[0].name
                : '1x NO PLAN MEMBERSHIP'}
            </Typography>
            <Box
              sx={{
                '& ul': {
                  padding: '10px',
                  fontSize: 16,
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  data.data.activeContracts.length > 0
                    ? data.data.activeContracts[0].description
                    : 'You are not in a price plan. Please contact your Membership Manager',
              }}
            />
          </Box>
        }
      />
    );
  }

  return null;
};
export default CardPlans;
