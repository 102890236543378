import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import { useAuth } from 'context/AuthContext';
import SectionHeader from 'components/SectionHeader';
import AnnouncementsSection from 'components/Announcements/AnnouncementsSection';
import BookingsSection from 'components/Bookings/BookingsSection';
import EventsSection from 'components/Events/EventsSection';
import PromoteSection from 'components/Promotions/PromotionsSection';
import LocationMenu from 'components/LocationMenu';
import WifiMenu from 'components/WifiMenu';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import OnboardingModal from 'components/Home/OnboardingModal';
import { useAppContext } from 'context/AppContext';

const Home = () => {
  const { user } = useAuth();
  const { openModal } = useAppContext();
  const currentHour = moment().hour();

  const welcomeMessage = useMemo(() => {
    if (user) {
      if (currentHour >= 12 && currentHour < 17) {
        return `Afternoon ${user?.firstName} 👋`;
      }
      if (currentHour >= 17 && currentHour < 24) {
        return `Evening ${user?.firstName} 👋`;
      }

      return `Morning ${user?.firstName} 👋`;
    }
    return 'Morning 👋';
  }, [user]);

  useEffect(() => {
    if (user?.onboarding) {
      openModal(<OnboardingModal />);
    }
  }, [user]);

  return (
    <>
      <SectionHeader
        title={welcomeMessage}
        elements={
          <>
            <LocationMenu />
            <WifiMenu />
          </>
        }
        dashboard
      />
      <AnnouncementsSection />
      <BookingsSection />
      <EventsSection />
      <PromoteSection />
      <Box textAlign="center" marginBottom={5}>
        <ScrollTopButton />
      </Box>
    </>
  );
};

export default Home;
