import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import NotificationDotIcon from 'assets/icons/NotificationDotIcon';

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas: 'side-menu main';
`;

export const Main = styled('main', {
  shouldForwardProp: (prop: string) =>
    !['onlyMainSection', 'shouldOverflow'].includes(prop as string),
})<{
  autoHeight?: boolean;
  onlyMainSection?: boolean;
  shouldOverflow?: boolean;
}>(({ onlyMainSection, theme, shouldOverflow }) => ({
  height: !onlyMainSection
    ? 'auto'
    : `
      100vh 
    `,
  gridArea: 'main',
  contain: 'inline-size',
  overflowY: shouldOverflow ? 'visible' : 'auto',
  position: 'relative',
  ...(!onlyMainSection && {
    backgroundColor: theme.palette.background.content,
    color: theme.palette.text.content,
    borderRadius: '48px 0px 0px 48px',
  }),
}));

export const ToggleButton = styled(IconButton, {
  shouldForwardProp: (prop: string) => !['isExpanded'].includes(prop as string),
})<{ isExpanded?: boolean }>(({ isExpanded, theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  transform: `rotate(${isExpanded ? '0deg' : '180deg'})`,
  transition: `transform ${theme.transitions.duration.standard}ms`,
  margin: '32px -20px 32px auto',
  zIndex: 10,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const NotificationDot = styled(NotificationDotIcon)`
  width: 8px;
  height: 8px;
  position: absolute;
`;

export const VideoWrapper = styled('div')({
  objectFit: 'cover',
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
});

export const BannerWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: theme.zIndex.modal,
  width: '90%',
  maxWidth: '500px',
}));
