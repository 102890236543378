import { FormHelperText, Grid, Stack } from '@mui/material';
import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';
import DatePickerController from 'components/Form/DatePickerController';

const DetailsForm = ({ isSignUp }: { isSignUp?: boolean }) => (
  <Section title="Add your details" id="details">
    {isSignUp ? (
      <Stack spacing={2}>
        <TextInput label="Full name" name="fullName" type="text" required />
        <TextInput label="Phone" name="phone" type="text" required />
        <TextInput label="Email" name="email" type="text" required />
        <DatePickerController
          name="dateOfBirth"
          label="Date of birth"
          disableFuture
        />
        <TextInput
          label="What should we call you?"
          name="salutation"
          type="text"
          required
        />
      </Stack>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextInput label="Full name" name="fullName" type="text" required />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="Phone" name="mobilePhone" type="text" required />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="Email" name="email" type="text" required disabled />
          <FormHelperText
            sx={{
              marginLeft: '24px',
              marginTop: '-4px',
            }}
          >
            Contact us to change your email
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatePickerController
            name="dateOfBirth"
            label="Date"
            disableFuture
            required
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="What should we call you?"
            name="salutation"
            type="text"
            required
          />
        </Grid>
      </Grid>
    )}
  </Section>
);

DetailsForm.defaultProps = {
  isSignUp: false,
};

export default DetailsForm;
