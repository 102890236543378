import { styled } from '@mui/material/styles';
import LoadingIconButton from 'components/Common/LoadingIconButton';

export const ActionButton = styled(LoadingIconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.accent,
  width: '48px',
  height: '48px',
  padding: '4px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&:disabled': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
