export default () => ({
  MuiMenu: {
    defaultProps: {
      MenuListProps: {
        sx: {
          padding: '24px 20px',
        },
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
    styleOverrides: {
      root: {
        marginTop: '8px',
        '& .MuiPaper-root': {
          borderRadius: '8px',
          border: 'solid 1px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',

          '& .MuiMenuItem-root': {
            lineHeight: '18px',
          },
        },
      },
    },
  },
});
