import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import VisitorForm from 'components/Visitors/VisitorForm';

const VisitorModal = ({
  initialValues,
  isSuccess,
  setShowToast,
  pastVisitor,
}: {
  initialValues?: VisitorProps;
  isSuccess?: boolean;
  pastVisitor?: boolean;
  setShowToast?: Dispatch<
    SetStateAction<{
      type: null | 'error' | 'success';
      open: boolean;
    }>
  >;
}) => {
  const theme = useTheme();
  const { closeModal } = useAppContext();

  const modalTitle = useMemo(() => {
    if (pastVisitor) return 'Re-add a visitor';
    if (initialValues?.visitorName) return 'Edit a visitor';

    return 'Add a new visitor';
  }, []);

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        height: '600px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },

        '&::-webkit-scrollbar-thumb': {
          background: '#888',
        },

        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      <Box position="sticky" top="0px" zIndex="101" bgcolor="background.card">
        <CardHeader
          action={
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              sx={{
                position: 'absolute',
                top: '16px',
                right: '14px',
                width: '20px',
                height: '20px',
                background: theme.palette.secondary.main,
                '&:hover': {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <CloseIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  color: theme.palette.blue[800],
                }}
              />
            </IconButton>
          }
          title={modalTitle}
          titleTypographyProps={{
            variant: 'h2',
          }}
          sx={{
            padding: '32px',
          }}
        />
        <Divider />
      </Box>
      <CardContent
        sx={{
          padding: '0px 32px 24px 32px',
        }}
      >
        <VisitorForm
          initialValues={initialValues}
          isSuccess={isSuccess}
          setShowToast={setShowToast}
          pastVisitor={pastVisitor}
        />
      </CardContent>
    </Card>
  );
};

VisitorModal.defaultProps = {
  initialValues: {},
  isSuccess: true,
  setShowToast: { type: null, open: false },
  pastVisitor: false,
};

export default VisitorModal;
