export const BANNER = {
  SLACK: {
    image: '/slack.svg',
    type: 'slack',
    title: 'Join the discussion',
    text: 'Get to know your fellow members, ask questions, chat to the Work.Life team and much more!',
    label: 'Join now',
  },
  APP: {
    image: '/app.svg',
    type: 'app',
    title: 'Download the app',
    text: 'Book meeting rooms, make the most of our perks & discounts, connect with the community and access your account on the go.',
    label: 'Send a download link',
  },
  OFFICE: {
    type: 'office',
    title: 'The final touches',
    text: 'Want a splash of colour or your very own breakout space?',
    label: 'View now',
    link: 'https://2043149.fs1.hubspotusercontent-na1.net/hubfs/2043149/Work.Life%20Member%20Extras%20Brochure.pdf',
  },
  REFER: {
    image: '/refer.svg',
    type: 'refer',
    title: 'Refer a friend',
    text: 'Spread the word, and share the reward. Find out how you can earn with our referral scheme.',
    label: 'Refer now',
    link: ' https://work.life/refer/member/',
  },
  DESK_AVAILABILITY: {
    image: '/availability.svg',
    type: 'availability',
    title: 'Quiet study or loud and vibrant?',
    text: 'Take a desk availability suggestion to find a place to suit your needs for the day.',
    label: 'Check availability',
  },
  MESSAGE: {
    image: '/raiseticket.svg',
    type: 'message',
    title: 'Can’t find what you’re looking for?',
    text: 'Don’t worry, please speak with your membership team and they will be able to help you.',
    label: 'Message us!',
  },
};
