import GridViewIcon from '@mui/icons-material/GridView';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Icon, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';

export type ViewButtonProps = {
  view: string;
  activeView: string;
};

const ViewButton = ({ view, activeView }: ViewButtonProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isGrid = view === 'grid';
  const route = isGrid ? routes.main.desks.charts : routes.main.desks.table;

  return (
    <IconButton
      onClick={() => navigate(route)}
      sx={{
        marginX: '4px',
      }}
    >
      <Icon
        component={isGrid ? GridViewIcon : MenuRoundedIcon}
        sx={{
          height: '24px',
          width: '24px',
          color: theme.palette.blue[900],
          opacity: view === activeView ? 1 : 0.65,
        }}
      />
    </IconButton>
  );
};

export default ViewButton;
