import { Box, useTheme } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import DatePickerIcon from 'assets/icons/DatePickerIcon';

export type DatePickerProps = {
  selectedDate: moment.Moment;
  setSelectedDate: (date: moment.Moment) => void;
  showOnlyMonth?: boolean;
};

const DatePicker = ({
  selectedDate,
  setSelectedDate,
  showOnlyMonth = false,
}: DatePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  return (
    <MuiDatePicker
      label="Date"
      onChange={(e) => {
        const date: any = moment(selectedDate).set({
          date: e?.date(),
          month: e?.month(),
          year: e?.year(),
        });
        setSelectedDate(date);

        const timeFrom: any = moment(searchParams.get('from'));
        const timeTo: any = moment(searchParams.get('to'));

        if (searchParams.get('capacity') !== null)
          setSearchParams({
            capacity: searchParams.get('capacity') as string,
            spaceId: searchParams.get('spaceId') as string,
            to: date
              .clone()
              .set({
                hours: timeTo.hours(),
                minutes: timeTo.minutes(),
              })
              .toISOString(),
            from: date
              .clone()
              .set({
                hours: timeFrom.hours(),
                minutes: timeFrom.minutes(),
              })
              .toISOString(),
          });
      }}
      value={selectedDate}
      dayOfWeekFormatter={(day) => day}
      minDate={moment()}
      maxDate={moment().add(1, 'year')}
      showDaysOutsideCurrentMonth
      components={{
        OpenPickerIcon: DatePickerIcon,
      }}
      OpenPickerButtonProps={{
        sx: {
          width: '32px',
          height: '32px',
          background: '#FFFFFF',
          border: `1px solid ${theme.palette.secondary.main}`,
          marginRight: '0px',
          '&:hover': {
            background: theme.palette.secondary.main,
          },
          '& .MuiSvgIcon-root': {
            width: '18px',
            height: '18px',
          },
        },
      }}
      InputAdornmentProps={{
        sx: {
          height: '32px',
        },
      }}
      views={showOnlyMonth ? ['month'] : ['day']}
      renderInput={({ inputRef, InputProps }) => (
        <Box ref={inputRef}>{InputProps?.endAdornment}</Box>
      )}
    />
  );
};

DatePicker.defaultProps = {
  showOnlyMonth: false,
};

export default DatePicker;
