import { FormControl, TextareaAutosize } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { debounce } from 'lodash-es';
import React, { ChangeEvent } from 'react';

export type CancellationNotesProps = {
  name: string;
  placeholder: string;
  theme: Theme;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
};

const StyledTextArea = styled(TextareaAutosize)({
  backgroundColor: '#fff',
  display: 'block',
  width: '100%',
  height: '140px !important',
  maxWidth: '688px',
  border: '1px solid #D9DFEF',
  borderRadius: '10px',
  resize: 'none',
  padding: '14px 16px',
  color: '',
  fontFamily: 'Antebas, sans-serif',
  fontSize: '14px',

  '&:focus': {
    outline: 'none',
  },
});

export const CancellationNotes = ({
  name,
  placeholder,
  theme,
  setNotes,
}: CancellationNotesProps) => (
  <FormControl
    sx={{
      width: 450,
      margin: 'auto',
      marginTop: '24px',
    }}
  >
    <StyledTextArea
      name={name}
      placeholder={placeholder}
      sx={{
        border: `1px solid ${theme.palette.mainBorderColor}`,
        '&:focus': {
          borderColor: theme.palette.secondary.main,
        },
      }}
      onChange={debounce((event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
      }, 500)}
    />
  </FormControl>
);
