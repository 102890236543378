import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiTab: {
    styleOverrides: {
      root: {
        width: '160px',
        height: '40px',
        minHeight: '40px',
        color: '#1D1954',
        fontWeight: '400',
        borderRadius: '8px 8px 0 0',
        backgroundColor: 'white',
        border: '2px solid #FABE79',
        borderBottom: 'none',
        padding: '12px 8px',
        marginTop: '8px',
        marginRight: '8px',
        '&.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: '#1D1954',
          fontWeight: '700',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});
