/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

const ToastSuccessIcon = ({ fill = '#68CA3D', ...props }) => (
  <SvgIcon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="10" fill={fill} />
    <path
      d="M14.6673 6.5L8.25065 12.9167L5.33398 10"
      fill="none"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ToastSuccessIcon;
