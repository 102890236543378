import { Grid } from '@mui/material';

import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';

const SOCIALS = [
  'twitter',
  'facebook',
  'instagram',
  'linkedin',
  'skype',
  'google',
  'github',
  'flickr',
  'vimeo',
  'blogger',
  'tumblr',
];

const SocialForm = () => (
  <Section title="Social networks" id="social">
    <Grid container spacing={2}>
      {SOCIALS.map((social) => (
        <Grid key={social} item xs={12} lg={4}>
          <TextInput
            label={social.charAt(0).toUpperCase() + social.slice(1)}
            name={social}
            type="text"
          />
        </Grid>
      ))}
    </Grid>
  </Section>
);

export default SocialForm;
