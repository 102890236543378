import { Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from 'assets/icons/ArrowIcon';

type BackButtonProps = {
  label: string;
  to: string | any;
  isHeader?: boolean;
  wrapIconWithButton?: boolean;
};

const BackButton = ({
  label,
  to,
  isHeader,
  wrapIconWithButton = false,
}: BackButtonProps) => {
  const navigate = useNavigate();

  if (!wrapIconWithButton) {
    return (
      <Box display="flex">
        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate(to)}
          sx={{ p: 0, minWidth: '0', flexShrink: 0 }}
          startIcon={
            <ArrowIcon
              sx={{
                width: '32px',
                height: '32px',
                padding: '8px',
                transform: 'rotate(180deg)',
                backgroundColor: 'text.main',
                color: 'blue.900',
                borderRadius: '50%',
                boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
                marginRight: '1px',
              }}
            />
          }
        />
        <Typography variant={isHeader ? 'h1' : 'inherit'}>{label}</Typography>
      </Box>
    );
  }

  return (
    <Button
      variant="text"
      color="inherit"
      onClick={() => navigate(to)}
      sx={{ p: 0 }}
      startIcon={
        <ArrowIcon
          sx={{
            width: '32px',
            height: '32px',
            padding: '8px',
            transform: 'rotate(180deg)',
            backgroundColor: 'text.main',
            color: 'blue.900',
            borderRadius: '50%',
            boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
          }}
        />
      }
    >
      <Typography variant={isHeader ? 'h1' : 'inherit'}>{label}</Typography>
    </Button>
  );
};

BackButton.defaultProps = {
  isHeader: true,
  wrapIconWithButton: false,
};

export default BackButton;
