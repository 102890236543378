import {
  Typography,
  Box,
  useTheme,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import TextInput from 'components/Form/TextInput';

const CompanyForm = () => {
  const theme = useTheme();

  const [removePhoto, setRemovePhoto] = useState(false);

  const { register, setValue, resetField } = useFormContext();

  useEffect(() => {
    if (removePhoto) {
      setValue('removePhoto', true, {
        shouldDirty: true,
      });
    } else {
      resetField('removePhoto');
    }
  }, [removePhoto]);

  return (
    <Grid item xs={12} lg={6}>
      <Box>
        <TextInput label="Name" name="name" type="text" disabled />
      </Box>
      <Box mt="24px">
        <TextInput label="Website" name="website" type="text" />
      </Box>
      <Box mt="24px">
        <FormControlLabel
          control={
            <Checkbox
              {...register('removePhoto')}
              checked={removePhoto}
              onChange={() => setRemovePhoto((isChecked) => !isChecked)}
            />
          }
          label={
            <Typography
              fontWeight={theme.typography.fontWeightBold}
              fontSize={theme.typography.smallFontSize}
            >
              Remove my photo
            </Typography>
          }
          sx={{
            marginBottom: '32px',
          }}
        />
      </Box>
    </Grid>
  );
};

export default CompanyForm;
