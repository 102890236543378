/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const WifiIcon = (props: SvgIconProps) => (
  <SvgIcon width="32px" height="24px" viewBox="0 0 32 24" {...props}>
    <path
      d="M6.37514 11.7563C9.09291 9.49256 12.5181 8.25294 16.0551 8.25294C19.5922 8.25294 23.0174 9.49256 25.7351 11.7563M1.45264 6.87502C5.47097 3.33297 10.6435 1.37866 16.0001 1.37866C21.3567 1.37866 26.5293 3.33297 30.5476 6.87502M11.2289 16.6513C12.6248 15.6595 14.2947 15.1267 16.007 15.1267C17.7193 15.1267 19.3892 15.6595 20.7851 16.6513M16.0001 22H16.0139"
      fill="none"
      stroke="currentColor"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default WifiIcon;
