import { useMutation, useQueryClient } from 'react-query';

import ActionMenu from 'components/Common/ActionMenu';
import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';

enum TYPES {
  ALL = 'all',
  READ = 'read',
}

const AlertsMenu = () => {
  const queryClient = useQueryClient();
  const { snack, setSnack } = useToast();

  const { mutate: clear } = useMutation(
    (type: TYPES) =>
      API.delete(
        type === TYPES.ALL
          ? APIRoutes.alerts.clearAll
          : APIRoutes.alerts.clearRead,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('alerts');
      },
      onError: () =>
        setSnack({
          ...snack,
          message: 'Unable to clear alerts',
          open: true,
          type: 'error',
        }),
    },
  );

  return (
    <ActionMenu
      options={[
        {
          label: 'Clear read',
          onSelect: () => clear(TYPES.READ),
        },
        {
          label: 'Clear all',
          onSelect: () => clear(TYPES.ALL),
        },
      ]}
    />
  );
};

export default AlertsMenu;
