import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import AlertsIcon from 'assets/icons/AlertsIcon';
import { API, APIRoutes } from 'utils/api';

const getAlerts = async () => {
  const { data } = await API.get(APIRoutes.alerts.index());
  return data;
};

const AlertsNotificationIcon = () => {
  const queryClient = useQueryClient();
  const [hasNotification, setHasNotification] = useState(false);

  const { data } = useQuery(['alerts', { notification: true }], getAlerts, {
    refetchInterval: 60 * 1000,
  });

  useEffect(() => {
    const flag = data?.totalUnread > 0;
    if (flag !== hasNotification) {
      setHasNotification(flag);
      queryClient.invalidateQueries({
        queryKey: ['alerts'],
        exact: true,
      });
    }
  }, [data]);

  return <AlertsIcon hasNotification={hasNotification} />;
};

export default AlertsNotificationIcon;
