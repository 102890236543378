import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { Backdrop, Button, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import routes from 'utils/routes';

import { useAppContext } from 'context/AppContext';
import CloseIcon from 'assets/icons/CloseIcon';
import { MENU_TYPES } from 'constants/common';
import { useToast } from 'context/ToastContext';
import { API } from 'utils/api';
import { useAuth } from 'context/AuthContext';
import BookingDetails from './BookingDetails';
import { MotionBox } from './style';

export type BookingConfirmationProps = {
  details: EventProps;
};

const variants = {
  closed: {
    y: '-100%',
  },
  open: {
    y: '0',
    transition: { stiffness: 1000 },
  },
};

const BookingConfirmation = ({ details }: BookingConfirmationProps) => {
  const { snack, setSnack } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const { anchorEl, setAnchorEl } = useAppContext();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const type = MENU_TYPES.CONFIRMATION;
  const open = anchorEl[type];

  const { mutate: bookEvent, isLoading } = useMutation(
    (bookUrl: string) => API.post(bookUrl, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events-booked');
        navigate(routes.main.eventsBooked);
      },
      onSettled: (_, error) => {
        setAnchorEl({ ...anchorEl, [type]: false });
        setSnack({
          ...snack,
          message: error ? 'Event booking error' : 'Event booking confirmed',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <>
      <Tooltip
        title={
          user?.canPurchaseEvents
            ? null
            : 'You cannot purchase events. Contact with your Team Leader.'
        }
      >
        <span>
          <Button
            id="dropdown-button"
            aria-controls={open ? 'dropdown-button' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={() => setAnchorEl({ ...anchorEl, [type]: true })}
            variant="contained"
            disabled={
              !details.bookable || !details.bookUrl || !user?.canPurchaseEvents
            }
            sx={{
              marginLeft: 'auto',
              backgroundColor: theme.palette.blue[600],
              height: 44,
              padding: '12px 34px',
            }}
          >
            Book now
          </Button>
        </span>
      </Tooltip>

      <Backdrop open={open}>
        <MotionBox
          initial={false}
          animate={open ? 'open' : 'closed'}
          variants={variants}
        >
          <IconButton
            aria-label="close"
            onClick={() => setAnchorEl({ ...anchorEl, [type]: false })}
            sx={{
              width: '20px',
              height: '20px',
              position: 'absolute',
              top: '16px',
              right: '12px',
              background: theme.palette.secondary.main,
              '&:hover': {
                background: theme.palette.secondary.main,
              },
            }}
          >
            <CloseIcon
              sx={{
                height: '14px',
                width: '14px',
                color: theme.palette.blue[900],
              }}
            />
          </IconButton>
          <BookingDetails
            details={details}
            bookEvent={bookEvent}
            isLoading={isLoading}
          />
        </MotionBox>
      </Backdrop>
    </>
  );
};

export default BookingConfirmation;
