import {
  Box,
  Icon,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash-es';

import { useNavigate } from 'react-router-dom';
import DataTable from 'components/Common/DataTable';
import HelpIcon from 'assets/icons/HelpIcon';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import DownloadButton from './DownloadButton';
import { ActionButton } from './style';
import { mapStatus } from './helpers';
import AcceptPaymentButton from './AcceptPaymentButton';

const LABELS = ['Full name', 'Date', 'Amount', 'Due', 'Status'];

const InvoicesTable = ({
  label,
  invoices,
}: {
  label: string;
  invoices: InvoiceProps[];
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();

  if (isEmpty(invoices)) {
    return null;
  }

  return (
    <Box marginBottom="41px">
      <Typography
        variant="h4"
        fontWeight={theme.typography.fontWeightBold}
        marginBottom="16px"
      >
        {label}
      </Typography>
      <DataTable labels={LABELS}>
        {invoices.map((invoice) => {
          const date = moment(invoice.dueDate).format('DD/MM/YY');
          const id = invoice.downloadLink.replace('/invoices/', '');
          const status = mapStatus(invoice.status);

          return (
            <TableRow
              key={invoice.invoiceNo}
              sx={{
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <TableCell>{invoice.invoiceNo}</TableCell>
              <TableCell>{date}</TableCell>
              <TableCell>£{invoice.totalAmount.toFixed(2)}</TableCell>
              <TableCell>£{invoice.dueAmount.toFixed(2)}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap="6px">
                  <Icon
                    component={status.icon}
                    sx={{
                      height: '24px',
                      width: '24px',
                      color: theme.palette.secondary.main,
                    }}
                  />
                  {status.label}
                </Box>
              </TableCell>
              <TableCell align="right">
                <Box display="flex" gap={1} justifyContent="end">
                  {status.label === 'Overdue' &&
                    user?.allowCreditCardPayment && (
                      <AcceptPaymentButton
                        invoiceId={invoice.invoiceId}
                        amount={invoice.totalAmount.toFixed(2)}
                        invoiceNumber={invoice.invoiceNo}
                      />
                    )}
                  <ActionButton
                    isLoading={false}
                    onClick={() => navigate(routes.main.help.raiseTicket)}
                  >
                    <HelpIcon />
                  </ActionButton>
                  <DownloadButton id={id} name={invoice.invoiceNo} />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </DataTable>
    </Box>
  );
};

export default InvoicesTable;
