import { useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTheme } from '@mui/material/styles';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CloseIcon from 'assets/icons/CloseIcon';
import CheckBox from 'components/Form/CheckBox';
import TextInput from 'components/Form/TextInput';
import { useAppContext } from 'context/AppContext';
import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';
import { OPTIONS } from 'constants/signUp';
import InductionForm from './InductionForm';

type FormData = {
  fullName: string;
  email: string;
  teamAdministrator: boolean;
  canMakeBookings: boolean;
  canPurchaseProducts: boolean;
  canPurchaseEvents: boolean;
  // induction
  inductionSpaceId: number | undefined;
  inductionDate: string | undefined;
  inductionTime: string | undefined;
};

const wrapperSchema = (bankHolidays: string[] | undefined) =>
  yup
    .object({
      fullName: yup
        .string()
        .required('Full name is required')
        .matches(/^(?!\s)/, 'Cannot start with a blank space'),
      email: yup
        .string()
        .email('Invalid e-mail')
        .required('E-mail is required'),
      inductionSpaceId: yup.number().required('Induction space is required'),
      inductionDate: yup
        .string()
        .test(
          'cant-above-3months',
          'Induction date cannot be more than 3 months on advance,',
          (d) => {
            const threeMonths = moment().add(3, 'months');
            return !moment(d).isAfter(threeMonths);
          },
        )
        .test(
          'cant-be-weekend',
          'Induction date cannot be set on Saturday on Sunday.',
          (d) => {
            const weekday = moment(d).isoWeekday();

            return !(weekday === 6 || weekday === 7);
          },
        )
        .test(
          'cant-be-today',
          'Induction date cannot be set to today.',
          (d) => {
            const isTodayBlocked =
              moment(d).isSame(moment(), 'day') &&
              moment().isSameOrAfter(moment({ hour: 15, minute: 0 }));
            return !isTodayBlocked;
          },
        )
        .test('cant-be-past', 'Induction date cannot be in the past.', (d) => {
          const isInThePast = moment(d).isBefore(moment(), 'day');
          return !isInThePast;
        })
        .test(
          'cant-be-bank',
          'Induction date cannot be on bank holiday.',
          (d) => {
            const isBankHoliday = bankHolidays?.includes(
              moment(d).format('YYYY-MM-DD'),
            );
            return !isBankHoliday;
          },
        )
        .test('must-be-full-date', 'Please provide full date', (d) => {
          const isNotFullDate = d === 'Invalid date';
          return !isNotFullDate;
        })
        .test(
          'cant-be-before-borough-launch',
          'Borough is not available before 8th July.',
          (d, context) => {
            const spaceId = context.parent.inductionSpaceId;
            const inductionDate = moment(d);

            if (
              spaceId === 1415144936 &&
              inductionDate.isBefore(moment('2024-07-08', 'YYYY-MM-DD'))
            )
              return false;

            return true;
          },
        )
        .typeError('Induction date is required')
        .required(),
      inductionTime: yup.string().test({
        name: 'induction-time-check',
        test: (value, ctx) => {
          const { inductionDate } = ctx.parent;
          const isToday = moment(inductionDate).isSame(moment(), 'day');

          if (isToday) {
            if (
              (moment().isSameOrAfter(moment({ hour: 9, minute: 0 })) &&
                value === '9:00am') ||
              (moment().isSameOrAfter(moment({ hour: 15, minute: 0 })) &&
                value === '3:00pm')
            ) {
              return ctx.createError({
                message:
                  'Selected time is in the past. Change either time or date.',
                path: 'inductionTime',
              });
            }
            return true;
          }
          return true;
        },
      }),
    })
    .required();

const getBankHolidays = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.bankHolidays);
  return data;
};

type AddTeamMemberModalProps = {
  teamId: number;
};

const AddTeamMemberModal = ({ teamId }: AddTeamMemberModalProps) => {
  const theme = useTheme();
  const { spaces, user, restoreProfile } = useAuth();

  const { spaceId } = user || { spaceId: null };
  const { closeModal } = useAppContext();
  const { snack, setSnack } = useToast();
  const queryClient = useQueryClient();

  const initDate = useMemo(() => {
    const today = moment.utc().startOf('day');
    let daysRemaining = 1;

    while (daysRemaining > 0) {
      today.add(1, 'day');
      if (!(today.isoWeekday() === 6 || today.isoWeekday() === 7)) {
        daysRemaining -= 1;
      }
    }
    return today.toISOString();
  }, []);

  const { isSuccess: bankHolidaysSuccess, data: bankHolidays } = useQuery<
    string[]
  >('bankHolidays', () => getBankHolidays());

  const { mutate: addTeamMember, isLoading } = useMutation(
    (data: FormData) => API.post(APIRoutes.teams.add(teamId), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('team');
        restoreProfile();
      },
      onSettled: (_, error: any) => {
        closeModal();
        setSnack({
          ...snack,
          message: error ? `Error: ${error.message}` : 'Success',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(wrapperSchema(bankHolidays)),
    defaultValues: {
      fullName: '',
      email: '',
      teamAdministrator: false,
      canMakeBookings: false,
      canPurchaseProducts: false,
      canPurchaseEvents: false,
      inductionDate: initDate,
      inductionTime: OPTIONS.inductionTime[0].value,
      inductionSpaceId: spaceId || undefined,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
    control,
    trigger,
    resetField,
  } = methods;

  useEffect(() => {
    if (spaceId) {
      resetField('inductionSpaceId', {
        defaultValue: spaces?.find((space) => space.id === spaceId)?.id,
      });
    }
  }, [spaces]);
  const inductionDate = useWatch({
    control,
    name: 'inductionDate',
  });

  useEffect(() => {
    trigger('inductionTime');
  }, [inductionDate]);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const payload = {
      ...data,
      inductionDate: moment(data?.inductionDate).toISOString(),
    };
    addTeamMember(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              overflowY: 'auto',
              maxWidth: '760px',
            }}
          >
            <Box
              position="sticky"
              top="0px"
              zIndex="101"
              bgcolor="background.card"
            >
              <CardHeader
                action={
                  <IconButton
                    aria-label="close"
                    onClick={() => closeModal()}
                    sx={{
                      position: 'absolute',
                      top: '16px',
                      right: '32px',
                      width: '20px',
                      height: '20px',
                      background: theme.palette.secondary.main,
                      '&:hover': {
                        background: theme.palette.secondary.main,
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        width: '14px',
                        height: '14px',
                        color: theme.palette.blue[800],
                      }}
                    />
                  </IconButton>
                }
                title={
                  <Typography
                    fontSize="26px"
                    fontWeight={theme.typography.fontWeightBold}
                    color={theme.palette.blue[900]}
                  >
                    Add new team member
                  </Typography>
                }
                sx={{
                  padding: '24px 32px 24px 32px',
                }}
              />
              <Divider />
            </Box>
            <CardContent
              sx={{
                padding: '24px 32px 24px 32px',
              }}
            >
              <Box display="flex" gap="48px">
                <Box width="50%" pt="10px">
                  <Box>
                    <TextInput
                      label="Full name"
                      name="fullName"
                      type="text"
                      required
                    />
                  </Box>
                  <Box mt="8px">
                    <TextInput
                      label="Email"
                      name="email"
                      type="email"
                      required
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <CheckBox
                    name="teamAdministrator"
                    label="Team administrator"
                    fontBold
                  />
                  <CheckBox
                    name="canMakeBookings"
                    label="Can make bookings"
                    fontBold
                  />
                  <CheckBox
                    name="canPurchaseProducts"
                    label="Can purchase products"
                    fontBold
                  />
                  <CheckBox
                    name="canPurchaseEvents"
                    label="Can purchase events"
                    fontBold
                  />
                </Box>
              </Box>
              {bankHolidaysSuccess && (
                <InductionForm spaces={spaces} bankHolidays={bankHolidays} />
              )}
            </CardContent>
            <Divider sx={{ mt: '8px' }} />
            <LoadingButton
              disabled={!isDirty}
              isLoading={isLoading}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: '200px',
                height: '48px',
                justifyContent: 'center',
                margin: '32px 32px 44px',
              }}
            >
              Add team member
            </LoadingButton>
          </Card>
        </form>
      </FormProvider>
    </LocalizationProvider>
  );
};

export default AddTeamMemberModal;
