import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import ViewBasketButton from 'components/Store/ViewBasketButton';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import ProductCard from 'components/Store/ProductCard';
import AdBanner from 'components/AdBanner';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';
import EmptySection from 'components/Common/EmptySection';
import { BANNER } from 'constants/banner';

const breadcrumbsConfig = () => [{ label: 'Store' }];

const Store = () => {
  const navigate = useNavigate();
  const { isBasketEmpty } = useAuth();
  const { resetQuantity } = useAppContext();

  useEffect(() => () => resetQuantity(), []);

  const getProducts = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.store.products);
    return data;
  };

  const {
    isLoading,
    isSuccess,
    data: products,
  } = useQuery<ProductProps[]>('products', () => getProducts());

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <>
        <SectionHeader
          title="Store"
          hideBackButton
          path={breadcrumbsConfig()}
          elements={<ViewBasketButton hasNotification={!isBasketEmpty} />}
        />
        <Section>
          <Box mb={2}>
            <AdBanner
              type={BANNER.OFFICE.type}
              title={BANNER.OFFICE.title}
              subtitle={BANNER.OFFICE.text}
              label={BANNER.OFFICE.label}
              onClick={() => {
                window.open(BANNER.OFFICE.link, '_blank');
              }}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill,minmax(352px, 1fr))"
            gap={2}
          >
            {!isEmpty(products) &&
              products.map((product) => (
                <ProductCard key={product.Id} details={product} />
              ))}
          </Box>
          <Box my={2}>
            <AdBanner
              image={BANNER.MESSAGE.image}
              type={BANNER.MESSAGE.type}
              title={BANNER.MESSAGE.title}
              subtitle={BANNER.MESSAGE.text}
              label={BANNER.MESSAGE.label}
              noTopMargin
              onClick={() => navigate(routes.main.help.raiseTicket)}
            />
          </Box>
          {isEmpty(products) && (
            <EmptySection
              missing="your store is empty"
              icon={<img src="/assets/images/EmptyBasket.svg" alt="Basket" />}
            />
          )}
          <Box textAlign="center" mt={isEmpty(products) ? 10 : 4}>
            <ScrollTopButton />
          </Box>
        </Section>
      </>
    );
  }
  return null;
};

export default Store;
