import { useState } from 'react';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';

import EditIcon from 'assets/icons/EditIcon';

type ActionMenuProps = {
  options: {
    label: string | null;
    onSelect: () => void;
  }[];
};

const ActionMenu = ({ options }: ActionMenuProps) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          width: '40px',
          height: '40px',
          border: `solid 1px ${theme.palette.secondary.main}`,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: open ? theme.palette.secondary.main : '#FFFFFF',
        }}
        onClick={handleClick}
      >
        <EditIcon
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        BackdropProps={{
          sx: {
            background: 'transparent',
            backdropFilter: 'none',
          },
        }}
        sx={{
          '& .MuiPaper-root': {
            borderColor: theme.palette.secondary.main,
          },
        }}
      >
        {options
          .filter((x) => x.label !== null)
          .map((option, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => {
                handleClose();
                option.onSelect();
              }}
              sx={{
                paddingX: '0px',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default ActionMenu;
