import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import BookingOptions from 'components/Rooms/BookingsOptions';
import ClockIcon from 'assets/icons/ClockIcon';
import { getTimeArray } from 'components/Rooms/helpers';

const TIME_RANGE = {
  start: { hour: 8, minute: 30 },
  end: { hour: 17, minute: 45 },
};

const TimeField = ({
  setIsDisabledChoice,
}: {
  setIsDisabledChoice: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getValues } = useFormContext();
  const values = getValues();

  const options = useMemo(
    () => getTimeArray(TIME_RANGE.start, TIME_RANGE.end, values),
    [values.day, values.month],
  );

  return (
    <BookingOptions
      icon={ClockIcon}
      title="What time?"
      category="time"
      options={options}
      setIsDisabledChoice={() => setIsDisabledChoice}
    />
  );
};

export default TimeField;
