/* eslint-disable prefer-const */
import React, { useEffect, useMemo, useState } from 'react';
import {
  CardContent,
  CardActionArea,
  Typography,
  Box,
  Button,
  LinearProgress,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useQueryClient } from 'react-query';
import MotionCard from 'components/MotionCard';
import routes from 'utils/routes';
import theme from 'styles/theme';
import { useAppContext } from 'context/AppContext';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';

const MS = 60 * 1000;
const getDuration = (date: Moment) =>
  date.diff(moment().set({ second: 0, millisecond: 0 }), 'minutes');

const CurrentBookingItem = ({
  _id,
  checkIn,
  checkOut,
  resource,
  nonClickable = false,
  isPartTimeBooking,
}: BookingProps) => {
  const { openModal } = useAppContext();
  const queryClient = useQueryClient();
  const parsedFrom = moment(checkIn);
  const parsedTo = moment(checkOut);
  const [durationInMinutes, setDurationInMinutes] = useState(
    getDuration(parsedTo),
  );

  const bookingProgress = useMemo(() => {
    const totalDuration = parsedTo.diff(parsedFrom, 'minutes');
    return (1 - durationInMinutes / totalDuration) * 100;
  }, [durationInMinutes, parsedFrom, parsedTo]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    let timeout: NodeJS.Timer;
    const updateDuration = () => setDurationInMinutes(getDuration(parsedTo));

    timeout = setTimeout(() => {
      updateDuration();
      interval = setInterval(updateDuration, MS);
    }, (60 - moment().seconds()) * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (durationInMinutes < 1) {
      queryClient.setQueryData<BookingProps[] | undefined>(
        ['bookings', { isPartTimeBooking }],
        (oldData) => oldData?.filter((booking) => booking._id !== _id),
      );
    }
  }, [durationInMinutes, isPartTimeBooking]);

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        overflow: 'visible',
      }}
    >
      <CardActionArea
        component={nonClickable ? Box : Link}
        to={(isPartTimeBooking
          ? routes.main.upcomingOfficeDayBooking
          : routes.upcomingBooking)(_id)}
      >
        <CardContent
          sx={{
            padding: '14px 16px 14px 16px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Stack
            sx={{
              overflow: 'hidden',
              width: '100%',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 'fontWeightBold',
                  lineHeight: '17px',
                }}
                noWrap
              >
                {resource.name}
              </Typography>
              <Typography sx={{ lineHeight: '17px' }}>
                {Math.floor(durationInMinutes / 60)}h {durationInMinutes % 60}m
                <Typography
                  component="span"
                  sx={{
                    color: 'text.content',
                    fontSize: 'smallFontSize',
                    lineHeight: '16.25px',
                    opacity: 0.65,
                  }}
                >
                  {' '}
                  until booking ends
                </Typography>
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={bookingProgress}
            />
          </Stack>
          <Button
            onClick={(e) => {
              e.preventDefault();
              openModal(
                <MeetingRoomModal
                  isPartTimeBooking={isPartTimeBooking}
                  initialValues={{
                    capacity: resource.capacity.toString(),
                    time: checkOut,
                  }}
                />,
              );
            }}
            variant="contained"
            sx={{
              justifyContent: 'center',
              backgroundColor: theme.palette.primary.main,
              width: '128px',
            }}
          >
            Extend
          </Button>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

CurrentBookingItem.defaultProps = {};

export default CurrentBookingItem;
