import { useFormContext, Controller } from 'react-hook-form';

export type HiddenInputProps = {
  name: string;
};

const HiddenInput = ({ name }: HiddenInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...rest } }) => (
        <input type="hidden" value={value} {...rest} />
      )}
    />
  );
};

export default HiddenInput;
