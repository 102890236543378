import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {},
      inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
          paddingTop: 0,
          paddingLeft: 0,
        },
      },
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: '#ffff!important',
          '&.MuiFocused': {
            backgroundColor: 'rgba(69, 56, 206, 0.08)!important',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(69, 56, 206, 0.08)!important',
        },
      },
      paper: {
        backgroundColor: '#ffffff',
        border: `1px solid ${theme.palette.mainBorderColor}`,
        fontSize: '16px',
      },
    },
  },
});
