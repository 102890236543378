import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = ({
  minHeight,
  width,
  size,
}: {
  minHeight?: string;
  width?: string;
  size?: number;
}) => (
  <Box
    minHeight={minHeight}
    width={width}
    alignItems="center"
    justifyContent="center"
    display="flex"
  >
    <CircularProgress size={size} />
  </Box>
);

LoadingSpinner.defaultProps = {
  minHeight: '0px',
  width: 'auto',
  size: 40,
};

export default LoadingSpinner;
