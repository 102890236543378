import { useQuery } from 'react-query';
import Slider from 'components/Slider';
import Section from 'components/Section';
import CardItem from 'components/Common/CardItem';
import AdBanner from 'components/AdBanner';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import { useAuth } from 'context/AuthContext';
import OfficeBanner from 'components/OfficeBanner';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import { ACCOUNT_TYPES } from 'constants/account';
import { BANNER } from 'constants/banner';
import AppBanner from 'components/AppBanner';

const getSlackInvite = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.user.slack);
  return data;
};

const EventsSection = () => {
  const { currentSpaceId, userType } = useAuth();

  const getEvents = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.events.index(LIMITS.DASHBOARD_SECTION, 1, currentSpaceId),
    );
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<EventProps[]>(
    ['events', currentSpaceId],
    () => getEvents(),
  );

  const {
    isLoading: isLoadingSlackInvite,
    isSuccess: isSuccessSlackInvite,
    data: slackInvite,
  } = useQuery('slack', () => getSlackInvite(), {
    enabled: [ACCOUNT_TYPES.FLEX, ACCOUNT_TYPES.UNLIMITED].includes(userType),
  });

  return (
    <Section>
      {isLoading && isLoadingSlackInvite && (
        <LoadingSpinner minHeight="334px" />
      )}
      {isSuccess && isSuccessSlackInvite && (
        <>
          <Slider
            id="events"
            title="Events"
            type="events"
            emptyComponent
            items={data.map((event) => (
              <CardItem
                type="events"
                key={`event-${event.id}`}
                summary={event.shortDescription}
                title={event.title}
                id={event.id}
                created={event.startDate}
                imageUrl={event.imageUrl}
                location={event.address}
              />
            ))}
          />

          {[ACCOUNT_TYPES.RESIDENT_PREACTIVE, ACCOUNT_TYPES.RES_TEAM].includes(
            userType,
          ) && <AppBanner />}

          {[ACCOUNT_TYPES.FLEX, ACCOUNT_TYPES.UNLIMITED].includes(userType) && (
            <AdBanner
              image={BANNER.SLACK.image}
              type={BANNER.SLACK.type}
              title={BANNER.SLACK.title}
              subtitle={BANNER.SLACK.text}
              label={BANNER.SLACK.label}
              onClick={() => window.open(slackInvite.url, '_blank')}
            />
          )}

          {[ACCOUNT_TYPES.RESIDENT_ACTIVE].includes(userType) && (
            <OfficeBanner />
          )}

          {[
            ACCOUNT_TYPES.RESIDENT_ACTIVE,
            ACCOUNT_TYPES.UNLIMITED,
            ACCOUNT_TYPES.FLEX,
          ].includes(userType) && <AppBanner />}

          {[ACCOUNT_TYPES.RES_TEAM].includes(userType) && <OfficeBanner />}

          {[ACCOUNT_TYPES.RESIDENT_PREACTIVE].includes(userType) && (
            <AdBanner
              image={BANNER.REFER.image}
              type={BANNER.REFER.type}
              title={BANNER.REFER.title}
              subtitle={BANNER.REFER.text}
              label={BANNER.REFER.label}
              onClick={() => {
                window.open(BANNER.REFER.link, '_blank');
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default EventsSection;
