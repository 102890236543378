import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { breakpoint } from 'styles/breakpoints';
import theme from 'styles/theme';

export const Wrapper = styled('div')({
  padding: '80px 60px 60px',
  backgroundImage: `url('/assets/images/Smile.svg')`,
  backgroundSize: '126px 81px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '65% bottom',
  position: 'relative',
  bottom: 0,
  width: '100%',
  gridArea: 'footer',
});

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  margin: '0 auto',
  paddingLeft: 200,
});

export const Row = styled(Box)`
  display: grid;
  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  @media ${breakpoint.lg} {
    grid-template-columns: 170px 170px 170px 1fr;
    grid-gap: 20px;
  }
`;

export const FooterLink = styled('a')({
  display: 'inline-block',
  height: 25,
  color: '#fff',
  marginBottom: 20,
  fontWeight: '400',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.orange[700],
    fontWeight: '700',
  },
});

export const SocialMedias = styled(Box)`
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  @media ${breakpoint.lg} {
    justify-content: flex-end;
  },
  `;

export const SocialMedia = styled('a')({
  display: 'inline-block',
  '&:hover': {
    color: theme.palette.orange[700],
  },
});

export const CopyrightText = styled('p')`
  font-size: 13px;
  color: #fff;
  margin-top: 70px;
  text-align: left;
  @media ${breakpoint.lg} {
    text-align: right;
  },
  `;

export const Logo = styled('img')({
  position: 'absolute',
  left: 60,
  width: 148,
});

export const CertifiedCorporation = styled('img')` 
  margin: 50px auto 0 0;
  @media ${breakpoint.lg} {
    margin: 30px 0 0 auto;
  },
`;
