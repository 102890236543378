/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const ThumbsUpIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_2554_6725)">
      <path
        d="M9.33398 6.00004V3.33337C9.33398 2.80294 9.12327 2.29423 8.7482 1.91916C8.37313 1.54409 7.86442 1.33337 7.33398 1.33337L4.66732 7.33337V14.6667H12.1873C12.5089 14.6703 12.8209 14.5576 13.0659 14.3494C13.3109 14.1411 13.4724 13.8513 13.5207 13.5334L14.4407 7.53337C14.4697 7.34228 14.4568 7.14716 14.4029 6.96154C14.349 6.77592 14.2554 6.60424 14.1286 6.45838C14.0017 6.31253 13.8447 6.196 13.6684 6.11685C13.492 6.03771 13.3006 5.99785 13.1073 6.00004H9.33398ZM4.66732 14.6667H2.66732C2.3137 14.6667 1.97456 14.5262 1.72451 14.2762C1.47446 14.0261 1.33398 13.687 1.33398 13.3334V8.66671C1.33398 8.31309 1.47446 7.97395 1.72451 7.7239C1.97456 7.47385 2.3137 7.33337 2.66732 7.33337H4.66732"
        fill="#68CA3D"
        fillOpacity="0.6"
      />
      <path
        d="M4.66732 14.6667H2.66732C2.3137 14.6667 1.97456 14.5262 1.72451 14.2762C1.47446 14.0261 1.33398 13.687 1.33398 13.3334V8.66671C1.33398 8.31309 1.47446 7.97395 1.72451 7.7239C1.97456 7.47385 2.3137 7.33337 2.66732 7.33337H4.66732M9.33398 6.00004V3.33337C9.33398 2.80294 9.12327 2.29423 8.7482 1.91916C8.37313 1.54409 7.86442 1.33337 7.33398 1.33337L4.66732 7.33337V14.6667H12.1873C12.5089 14.6703 12.8209 14.5576 13.0659 14.3494C13.3109 14.1411 13.4724 13.8513 13.5207 13.5334L14.4407 7.53337C14.4697 7.34228 14.4568 7.14716 14.4029 6.96154C14.349 6.77592 14.2554 6.60424 14.1286 6.45838C14.0017 6.31253 13.8447 6.196 13.6684 6.11685C13.492 6.03771 13.3006 5.99785 13.1073 6.00004H9.33398Z"
        fill="none"
        stroke="#1D1954"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2554_6725">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ThumbsUpIcon;
