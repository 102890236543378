import { Theme } from '@mui/material';
import CheckboxIcon from 'assets/icons/CheckboxIcon';
import CheckboxCheckedIcon from 'assets/icons/CheckboxCheckedIcon';

export default ({ theme }: { theme: Theme }) => ({
  MuiCheckbox: {
    defaultProps: {
      color: 'default',
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxCheckedIcon />,
    },
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
        },
      },
    },
  },
});
