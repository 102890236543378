/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const PinIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    color="inherit"
    {...props}
  >
    <path
      d="M18.25 9.16666C18.25 15.5833 10 21.0833 10 21.0833C10 21.0833 1.75 15.5833 1.75 9.16666C1.75 6.97862 2.61919 4.8802 4.16637 3.33303C5.71354 1.78585 7.81196 0.916656 10 0.916656C12.188 0.916656 14.2865 1.78585 15.8336 3.33303C17.3808 4.8802 18.25 6.97862 18.25 9.16666Z"
      fill="inherit"
    />
    <path
      d="M10 11.9167C11.5188 11.9167 12.75 10.6854 12.75 9.16666C12.75 7.64787 11.5188 6.41666 10 6.41666C8.48122 6.41666 7.25 7.64787 7.25 9.16666C7.25 10.6854 8.48122 11.9167 10 11.9167Z"
      fill="inherit"
    />
    <path
      d="M18.25 9.16666C18.25 15.5833 10 21.0833 10 21.0833C10 21.0833 1.75 15.5833 1.75 9.16666C1.75 6.97862 2.61919 4.8802 4.16637 3.33303C5.71354 1.78585 7.81196 0.916656 10 0.916656C12.188 0.916656 14.2865 1.78585 15.8336 3.33303C17.3808 4.8802 18.25 6.97862 18.25 9.16666Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.83333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.9167C11.5188 11.9167 12.75 10.6854 12.75 9.16666C12.75 7.64787 11.5188 6.41666 10 6.41666C8.48122 6.41666 7.25 7.64787 7.25 9.16666C7.25 10.6854 8.48122 11.9167 10 11.9167Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.83333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PinIcon;
