import { useState, useCallback, useEffect } from 'react';
import { Box, TextField, FormHelperText, Autocomplete } from '@mui/material';
import { Controller, get, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash-es';
import LoadingSpinner from 'components/Common/LoadingSpinner';

export type OptionType = {
  label: string;
  value: number | string;
};

type SelectFieldProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  fetchFunction: any;
  getLabelFromFetchFunction: (el: Record<string, any>) => string;
  getValueFromFetchFunction: (el: Record<string, unknown>) => number | string;
  isOptionEqualToValue?: (option?: any, value?: string) => boolean;
};

/**
 * Required to make async work
 * @param {function} fetchFunction function that returns list of options
 * @param {function} getLabelFromFetch function that returns label from element from what fetchFunction returned
 * @param {function} getValueFromFetch function that returns value from element from what fetchFunction returned, value should have unique id
 */

const AsyncAutocomplete = ({
  name,
  label,
  disabled,
  required,
  fetchFunction,
  getLabelFromFetchFunction,
  getValueFromFetchFunction,
  isOptionEqualToValue,
  ...props
}: SelectFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  const [fetchLoading, setFetchLoading] = useState(false);

  const [value, setValue] = useState<string | null | undefined>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<OptionType[]>([]);

  const debouncedFetch = useCallback(
    debounce(async (val, active) => {
      const data = await fetchFunction(val);

      if (active) {
        let newOptions: any[] = [];

        if (data) {
          newOptions = [...newOptions, ...data];
        }
        setOptions(
          newOptions?.length
            ? newOptions.map((element) => ({
                label: getLabelFromFetchFunction?.(element),
                value: getValueFromFetchFunction?.(element),
              }))
            : [],
        );
        setFetchLoading(false);
      }
      return data;
    }, 300),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [{ label: value, value }] : []);
      return undefined;
    }

    const handleFetch = async () => {
      setFetchLoading(true);
      await debouncedFetch(inputValue, active);
    };

    if (!value) {
      handleFetch();
    }

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...props}
            id={name}
            loading={fetchLoading}
            loadingText={<LoadingSpinner size={20} />}
            filterOptions={(x) => x}
            noOptionsText="No matches..."
            options={options as any}
            autoComplete
            value={value || ''}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(_, newValue) => {
              setValue(newValue);
              field.onChange(newValue);
            }}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={required}
                label={label}
                disabled={disabled}
                sx={{
                  '.MuiInputBase-input': {
                    padding: '24px 10px 15px 18px!important',
                  },
                }}
                SelectProps={{
                  variant: 'outlined',
                  MenuProps: {
                    BackdropProps: {
                      sx: {
                        background: 'transparent',
                        backdropFilter: 'none',
                      },
                    },
                  },
                }}
              />
            )}
          />
        )}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
};

AsyncAutocomplete.defaultProps = {
  label: '',
  disabled: false,
  required: false,
  isOptionEqualToValue: (option: string, val: string) => val === option,
};

export default AsyncAutocomplete;
