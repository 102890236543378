import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PLAN } from 'constants/plans';

type BoxWithPlanProps = {
  tariffGuid: string;
};

const BoxWithPlan = ({ tariffGuid }: BoxWithPlanProps) => {
  const theme = useTheme();
  const plan = PLAN[tariffGuid];

  return (
    <Box
      sx={{
        borderRadius: '10px',
        background: '#f1f1f1',
      }}
      p={4}
      mt={2}
      mx="auto"
      width={{ xs: '100%', lg: '341px' }}
    >
      <Stack spacing={2}>
        <Typography variant="h3" textTransform="uppercase">
          Your chosen plan
        </Typography>
        <Typography>
          {plan.name === 'Flex' ? 'Flex' : 'Unlimited'} Membership
        </Typography>
        {plan.additionalInfo && (
          <Typography
            fontSize={theme.typography.smallFontSize}
            sx={{ opacity: 0.6 }}
          >
            <Box
              component="span"
              dangerouslySetInnerHTML={{ __html: plan.additionalInfo }}
            />
          </Typography>
        )}
        <Typography
          fontSize={theme.typography.smallFontSize}
          sx={{ opacity: 0.6 }}
        >
          <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: plan.description }}
          />
        </Typography>
      </Stack>
    </Box>
  );
};

export default BoxWithPlan;
