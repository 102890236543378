/* eslint-disable react/require-default-props */
import { ReactNode } from 'react';
import { isEmpty } from 'lodash-es';
import { Badge, Box, Breadcrumbs, SxProps, Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import moment from 'moment';

import routes, { breadcrumbGeneralMap } from 'utils/routes';
import ButtonContainer from 'components/ButtonContainer';
import BackButton from 'components/BackButton';
import WeatherWidget from 'components/Layout/WeatherWidget';
import CustomChip from 'components/Common/CustomChip';

export type SectionProps = {
  elements: ReactNode;
  dashboard?: boolean;
  path?: any;
  title: string;
  hideBackButton?: boolean;
  wrapIconWithButton?: boolean;
  isBooked?: boolean;
  tabs?: ReactNode;
  notification?: number | undefined | null;
  sx?: SxProps;
  backLink?: string | number;
};
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const SectionHeader = ({
  elements,
  dashboard = false,
  title,
  path,
  backLink,
  hideBackButton = false,
  wrapIconWithButton = false,
  isBooked = false,
  notification,
  tabs,
  sx,
  ...rest
}: SectionProps) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="8px"
        sx={{
          padding: `${dashboard ? '13px' : '54px'} 40px 31px 40px`,
          background: `#F9F8F7`,
          borderTopLeftRadius: '48px',
          ...sx,
        }}
        {...rest}
      >
        <>
          <Box alignSelf="flex-end">
            {!isEmpty(path) && !hideBackButton ? (
              <Box display="flex">
                <BackButton
                  label={title}
                  to={backLink}
                  isHeader
                  wrapIconWithButton={wrapIconWithButton}
                />
                {isBooked && (
                  <CustomChip
                    label="Booked!"
                    variant="filled"
                    color="success"
                    sx={{
                      alignSelf: 'center',
                      marginLeft: '8px',
                    }}
                  />
                )}
              </Box>
            ) : (
              <Badge
                badgeContent={notification}
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    top: '8px',
                    right: '-12px',
                  },
                }}
              >
                <Typography variant="h1">{title}</Typography>
              </Badge>
            )}
            {!dashboard && (
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="-"
                sx={{ paddingTop: '8px' }}
              >
                <LinkRouter
                  underline="hover"
                  color="inherit"
                  to={routes.main.dashboard}
                >
                  Home
                </LinkRouter>
                {!isEmpty(path) &&
                  path.map((breadcrumb: { href: any; label: string }) =>
                    breadcrumb.href ? (
                      <LinkRouter
                        underline="hover"
                        color="inherit"
                        to={breadcrumb.href}
                        key={breadcrumb.href}
                      >
                        {breadcrumb.label}
                      </LinkRouter>
                    ) : (
                      <Typography color="text.primary" key={breadcrumb.label}>
                        {breadcrumb.label}
                      </Typography>
                    ),
                  )}
                {isEmpty(path) &&
                  pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (
                      <Typography color="text.primary" key={to}>
                        {breadcrumbGeneralMap[to]}
                      </Typography>
                    ) : (
                      <LinkRouter
                        underline="hover"
                        color="inherit"
                        to={to}
                        key={to}
                      >
                        {breadcrumbGeneralMap[to]}
                      </LinkRouter>
                    );
                  })}
              </Breadcrumbs>
            )}
          </Box>
          <Box alignSelf="flex-start" flexShrink={0}>
            {dashboard && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap="6px"
                mb={2}
              >
                <WeatherWidget />
                <Typography>{moment().format('ddd D - HH:mm')}</Typography>
              </Box>
            )}
            <ButtonContainer justifyContent="flex-end">
              {elements}
            </ButtonContainer>
          </Box>
        </>
      </Box>
      {tabs && tabs}
    </>
  );
};

SectionHeader.defaultProps = {
  dashboard: false,
  hideBackButton: false,
  wrapIconWithButton: false,
  isBooked: false,
  path: {},
  tabs: null,
  sx: {},
  backLink: -1,
};

export default SectionHeader;
