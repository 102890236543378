import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import moment from 'moment';
import { TableCell, TableRow, useTheme } from '@mui/material';
import EmptySection from 'components/Common/EmptySection';
import Loader from 'components/Loader';
import SectionHeader from 'components/SectionHeader';
import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';
import DataTable from 'components/Common/DataTable';
import Section from 'components/Section';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import HelpIcon from 'assets/icons/HelpIcon';
import { ActionButton } from 'components/Profile/style';
import LayoutBanner from 'components/Layout/LayoutBanner';

const breadcrumbsConfig = () => [{ label: 'Parser Feed' }];

const getFeed = async ({ pageParam = 1, query = undefined }) => {
  const { data } = await API.get(
    APIRoutes.platform.parserFeed(pageParam, query),
  );
  return data;
};

const ParserFeed = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
    isSuccess,
  } = useInfiniteQuery(['parserFeed'], getFeed, {
    getNextPageParam: (lastPage) => parseInt(lastPage.query.page, 10) + 1,
    enabled: true,
  });
  console.log('SHOW: ', showDetails);
  if (isLoading) {
    return <Loader />;
  }
  if (isSuccess) {
    const feed = data?.pages?.flatMap((page) => page?.data);
    return (
      <>
        <SectionHeader
          title="AI Parser Status"
          hideBackButton
          path={breadcrumbsConfig()}
          elements={null}
          notification={null}
        />
        <Section>
          <DataTable
            labels={[
              'Date',
              'From',
              'Subject',
              'Status',
              'State',
              'Description',
              'Deal',
              'Details',
            ]}
          >
            {feed.map((item) => (
              <TableRow
                key={item._id}
                sx={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <TableCell>
                  {moment(item.ts).format('MMM DD, YYYY HH:mm')}
                </TableCell>
                <TableCell>{item.from}</TableCell>
                <TableCell>{item.subject}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.state}</TableCell>
                <TableCell>{item.error}</TableCell>
                <TableCell>
                  {item.dealId ? (
                    <a
                      href={`https://app.hubspot.com/contacts/2043149/record/0-3/${item.dealId}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'normal',
                      }}
                    >
                      HS Deal: {item.dealId}
                    </a>
                  ) : (
                    'No Hubspot Deal'
                  )}
                </TableCell>
                <TableCell>
                  <ActionButton
                    isLoading={false}
                    onClick={() => {
                      console.log('XXXXX');
                      setDetails({
                        ...item,
                      });
                      setShowDetails(true);
                    }}
                  >
                    <HelpIcon />
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </DataTable>
          {showDetails && (
            <LayoutBanner
              styleOverrides={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                bottom: 'auto',
                width: 800,
                height: 600,
                marginLeft: '-400px',
                marginTop: '-300px',
                zIndex: theme.zIndex.modal,
                overflow: 'scroll',
                textMaxWidth: '600px',
              }}
              buttonText="Close"
              onClick={() => setShowDetails(false)}
              text={<pre>{JSON.stringify(details, null, 4)}</pre>}
            />
          )}
          {isFetchingNextPage ? (
            <LoadingSpinner />
          ) : (
            <LoadMoreButton
              hasNextPage={hasNextPage}
              onClick={fetchNextPage}
              hideScrollToTopButton
            />
          )}
        </Section>
      </>
    );
  }
  return (
    <EmptySection
      onClick={() => navigate(routes.main.dashboard)}
      missing="the parser feed is empty"
      helperText="Go back to the homepage"
      label="Home"
      icon={<img src="/assets/images/NoInvoice.svg" alt="No invoices" />}
    />
  );
};

export default ParserFeed;
