import { Chip, ChipProps, SxProps } from '@mui/material';

export type CustomChipProps = {
  label: string;
  variant: ChipProps['variant'];
  color: ChipProps['color'];
  sx?: SxProps;
};

const CustomChip = ({ label, variant, color, sx }: CustomChipProps) => (
  <Chip
    label={label}
    variant={variant}
    color={color}
    sx={{
      height: '20px',
      fontSize: 'smallFontSize',
      ml: '5px',
      padding: '0 9px',
      '& .MuiChip-label': {
        px: 0,
        color: '#1D1954',
      },
      ...sx,
    }}
  />
);

CustomChip.defaultProps = {
  sx: null,
};

export default CustomChip;
