/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="154px"
    height="121px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 154 121"
    {...props}
  >
    <g clipPath="url(#clip0_271_21202)" fill="none">
      <circle cx="46.75" cy="55" r="46.75" fill="#D9DFEF" />
      <rect
        x="30.25"
        y="12.375"
        width="105.875"
        height="81.125"
        rx="11"
        fill="white"
      />
      <path
        d="M69.4869 41.1335H74.7935C75.1957 41.1335 75.5813 40.9724 75.8656 40.6856C76.15 40.3988 76.3097 40.0099 76.3097 39.6043C76.3097 39.1988 76.15 38.8098 75.8656 38.5231C75.5813 38.2363 75.1957 38.0752 74.7935 38.0752H69.4869C69.0848 38.0752 68.6991 38.2363 68.4148 38.5231C68.1304 38.8098 67.9707 39.1988 67.9707 39.6043C67.9707 40.0099 68.1304 40.3988 68.4148 40.6856C68.6991 40.9724 69.0848 41.1335 69.4869 41.1335Z"
        fill="#1D1954"
      />
      <path
        d="M48.2603 41.1335H53.567C53.9691 41.1335 54.3547 40.9724 54.6391 40.6856C54.9234 40.3988 55.0832 40.0099 55.0832 39.6043C55.0832 39.1988 54.9234 38.8098 54.6391 38.5231C54.3547 38.2363 53.9691 38.0752 53.567 38.0752H48.2603C47.8582 38.0752 47.4726 38.2363 47.1882 38.5231C46.9039 38.8098 46.7441 39.1988 46.7441 39.6043C46.7441 40.0099 46.9039 40.3988 47.1882 40.6856C47.4726 40.9724 47.8582 41.1335 48.2603 41.1335Z"
        fill="#1D1954"
      />
      <path
        d="M90.7135 41.1335H96.0201C96.4222 41.1335 96.8079 40.9724 97.0922 40.6856C97.3765 40.3988 97.5363 40.0099 97.5363 39.6043C97.5363 39.1988 97.3765 38.8098 97.0922 38.5231C96.8079 38.2363 96.4222 38.0752 96.0201 38.0752H90.7135C90.3113 38.0752 89.9257 38.2363 89.6413 38.5231C89.357 38.8098 89.1973 39.1988 89.1973 39.6043C89.1973 40.0099 89.357 40.3988 89.6413 40.6856C89.9257 40.9724 90.3113 41.1335 90.7135 41.1335Z"
        fill="#1D1954"
      />
      <path
        d="M111.94 41.1335H117.247C117.649 41.1335 118.034 40.9724 118.319 40.6856C118.603 40.3988 118.763 40.0099 118.763 39.6043C118.763 39.1988 118.603 38.8098 118.319 38.5231C118.034 38.2363 117.649 38.0752 117.247 38.0752H111.94C111.538 38.0752 111.152 38.2363 110.868 38.5231C110.584 38.8098 110.424 39.1988 110.424 39.6043C110.424 40.0099 110.584 40.3988 110.868 40.6856C111.152 40.9724 111.538 41.1335 111.94 41.1335Z"
        fill="#1D1954"
      />
      <path
        d="M69.4869 59.0246H74.7935C75.1957 59.0246 75.5813 58.8635 75.8656 58.5767C76.15 58.2899 76.3097 57.901 76.3097 57.4954C76.3097 57.0899 76.15 56.7009 75.8656 56.4142C75.5813 56.1274 75.1957 55.9663 74.7935 55.9663H69.4869C69.0848 55.9663 68.6991 56.1274 68.4148 56.4142C68.1304 56.7009 67.9707 57.0899 67.9707 57.4954C67.9707 57.901 68.1304 58.2899 68.4148 58.5767C68.6991 58.8635 69.0848 59.0246 69.4869 59.0246Z"
        fill="#1D1954"
      />
      <path
        d="M48.2603 59.0246H53.567C53.9691 59.0246 54.3547 58.8635 54.6391 58.5767C54.9234 58.2899 55.0832 57.901 55.0832 57.4954C55.0832 57.0899 54.9234 56.7009 54.6391 56.4142C54.3547 56.1274 53.9691 55.9663 53.567 55.9663H48.2603C47.8582 55.9663 47.4726 56.1274 47.1882 56.4142C46.9039 56.7009 46.7441 57.0899 46.7441 57.4954C46.7441 57.901 46.9039 58.2899 47.1882 58.5767C47.4726 58.8635 47.8582 59.0246 48.2603 59.0246Z"
        fill="#1D1954"
      />
      <path
        d="M69.4869 76.7624H74.7935C75.1957 76.7624 75.5813 76.6013 75.8656 76.3145C76.15 76.0277 76.3097 75.6388 76.3097 75.2332C76.3097 74.8277 76.15 74.4387 75.8656 74.152C75.5813 73.8652 75.1957 73.7041 74.7935 73.7041H69.4869C69.0848 73.7041 68.6991 73.8652 68.4148 74.152C68.1304 74.4387 67.9707 74.8277 67.9707 75.2332C67.9707 75.6388 68.1304 76.0277 68.4148 76.3145C68.6991 76.6013 69.0848 76.7624 69.4869 76.7624Z"
        fill="#1D1954"
      />
      <rect
        x="30.25"
        y="12.375"
        width="105.875"
        height="81.125"
        rx="11"
        stroke="#1D1954"
        strokeWidth="4.125"
      />
      <path
        d="M48.2603 76.7624H53.567C53.9691 76.7624 54.3547 76.6013 54.6391 76.3145C54.9234 76.0277 55.0832 75.6388 55.0832 75.2332C55.0832 74.8277 54.9234 74.4387 54.6391 74.152C54.3547 73.8652 53.9691 73.7041 53.567 73.7041H48.2603C47.8582 73.7041 47.4726 73.8652 47.1882 74.152C46.9039 74.4387 46.7441 74.8277 46.7441 75.2332C46.7441 75.6388 46.9039 76.0277 47.1882 76.3145C47.4726 76.6013 47.8582 76.7624 48.2603 76.7624Z"
        fill="#1D1954"
      />
      <ellipse
        cx="117.465"
        cy="84.2403"
        rx="30.4464"
        ry="30.6329"
        fill="#FEDA8F"
      />
      <circle cx="105.875" cy="77" r="4.125" fill="#1D1954" />
      <circle cx="127.875" cy="77" r="4.125" fill="#1D1954" />
      <path
        d="M46.0625 4.125V19.9375"
        stroke="#1D1954"
        strokeWidth="4.125"
        strokeLinecap="round"
      />
      <path
        d="M120.312 4.125V19.9375"
        stroke="#1D1954"
        strokeWidth="4.125"
        strokeLinecap="round"
      />
      <circle
        cx="116.875"
        cy="83.875"
        r="31.9688"
        stroke="#1D1954"
        strokeWidth="4.2625"
      />
      <path
        d="M101.593 99.8594C105.347 95.4992 110.803 92.7552 116.875 92.7552C122.948 92.7552 128.403 95.4992 132.158 99.8594"
        stroke="#1D1954"
        strokeWidth="4.2625"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_271_21202">
        <rect width="154" height="121" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SvgComponent;
