import { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Typography,
  Box,
  useTheme,
  FormHelperText,
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useAuth } from 'context/AuthContext';

import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import SelectableSection from 'pages/Desks/SelectableSection';
import TextArea from 'components/Form/TextArea';

import TopicIcon from 'assets/icons/TopicIcon';
import PinIcon from 'assets/icons/PinIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import AlignIcon from 'assets/icons/AlignIcon';
import TrashIcon from 'assets/icons/Trash';
import CameraIcon from 'assets/icons/Camera';
import UploadIcon from 'assets/icons/Upload';

import {
  DEFAULT_VALUES,
  FORM_OPTIONS,
  IMAGE_PICKER_CONFIG,
} from 'constants/help';

import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';
import { useAppContext } from 'context/AppContext';
import ChatModal from 'components/Help/ChatModal';
import LoadingButton from 'components/Common/LoadingButton';

const schema = yup.object({
  description: yup.string().trim().required('Field contain whitespace'),
});

type FormData = {
  location: string;
  topic: string;
  description: string;
  urgency: string;
  photos: string[];
  spaceId: number;
};

const breadcrumbsConfig = () => [
  { label: 'Help/FAQs', href: routes.main.help.faq },
  { label: 'Raise a ticket' },
];

const RaiseTicket = () => {
  const { spaces, user } = useAuth();
  const { isOpen, openModal } = useAppContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { snack, setSnack } = useToast();
  const [images, setImages] = useState<{ src: string }[]>([]);
  const [uploadError, setUploadError] = useState<string>();

  const {
    mutate: createChat,
    isSuccess,
    isLoading,
  } = useMutation((payload: any) => API.post(APIRoutes.help.index, payload), {
    onSuccess: ({ data: response }) => {
      const {
        data: { _id },
      } = response;
      openModal(<ChatModal chatId={_id} />);
    },
    onError: () => {
      setSnack({
        ...snack,
        message: 'Unable to create chat',
        color: 'white',
        open: true,
        type: 'error',
        icon: (
          <ErrorOutlineOutlinedIcon
            sx={{
              color: 'white',
              width: '20px',
              height: '20px',
            }}
          />
        ),
      });
    },
  });

  useEffect(() => {
    if (isSuccess && !isOpen) {
      navigate(routes.main.help.faq);
    }
  }, [isOpen]);

  const methods = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: { ...DEFAULT_VALUES, description: '' },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, formState, getFieldState } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    createChat({
      topic: data.topic,
      photos: images.map((x) => x.src),
      spaceId:
        spaces.find((x) => x.shortName === data.location)?.id || user?.spaceId,
      urgency: data.urgency,
      description: data.description,
      category: '',
      os: '',
    });
  };

  const isDirty =
    getFieldState('location', formState).isDirty &&
    getFieldState('urgency', formState).isDirty &&
    getFieldState('topic', formState).isDirty &&
    getFieldState('description', formState).isDirty;

  const onDropAccepted = (acceptedFiles: File[]) => {
    if (
      images.length === 3 ||
      (images.length === 2 && acceptedFiles.length > 1) ||
      (images.length === 1 && acceptedFiles.length > 2)
    ) {
      setUploadError(IMAGE_PICKER_CONFIG.errors['too-many-files']);
      return null;
    }
    setUploadError('');
    return acceptedFiles.map((file: any) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImages((prevState) => [...prevState, { src: e.target.result }]);
      };

      reader.readAsDataURL(file);
      return file;
    });
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorCode = fileRejections[0]?.errors[0]?.code;
    if (!errorCode) return;
    if (errorCode === 'too-many-files' || errorCode === 'file-too-large') {
      setUploadError(IMAGE_PICKER_CONFIG.errors[errorCode]);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    maxFiles: 3,
    multiple: true,
    maxSize: 10485760,
    accept: {
      'image/jpg': [],
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const removeFile = (id: string) => {
    setImages(images.filter((x) => x.src !== id));
  };

  return (
    <>
      <SectionHeader
        title="Raise a ticket"
        path={breadcrumbsConfig()}
        elements={null}
        backLink={routes.main.help.faq}
      />
      <Section>
        <Typography variant="h3" mb="32px">
          What&apos;s the issue?
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SelectableSection
              icon={TopicIcon}
              title="Topic"
              name="topic"
              options={FORM_OPTIONS.topic}
            />

            <SelectableSection
              icon={PinIcon}
              title="Location"
              name="location"
              options={FORM_OPTIONS.location}
            />

            <SelectableSection
              icon={InfoIcon}
              title="Priority"
              name="urgency"
              options={FORM_OPTIONS.urgency}
              colors={FORM_OPTIONS.colors}
            />
            <Box display="flex" alignItems="center" gap="8px" mb="24px">
              <AlignIcon />
              <Typography
                variant="h4"
                fontWeight={theme.typography.fontWeightBold}
                mb="10px"
              >
                Explain the issue
              </Typography>
            </Box>
            <TextArea name="description" placeholder="Write message" />

            <Box display="flex" alignItems="center" gap="8px" mt="32px">
              <CameraIcon />
              <Typography
                variant="h4"
                fontWeight={theme.typography.fontWeightBold}
              >
                Upload any images
              </Typography>
            </Box>
            <Typography ml="36px" fontSize="13px">
              Max upload: 10mb, JPEG or PNG, max 3 files
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ backgroundColor: '#F1F1F1', maxWidth: '688px' }}
              py="30px"
              borderRadius="8px"
              mt="30px"
            >
              <div {...getRootProps()} style={{ textAlign: 'center' }}>
                <input {...getInputProps()} />
                <UploadIcon />
                <Typography>Drag and drop file to upload</Typography>
                <Typography fontWeight={700}>or</Typography>
                <Button
                  onClick={open}
                  variant="contained"
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'primary.main',
                    justifyContent: 'space-between',
                    px: '40px',
                    height: '44px',
                    my: 1,
                  }}
                >
                  Select file
                </Button>
              </div>
            </Box>
            {uploadError && (
              <FormHelperText error>{uploadError}</FormHelperText>
            )}
            <Box
              mt="16px"
              marginBottom="84px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              maxWidth="688px"
              gap="16px"
            >
              {images.map((image) => (
                <Box
                  key={image.src}
                  width="130px"
                  onClick={() => removeFile(image.src)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      '& .delete-hover': {
                        opacity: 1,
                        transition: 'opacity 0.3s',
                      },
                    },
                  }}
                  position="relative"
                >
                  <img
                    src={image.src}
                    alt=""
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      height: '71px',
                    }}
                  />
                  <Box
                    position="absolute"
                    className="delete-hover"
                    sx={{
                      opacity: 0,
                      backgroundColor: 'rgba(29, 25, 84, 0.3)',
                      borderRadius: '8px',
                      transition: 'opacity 0.3s',
                    }}
                    top="0"
                    width="100%"
                    height="71px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TrashIcon />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box maxWidth="688px">
              <Typography
                variant="h4"
                fontWeight={theme.typography.fontWeightBold}
                marginBottom="32px"
              >
                Start chatting!
              </Typography>
              <LoadingButton
                isLoading={isLoading}
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                  justifyContent: 'center',
                  width: '168px',
                  height: '44px',
                  my: 1,
                  marginRight: '8px',
                }}
                type="submit"
                disabled={!isDirty}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
      </Section>
    </>
  );
};

export default RaiseTicket;
