import { SvgIcon, SvgIconProps } from '@mui/material';

const OverdueIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="12" fill="#F76666" />
    <path
      d="M16.2008 7.80002L7.80078 16.2M7.80078 7.80002L16.2008 16.2"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default OverdueIcon;
