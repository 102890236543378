import { AddToCalendarButton as CalendarButton } from 'add-to-calendar-button-react';
import moment from 'moment';

// temporary TS issue fix
const Button: (props: any) => any = CalendarButton;

function AddToCalendarButton({ start, end, title, address }: BookingDetails) {
  const startDate = moment(start);
  const endDate = moment(end);

  return (
    <Button
      name={title}
      options={['Apple', 'Google', 'Microsoft365', 'Outlook.com']}
      location={address}
      startDate={startDate.format('YYYY-MM-DD')}
      endDate={endDate.format('YYYY-MM-DD')}
      startTime={startDate.format('HH:mm')}
      endTime={endDate.format('HH:mm')}
      timeZone="currentBrowser"
      hideBackground
      hideBranding
    />
  );
}

export default AddToCalendarButton;
