import { useState } from 'react';
import { Box, Divider } from '@mui/material';
import EmptySection from 'components/Common/EmptySection';
import DeliveriesTable from 'components/Deliveries/DeliveriesTable';
import ScrollTopButton from 'components/Common/ScrollTopButton';

const Deliveries = () => {
  const [hasDeliveries, setHasDeliveries] = useState(true);
  const [hasCollectedDeliveries, setHasCollectedDeliveries] = useState(true);

  if (!hasDeliveries && !hasCollectedDeliveries) {
    return (
      <EmptySection
        missing="you have no deliveries due"
        icon={<img src="/assets/images/NoDeliveries.svg" alt="No deliveries" />}
      />
    );
  }

  return (
    <Box>
      {hasDeliveries && <DeliveriesTable setHasDeliveries={setHasDeliveries} />}
      {hasDeliveries && hasCollectedDeliveries && (
        <Divider
          sx={{
            marginY: '32px',
          }}
        />
      )}
      {hasCollectedDeliveries && (
        <DeliveriesTable
          setHasDeliveries={setHasCollectedDeliveries}
          collectedDeliveries
        />
      )}
      <Box textAlign="center">
        <ScrollTopButton />
      </Box>
    </Box>
  );
};

export default Deliveries;
