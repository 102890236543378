import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

export const MotionBox = styled(motion(Paper))`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 400px;
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: none;
  &::after {
    content: '';
    background-image: url('/assets/images/triangle.svg');
    background-position-x: -18px;
    background-size: 36px 12px;
    width: 100%;
    height: 12px;
    position: absolute;
    bottom: -12px;
    right: 0;
    z-index: 2;
  }
`;
