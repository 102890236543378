import { Button, OutlinedInput, styled } from '@mui/material';

export const QuantityButton = styled(Button)(({ theme }) => ({
  height: '44px',
  minWidth: 'auto',
  width: '42px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '0px',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '& > svg': {
    width: '16px',
    height: '16px',
    color: '#FFFFFF',
  },
}));

export const QuantityInput = styled(OutlinedInput)(({ theme }) => ({
  width: '122px',
  padding: '0px',
  height: '44px',
  borderRadius: '8px',
  overflow: 'hidden',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,

  '& .MuiInputBase-input': {
    borderTop: `solid 4px ${theme.palette.primary.main}`,
    borderBottom: `solid 4px ${theme.palette.primary.main}`,
    padding: '8px',
    textAlign: 'center',
    boxSizing: 'border-box',
    height: '100%',

    '&:disabled': {
      borderColor: theme.palette.blue[800],
      opacity: 0.5,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));
