import { MenuItem, TextField, FormHelperText } from '@mui/material';
import { Controller, get, useFormContext } from 'react-hook-form';

type SelectFieldProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options: {
    value: string | boolean | number;
    label: string;
  }[];
};

const SelectField = ({
  name,
  label,
  options,
  disabled,
  required,
}: SelectFieldProps) => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            disabled={disabled}
            select
            fullWidth
            label={label}
            value={value || ''}
            onChange={onChange}
            required={required}
            SelectProps={{
              variant: 'outlined',
              onClose: () => trigger(name),
              MenuProps: {
                BackdropProps: {
                  sx: {
                    background: 'transparent',
                    backdropFilter: 'none',
                  },
                },
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value.toString()}
                value={option.value.toString()}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

SelectField.defaultProps = {
  label: '',
  disabled: false,
  required: false,
};

export default SelectField;
