import { createTheme, ThemeOptions } from '@mui/material/styles';
import breakpoints from 'styles/theme/breakpoints';
import components from 'styles/theme/components';
import palette from 'styles/theme/palette';
import typography from 'styles/theme/typography';
import transitions from 'styles/theme/transitions';

declare module '@mui/material/styles' {
  interface TypeBackground {
    main: string;
    content: string;
  }
  interface TypeText {
    main: string;
    content: string;
  }

  interface TypographyVariants {
    smallFontSize: number;
  }
}

const initTheme = createTheme({
  palette,
  breakpoints,
  typography,
  transitions,
} as ThemeOptions);
const theme = createTheme(initTheme, components(initTheme));

export default theme;
