const breakpoints = {
  values: {
    xs: 320,
    sm: 375,
    md: 960,
    lg: 1024,
    xl: 1200,
  },
};

export default breakpoints;
