import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { findIndex } from 'lodash-es';

import ActionMenu from 'components/Common/ActionMenu';
import AddVisitorModal from 'components/Visitors/AddVisitorModal';

import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';

import { useAppContext } from 'context/AppContext';
import ConfirmationBox from 'components/Common/ConfirmationBox';

const VisitorMenu = ({
  visitor,
  queryKey,
  isSuccess,
  setShowToast,
}: {
  visitor: VisitorProps;
  queryKey: string;
  isSuccess?: boolean;
  setShowToast?: Dispatch<
    SetStateAction<{
      type: null | 'error' | 'success';
      open: boolean;
    }>
  >;
}) => {
  const queryClient = useQueryClient();
  const { snack, setSnack } = useToast();

  const { openModal } = useAppContext();

  const { mutate: removeVisitor } = useMutation(
    () => API.delete(APIRoutes.visitors.remove(visitor.id)),
    {
      onSuccess: async () => {
        const { pages } = queryClient.getQueryData<any>(queryKey);
        const currentPage = findIndex(pages, (page: any) =>
          page.data.some((x: VisitorProps) => x.id === visitor.id),
        );
        await queryClient.refetchQueries({
          queryKey: [queryKey],
          refetchPage: (_, index) => index >= currentPage,
        });
      },
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error ? 'Unable to remove this visitor' : 'Visitor removed',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <ActionMenu
      options={[
        {
          label: queryKey === 'past-visitors' ? null : 'Edit',
          onSelect:
            queryKey === 'past-visitors'
              ? () => null
              : () =>
                  openModal(
                    <AddVisitorModal
                      initialValues={visitor}
                      isSuccess={isSuccess}
                      setShowToast={setShowToast}
                    />,
                  ),
        },
        {
          label: 'Remove',
          onSelect: () =>
            openModal(
              <ConfirmationBox
                text="Are you sure you want to delete this?"
                onClick={removeVisitor}
              />,
            ),
        },
        {
          label: queryKey === 'past-visitors' ? 'Re-add' : null,
          onSelect:
            queryKey === 'past-visitors'
              ? () =>
                  openModal(
                    <AddVisitorModal
                      initialValues={{ ...visitor, visitorArrival: '' }}
                      isSuccess={isSuccess}
                      setShowToast={setShowToast}
                      pastVisitor
                    />,
                  )
              : () => null,
        },
      ]}
    />
  );
};

VisitorMenu.defaultProps = {
  isSuccess: true,
  setShowToast: { type: null, open: false },
};

export default VisitorMenu;
