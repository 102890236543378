/* eslint-disable func-names */
/* eslint-disable prefer-const */

import { useAuth } from 'context/AuthContext';

const WeatherWidget = () => {
  const { currentSpaceId, spaces } = useAuth();

  const currentLocalization = spaces.find(
    (space) => space.id === currentSpaceId,
  )?.location;

  const prepareLink = () => {
    switch (currentLocalization) {
      case 'london':
        return 'https://forecast7.com/en/51d51n0d13/london/';
      case 'reading':
        return 'https://forecast7.com/en/51d45n0d98/reading/';
      case 'manchester':
        return 'https://forecast7.com/en/53d48n2d24/manchester/';
      default:
        return 'https://forecast7.com/en/55d38n3d44/united-kingdom/';
    }
  };

  return (
    <>
      <a
        className="weatherwidget-io"
        href={prepareLink()}
        data-font="Roboto"
        data-icons="Climacons"
        data-mode="Current"
        data-days="3"
        data-textcolor="#1d1954"
        data-suncolor="#FABE79"
        data-mooncolor="#4538CE"
        data-cloudcolor="#4538CE"
        data-raincolor="#4538CE"
        data-snowcolor="#4538CE"
      >
        Location
      </a>
      {
        !(function (d, s, id) {
          let js;
          const fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s) as HTMLImageElement;
          js.id = id;
          js.src = 'https://weatherwidget.io/js/widget.min.js';
          if (fjs.parentNode !== null) {
            fjs.parentNode.insertBefore(js, fjs);
          }
          return '';
        })(document, 'script', 'weatherwidget-io-js')
      }
    </>
  );
};

export default WeatherWidget;
