import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Button,
  Card,
  Divider,
  Icon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty, sumBy } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { breakpoint } from 'styles/breakpoints';

import SectionHeader from 'components/SectionHeader';
import EmptySection from 'components/Common/EmptySection';
import BasketItemCard from 'components/Store/BasketItemCard';
import Section from 'components/Section';
import Loader from 'components/Loader';
import ConfirmationBox from 'components/Common/ConfirmationBox';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import { useAppContext } from 'context/AppContext';
import { API, APIRoutes } from 'utils/api';
import routes from 'utils/routes';

import ArrowIcon from 'assets/icons/ArrowIcon';
import StoreIcon from 'assets/icons/StoreIcon';
import TrashIcon from 'assets/icons/Trash';
import CheckoutModal from 'components/Store/CheckoutModal';
import { useAuth } from 'context/AuthContext';
import ScrollTopButton from 'components/Common/ScrollTopButton';

const breadcrumbsConfig = () => [{ label: 'Basket' }];

type BasketResponse = {
  _id: string;
  basket: BasketItem[];
};

const VISIBLE_BASKET_ITEMS = 6;

const Basket = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal } = useAppContext();
  const { setIsBasketEmpty, user } = useAuth();

  const isSmallerResolution = useMediaQuery(breakpoint.xl);

  const { mutate: clearBasket, isLoading: isMutating } = useMutation(
    () => API.delete(APIRoutes.basket.index),
    {
      onSuccess: () => {
        queryClient.setQueryData('basket', {});
        setIsBasketEmpty(true);
      },
    },
  );

  const getBasket = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.basket.index);
    return data;
  };

  const { isLoading, isFetching, isSuccess, data } = useQuery<BasketResponse>(
    'basket',
    () => getBasket(),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    const basket = data?.basket;
    const isBasketEmpty = isEmpty(basket);
    const total = sumBy(
      basket,
      (item) =>
        item.CoworkerProduct.quantity * item.CoworkerProduct.pricePerItem,
    );

    return (
      <>
        <SectionHeader
          title="Your basket"
          hideBackButton
          path={breadcrumbsConfig()}
          elements={
            <Button
              variant="contained"
              color="secondary"
              disabled={isBasketEmpty}
              sx={{
                width: '168px',
                height: '44px',
                '&:disabled': {
                  color: 'inherit',
                },
              }}
              endIcon={<TrashIcon />}
              onClick={() =>
                openModal(
                  <ConfirmationBox
                    text="Are you sure you want to clear your basket?"
                    onClick={clearBasket}
                  />,
                )
              }
            >
              Clear basket
            </Button>
          }
        />
        <Section pb="140px !important">
          {isFetching || isMutating ? (
            <LoadingSpinner minHeight="100px" />
          ) : (
            <>
              <Box
                display="flex"
                gap="32px"
                sx={{ flexDirection: isSmallerResolution ? 'row' : 'column' }}
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  {isBasketEmpty ? (
                    <EmptySection
                      missing="your basket is empty"
                      label="Add items"
                      onClick={() => navigate(routes.main.store)}
                      helperText="Check out the store"
                      icon={
                        <img
                          src="/assets/images/EmptyBasket.svg"
                          alt="Empty basket"
                        />
                      }
                    />
                  ) : (
                    basket?.map((basketItem) => (
                      <BasketItemCard
                        key={basketItem._id}
                        {...basketItem.CoworkerProduct}
                      />
                    ))
                  )}
                </Box>
                <Box width="100%" maxWidth="312px">
                  <Card>
                    <Box padding="24px">
                      <Typography variant="h2">Total to pay now:</Typography>
                      <Divider
                        sx={{
                          marginY: '16px',
                        }}
                      />
                      <Typography variant="h1" marginBottom="4px">
                        £{total.toFixed(2)}
                      </Typography>
                      <Typography
                        fontSize={theme.typography.smallFontSize}
                        marginBottom="20px"
                      >
                        Prices include VAT
                      </Typography>
                      <Tooltip
                        title={
                          isBasketEmpty || user?.canPurchaseProducts
                            ? null
                            : 'You cannot purchase products. Contact with your Team Leader.'
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            disabled={
                              !isBasketEmpty && !user?.canPurchaseProducts
                            }
                            sx={{
                              width: '200px',
                              height: '44px',
                              backgroundColor: theme.palette.primary.main,
                            }}
                            onClick={() =>
                              isBasketEmpty
                                ? navigate(routes.main.store)
                                : openModal(<CheckoutModal />)
                            }
                            endIcon={
                              <Icon
                                component={
                                  isBasketEmpty ? StoreIcon : ArrowIcon
                                }
                                sx={{
                                  width: '18px',
                                  height: '18px',
                                }}
                              />
                            }
                          >
                            {isBasketEmpty ? 'Go to store' : 'Checkout'}
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  </Card>
                </Box>
              </Box>
              {basket?.length > VISIBLE_BASKET_ITEMS && (
                <Box textAlign="center" mt={4}>
                  <ScrollTopButton />
                </Box>
              )}
            </>
          )}
        </Section>
      </>
    );
  }
  return null;
};

export default Basket;
