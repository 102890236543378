import { useDropzone, FileRejection } from 'react-dropzone';
import { useMutation } from 'react-query';
import { useToast } from 'context/ToastContext';
import LoadingButton from 'components/Common/LoadingButton';
import { API, APIRoutes } from 'utils/api';

const UploadFormsButton = () => {
  const { snack, setSnack } = useToast();

  const { mutate: uploadFiles, isLoading } = useMutation(
    (formData: FormData) =>
      API.post(APIRoutes.onboarding.filesUpload, formData),
    {
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error ? 'Unable to upload files' : 'Files uploaded',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorCode = fileRejections[0]?.errors[0]?.code;
    if (!errorCode) return;
    setSnack({
      ...snack,
      message: 'Unable to upload files',
      open: true,
      type: 'error',
    });
  };

  const onDropAccepted = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => formData.append('file', file));
    uploadFiles(formData);
  };

  const { open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: true,
    maxSize: 10485760,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <LoadingButton
      variant="contained"
      color="secondary"
      fullWidth
      sx={{
        justifyContent: 'center',
        height: '100%',
      }}
      onClick={open}
      isLoading={isLoading}
    >
      Submit
    </LoadingButton>
  );
};

export default UploadFormsButton;
