import {
  Box,
  Divider,
  IconButton,
  Typography,
  Link,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { useEffect, useState } from 'react';
import BasketIcon from 'assets/icons/BasketIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import { MotionBox } from 'components/Events/Booking/style';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { useAppContext } from 'context/AppContext';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import DiscountInput from 'components/DiscountInput';
import ConfirmOrderButton from 'components/Store/ConfirmOrderButton';

const getInvoicePreview = async (discountCode: string) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.basket.previewInvoice(discountCode));
  return data;
};

const CheckoutModal = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { closeModal } = useAppContext();
  const [discountCode, setDiscountCode] = useState('');

  const { isLoading, data, isFetching, refetch, isSuccess } =
    useQuery<InvoicePreviewProps>(['preview-invoice'], () =>
      getInvoicePreview(discountCode),
    );

  useEffect(() => {
    if (discountCode) {
      refetch();
    }
  }, [discountCode]);

  const finalPrice =
    data?.discount && data?.discount?.priceAfterDiscount
      ? data?.discount?.priceAfterDiscount
      : data?.grossAmountToPay;

  return (
    <MotionBox
      initial={{ y: '-100%' }}
      animate={{ y: '0' }}
      transition={{ stiffness: 1000 }}
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '18px',
          width: '20px',
          height: '20px',
          background: theme.palette.secondary.main,
          '&:hover': {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <CloseIcon
          sx={{
            width: '14px',
            height: '14px',
            color: theme.palette.blue[800],
          }}
        />
      </IconButton>
      <Box
        display="flex"
        alignItems="center"
        gap="8px"
        padding="34px 31px 32px 31px"
      >
        <BasketIcon
          sx={{
            width: '24px',
            height: '24px',
            color: theme.palette.secondary.main,
          }}
        />
        <Typography variant="h2">Checkout</Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        padding="28px 32px"
        maxHeight="calc(100vh - 150px)"
      >
        {isLoading || isFetching ? (
          <LoadingSpinner />
        ) : (
          isSuccess && (
            <>
              <Box overflow="auto">
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="8px"
                  marginBottom="32px"
                >
                  <Typography
                    variant="h4"
                    fontWeight={theme.typography.fontWeightBold}
                  >
                    Summary
                  </Typography>
                  {data?.products?.map((basketItem) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="34px"
                      key={basketItem?.productName}
                    >
                      <Typography width="100%">
                        {basketItem?.quantity}x {basketItem?.productName}
                      </Typography>
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{
                          marginY: '-4px',
                        }}
                      />
                      <Typography
                        fontWeight={theme.typography.fontWeightBold}
                        width="100%"
                        maxWidth="70px"
                      >
                        £{basketItem?.grossSum?.toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="h4"
                      fontWeight={theme.typography.fontWeightBold}
                    >
                      Billing
                    </Typography>
                    <Typography
                      component={Link}
                      color="#1D1954"
                      fontWeight={theme.typography.fontWeightBold}
                      onClick={() => {
                        closeModal();
                        navigate(`${routes.main.account.profile}#billing`);
                      }}
                    >
                      Edit
                    </Typography>
                  </Box>
                  <Typography>{data?.billingData?.billingName}</Typography>
                  <Box display="flex" gap="8px">
                    <Typography width="50%">
                      {data?.billingData?.billingAddress}
                    </Typography>
                    <Typography width="50%">
                      {data?.billingData?.billingState}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="8px">
                    <Typography width="50%">
                      {data?.billingData?.billingCityName}
                    </Typography>
                    <Typography width="50%">
                      {data?.billingData?.billingPostCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider
                sx={{
                  marginY: '20px',
                }}
              />
              <DiscountInput
                discountCode={discountCode}
                setDiscountCode={setDiscountCode}
                discount={data.discount}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginY="24px"
              >
                <Typography variant="h4" fontWeight="fontWeightBold">
                  Total (inc VAT):
                </Typography>
                <Typography variant="h2">£{finalPrice?.toFixed(2)}</Typography>
              </Box>
              <ConfirmOrderButton
                closeModal={closeModal}
                discountCode={discountCode}
                isCodeValid={!!data.discount?.isCodeValid}
              />
            </>
          )
        )}
      </Box>
    </MotionBox>
  );
};

export default CheckoutModal;
