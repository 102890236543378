import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import routes from 'utils/routes';

import SadFace from 'assets/icons/404';

const NotFound = () => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
      }}
      color={theme.palette.text.content}
    >
      <Box sx={{ svg: { width: '100%', height: '100%' } }}>
        <SadFace />
      </Box>
      <Typography fontWeight="bold" fontSize="26px" mt={2}>
        Oops!
      </Typography>
      <Typography
        fontWeight="400"
        fontSize="20px"
        mt={1}
        mb={3}
        lineHeight="26px"
        maxWidth="230px"
      >
        We can’t find the page you’re looking for
      </Typography>

      <Link to={routes.main.dashboard} style={{ textDecoration: 'none' }}>
        <Button
          variant="contained"
          sx={{
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            width: '144px',
            height: '44px',
          }}
        >
          Back to Home
        </Button>
      </Link>
    </Box>
  );
};

export default NotFound;
