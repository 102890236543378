import { Stack, Typography, useTheme } from '@mui/material';
import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';

const PasswordForm = () => {
  const theme = useTheme();

  return (
    <Section title="Set your account password">
      <Stack spacing={2}>
        <Typography fontWeight={theme.typography.fontWeightBold}>
          You&apos;ll use this to get into our member portal to see the latest
          news, events as well as your account details
        </Typography>
        <Typography
          fontSize={theme.typography.smallFontSize}
          color={theme.palette.text.content}
          sx={{
            opacity: 0.65,
          }}
        >
          To keep your account secure your password must have at least one
          uppercase letter (A-Z), one lowercase letter (a-z), a number (0-9) and
          a symbol (!@#$%^&*).
        </Typography>
        <TextInput label="Password" name="password" type="password" required />
        <TextInput
          label="Password confirmation"
          name="passwordConfirmation"
          type="password"
          required
        />
      </Stack>
    </Section>
  );
};

export default PasswordForm;
