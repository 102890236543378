import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';

import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';
import ViewButton from 'components/Desks/ViewButton';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import CapacityLabel from 'components/Desks/CapacityLabel';
import { useAuth } from 'context/AuthContext';
import { SelectIcon } from 'components/Common/SelectIcon';
import { FILTERS, FORM_OPTIONS } from 'constants/desks';
import { getItem, KEYS, setItem } from 'utils/cache';
import EmptySection from 'components/Common/EmptySection';
import SadFace from 'assets/icons/SadFace';
import routes from 'utils/routes';

import Charts from './Charts';
import Table from './Table';

const breadcrumbsConfig = () => [{ label: 'Desk availability' }];

type ViewTypes = 'grid' | 'table';

const options = [
  FILTERS.ALL,
  ...FORM_OPTIONS.atmosphere,
  ...FORM_OPTIONS.location,
  FILTERS.FAVOURITES,
];

interface DeskAvailabilityProps {
  view: ViewTypes;
}

const DesksAvailability = ({ view }: DeskAvailabilityProps) => {
  const [searchParams] = useSearchParams();
  const paramFilter = searchParams.get('filter');
  const defaultFilter =
    paramFilter && options.includes(paramFilter) ? paramFilter : FILTERS.ALL;
  const [filter, setFilter] = useState(defaultFilter);
  const { isFromLondon } = useAuth();
  const theme = useTheme();

  const [favourites, setFavourites] = useState<number[]>(
    JSON.parse(getItem(KEYS.FAVOURITES) as string) || [],
  );

  useEffect(() => {
    setItem(KEYS.FAVOURITES, JSON.stringify(favourites));
  }, [favourites]);

  return (
    <>
      <SectionHeader
        title="Desk availability"
        path={breadcrumbsConfig()}
        hideBackButton={!isFromLondon}
        elements={
          <Box display="flex" flexWrap="wrap" width="190px">
            <CapacityLabel isAnimated />
            <Typography fontSize={theme.typography.smallFontSize}>
              Data updated every 30 minutes
            </Typography>
          </Box>
        }
        backLink={routes.main.desks.form}
      />
      <Section>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="24px"
        >
          <Typography variant="h2">Locations</Typography>
          <Box display="flex" alignItems="center">
            <FormControl
              sx={{
                width: '208px',
                marginRight: '18px',
              }}
            >
              {isFromLondon && (
                <Select
                  value={filter}
                  onChange={(event: SelectChangeEvent) =>
                    setFilter(event.target.value)
                  }
                  IconComponent={SelectIcon}
                  sx={{
                    height: '44px',
                    borderRadius: '10px',
                    background: '#FFFFFF',
                    '& .MuiInputBase-input': {
                      padding: '8px 8px 8px 16px',
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <ViewButton view="grid" activeView={view} />
            <ViewButton view="table" activeView={view} />
          </Box>
        </Box>
        {filter === FILTERS.FAVOURITES && isEmpty(favourites) ? (
          <EmptySection missing="you have no favourites" icon={<SadFace />} />
        ) : (
          <>
            {view === 'table' && (
              <Table
                filter={filter}
                favourites={favourites}
                setFavourites={setFavourites}
              />
            )}
            {view === 'grid' && (
              <Charts
                filter={filter}
                favourites={favourites}
                setFavourites={setFavourites}
              />
            )}
            <Box textAlign="center" mt="27px">
              <ScrollTopButton />
            </Box>
          </>
        )}
      </Section>
    </>
  );
};

export default DesksAvailability;
