import React, { useMemo } from 'react';
import { Box, Divider, useTheme, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';

import RelatedItemsSection from 'pages/Events/RelatedItemsSection';
import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import routes from 'utils/routes';
import CustomChip from 'components/Common/CustomChip';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import InfoIcon from 'assets/icons/InfoIcon';
import PinIcon from 'assets/icons/PinIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import ClockIcon from 'assets/icons/ClockIcon';
import DetailsSection from 'components/Common/DetailsSection';
import LabelWithIcon from 'components/Common/LabelWithIcon';
import Price from 'components/Common/Price';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import ButtonAddToBasket from 'components/Common/ButtonAddToBasket';
import SharpenedCard from 'components/Common/SharpenedCard';
import ShowButton from 'components/Events/Booking/ShowButton';

const breadcrumbsConfig = ({ description }: { description: string }) => [
  { label: 'Events', href: routes.main.events },
  { label: description },
];

const getBookedEvents = async () => {
  const {
    data: { data: events },
  } = await API.get(APIRoutes.events.booked);
  return events;
};

const getEventDetails = async (eventId: string) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.events.indexById(eventId));
  return data;
};

const EventDetail = () => {
  const { id } = useParams() as { id: string };
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const { isLoading: isLoadingBookedEvents, data: bookedEvents } = useQuery<
    EventProps[]
  >('events-booked', () => getBookedEvents(), {
    enabled: searchParams.get('booked') !== null,
  });

  const { isLoading, isSuccess, data } = useQuery<EventDetails>(
    ['event', id],
    () => getEventDetails(id),
  );

  const { event, relatedEvents } = data || {};

  const bookedEvent = useMemo(() => {
    if (
      searchParams.get('booked') !== null &&
      bookedEvents &&
      bookedEvents.length > 0
    ) {
      return bookedEvents.find((x) => x.eventId.toString() === id.toString());
    }
    return null;
  }, [id, bookedEvents]);

  const hasPrice = useMemo(() => event && event.price > 0, [event?.price]);

  const LeftWrapper = hasPrice ? SharpenedCard : Box;

  if (isLoading || isLoadingBookedEvents) {
    return <Loader />;
  }

  if (isSuccess && event && relatedEvents) {
    const from = moment(event.startDate);
    const to = moment(event.endDate);

    return (
      <>
        <SectionHeader
          title={event.title}
          path={breadcrumbsConfig({
            description: event.title,
          })}
          isBooked={!!bookedEvent}
          elements={
            <>
              <CustomChip
                label={event.spaceName}
                variant="outlined"
                color="secondary"
              />
              {event.price === 0 && (
                <CustomChip label="Free!" variant="filled" color="secondary" />
              )}
              {from.isSame(moment(), 'day') && (
                <CustomChip label="Today!" variant="filled" color="secondary" />
              )}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ bgcolor: theme.palette.blue[600], height: 36, margin: 1 }}
              />
              <ShowButton
                event={event}
                hasPrice={hasPrice}
                bookedEvent={bookedEvent}
              />
            </>
          }
          backLink={routes.main.events}
        />
        <Section>
          <DetailsSection
            leftColumn={
              <LeftWrapper>
                <img
                  src={event.imageUrl}
                  alt={`${event.title}-img`}
                  width="100%"
                  style={{
                    maxHeight: '384px',
                    maxWidth: '100%',
                    width: 'auto',
                    margin: '0 auto',
                    display: 'block',
                  }}
                />
                {hasPrice && (
                  <Box sx={{ padding: 3 }}>
                    <Box
                      display="flex"
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                      }}
                    >
                      <Box display="flex">
                        <Price price={event.price} vat />
                      </Box>
                      <Box display="flex">
                        <Typography display="flex">
                          {`${from.format('HH:mm')} - ${to.format('HH:mm')}`}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            background: '#1D1954',
                            marginX: 1,
                            marginY: '3px',
                            height: 20,
                          }}
                        />
                        <Typography display="flex">
                          {`${from.format('DD/MM/YY')}`}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        margin: '8px 0',
                        border: '1px solid #D9DFEF',
                      }}
                    />
                    <ButtonAddToBasket details={event} />
                  </Box>
                )}
              </LeftWrapper>
            }
            rightColumn={
              <>
                <Typography
                  marginBottom="16px"
                  display="flex"
                  variant="h4"
                  fontWeight="fontWeightBold"
                >
                  Location
                </Typography>
                <LabelWithIcon label={event.address} icon={PinIcon} />
                <LabelWithIcon
                  label={from.format('dddd Do MMMM YYYY')}
                  icon={CalendarIcon}
                />
                <LabelWithIcon
                  label={`${from.format('HH:mm')} - ${to.format('HH:mm')}`}
                  icon={ClockIcon}
                />
                <Divider
                  sx={{
                    marginTop: '16px',
                    marginBottom: '18px',
                  }}
                />
                <LabelWithIcon label="About" icon={InfoIcon} isHeader />
                <Box
                  sx={{
                    lineHeight: '18px',
                    marginBottom: '12px',
                    overflowWrap: 'break-word',
                    a: {
                      color: 'inherit',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </>
            }
          />
        </Section>
        {relatedEvents.length > 0 && (
          <Section>
            <RelatedItemsSection type="events" relatedItems={relatedEvents} />
            <Box textAlign="center">
              <ScrollTopButton />
            </Box>
          </Section>
        )}
      </>
    );
  }
  return null;
};

export default EventDetail;
