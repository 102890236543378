import {
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
  useTheme,
  Divider,
  Box,
} from '@mui/material';

import MotionCard from 'components/MotionCard';
import { useAppContext } from 'context/AppContext';
import CardActions from './CardActions';
import ProductModal from './ProductModal';

const ProductCard = ({ details }: { details: ProductProps }) => {
  const theme = useTheme();
  const { openModal } = useAppContext();

  return (
    <MotionCard whileHover={{ scale: 1.025 }}>
      <CardContent
        sx={{
          padding: '16px 16px 24px 16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardActionArea
          component={Box}
          onClick={() => openModal(<ProductModal details={details} />)}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Box width="100%" marginBottom="auto">
            <CardMedia
              component="img"
              height="176"
              src={details.imagePath}
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>,
              ) => {
                event.currentTarget.src = '/assets/images/placeholder.png';
              }}
              alt={`Product ${details.Id} image`}
              sx={{ borderRadius: '4px', marginBottom: '16px' }}
            />
            <Typography
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
              lineHeight="18px"
              marginBottom="5px"
            >
              {details.Name}
            </Typography>
            <Typography
              fontSize={theme.typography.smallFontSize}
              lineHeight="16.25px"
              sx={{
                opacity: 0.65,
              }}
            >
              {details.Description}
            </Typography>
          </Box>
          <Divider
            sx={{
              marginY: '10px',
            }}
            flexItem
          />
          <Typography component={Box} variant="h2" lineHeight="26px">
            £{details.Price.toFixed(2)}{' '}
            <Typography
              display="inline"
              fontSize={theme.typography.smallFontSize}
              lineHeight="16.25px"
            >
              + VAT
            </Typography>
          </Typography>
        </CardActionArea>

        <CardActions
          productId={details.Id}
          regularCharge={details.RegularCharge}
        />
      </CardContent>
    </MotionCard>
  );
};

export default ProductCard;
