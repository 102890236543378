/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneCallIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M15.0494 5C16.0262 5.19057 16.9238 5.66826 17.6275 6.37194C18.3312 7.07561 18.8089 7.97326 18.9994 8.95M15.0494 1C17.0787 1.22544 18.971 2.13417 20.4157 3.57701C21.8603 5.01984 22.7715 6.91101 22.9994 8.94M21.9994 16.92V19.92C22.0006 20.1985 21.9435 20.4742 21.832 20.7293C21.7204 20.9845 21.5567 21.2136 21.3515 21.4019C21.1463 21.5901 20.904 21.7335 20.6402 21.8227C20.3764 21.9119 20.0968 21.9451 19.8194 21.92C16.7423 21.5856 13.7864 20.5341 11.1894 18.85C8.77327 17.3147 6.72478 15.2662 5.18945 12.85C3.49942 10.2412 2.44769 7.27099 2.11944 4.18C2.09446 3.90347 2.12732 3.62476 2.21595 3.36162C2.30457 3.09849 2.44702 2.85669 2.63421 2.65162C2.82141 2.44655 3.04925 2.28271 3.30324 2.17052C3.55722 2.05833 3.83179 2.00026 4.10945 2H7.10945C7.59475 1.99522 8.06524 2.16708 8.43321 2.48353C8.80118 2.79999 9.04152 3.23945 9.10944 3.72C9.23607 4.68007 9.47089 5.62273 9.80945 6.53C9.94399 6.88792 9.97311 7.27691 9.89335 7.65088C9.8136 8.02485 9.62831 8.36811 9.35944 8.64L8.08945 9.91C9.513 12.4135 11.5859 14.4864 14.0894 15.91L15.3594 14.64C15.6313 14.3711 15.9746 14.1858 16.3486 14.1061C16.7225 14.0263 17.1115 14.0555 17.4694 14.19C18.3767 14.5286 19.3194 14.7634 20.2794 14.89C20.7652 14.9585 21.2088 15.2032 21.526 15.5775C21.8431 15.9518 22.0116 16.4296 21.9994 16.92Z"
      fill="none"
      stroke="#FABE79"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PhoneCallIcon;
