/* eslint-disable max-len */
import { useQuery } from 'react-query';
import Slider from 'components/Slider';
import Section from 'components/Section';
import CardItem from 'components/Common/CardItem';
import AdBanner from 'components/AdBanner';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import { useAuth } from 'context/AuthContext';
import LoadingSpinner from 'components/Common/LoadingSpinner';

import { ACCOUNT_TYPES } from 'constants/account';
import { BANNER } from 'constants/banner';

const PromotionsSection = () => {
  const { currentSpaceId, userType } = useAuth();

  const getPerks = async () => {
    const {
      data: { data },
    } = await API.get(
      APIRoutes.perks.index(LIMITS.DASHBOARD_SECTION, 1, currentSpaceId),
    );
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<PerkProps[]>(
    ['perks', currentSpaceId],
    () => getPerks(),
  );

  return (
    <Section>
      {isLoading && <LoadingSpinner minHeight="334px" />}
      {isSuccess && (
        <>
          <Slider
            id="promotions"
            title="Promotions"
            type="promotions"
            emptyComponent
            items={data.map((promotion) => (
              <CardItem
                type="promotions"
                key={`promotion-${promotion.id}`}
                {...promotion}
              />
            ))}
          />
          {[
            ACCOUNT_TYPES.RESIDENT_ACTIVE,
            ACCOUNT_TYPES.FLEX,
            ACCOUNT_TYPES.UNLIMITED,
          ].includes(userType) && (
            <AdBanner
              image={BANNER.REFER.image}
              type={BANNER.REFER.type}
              title={BANNER.REFER.title}
              subtitle={BANNER.REFER.text}
              label={BANNER.REFER.label}
              onClick={() => {
                window.open(BANNER.REFER.link, '_blank');
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default PromotionsSection;
