import {
  Box,
  FormHelperText,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, get, useFormContext } from 'react-hook-form';
import moment from 'moment';

import CalendarIcon from 'assets/icons/CalendarIcon';

const DatePickerController = ({
  name,
  label,
  required,
  disablePast,
  disableFuture,
  disableWeekends,
  bankHolidays,
  sx,
}: {
  name: string;
  label: string;
  required?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableWeekends?: boolean;
  bankHolidays?: string[];
  sx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <Box sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <DatePicker
            {...rest}
            value={value || null}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            label={label}
            disablePast={disablePast}
            disableFuture={disableFuture}
            shouldDisableDate={(date) => {
              if (!disableWeekends) return false;
              const threeMonths = moment().add(3, 'months');
              const isTodayBlocked =
                moment(date).isSame(moment(), 'day') &&
                moment().isSameOrAfter(moment({ hour: 15, minute: 0 }));
              const isBankHoliday = bankHolidays?.includes(
                moment(date).format('YYYY-MM-DD'),
              );
              const weekday = moment(date).isoWeekday();
              return (
                weekday === 6 ||
                weekday === 7 ||
                isBankHoliday ||
                moment(date).isAfter(threeMonths) ||
                isTodayBlocked
              );
            }}
            inputFormat="DD/MM/YYYY"
            InputProps={{ disableUnderline: true }}
            components={{
              OpenPickerIcon: CalendarIcon,
            }}
            PopperProps={{
              placement: 'bottom-end',
            }}
            dayOfWeekFormatter={(day) => day}
            showDaysOutsideCurrentMonth
            OpenPickerButtonProps={{
              sx: {
                color: theme.palette.text.primary,
                marginRight: '0px',
                '& .MuiSvgIcon-root': {
                  width: '24px',
                  height: '24px',
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  width: '100%',
                  mb: 1,
                }}
                required={required}
              />
            )}
          />
        )}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
};

DatePickerController.defaultProps = {
  disablePast: false,
  disableFuture: false,
  required: false,
  disableWeekends: false,
  bankHolidays: [],
  sx: null,
};

export default DatePickerController;
