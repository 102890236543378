/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

type FavoriteIconProps = {
  isFavorite?: boolean;
} & SvgIconProps;

const FavoriteIcon = ({ isFavorite, ...props }: FavoriteIconProps) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fontSize="large"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <path
        d="M20.5 29a.63.63 0 0 1-.445-.184l-8.4-8.372a5.45 5.45 0 0 1-1.655-3.9 5.452 5.452 0 0 1 1.667-3.87A5.603 5.603 0 0 1 15.565 11a5.606 5.606 0 0 1 3.91 1.65l1.025 1.02 1.025-1.02a5.579 5.579 0 0 1 6.032-1.182A5.547 5.547 0 0 1 31 16.544a5.452 5.452 0 0 1-1.657 3.902l-8.4 8.372A.63.63 0 0 1 20.5 29z"
        fillRule="nonzero"
        stroke="#212840"
        fill={isFavorite ? '#212840' : 'none'}
      />
    </g>
  </SvgIcon>
);

FavoriteIcon.defaultProps = {
  isFavorite: false,
};

export default FavoriteIcon;
