import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="7.5"
      fill="none"
      stroke="currentColor"
    />
  </SvgIcon>
);

export default CheckboxIcon;
