import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseMenuIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M18 6L6 18M6 6L18 18"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CloseMenuIcon;
