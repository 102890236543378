/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const AlertCircle = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 40 40" {...props}>
    <path
      d="M19.9987 13.3335V20.0002M19.9987 26.6668H20.0154M36.6654 20.0002C36.6654 29.2049 29.2034 36.6668 19.9987 36.6668C10.794 36.6668 3.33203 29.2049 3.33203 20.0002C3.33203 10.7954 10.794 3.3335 19.9987 3.3335C29.2034 3.3335 36.6654 10.7954 36.6654 20.0002Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default AlertCircle;
