import React from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';

import { isEmpty, remove } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import SectionHeader from 'components/SectionHeader';
import CustomTabs from 'components/Common/Tabs';
import PincodeInput from 'components/Common/PincodeInput';
import Section from 'components/Section';
import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import InvoicesTable from 'components/Profile/InvoicesTable';
import DownloadButton from 'components/Profile/DownloadButton';
import EmptySection from 'components/Common/EmptySection';
import routes from 'utils/routes';

const breadcrumbsConfig = () => [{ label: 'Invoices' }];

const Invoices = () => {
  const navigate = useNavigate();

  const getInvoices = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.invoices.index);
    return data;
  };

  const {
    isLoading,
    isSuccess,
    data: prevInvoices,
  } = useQuery<InvoiceProps[]>('invoices', () => getInvoices());

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    const newInvoices = remove(
      prevInvoices,
      (invoice) => invoice.status === 'new',
    );

    return (
      <>
        <SectionHeader
          title="My profile"
          path={breadcrumbsConfig()}
          tabs={<CustomTabs scrollable tabsConfig={TABS_ACCOUNT} />}
          elements={
            <Box width="250px">
              <PincodeInput lightBackground />
            </Box>
          }
          backLink={routes.main.account.dashboard}
        />
        <Section>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="24px"
            gap="16px"
            flexWrap="wrap"
          >
            <Typography maxWidth="490px" lineHeight="18px">
              Please note that charges for bookings may be added to your account
              as each booking ends so they may not show until then.
            </Typography>
            <DownloadButton name="statement" />
          </Box>
          {isEmpty(prevInvoices) && isEmpty(newInvoices) && (
            <EmptySection
              onClick={() => navigate(routes.main.account.plans)}
              missing="you have no new invoices"
              helperText="Check out your plans & benefits"
              label="Plans & Benefits"
              icon={
                <img src="/assets/images/NoInvoice.svg" alt="No invoices" />
              }
            />
          )}
          <InvoicesTable label="My current invoice" invoices={newInvoices} />
          <InvoicesTable label="Previous invoices" invoices={prevInvoices} />
        </Section>
      </>
    );
  }
  return null;
};

export default Invoices;
