import { ReactNode } from 'react';
import { Grid } from '@mui/material';

export type DetailsSectionProps = {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
};

const DetailsSection = ({ leftColumn, rightColumn }: DetailsSectionProps) => (
  <Grid container spacing={6}>
    <Grid item xs={12} lg={7}>
      {leftColumn}
    </Grid>
    <Grid item xs={12} lg={5}>
      {rightColumn}
    </Grid>
  </Grid>
);

export default DetailsSection;
