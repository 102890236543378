import { Grid, Box, Typography, useTheme } from '@mui/material';

import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';
import SelectField from 'components/Form/SelectField';
import { years, months, countries } from 'components/Profile/helpers';
import LoadingButton from 'components/Common/LoadingButton';

const CreditCardDetailsForm = ({ isLoading }: { isLoading: boolean }) => {
  const theme = useTheme();

  return (
    <Section title="Credit/Debit Card Details" id="credit-card-details">
      <Box display="flex" gap="32px" mb="15px" flexDirection="column">
        <Typography variant="h5">
          By clicking &ldquo;Update Payment Details&rdquo;, you authorize
          Work.Life to automatically debit your bank account for any due
          invoices and, if necessary, credit your account to correct any
          erroneous debits.
        </Typography>
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={6}>
          <TextInput label="First name" name="firstName" type="text" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="Last name" name="lastName" type="text" />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput label="Card Number" name="number" type="text" />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextInput
            label="Security code"
            name="verificationValue"
            type="text"
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SelectField label="Expiry" name="month" options={months} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SelectField label="Year" name="year" options={years} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextInput name="address1" label="Billing Address" type="text" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="City" name="city" type="text" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="State" name="state" type="text" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput label="Post code" name="zip" type="text" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectField label="Country" name="country" options={countries} />
        </Grid>
      </Grid>
      <LoadingButton
        isLoading={isLoading}
        variant="contained"
        type="submit"
        sx={{
          backgroundColor: theme.palette.primary.main,
          textDecoration: 'none',
          px: '24px',
          maxHeight: '60px',
          minHeight: '46px',
          lineHeight: '16px',
        }}
      >
        Update Payment Details
      </LoadingButton>
    </Section>
  );
};

export default CreditCardDetailsForm;
