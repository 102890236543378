import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        fontWeight: theme.typography.button.fontWeight,
        borderRadius: '56px',
        padding: '9px 15px',
        justifyContent: 'space-between',
      },
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          backgroundColor: theme.palette.blue[900],
          color: theme.palette.text.main,
          '&:hover': {
            backgroundColor: theme.palette.blue[800],
          },
          '&:disabled': {
            backgroundColor: theme.palette.blue[800],
            opacity: 0.5,
            color: theme.palette.text.main,
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          backgroundColor: theme.palette.orange[800],
          color: theme.palette.blue[900],
          '&:disabled': {
            backgroundColor: theme.palette.orange[800],
            opacity: 0.5,
            color: theme.palette.text.main,
          },
          '&:hover': {
            backgroundColor: theme.palette.orange[700],
          },
        },
      },
    ],
  },
});
