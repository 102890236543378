import { SvgIcon, SvgIconProps } from '@mui/material';

const ChevronsRightIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M13 17L18 12L13 7M6 17L11 12L6 7"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronsRightIcon;
