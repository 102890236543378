import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';

import FindMore from 'pages/MeetingRooms/FindMore';
import Section from 'components/Section';

import breakpoints from 'styles/theme/breakpoints';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import CalendarRow from 'components/Rooms/CalendarRow';
import { State } from 'components/Rooms/BookingSlotsSlider';
import { DEFAULT_STATE } from 'constants/rooms';
import { useAppContext } from 'context/AppContext';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import EmptySection from 'components/Common/EmptySection';
import SadFace from 'assets/icons/SadFace';
import MeetingRoomModal from 'components/Rooms/MeetingRoomModal';
import theme from 'styles/theme';
import HeaderSection from './ResultsViewHeader';

interface CalendarProps {
  changeLoc: (value: number) => void;
  loc: number | undefined;
  extendedGrid: boolean;
  setExtendedGrid: React.Dispatch<React.SetStateAction<boolean>>;
  isPartTimeBooking: boolean;
}
interface Params {
  spaceId: number | undefined;
  capacity: number;
  from: string;
  to: string;
  showAll: boolean;
  roomId?: number | undefined;
  isPartTimeBooking: boolean;
}

const ExternalCalendar = ({
  changeLoc,
  loc,
  extendedGrid,
  setExtendedGrid,
  isPartTimeBooking,
}: CalendarProps) => {
  const { openModal } = useAppContext();
  const [searchParams] = useSearchParams();

  const paramsSpaceId = searchParams.get('spaceId') as string;
  const paramFrom = searchParams.get('from') as string;
  const paramTo = searchParams.get('to') as string;
  const paramCapacity = searchParams.get('capacity') as string;
  const paramRoomId = searchParams.get('roomId') as string;

  const [selectedDate] = useState(
    paramFrom
      ? moment(paramFrom)
      : moment().set({
          minutes:
            moment().get('minutes') - (moment().get('minutes') % 15) + 15,
          seconds: 0,
          milliseconds: 0,
        }),
  );
  const [selected, setSelected] = useState<State>(DEFAULT_STATE);
  const [timeIndex, setTimeIndex] = useState(0);

  const defaultStartDate = isPartTimeBooking
    ? moment().startOf('month').set({ hour: 8, minutes: 30 })
    : selectedDate;
  const defaultEndDate = isPartTimeBooking
    ? moment().startOf('month').add(35, 'days').set({ hour: 17, minutes: 30 })
    : selectedDate.clone().add(1, 'hours');

  const defaultParams: Params = {
    spaceId: +paramsSpaceId,
    capacity: Number.isNaN(parseInt(paramCapacity, 10))
      ? 1
      : parseInt(paramCapacity, 10),
    from: (paramFrom ? moment(paramFrom) : defaultStartDate).toISOString(),
    to: (paramTo ? moment(paramTo) : defaultEndDate).toISOString(),
    showAll: !paramCapacity,
    roomId: paramRoomId ? +paramRoomId : undefined,
    isPartTimeBooking,
  };

  const getRooms = async (params: Params) => {
    const {
      data: { data },
    } = await API.get(APIRoutes.bookings.external(params));
    return data;
  };

  const { data, isLoading, isSuccess } = useQuery<Room[]>(
    [
      'rooms',
      { isPartTimeBooking },
      [
        searchParams.get('spaceId'),
        searchParams.get('capacity'),
        searchParams.get('from'),
        searchParams.get('to'),
        searchParams.get('roomId'),
      ],
      selectedDate,
    ],
    () => getRooms(defaultParams),
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      const currentTime = defaultParams.from
        ? moment(defaultParams.from).format('HH:mm')
        : moment()
            .set({
              minutes:
                moment().get('minutes') - (moment().get('minutes') % 15) + 15,
            })
            .format('HH:mm');
      const index = data?.[0].availability.findIndex(
        (item) => moment(item.date).format('HH:mm') === currentTime,
      );
      setTimeIndex(index || 0);
    }
  }, [data]);

  useEffect(() => {
    const state = extendedGrid
      ? {
          ...DEFAULT_STATE,
          space: paramsSpaceId ? parseInt(paramsSpaceId, 10) : undefined,
          from: defaultParams.from,
          to: defaultParams.to,
          roomId: defaultParams.roomId,
        }
      : {
          ...DEFAULT_STATE,
          space: paramsSpaceId ? parseInt(paramsSpaceId, 10) : undefined,
        };
    setSelected(state);
  }, [selectedDate, loc, extendedGrid, paramsSpaceId]);

  useEffect(() => {
    if (isSuccess) {
      setExtendedGrid(searchParams.get('from') !== null);

      if (
        searchParams.get('from') !== null &&
        loc === parseInt(searchParams.get('spaceId') as string, 10)
      )
        changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
    }
  }, [data]);

  useEffect(() => {
    if (extendedGrid)
      changeLoc(parseInt(searchParams.get('spaceId') as string, 10));
  }, [isSuccess]);

  if (isLoading) {
    return <Loader />;
  }

  if (moment(defaultParams.from).isBetween('2023-12-25', '2024-01-01')) {
    return (
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        color={theme.palette.text.content}
      >
        <Box sx={{ svg: { width: '100%', height: '100%' } }} mt="70px">
          <SadFace />
        </Box>
        <Typography
          fontWeight="400"
          fontSize="20px"
          mt={1}
          lineHeight="20px"
          maxWidth="600px"
        >
          We’re sorry, but bookings are unavailable for the festive period.
          Please search for a booking after the 1st of January 2024.
        </Typography>
      </Box>
    );
  }

  if (moment(defaultParams.from).isBetween('2024-04-12', '2024-04-13')) {
    return (
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        color={theme.palette.text.content}
      >
        <Box sx={{ svg: { width: '100%', height: '100%' } }} mt="70px">
          <SadFace />
        </Box>
        <Typography
          fontWeight="400"
          fontSize="20px"
          mt={1}
          lineHeight="20px"
          maxWidth="600px"
        >
          We’re sorry, but bookings are unavailable on April 12th.
        </Typography>
      </Box>
    );
  }

  if (isSuccess) {
    return (
      <Section height="calc(100% - 250px)">
        <Box
          sx={{
            [`@media (max-width: ${breakpoints.values.md}px)`]: {
              marginTop: '64px',
            },
          }}
        >
          <HeaderSection
            startDate={defaultParams.from}
            loc={loc}
            changeLoc={changeLoc}
          />
        </Box>
        {isEmpty(data) ? (
          <EmptySection
            missing={`there were no ${
              extendedGrid ? 'results' : 'rooms'
            } found`}
            label={extendedGrid ? 'Edit search' : 'Book a room'}
            helperText={
              extendedGrid ? 'Try adjusting your search' : 'Book a room today'
            }
            icon={<SadFace />}
            isEdit={extendedGrid}
            onClick={() => {
              openModal(
                <MeetingRoomModal
                  initialValues={{
                    location: loc?.toString(),
                  }}
                  isPartTimeBooking={isPartTimeBooking}
                />,
              );
            }}
          />
        ) : (
          <>
            <Box display="flex" flexDirection="column" gap="16px" pt="48px">
              {data.map((room, index) => (
                <CalendarRow
                  key={room.id}
                  index={index}
                  room={room}
                  selected={selected}
                  setSelected={setSelected}
                  isResultsView={extendedGrid}
                  isExternal
                  timeIndex={timeIndex}
                  setTimeIndex={setTimeIndex}
                  isPartTimeBooking={isPartTimeBooking}
                />
              ))}
            </Box>
            {!extendedGrid && (
              <FindMore
                onClick={() => {
                  openModal(
                    <MeetingRoomModal isPartTimeBooking={isPartTimeBooking} />,
                  );
                }}
              />
            )}
          </>
        )}

        <Box textAlign="center" mt="27px">
          <ScrollTopButton />
        </Box>
      </Section>
    );
  }
  return null;
};

export default ExternalCalendar;
