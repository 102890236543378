import moment from 'moment';
import ClockIcon from 'assets/icons/ClockIcon';
import OverdueIcon from 'assets/icons/OverdueIcon';
import PaidIcon from 'assets/icons/PaidIcon';
import COUNTRIES from 'assets/countries.json';

export const mapStatus = (status: string) => {
  const result = {
    label: 'Paid',
    icon: PaidIcon,
  };
  if (status === 'new') {
    result.label = 'Pending';
    result.icon = ClockIcon;
  } else if (status === 'due') {
    result.label = 'Overdue';
    result.icon = OverdueIcon;
  }
  return result;
};

export const months = Array.from({ length: 12 }, (_, i) => {
  const month = moment().startOf('year').add(i, 'month');
  return {
    label: month.format('MM'),
    value: month.format('MM'),
  };
});

export const years = Array.from({ length: 10 }, (_, i) => {
  const year = moment().add(i, 'year');
  return {
    label: year.format('YY'),
    value: year.format('YYYY'),
  };
});

export const countries = Object.entries(COUNTRIES).map(([id, name]) => ({
  value: id,
  label: name,
}));
