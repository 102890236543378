/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const NavigationIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 8 14" width="8px" height="14px" {...props}>
    <path
      d="M7 13L1 7L7 1"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default NavigationIcon;
