import { Box, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import routes from 'utils/routes';

import LoginForm from './LoginForm';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Login = () => {
  const { state } = useLocation();
  const isRetrying = state?.isRetrying;

  return (
    <Container>
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          right: '35px',
        }}
      >
        <img src="./logo.svg" alt="logo" width="280px" height="35px" />
      </Box>
      <Box width="343px">
        <Typography variant="h1" sx={{ mb: 1 }}>
          {isRetrying ? 'Give it another go' : 'Welcome'}
        </Typography>
        <Typography
          sx={{
            width: '322px',
            fontWeight: 'fontWeightRegular',
            mb: 3,
          }}
        >
          Please enter your details to login
        </Typography>
        <LoginForm />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 50,
        }}
      >
        <Link to={routes.auth.resetPassword}>Forgot password?</Link>
      </Box>
    </Container>
  );
};

export default Login;
