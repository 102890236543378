import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ScrollTopButton = ({ hideLabel }: { hideLabel?: boolean }) => {
  const theme = useTheme();

  return (
    <Button
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      sx={{
        flexFlow: 'column',
        cursor: 'pointer',
      }}
    >
      <Typography fontSize={33}>☝️</Typography>
      {!hideLabel && (
        <Typography
          variant="h4"
          fontWeight={theme.typography.fontWeightBold}
          color={theme.palette.secondary.contrastText}
        >
          Take me back to the top
        </Typography>
      )}
    </Button>
  );
};

ScrollTopButton.defaultProps = {
  hideLabel: false,
};

export default ScrollTopButton;
