import {
  CardContent,
  CardActionArea,
  Divider,
  Typography,
  Box,
  useTheme,
  Chip,
  Icon,
} from '@mui/material';
import moment from 'moment';
import ClockIcon from 'assets/icons/ClockIcon';
import ThumbsUpIcon from 'assets/icons/ThumbUpIcon';
import MotionCard from 'components/MotionCard';
import { useAppContext } from 'context/AppContext';
import ChatModal from './ChatModal';

const TicketCard = ({
  _id,
  description,
  hubspotId,
  closed,
  lastMessage,
}: TicketProps) => {
  const { openModal } = useAppContext();
  const theme = useTheme();
  const date = moment(lastMessage.ts);
  const isClosed = closed === true;

  return (
    <MotionCard
      whileHover={{ scale: 1.025 }}
      sx={{
        overflow: 'visible',
      }}
    >
      <CardActionArea onClick={() => openModal(<ChatModal chatId={_id} />)}>
        <CardContent
          sx={{
            padding: '5px 12px 16px 12px',
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.blue[900],
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={theme.typography.smallFontSize}
              sx={{
                opacity: 0.65,
              }}
            >
              {date.format('DD/MM/YY')}
            </Typography>
            <Box display="flex" alignItems="center" gap="8px" marginLeft="auto">
              <Typography
                fontSize={theme.typography.smallFontSize}
                sx={{
                  opacity: 0.65,
                }}
              >
                {date.format('HH:mm')}
              </Typography>
              {!lastMessage.read && lastMessage.actor === 'support' && (
                <Chip
                  label="New!"
                  color="secondary"
                  sx={{
                    width: '62px',
                    height: '20px',
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: theme.typography.smallFontSize,
                  }}
                />
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              marginTop: '8px',
              marginBottom: '12px',
            }}
          />
          <Typography
            variant="h5"
            fontWeight={theme.typography.fontWeightBold}
            lineHeight="18px"
            marginBottom="4px"
            width="100%"
            noWrap
          >
            {description}
          </Typography>
          <Typography fontSize="15px" lineHeight="17px" marginBottom="4px">
            Ticket no. {hubspotId}
          </Typography>
          <Box marginLeft="auto" display="flex" alignItems="center">
            <Icon
              component={isClosed ? ThumbsUpIcon : ClockIcon}
              sx={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
            <Typography
              fontSize={theme.typography.smallFontSize}
              line-height="13px"
            >
              {isClosed ? 'Resolved' : 'Logged'}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </MotionCard>
  );
};

export default TicketCard;
