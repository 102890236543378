import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';
import BackButton from 'components/BackButton';
import { useAuth } from 'context/AuthContext';
import { Container } from './Login';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.main.dashboard);
    }
  }, [isAuthenticated]);

  return (
    <Container>
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          right: '35px',
        }}
      >
        <img src="/logo.svg" alt="logo" width="280px" height="35px" />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: '33px',
        }}
      >
        <BackButton
          label="Back to login"
          to={routes.auth.signIn}
          wrapIconWithButton
          isHeader={false}
        />
      </Box>
      <Box width="343px">
        <Typography variant="h1" sx={{ mb: 1 }}>
          Not to worry!
        </Typography>
        <Typography
          sx={{
            width: '322px',
            fontWeight: 'fontWeightRegular',
            mb: 3,
          }}
        >
          Enter your email associated with your Work.Life account and we&apos;ll
          send you a&nbsp;recovery link.
        </Typography>
        <ResetPasswordForm />
      </Box>
    </Container>
  );
};

export default ResetPassword;
