import moment from 'moment';

export const METRICS_TYPE = [
  {
    label: 'Me',
    value: 'me',
  },
  {
    label: 'My team',
    value: 'team',
  },
  {
    label: 'Me and my team',
    value: 'me_and_team',
  },
];

export const MONTH_FILTER = Array.from({ length: 13 }, (_, i) => {
  const day = moment().startOf('month').add(i, 'month');
  return {
    label: day.format('MMMM YYYY'),
    value: day.toISOString(),
  };
});
