const BASE_FONT_SIZE = 16;
const remCalc = (size: number) => `${size / BASE_FONT_SIZE}rem`;

const typography = {
  fontFamily: '"Antebas", "Roboto", sans-serif',
  fontSize: 16,
  smallFontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: remCalc(33),
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: remCalc(26),
    fontWeight: 700,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: remCalc(20),
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: '1.0625rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  body1: {
    fontSize: 16,
  },
  button: {
    fontWeight: 700,
    textTransform: 'none',
  },
};

export default typography;
