import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomTabs from 'components/Common/Tabs';
import SectionHeader from 'components/SectionHeader';
import theme from 'styles/theme';
import Section from 'components/Section';
import TicketsSection from 'pages/Help/TicketsSection';
import FAQsSection from 'pages/Help/FAQSection';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import { TABS_HELP } from 'constants/tabs/tabsHelp';

import routes from 'utils/routes';

const breadcrumbsConfig = () => [{ label: 'Help/FAQs' }];

type HelpFAQsProps = {
  tab: string;
};

const HelpFAQs = ({ tab }: HelpFAQsProps) => (
  <>
    <SectionHeader
      title="Help/FAQs"
      hideBackButton
      path={breadcrumbsConfig()}
      tabs={<CustomTabs tabsConfig={TABS_HELP} />}
      elements={
        <Link
          to={routes.main.help.raiseTicket}
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            sx={{
              justifyContent: 'center',
              backgroundColor: theme.palette.primary.main,
              width: '200px',
              height: '48px',
            }}
          >
            Raise a ticket
          </Button>
        </Link>
      }
    />
    <Section>
      {tab === 'ticket' ? <TicketsSection /> : <FAQsSection />}
      <Box textAlign="center" mt="48px">
        <ScrollTopButton />
      </Box>
    </Section>
  </>
);

export default HelpFAQs;
