import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

import MeetingIcon from 'assets/icons/MeetingIcon';
import InvoiceIcon from 'assets/icons/SmallInvoiceIcon';
import MessageIcon from 'assets/icons/MessageIcon';
import { useAppContext } from 'context/AppContext';
import ChatModal from 'components/Help/ChatModal';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';

interface MessageItemProps {
  type: 'chatmessage' | 'coworkerinvoice' | 'upcomingbooking';
  label: string;
  date: string;
  isNew?: boolean | undefined | null;
  id: string;
  entityId: string;
}

const MessageItem = ({
  type,
  label,
  date,
  isNew,
  id,
  entityId,
}: MessageItemProps) => {
  const theme = useTheme();
  const { openModal } = useAppContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: markAsRead } = useMutation(
    () => API.patch(APIRoutes.alerts.markAsRead(id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('alerts');
      },
    },
  );

  const handleClick = useCallback(() => {
    markAsRead();
    if (type === 'chatmessage') {
      openModal(<ChatModal chatId={entityId} />);
    } else if (type === 'coworkerinvoice') {
      navigate(routes.main.account.invoices);
    } else if (type === 'upcomingbooking') {
      navigate(routes.bookings(entityId));
    }
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      sx={{
        backgroundColor: '#fff',
        cursor: 'pointer',
      }}
      px="30px"
      py="20px"
      mb="24px"
      borderRadius="8px"
      gap="24px"
      position="relative"
      border={isNew ? `1px solid ${theme.palette.secondary.main}` : 'none'}
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      onClick={handleClick}
    >
      <Box sx={{ '& svg': { fontSize: '2.5rem' } }}>
        {type === 'chatmessage' && <MessageIcon />}
        {type === 'coworkerinvoice' && <InvoiceIcon />}
        {type === 'upcomingbooking' && <MeetingIcon />}
      </Box>
      <Box lineHeight="16px" pb="8px">
        <Typography
          fontSize="13px"
          sx={{ color: theme.palette.text.content, fontWeight: 300 }}
          mb="2px"
        >
          {moment(date).format('DD/MM/YYYY')}
        </Typography>

        <Box fontWeight="600" dangerouslySetInnerHTML={{ __html: label }} />
        {isNew && (
          <Box
            position="absolute"
            textAlign="center"
            width="62px"
            py="5px"
            fontSize="14px"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '16px',
              top: '-13px',
              right: '25px',
            }}
          >
            New!
          </Box>
        )}
      </Box>
    </Box>
  );
};

MessageItem.defaultProps = {
  isNew: false,
};

export default MessageItem;
