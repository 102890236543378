import React from 'react';
import { Box, Typography } from '@mui/material';

export const Wrapper = ({
  children,
  label,
  withMargins,
  customSelector,
  childBoxHeight,
}: {
  children: React.ReactNode;
  label: string;
  withMargins?: boolean;
  customSelector?: React.ReactNode;
  childBoxHeight?: string;
}) => (
  <Box
    sx={{
      backgroundColor: '#fff',
      borderRadius: '8px',
      my: withMargins ? '24px' : 0,
    }}
  >
    <Box
      sx={{ borderBottom: `1px solid #D9DFEF` }}
      p="24px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography fontSize="20px" fontWeight="bold">
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        {customSelector || (
          <>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                background: '#1D1954',
                borderRadius: '4px',
                marginRight: '8px',
              }}
            />
            <Typography fontSize="16px">Me</Typography>
          </>
        )}
      </Box>
    </Box>
    <Box p="24px" height={childBoxHeight || '320px'}>
      {children}
    </Box>
  </Box>
);
Wrapper.defaultProps = {
  withMargins: false,
  customSelector: null,
  childBoxHeight: '320px',
};
