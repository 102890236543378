import { Box, Divider, Typography, useTheme } from '@mui/material';
import PinIcon from 'assets/icons/PinIcon';
import { useAuth } from 'context/AuthContext';
import { MENU_TYPES } from 'constants/common';
import Menu from 'components/Common/HeaderMenu';
import { useAppContext } from 'context/AppContext';

type LocationListProps = {
  label: string;
  locations: SpaceProps[];
  currentSpaceId: number;
  onSelect: (id: number) => void;
};

const LocationList = ({
  label,
  locations,
  currentSpaceId,
  onSelect,
}: LocationListProps) => {
  const theme = useTheme();
  const { anchorEl, setAnchorEl } = useAppContext();

  return (
    <Box width="100%">
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'fontWeightBold',
        }}
      >
        {label}
      </Typography>
      <Divider
        sx={{
          marginTop: '12px',
          marginBottom: '16px',
        }}
      />
      {locations.map((location) => (
        <Typography
          key={location.id}
          sx={{
            my: '12px',
            lineHeight: '18px',
            cursor: 'pointer',
            fontWeight:
              location.id === currentSpaceId
                ? 'fontWeightBold'
                : 'fontWeightRegular',
          }}
          onClick={() => {
            setAnchorEl({ ...anchorEl, [MENU_TYPES.LOCATION]: false });
            onSelect(location.id);
          }}
        >
          <>
            {location.id === currentSpaceId && (
              <PinIcon
                sx={{
                  fill: theme.palette.success.main,
                  height: '16px',
                  width: '16px',
                  marginRight: '6px',
                }}
              />
            )}
            {location.shortName}
          </>
        </Typography>
      ))}
    </Box>
  );
};

const LocationMenu = () => {
  const theme = useTheme();
  const { currentSpaceId, setUserSpaceId, spaces } = useAuth();
  const { anchorEl, setAnchorEl } = useAppContext();
  const currentSpace = spaces.find((space) => space.id === currentSpaceId);

  return (
    <Menu
      label={currentSpace?.shortName || ''}
      icon={
        <PinIcon
          sx={{
            fill: theme.palette.success.main,
          }}
        />
      }
      type={MENU_TYPES.LOCATION}
      color="secondary"
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: '24px',
          cursor: 'pointer',
          fontWeight: 'fontWeightBold',
        }}
        onClick={() => {
          setAnchorEl({ ...anchorEl, [MENU_TYPES.LOCATION]: false });
          setUserSpaceId(0);
        }}
      >
        {currentSpaceId === 0 && (
          <PinIcon
            sx={{
              fill: theme.palette.success.main,
              height: '16px',
              width: '16px',
              marginRight: '6px',
            }}
          />
        )}
        All locations
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '34px',
        }}
      >
        <LocationList
          label="London"
          locations={spaces.filter((space) => space.location === 'london')}
          currentSpaceId={currentSpaceId}
          onSelect={setUserSpaceId}
        />
        <LocationList
          label="Rest of the UK"
          locations={spaces.filter(
            (space) => space.location && space.location !== 'london',
          )}
          currentSpaceId={currentSpaceId}
          onSelect={setUserSpaceId}
        />
      </Box>
    </Menu>
  );
};

export default LocationMenu;
