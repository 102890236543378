import { useNavigate } from 'react-router-dom';
import { Box, Typography, Avatar } from '@mui/material';
import moment from 'moment';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import AvatarIcon from 'assets/icons/AvatarIcon';
import { TABS_PROFILE } from 'constants/tabs/tabsProfile';
import { ChipButton } from '../style';
import AccountCard from './AccountCard';

type CardYourProfile = {
  label: string;
};

const CardYourProfile = ({ label }: CardYourProfile) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <AccountCard
      label={label}
      value={routes.main.account.profile}
      elements={
        <>
          <Box display="flex" gap="10px 18px">
            <Box>
              <Avatar
                sx={{ width: 116, height: 116, backgroundColor: 'transparent' }}
              >
                {user?.avatar ? (
                  <Avatar src={user?.avatar} sx={{ width: 116, height: 116 }} />
                ) : (
                  <AvatarIcon sx={{ width: 116, height: 116 }} />
                )}
              </Avatar>
            </Box>
            <Box margin="0 16px">
              <Typography fontSize={20} fontWeight={700}>
                {user?.fullName}
              </Typography>
              <Typography>{user?.email}</Typography>
              <Typography>{user?.phone}</Typography>
              <Typography>
                {user?.birthday && moment(user?.birthday).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" gap="10px 26px" marginTop="24px">
            {TABS_PROFILE.map((option) => (
              <ChipButton
                key={option.label}
                label={option.label}
                variant="outlined"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`${routes.main.account.profile}#${option.value}`);
                }}
              />
            ))}
          </Box>
        </>
      }
    />
  );
};

export default CardYourProfile;
