import { useState } from 'react';
import { Backdrop, Button, Menu, ButtonProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type DropdownMenuProps = {
  type: string;
  label?: string;
  color: ButtonProps['color'];
  minWidth?: string;
  icon: JSX.Element;
  items: JSX.Element[];
};

const DropdownMenu = ({
  type,
  label,
  color,
  minWidth,
  icon,
  items,
}: DropdownMenuProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="dropdown-button"
        aria-controls={open ? 'dropdown-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        color={color}
        sx={{
          padding: '4px 12px',
          minWidth: { minWidth },
          justifyContent: minWidth ? 'center' : 'space-between',
          fontWeight: theme.typography.fontWeightRegular,
          ...(color === 'primary' && {
            backgroundColor: theme.palette.blue[600],
          }),
          ...(type === 'category' &&
            open && {
              '& .MuiButton-startIcon': {
                transition: `transform ${theme.transitions.duration.standard}ms`,
                transform: 'rotate(180deg)',
              },
            }),
        }}
        startIcon={icon}
      >
        {label}
      </Button>
      <Backdrop open={open} onClick={handleClose}>
        <Menu
          id="dropdown-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              borderColor: `${color}.main`,
            },
          }}
        >
          {items}
        </Menu>
      </Backdrop>
    </>
  );
};

DropdownMenu.defaultProps = {
  label: '',
  minWidth: 'auto',
};

export default DropdownMenu;
