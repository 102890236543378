import { IconButtonProps, CircularProgress, IconButton } from '@mui/material';

type LoadingIconButtonProps = IconButtonProps & {
  isLoading: boolean;
};

const LoadingIconButton = ({
  isLoading,
  disabled,
  children,
  ...rest
}: LoadingIconButtonProps) => (
  <IconButton disabled={isLoading || disabled} {...rest}>
    {isLoading ? <CircularProgress size={20} /> : children}
  </IconButton>
);

export default LoadingIconButton;
