/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import CardItem from 'components/Common/CardItem';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { API, APIRoutes } from 'utils/api';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import { LIMITS } from 'constants/common';
import { useAuth } from 'context/AuthContext';
import LocationDropdown from 'components/Common/LocationDropdown';
import routes from 'utils/routes';

const PerksListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentSpaceId, setUserSpaceId, spaces, isValidSpaceId } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const paramSpace = searchParams.get('space');
    if (paramSpace && isValidSpaceId(paramSpace)) {
      setUserSpaceId(+paramSpace);
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    setSearchParams(currentSpaceId ? `?space=${currentSpaceId}` : '', {
      replace: true,
    });
  }, [currentSpaceId]);

  const getPerks = async ({ pageParam = 1 }) => {
    const { data } = await API.get(
      APIRoutes.perks.index(LIMITS.LISTING, pageParam, currentSpaceId),
    );
    return data;
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isSuccess, isFetching } =
    useInfiniteQuery(['perks-listing', currentSpaceId], getPerks, {
      getNextPageParam: (lastPage, pages) =>
        pages.length < lastPage?.totalPages ? pages.length + 1 : undefined,
      enabled: isInitialized,
    });

  return (
    <>
      <SectionHeader
        title="Perks"
        elements={
          <LocationDropdown
            items={spaces}
            currentId={currentSpaceId}
            onSelect={setUserSpaceId}
          />
        }
        backLink={routes.main.promotions}
      />
      <Section>
        {isLoading ? (
          <LoadingSpinner minHeight="500px" />
        ) : isSuccess ? (
          isEmpty(data.pages[0]?.data) ? (
            <Box sx={{ opacity: 0.6, textAlign: 'center' }}>
              No perks available
            </Box>
          ) : (
            <>
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill,minmax(312px, 1fr))"
                gap={2}
              >
                {data.pages.map((group) =>
                  group.data.map((perk: PerkProps) => (
                    <Box key={`perk-${perk.id}`}>
                      <CardItem type="promotions" {...perk} />
                    </Box>
                  )),
                )}
              </Box>

              <LoadMoreButton
                hasNextPage={hasNextPage}
                onClick={fetchNextPage}
                isFetching={isFetching}
              />
            </>
          )
        ) : null}
      </Section>
    </>
  );
};

export default PerksListing;
