import { Stack } from '@mui/material';
import SelectField from 'components/Form/SelectField';
import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';
import { OPTIONS } from 'constants/signUp';

const CompanyForm = () => (
  <Section title="For registered companies">
    <Stack spacing={2}>
      <SelectField
        name="onlyBusiness"
        options={OPTIONS.onlyBusiness}
        label="Will Work.Life be the only business property occupied by your company?"
      />
      <TextInput
        label="Company Registration Number"
        name="companyRegistrationNumber"
        type="text"
      />
    </Stack>
  </Section>
);

export default CompanyForm;
