/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const DatePicker = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      d="M12 1.5V4.5M6 1.5V4.5M2.25 7.5H15.75M3.75 3H14.25C15.0784 3 15.75 3.67157 15.75 4.5V15C15.75 15.8284 15.0784 16.5 14.25 16.5H3.75C2.92157 16.5 2.25 15.8284 2.25 15V4.5C2.25 3.67157 2.92157 3 3.75 3Z"
      fill="none"
      stroke="#1D1954"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="4.5" y="9.75" width="1.5" height="1.5" rx="0.15" fill="#1D1954" />
    <rect x="12" y="9.75" width="1.5" height="1.5" rx="0.15" fill="#1D1954" />
    <rect x="8.25" y="9.75" width="1.5" height="1.5" rx="0.15" fill="#1D1954" />
    <rect x="4.5" y="12.75" width="1.5" height="1.5" rx="0.15" fill="#1D1954" />
    <rect x="12" y="12.75" width="1.5" height="1.5" rx="0.15" fill="#1D1954" />
    <rect
      x="8.25"
      y="12.75"
      width="1.5"
      height="1.5"
      rx="0.15"
      fill="#1D1954"
    />
  </SvgIcon>
);

export default DatePicker;
