import * as yup from 'yup';
import routes from 'utils/routes';

export const STEPPER_LABELS = [
  'Your details',
  'Your plan',
  'Payment',
  'Complete',
];

export const OPTIONS = {
  onlyBusiness: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
  tellUsMore: [
    {
      value: 'Broker',
      label: 'Broker',
    },
    {
      value: 'Email marketing',
      label: 'Email marketing',
    },
    {
      value: 'Events',
      label: 'Events',
    },
    {
      value: 'I walked past',
      label: 'I walked past',
    },
    {
      value: 'Online article',
      label: 'Online article',
    },
    {
      value: 'Podcast',
      label: 'Podcast',
    },
    {
      value: 'Search engine',
      label: 'Search engine',
    },
    {
      value: 'Social media',
      label: 'Social media',
    },
    {
      value: 'The Copy Club',
      label: 'The Copy Club',
    },
    {
      value: 'Word of mouth',
      label: 'Word of mouth',
    },
    {
      value: 'Other',
      label: 'Other',
    },
    {
      value: 'Referral',
      label: 'Referral',
    },
  ],
  inductionSpaceId: [
    {
      value: 1405478076,
      label: 'Work.Life Bermondsey',
    },
    {
      value: 1415144936,
      label: 'Work.Life Borough',
    },
    {
      value: 1405438035,
      label: 'Work.Life Camden',
    },
    {
      value: 1414893749,
      label: 'Work.Life Farringdon',
    },
    {
      value: 1405478077,
      label: 'Work.Life Fitzrovia',
    },
    {
      value: 1382140760,
      label: 'Work.Life Hammersmith',
    },
    {
      value: 1382135756,
      label: 'Work.Life Holborn',
    },
    {
      value: 1414901027,
      label: 'Work.Life Liverpool Street',
    },
    {
      value: 1405438036,
      label: 'Work.Life Manchester',
    },
    {
      value: 1414898801,
      label: 'Work.Life Old Street',
    },
    {
      value: 1405478074,
      label: 'Work.Life Reading',
    },
    {
      value: 1405478075,
      label: 'Work.Life Soho',
    },
  ],
  inductionTime: [
    {
      value: '9:00am',
      label: '9:00am',
    },
    {
      value: '3:00pm',
      label: '3:00pm',
    },
  ],
};

export const STEPS = [
  {
    route: routes.auth.signUp.index(),
    queryKey: 'sign-up',
    buttonLabel: 'Next step',
  },
  {
    route: routes.auth.signUp.plan(),
    queryKey: 'sign-up-plan',
    buttonLabel: 'Proceed to payment',
  },
  {
    route: '',
    queryKey: 'sign-up-payment',
    buttonLabel: '',
  },
  {
    route: routes.auth.signUp.summary,
    queryKey: 'sign-up-summary',
    buttonLabel: '',
  },
];

export const PASSWORD_CHANGE_SCHEMA = {
  newPassword: yup
    .string()
    .required('This field is required')
    .matches(
      /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must have at least one uppercase letter (A-Z), one lowercase letter (a-z), a number (0-9) and a symbol (!@#$%^&*).',
    ),
  repeatNewPassword: yup.string().when('newPassword', {
    is: (val: string) => !!(val && val.length > 0),
    then: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('newPassword'), null], 'Your passwords do not match.'),
  }),
};
