import * as yup from 'yup';
import map from 'lodash-es/map';
import moment from 'moment';

import FirstStepForm from 'components/TourBooking/FirstStep/Form';
import SecondStepForm from 'components/TourBooking/SecondStep/Form';
import FirstStepFooter from 'components/TourBooking/FirstStep/Footer';
import SecondStepFooter from 'components/TourBooking/SecondStep/Footer';

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  desks: '',
  location: '',
  moveDate: moment().startOf('day'),
  interestedIn: 'Private Office',
  month: moment().startOf('month').toString(),
  day: moment().startOf('day').toString(),
  slot: null,
  dealId: null,
};

const STEPS = [
  {
    label: 'About you',
    schema: yup
      .object()
      .shape({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        phone: yup
          .string()
          .matches(/(?:\D*\d){6,}/, 'Phone number is not valid')
          .required('Phone number is required'),
        email: yup
          .string()
          .email('Invalid e-mail')
          .required('Email is required'),
        desks: yup.string().nullable().required('Number of desks is required'),
        location: yup.string().required('Location is required'),
        moveDate: yup
          .string()
          .nullable()
          .test('is-valid-date', 'Invalid date', (value) =>
            moment(value).isSameOrAfter(moment(), 'day'),
          )
          .required('Date is required'),
        dealId: yup.string().nullable().required('Deal ID is required'),
      })
      .required(),
    form: FirstStepForm,
    footer: FirstStepFooter,
  },
  {
    label: 'Date & time',
    schema: yup
      .object()
      .shape({
        month: yup.string().nullable().required(),
        day: yup.string().nullable().required(),
        slot: yup.string().nullable().required(),
      })
      .required(),
    form: SecondStepForm,
    footer: SecondStepFooter,
  },
];

const [first, ...rest] = map(STEPS, 'schema');
const BODY_SCHEMA = rest.reduce(
  (schemas, schema) => schemas.concat(schema),
  first,
);

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { DEFAULT_VALUES, STEPS, FormData };
