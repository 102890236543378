import { Stack, Typography } from '@mui/material';
import AddToCalendarButton from 'components/TourBooking/Summary/AddToCalendarButton';
import HintBox from 'components/TourBooking/HintBox';

function BookingSummary(bookingDetails: BookingDetails) {
  const { title, details } = bookingDetails;
  return (
    <Stack spacing={2}>
      <Typography variant="h2">{title}</Typography>
      <HintBox
        dangerouslySetInnerHTML={{
          __html: details?.content,
        }}
        width={{ xs: '100%', lg: '760px' }}
      />
      <AddToCalendarButton {...bookingDetails} />
    </Stack>
  );
}

export default BookingSummary;
