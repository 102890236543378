import { Typography } from '@mui/material';
import { useQuery } from 'react-query';
import moment from 'moment';

import { API, APIRoutes } from 'utils/api';
import { ALL_LOCATIONS_FILTER } from 'constants/locations';
import LocationDropdown from 'components/Common/LocationDropdown';
import LoadingSpinner from 'components/Common/LoadingSpinner';

const fetchSpaces = async () => {
  const {
    data: { data: spaces },
  } = await API.get(APIRoutes.spaces);
  spaces.unshift(ALL_LOCATIONS_FILTER);
  return spaces;
};

const HeaderSection = ({
  startDate,
  loc,
  changeLoc,
}: {
  startDate: string;
  loc: number | undefined;
  changeLoc: (value: number) => void;
}) => {
  const from = moment(startDate);

  const { isLoading: spacesLoading, data: spaces } = useQuery(
    'spaces',
    fetchSpaces,
  );
  return (
    <>
      {spacesLoading ? (
        <LoadingSpinner />
      ) : (
        <LocationDropdown
          items={spaces.filter((space: { id: any }) => space.id)}
          currentId={loc as number}
          onSelect={changeLoc}
        />
      )}
      <Typography fontWeight="700" mt="24px">
        {from.format('dddd D MMMM YYYY')}
      </Typography>
    </>
  );
};

export default HeaderSection;
