import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import ButtonContainer from 'components/ButtonContainer';
import ArrowIcon from 'assets/icons/ArrowIcon';
import TextInput from 'components/Form/TextInput';
import routes from 'utils/routes';

type FormData = {
  email: string;
};

const schema = yup
  .object({
    email: yup
      .string()
      .required('This field is required')
      .email('Incorrect e-mail'),
  })
  .required();

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { snack, setSnack } = useToast();
  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { resetPassword } = useAuth();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await resetPassword(data);
      setSnack({
        ...snack,
        message:
          "You've requested a password reset link. If your user record exists, you'll receive an email with instructions shortly",
        open: true,
        type: 'success',
      });
    } catch (e) {
      setSnack({
        ...snack,
        message: 'Something went wrong. Please try again later',
        open: true,
        type: 'error',
      });
    }
    navigate(routes.auth.signIn, {
      state: { isRetrying: true },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput label="Email" name="email" type="text" />

        <ButtonContainer justifyContent="center">
          <Button
            sx={{ width: '200px', mt: 2 }}
            endIcon={
              <ArrowIcon
                sx={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
            }
            variant="contained"
            type="submit"
            disabled={!isDirty}
          >
            Continue
          </Button>
        </ButtonContainer>
      </form>
    </FormProvider>
  );
}
