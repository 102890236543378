import { Dispatch, SetStateAction } from 'react';
import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useInfiniteQuery } from 'react-query';

import DataTable from 'components/Common/DataTable';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import Loader from 'components/Loader';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import CustomChip from 'components/Common/CustomChip';

const LABELS = ['Reference', 'Location', 'Status'];

const DeliveriesTable = ({
  setHasDeliveries,
  collectedDeliveries,
}: {
  setHasDeliveries: Dispatch<SetStateAction<boolean>>;
  collectedDeliveries?: boolean;
}) => {
  const queryKey = collectedDeliveries ? 'collected-deliveries' : 'deliveries';
  const theme = useTheme();

  const getDeliveries = async ({ pageParam = 1 }) => {
    const { data } = await API.get(
      collectedDeliveries
        ? APIRoutes.deliveries.collected(LIMITS.DELIVERIES, pageParam)
        : APIRoutes.deliveries.awaiting(LIMITS.DELIVERIES, pageParam),
    );
    if (data?.total === 0) {
      setHasDeliveries(false);
    }
    return data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess,
    isFetchingNextPage,
  } = useInfiniteQuery([queryKey], getDeliveries, {
    getNextPageParam: (lastPage, pages) =>
      pages.length < lastPage?.totalPages ? pages.length + 1 : undefined,
  });

  if (isFetching && !isFetchingNextPage) {
    return <Loader />;
  }

  if (isSuccess) {
    const deliveries = data.pages.flatMap((page) => page?.data);

    return (
      <Box>
        <Typography variant="h2" marginBottom="16px">
          {collectedDeliveries ? 'Collected deliveries' : 'Deliveries'}
        </Typography>
        <DataTable labels={collectedDeliveries ? [] : LABELS}>
          {deliveries.map((delivery: DeliveryProps) => {
            const today = moment();
            const date = moment(delivery.receivedAt);

            return (
              <TableRow
                key={delivery.id}
                sx={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                }}
              >
                <TableCell>{delivery.name}</TableCell>
                <TableCell>{delivery.location}</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="8px"
                  >
                    {date.isSame(today, 'day') && (
                      <Box position="absolute" top="-10px">
                        <CustomChip
                          label="Today!"
                          variant="filled"
                          color="secondary"
                          sx={{
                            marginLeft: '0px',
                          }}
                        />
                      </Box>
                    )}
                    <Box>
                      <Typography
                        sx={{
                          lineHeight: '18px',
                        }}
                      >
                        {delivery.collected ? 'Arrived' : 'Arriving'} on{' '}
                        {date.format('DD/MM/YY')}
                      </Typography>
                      <Typography
                        sx={{
                          lineHeight: '18px',
                        }}
                      >
                        {delivery.comment}
                      </Typography>
                    </Box>

                    {delivery.collected && (
                      <ToastSuccessIcon
                        fill={theme.palette.secondary.main}
                        sx={{
                          background: theme.palette.secondary.main,
                          padding: '2px',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </DataTable>
        {isFetchingNextPage ? (
          <LoadingSpinner />
        ) : (
          <LoadMoreButton
            hasNextPage={hasNextPage}
            onClick={fetchNextPage}
            marginTop="32px"
            hideScrollToTopButton
          />
        )}
      </Box>
    );
  }
  return null;
};

DeliveriesTable.defaultProps = {
  collectedDeliveries: false,
};

export default DeliveriesTable;
