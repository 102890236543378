export const mapAvailability = (value = 0) => {
  let color = '#F76666';
  let message = 'Very Busy';
  if (value <= 0.3) {
    color = '#68CA3D';
    message = 'Plenty of space';
  }
  if (value > 0.3 && value <= 0.45) {
    color = '#FEDA8F';
    message = 'Some space';
  }
  if (value > 0.45 && value <= 0.6) {
    color = '#FABE79';
    message = 'Busy';
  }
  return { color, message };
};
