import GlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material/styles';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={`
        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin: 0;
          padding: 0;
        }
        html {
          scroll-padding-top: 56px;
        }
        body {
          scroll-behavior: smooth;
          background: ${theme.palette.background.main};
          color: ${theme.palette.text.main};
          font-family: ${theme.typography.fontFamily};
          font-weight: ${theme.typography.fontWeightRegular};
          font-size: ${theme.typography.fontSize}px;
        }
        a {
          cursor: pointer;
          color:  ${theme.palette.text.main};
          font-weight: ${theme.typography.fontWeightBold};
          &:hover {
            text-decoration: none;
            color: ${theme.palette.orange[800]};
            fontWeight: ${theme.typography.fontWeightBold},
          }
        }
        .weatherwidget-io {
          display: flex!important;
          width: 227px!important;
          min-height: 75px!important;
          max-height: 79px!important;
          margin-top: -23px!important;
          margin-right: -30px!important;
          scale: 0.75!important;
        }
        ul {
          list-style-position: inside;
        }
      `}
    />
  );
};

export default GlobalStyle;
