import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink, {
  shouldForwardProp: (prop: string) =>
    !['noUnderline', 'color', 'fontWeight', 'fontSize'].includes(
      prop as string,
    ),
})<{
  color?: string;
  fontWeight?: string | number;
  noUnderline?: boolean;
  fontSize?: string;
}>(({ color, fontWeight, noUnderline, fontSize, theme }) => ({
  color: color || theme.palette.text.main,
  fontWeight: fontWeight || theme.typography.fontWeightRegular,
  ...(noUnderline && {
    textDecoration: 'none',
  }),
  fontSize: `${fontSize || theme.typography.fontSize}px`,
  '&:hover': {
    color: theme.palette.orange[800],
    fontWeight: theme.typography.fontWeightBold,
  },
}));
