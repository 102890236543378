/* eslint-disable no-nested-ternary */
import { Stack } from '@mui/material';
import moment from 'moment';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';
import { getDaysArray } from 'components/Rooms/helpers';
import BookingOptions from 'components/Rooms/BookingsOptions';
import TitleWithIcon from 'components/TourBooking/TitleWithIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import ClockIcon from 'assets/icons/ClockIcon';
import { API, APIRoutes } from 'utils/api';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import HintBox from 'components/TourBooking/HintBox';

const AVAILABLE_MONTHS = Array.from({ length: 3 }, (_, i) => {
  const date = moment().startOf('month').add(i, 'month');
  return {
    label: date.format('MMMM'),
    value: date.toString(),
  };
});

const getAvailability = async (spaceId: number, date: string | null) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.tourBoking.availability(spaceId, date));
  return data;
};

function Form() {
  const { control, getValues, setValue } = useFormContext();
  const spaceId = getValues('location');
  const [selectedMonth, selectedDay, selectedSlot] = useWatch({
    control,
    name: ['month', 'day', 'slot'],
  });

  useEffect(() => {
    if (!moment(selectedMonth).isSame(selectedDay, 'month')) {
      setValue('day', null, {
        shouldValidate: true,
      });
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (!moment(selectedMonth).isSame(selectedSlot, 'date')) {
      setValue('slot', null, {
        shouldValidate: true,
      });
    }
  }, [selectedDay]);

  const isSelectedToday = moment().isSame(selectedDay, 'date');

  const { isFetching, data: availability = [] } = useQuery<
    BookingAvailability[]
  >(
    ['bookingAvailability', spaceId, selectedDay],
    () => {
      const date = selectedDay
        ? moment(selectedDay).format('YYYY-MM-DD')
        : null;

      return getAvailability(spaceId, date);
    },
    {
      enabled: !!spaceId && !!selectedDay && !isSelectedToday,
    },
  );

  const availabilityOptions = useMemo(
    () =>
      availability?.map((slot) => ({
        label: moment(slot.start).format('h:mm a'),
        value: slot.start,
      })),
    [availability],
  );

  return (
    <Stack spacing={6} maxWidth="760px">
      <Stack spacing={2}>
        <TitleWithIcon title="Pick a date" icon={CalendarIcon} />
        <BookingOptions options={AVAILABLE_MONTHS} category="month" />
        <BookingOptions options={getDaysArray(selectedMonth)} category="day" />
      </Stack>
      <Stack spacing={2}>
        <TitleWithIcon title="What time?" icon={ClockIcon} />
        {selectedDay &&
          (isFetching ? (
            <LoadingSpinner />
          ) : isSelectedToday ? (
            <HintBox>
              Looking to book a tour today? Please call the team directly on 020
              8124 3416 to confirm.
            </HintBox>
          ) : isEmpty(availability) ? (
            <HintBox>
              Sorry! We&apos;re not available then, please select another date.
            </HintBox>
          ) : (
            <BookingOptions options={availabilityOptions} category="slot" />
          ))}
      </Stack>
    </Stack>
  );
}

export default Form;
