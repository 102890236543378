/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="20px"
    height="14px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 5H4m15-4H1m18 8H1m15 4H4"
      stroke="#FABE79"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SvgComponent;
