import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M1 8H15M15 8L8 1M15 8L8 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ArrowIcon;
