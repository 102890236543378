import { Box, Typography } from '@mui/material';

export type PriceProps = {
  price?: number;
  vat?: boolean;
  perHour?: boolean;
};

const Price = ({ price, vat, perHour }: PriceProps) => (
  <Typography
    sx={{ fontSize: '33px', lineHeight: '48px', fontWeight: 'fontWeightBold' }}
  >
    £{price?.toFixed(2)}
    {vat && (
      <Box
        component="span"
        sx={{
          fontSize: '16px',
          fontWeight: 'fontWeightRegular',
        }}
      >
        {' '}
        + VAT
      </Box>
    )}
    {perHour && (
      <Box
        component="span"
        sx={{
          fontSize: '16px',
          fontWeight: 'fontWeightRegular',
        }}
      >
        {' '}
        per hour
      </Box>
    )}
  </Typography>
);

Price.defaultProps = {
  price: 0,
  vat: false,
  perHour: false,
};

export default Price;
