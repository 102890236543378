import routes from 'utils/routes';

export const TABS_HELP = [
  {
    value: routes.main.events,
    label: 'Upcoming',
  },
  {
    value: routes.main.eventsBooked,
    label: 'Booked',
  },
];
