export const OPTIONS = {
  scales: {
    x: {
      grid: {
        display: false,
        borderColor: '#1D1954',
      },
      ticks: {
        color: '#1D1954',
        font: {
          family: '"Antebas", "Roboto", sans-serif',
          size: 13,
          weight: '700',
        },
      },
    },
    y: {
      grid: {
        color: 'rgba(217, 223, 239, 0.3)',
        drawBorder: false,
        tickColor: 'white',
      },
      ticks: {
        display: true,
        stepSize: 1,
        color: '#1D1954',
        font: {
          family: '"Antebas", "Roboto", sans-serif',
          size: 13,
          weight: '700',
        },
      },
    },
  },
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
      align: 'end' as const,
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        useBorderRadius: true,
        borderRadius: 4,
        font: {
          size: 16,
        },
      },
    },
    tooltip: {
      backgroundColor: '#fff',
      borderColor: '#FABE79',
      borderWidth: 1,
      padding: 20,
      titleColor: '#1D1954',
      titleFont: { weight: '400' },
      bodyColor: '#1D1954',
      bodyFont: { weight: 'bold' },
      displayColors: false,
    },
  },
};
