import { styled } from '@mui/material/styles';
import { Box, Chip, IconButton } from '@mui/material';

export const NavigationBox = styled(Box)`
  width: 104px;
  z-index: 100;
  position: absolute;
  height: 100%;
  padding-top: 8px;
`;

export const NavigationButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.primary.main}`,
  width: '32px',
  height: '32px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  '.MuiSvgIcon-root': {
    color: 'transparent',
  },
}));

export const StyledChip = styled(Chip)`
  width: 100%;
  min-width: 48px;
  height: 48px;
  color: #000000;
  border-radius: 100px;
  font-size: 20px;
  & .muichip-label {
    padding: '0px';
  }
`;

export const IconBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
