/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const ChosenPlanIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="54"
    height="47"
    viewBox="0 0 54 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0012 11.4046C27.9597 11.4046 28.7367 10.6334 28.7367 9.68221C28.7367 8.73079 27.9597 7.95979 27.0012 7.95979C26.0428 7.95979 25.2657 8.73079 25.2657 9.68221C25.2657 10.6334 26.0428 11.4046 27.0012 11.4046ZM27.5171 21.5706C27.2106 21.7956 26.7919 21.7956 26.4854 21.5706C26.4222 21.5243 26.3112 21.4398 26.1597 21.319C25.9117 21.1213 25.6364 20.8916 25.3407 20.6312C24.498 19.8893 23.6557 19.049 22.8677 18.1246C20.5781 15.4383 19.1914 12.5926 19.1914 9.68231C19.1914 5.40148 22.688 1.93115 27.0015 1.93115C31.315 1.93115 34.8115 5.40134 34.8115 9.68231C34.8115 12.5925 33.4248 15.4384 31.1353 18.1246C30.3471 19.049 29.5049 19.8892 28.6622 20.6312C28.3665 20.8915 28.0912 21.1213 27.8432 21.319C27.6917 21.4398 27.5807 21.5243 27.5175 21.5706H27.5171Z"
      fill="#FABE79"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0012 11.4046C27.9597 11.4046 28.7367 10.6334 28.7367 9.68221C28.7367 8.73079 27.9597 7.95979 27.0012 7.95979C26.0428 7.95979 25.2657 8.73079 25.2657 9.68221C25.2657 10.6334 26.0428 11.4046 27.0012 11.4046ZM27.5171 21.5706C27.2106 21.7956 26.7919 21.7956 26.4854 21.5706C26.4222 21.5243 26.3112 21.4398 26.1597 21.319C25.9117 21.1213 25.6364 20.8916 25.3407 20.6312C24.498 19.8893 23.6557 19.049 22.8677 18.1246C20.5781 15.4383 19.1914 12.5926 19.1914 9.68231C19.1914 5.40148 22.688 1.93115 27.0015 1.93115C31.315 1.93115 34.8115 5.40134 34.8115 9.68231C34.8115 12.5925 33.4248 15.4384 31.1353 18.1246C30.3471 19.049 29.5049 19.8892 28.6622 20.6312C28.3665 20.8915 28.0912 21.1213 27.8432 21.319C27.6917 21.4398 27.5807 21.5243 27.5175 21.5706H27.5171Z"
      stroke="#1D1954"
      strokeWidth="1.89154"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3181 22.5996C38.1488 22.5996 35.0773 23.8384 32.1922 26.2808C31.9515 26.4843 31.802 26.7746 31.777 27.0875C31.7518 27.4006 31.8531 27.7105 32.0581 27.9495C32.2634 28.1884 32.5558 28.3366 32.8712 28.3616C33.1864 28.3866 33.4989 28.2861 33.7396 28.0824C36.1819 26.0162 38.7314 24.9682 41.3182 24.9682C43.8498 24.9682 46.2775 25.9662 48.0677 27.7429C49.858 29.5195 50.8635 31.9291 50.8635 34.4416C50.8635 36.954 49.8579 39.3635 48.0677 41.1403C46.2776 42.917 43.8497 43.9149 41.3182 43.9149C35.3661 43.9149 31.7717 38.9769 27.9674 33.7475C23.9789 28.2671 19.8553 22.5996 12.6819 22.5996C9.51737 22.5996 6.48264 23.8472 4.24472 26.068C2.00713 28.2887 0.75 31.3006 0.75 34.4416C0.75 37.5825 2.00713 40.5942 4.24472 42.8152C6.48231 45.0359 9.51713 46.2837 12.6819 46.2837C15.8513 46.2837 18.9227 45.045 21.8078 42.6026C22.1322 42.3284 22.2843 41.9032 22.2071 41.4873C22.1298 41.0713 21.8348 40.7279 21.4333 40.5863C21.0319 40.4448 20.5846 40.5266 20.2604 40.801C17.8181 42.8675 15.2686 43.9152 12.6818 43.9152C10.1502 43.9152 7.7225 42.9171 5.93226 41.1405C4.14201 39.3638 3.1365 36.9542 3.1365 34.4418C3.1365 31.9294 4.14211 29.5198 5.93226 27.7431C7.7224 25.9663 10.1503 24.9684 12.6818 24.9684C18.6339 24.9684 22.2282 29.9065 26.0325 35.1358C30.0211 40.6162 34.1447 46.2837 41.3181 46.2837C44.4826 46.2837 47.5173 45.0361 49.7553 42.8154C51.9929 40.5946 53.25 37.5827 53.25 34.4417C53.25 31.3008 51.9929 28.2891 49.7553 26.0681C47.5177 23.8474 44.4829 22.5996 41.3181 22.5996Z"
      fill="#1D1954"
      stroke="#1D1954"
      strokeWidth="0.820312"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9984 13.1276C25.0813 13.1276 23.5273 11.5852 23.5273 9.68268C23.5273 7.78003 25.0813 6.23779 26.9984 6.23779C28.9155 6.23779 30.4694 7.78003 30.4694 9.68268C30.4694 11.5851 28.9155 13.1276 26.9984 13.1276Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9984 13.1276C25.0813 13.1276 23.5273 11.5852 23.5273 9.68268C23.5273 7.78003 25.0813 6.23779 26.9984 6.23779C28.9155 6.23779 30.4694 7.78003 30.4694 9.68268C30.4694 11.5851 28.9155 13.1276 26.9984 13.1276Z"
      stroke="#1D1954"
      strokeWidth="1.89154"
      fill="none"
    />
  </SvgIcon>
);

export default ChosenPlanIcon;
