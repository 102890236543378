import { Button, ButtonProps, CircularProgress } from '@mui/material';

type LoadingButtonProps = ButtonProps & {
  isLoading: boolean;
};

const LoadingButton = ({
  isLoading,
  disabled,
  children,
  endIcon,
  color,
  ...rest
}: LoadingButtonProps) => (
  <Button
    disabled={isLoading || disabled}
    endIcon={isLoading ? null : endIcon}
    color={color}
    {...rest}
  >
    {isLoading ? (
      <CircularProgress
        size={20}
        sx={{
          color: color === 'error' ? '#facccc' : '#FFFFFF',
          margin: '0 auto',
        }}
      />
    ) : (
      children
    )}
  </Button>
);

export default LoadingButton;
