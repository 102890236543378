// eslint-disable-next-line no-underscore-dangle
const _PLAN: { [key: string]: any } = {
  production: {
    'e9a97cf8-d6e0-4ca5-ad55-ebb61cb1ebc3': {
      name: 'Flex',
      additionalInfo: '£20 monthly minimum spend <br/> £25 one off set-up fee',
      description: 'Pay-as-you-go coworking (from £4 per hour)',
      spaceId: 1405478076,
    },
    '6eddd36f-8858-4c84-8d60-ca74386a9a6a': {
      name: 'Unlimited',
      description:
        'Unlimited hotdesking (from £350 per month - taken from first card pickup)',
      spaceId: 1405478076,
    },
    '5904d586-db4a-484b-a236-0f4841678172': {
      name: 'Unlimited_Manchester',
      description:
        'Unlimited hotdesking (from £200 per month - taken from first card pickup)',
      spaceId: 1405438036,
    },
    '175873f4-732d-4897-9b95-faacf6c72b8d': {
      name: 'Unlimited_Reading',
      description:
        'Unlimited hotdesking (from £250 per month - taken from first card pickup)',
      spaceId: 1405478074,
    },
  },
  default: {
    'fcc9e804-d6ff-453f-bf9e-72b6c3527ed6': {
      name: 'Flex',
      additionalInfo: '£20 monthly minimum spend <br/> £25 one off set-up fee',
      description: 'Pay-as-you-go coworking (from £4 per hour)',
      spaceId: 1414870520,
    },
    'cbb78887-6645-4ec2-98ea-e2db2034f176': {
      name: 'Unlimited',
      description:
        'Unlimited hotdesking (from £350 per month - taken from first card pickup)',
      spaceId: 1414870520,
    },
  },
};

export const PLAN: { [key: string]: any } =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  _PLAN[process.env.REACT_APP_ENV!] || _PLAN.default;
