/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

type NotificationDotIcon = {
  buttonViewBasket?: boolean;
} & SvgIconProps;

const NotificationDotIcon = ({
  buttonViewBasket,
  ...props
}: NotificationDotIcon) => (
  <SvgIcon {...props} viewBox="0 0 8 8">
    {buttonViewBasket ? (
      <circle cx="4" cy="4" r="3.5" fill="#F76666" stroke="#FABE79" />
    ) : (
      <circle cx="4" cy="4" r="3.5" fill="#F76666" stroke="#4538CE" />
    )}
  </SvgIcon>
);

NotificationDotIcon.defaultProps = {
  buttonViewBasket: false,
};
export default NotificationDotIcon;
