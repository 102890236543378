import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import PublicLayout from 'components/PublicLayout/PublicLayout';
import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import {
  DEFAULT_VALUES,
  STEPS,
  FormData,
} from 'components/TourBooking/constants';
import { FormBox, FormBanner } from 'components/PublicLayout/style';
import { Title } from 'components/Title';
import BookingSummary from 'components/TourBooking/Summary';

const TourBooking = () => {
  const [searchParams] = useSearchParams();
  const { snack, setSnack } = useToast();

  const [step, setStep] = useState(0);
  const currentStep = STEPS[step];
  const goToNextStep = () => setStep((prevState) => prevState + 1);
  const Form = currentStep?.form;
  const Footer = currentStep?.footer;

  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(
    null,
  );
  const showSummary = step === STEPS.length && bookingDetails;

  const defaultValues = useMemo(() => {
    const { moveDate, ...params } = Object.fromEntries(
      new URLSearchParams(searchParams),
    );
    return {
      ...params,
      moveDate: moveDate ? new Date(moveDate) : undefined,
    };
  }, [searchParams]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(currentStep?.schema),
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
  });

  const { mutate: bookTour } = useMutation({
    mutationKey: 'tourBooking',
    mutationFn: (data: FormData) => {
      const { location, ...payload } = data;
      return API.post(APIRoutes.tourBoking.book(location), payload);
    },
    onSuccess: (response) => {
      goToNextStep();
      setBookingDetails(response?.data?.data);
    },
    onError: () => {
      setSnack({
        ...snack,
        message: 'Unable to book a tour',
        open: true,
        type: 'error',
      });
    },
  });

  const {
    handleSubmit,
    trigger,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit(async (data: FormData) => bookTour(data));

  const submitStep = useCallback(() => {
    if (step === STEPS.length - 1) {
      onSubmit();
    } else {
      goToNextStep();
    }
  }, [step, goToNextStep, onSubmit]);

  useEffect(() => {
    if (isDirty) {
      trigger();
    }
  }, [step]);

  return (
    <PublicLayout>
      <FormProvider {...methods}>
        <FormBox>
          <Stack spacing={2} mb={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Title variant="h1">Book a tour</Title>
              <Stepper activeStep={step} sx={{ width: '350px' }}>
                {STEPS.map(({ label }, index) => (
                  <Step
                    key={label}
                    onClick={() => {
                      if (index < step && step !== STEPS.length) {
                        setStep(index);
                      }
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
            <Divider />
          </Stack>
          {showSummary ? <BookingSummary {...bookingDetails} /> : <Form />}
        </FormBox>
        {!showSummary && (
          <FormBanner>
            <Footer submitStep={submitStep} />
          </FormBanner>
        )}
      </FormProvider>
    </PublicLayout>
  );
};

export default TourBooking;
