import React from 'react';
import { Icon, Stack, SvgIconProps, Typography } from '@mui/material';
import theme from 'styles/theme';

export type TitleWithIconProps = {
  title: string;
  icon: (props: SvgIconProps) => JSX.Element;
};

const TitleWithIcon = ({ title, icon }: TitleWithIconProps) => (
  <Stack direction="row" alignItems="center">
    <Icon
      component={icon}
      sx={{
        color: theme.palette.secondary.main,
        fill: 'none',
        width: '24px',
        height: '24px',
        marginRight: '8px',
      }}
    />
    <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
      {title}
    </Typography>
  </Stack>
);

export default TitleWithIcon;
