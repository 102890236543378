import { Button, Box, useTheme, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import CustomTabs from 'components/Common/Tabs';
import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import Visitors from 'pages/VisitorsAndDeliveries/Visitors';
import Deliveries from 'pages/VisitorsAndDeliveries/Deliveries';
import { TABS_HELP } from 'constants/tabs/tabsVisitorsDelivery';

import PlusIcon from 'assets/icons/PlusWithCircleIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import AddVisitorModal from 'components/Visitors/AddVisitorModal';

import { useAuth } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';

const breadcrumbsConfig = () => [{ label: 'Visitors & Deliveries' }];

type VisitorsAndDeliveriesProps = {
  tab: string;
};

const VisitorsAndDeliveries = ({ tab }: VisitorsAndDeliveriesProps) => {
  const theme = useTheme();

  const { user } = useAuth();

  const { openModal } = useAppContext();

  return (
    <>
      <SectionHeader
        title="Visitors & Deliveries"
        hideBackButton
        path={breadcrumbsConfig()}
        tabs={<CustomTabs tabsConfig={TABS_HELP} />}
        sx={{
          '& > div': {
            alignSelf: 'flex-start',
          },
        }}
        elements={
          tab === 'visitors' ? (
            <Button
              variant="contained"
              endIcon={<PlusIcon />}
              onClick={() => {
                openModal(<AddVisitorModal />);
              }}
              sx={{
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.main,
                width: '200px',
                height: '48px',
              }}
            >
              Add new visitor
            </Button>
          ) : (
            <Box
              padding="16px"
              sx={{ backgroundColor: theme.palette.secondary.main }}
              borderRadius="8px"
              maxWidth="300px"
            >
              <Box display="flex" alignItems="center" gap="8px">
                <InfoIcon />
                <Typography variant="h6" fontWeight="bold">
                  Heads up!
                </Typography>
              </Box>

              <Box
                fontSize="13px"
                mt="8px"
                sx={{ '& span': { fontSize: '13px', fontWeight: 'bold' } }}
              >
                To make sure we can match your deliveries correctly, make sure
                they are addressed to&nbsp;
                {!isEmpty(user?.company) && (
                  <>
                    <Typography
                      component="span"
                      sx={{
                        overflowWrap: 'break-word',
                      }}
                    >
                      &quot;{user?.company?.toUpperCase()}&quot;
                    </Typography>
                    &nbsp; or &nbsp;
                  </>
                )}
                <Typography
                  component="span"
                  sx={{
                    overflowWrap: 'break-word',
                  }}
                >
                  &quot;{user?.firstName} {user?.lastName}&quot;
                </Typography>
                .
              </Box>
            </Box>
          )
        }
      />
      <Section>{tab === 'visitors' ? <Visitors /> : <Deliveries />}</Section>
    </>
  );
};

export default VisitorsAndDeliveries;
