import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxCheckedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
    <path
      d="M24 10.75L13 21.2917L8 16.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="7.5"
      fill="none"
      stroke="currentColor"
    />
  </SvgIcon>
);

export default CheckboxCheckedIcon;
