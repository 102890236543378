/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { isEmpty, noop } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useToast } from 'context/ToastContext';
import { STEPS } from 'constants/signUp';

import {
  API,
  APIRoutes,
  setAuthenticationToken,
  destroySession,
} from 'utils/api';
import { setItem, getItem, removeItem, KEYS } from 'utils/cache';
import routes from 'utils/routes';
import { ALL_LOCATIONS_FILTER } from 'constants/locations';
import { ACCOUNT_TYPES } from 'constants/account';

const MULTI_GATEWAY_ID = 1;

const fetchUser = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.user.profile);
  return {
    ...data,
    allowCreditCardPayment: data?.regularPaymentProvider === MULTI_GATEWAY_ID,
  };
};

const fetchSpaces = async () => {
  const {
    data: { data: spaces },
  } = await API.get(APIRoutes.spaces);
  spaces.unshift(ALL_LOCATIONS_FILTER);
  return spaces;
};

const fetchBasket = async () => {
  const {
    data: { data },
  } = await API.get(APIRoutes.basket.index);
  return data?.basket;
};

const AuthContextDefaultState: AuthContextType = {
  setUser: () => null,
  signIn: async () => {},
  signInIsLoading: false,
  restoreUser: async () => {},
  restoreProfile: async () => {},
  user: null,
  setUserToken: noop,
  logout: noop,
  resetPassword: async () => {},
  doResetPassword: async () => {},
  activate: async () => {},
  isAuthenticated: false,
  isImpersonation: false,
  isInitialized: false,
  spaces: [],
  currentSpaceId: 0,
  setUserSpaceId: noop,
  isBasketEmpty: true,
  setIsBasketEmpty: noop,
  userType: ACCOUNT_TYPES.FLEX,
  isValidSpaceId: () => false,
  isFromLondon: false,
  setIsFromLondon: noop,
  fetchUser: noop,
};
type Props = {
  children: ReactNode;
};

const AuthContext = createContext(AuthContextDefaultState);

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [signInIsLoading, setSignInIsLoading] = useState(false);
  const [spaces, setSpaces] = useState([]);
  const [isBasketEmpty, setIsBasketEmpty] = useState(true);
  const [isFromLondon, setIsFromLondon] = useState(false);
  const [currentSpaceId, setCurrentSpaceId] = useState(0);
  const [userType, setUserType] = useState(ACCOUNT_TYPES.EXTERNAL);

  const isAuthenticated = !!getItem(KEYS.AUTH_TOKEN);
  const isImpersonation = !!getItem(KEYS.IMPERSONATE_TOKEN);
  const { snack, setSnack } = useToast();

  const setUserToken = useCallback((token: string) => {
    setAuthenticationToken(token);
  }, []);

  const setUserSpaceId = (id: number) => {
    setCurrentSpaceId(id);
    setItem(KEYS.CURRENT_SPACE_ID, id.toString());
  };

  const logout = useCallback(
    async (onSuccess?: () => void) => {
      destroySession();
      removeItem(KEYS.AUTH_TOKEN);
      removeItem(KEYS.IMPERSONATE_TOKEN);
      removeItem(KEYS.REFRESH_TOKEN);
      removeItem(KEYS.KEEP_LOGGED_IN);
      removeItem(KEYS.FAVOURITES);
      setUser(null);
      sessionStorage.clear();
      queryClient.removeQueries();
      navigate(routes.auth.signIn, { replace: true });
      setIsInitialized(false);

      if (onSuccess) {
        onSuccess();
      }
    },
    [navigate],
  );

  const restoreProfile = async () => {
    const userData = await fetchUser();

    setUser(userData);
    const spaceId = getItem(KEYS.CURRENT_SPACE_ID) || userData.spaceId;
    setUserSpaceId(+spaceId);
    setIsFromLondon(
      (spaces as SpaceProps[]).find(
        (space: SpaceProps) => space.id === userData?.spaceId,
      )?.location === 'london',
    );
    setUserType(userData?.userType);
    if (!userData.active && !isImpersonation) {
      setSnack({
        ...snack,
        autoHideDuration: 6000,
        message: `Your account has been suspended. Please contact customer support: <a href="mailto:membership@work.life">membership@work.life</a>`,
        open: true,
        type: 'error',
      });
      navigate(routes.main.account.dashboard, { replace: true });
    }
  };

  const initializeUser = async () => {
    const availableSpaces = await fetchSpaces();
    setSpaces(availableSpaces);
    await restoreProfile();
    const basket = await fetchBasket();
    setIsBasketEmpty(isEmpty(basket));
    setIsInitialized(true);
  };

  const signIn = useCallback<SignIn>(
    async (payload, onSuccess) => {
      setSignInIsLoading(true);
      let userToken = '';
      let userRefreshToken = '';
      if (payload) {
        const {
          data: { token, refreshToken },
        } = await API.post(APIRoutes.auth.signIn, payload).finally(() =>
          setSignInIsLoading(false),
        );
        userToken = token;
        userRefreshToken = refreshToken;
      }

      if (userToken && userRefreshToken) {
        setItem(
          KEYS.KEEP_LOGGED_IN,
          payload?.keep_logged_in.toString() || 'false',
        );
        setUserToken(userToken);
        setItem(KEYS.REFRESH_TOKEN, userRefreshToken);
        await initializeUser();

        if (onSuccess) {
          await onSuccess();
        }
      }
    },
    [setUserToken],
  );

  const resetPassword = useCallback<ResetPassword>(async (payload) => {
    if (payload) {
      await API.post(APIRoutes.auth.resetPassword, payload);
    }
  }, []);

  const doResetPassword = useCallback<DoResetPassword>(async (payload) => {
    if (payload) {
      await API.put(APIRoutes.auth.doResetPassword, payload);
    }
  }, []);

  const activate = useCallback<DoResetPassword>(async (payload) => {
    if (payload) {
      await API.post(APIRoutes.auth.activation, payload);
    }
  }, []);

  const restoreUser = useCallback(async () => {
    const token = getItem(KEYS.AUTH_TOKEN) as string;
    const impersonateToken = getItem(KEYS.IMPERSONATE_TOKEN) as string;

    if (token) {
      setAuthenticationToken(token, impersonateToken);
      await initializeUser();
    }
  }, []);

  useEffect(() => {
    (async () => {
      await restoreUser();
    })();
  }, [restoreUser]);

  const isValidSpaceId = useCallback(
    (spaceId: string) =>
      spaces.some((space: { id: number }) => space.id.toString() === spaceId),
    [spaces],
  );

  const globalContextValue = useMemo(
    () => ({
      ...AuthContextDefaultState,
      signIn,
      logout,
      restoreUser,
      restoreProfile,
      resetPassword,
      activate,
      doResetPassword,
      user,
      isAuthenticated,
      isImpersonation,
      isInitialized,
      signInIsLoading,
      spaces,
      currentSpaceId,
      setUserSpaceId,
      isBasketEmpty,
      setIsBasketEmpty,
      userType,
      isValidSpaceId,
      isFromLondon,
      fetchUser,
      setUser,
    }),
    [
      user,
      currentSpaceId,
      isInitialized,
      isBasketEmpty,
      signInIsLoading,
      userType,
      isFromLondon,
      isAuthenticated,
      isImpersonation,
    ],
  );

  return (
    <AuthContext.Provider value={globalContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = () => {
  const { isAuthenticated, user, isImpersonation } = useAuth();
  const { pathname } = useLocation();

  const suspendedRoutes = [
    routes.main.account.dashboard,
    routes.main.account.profile,
    routes.main.account.team,
    routes.main.account.plans,
    routes.main.account.metrics,
    routes.main.account.invoices,
    routes.main.account.directDebit,
    routes.main.account.creditCard,
  ];

  const allowedSuspendedUserRoutes = useMemo(
    () => suspendedRoutes.includes(pathname),
    [pathname],
  );

  if (
    !isAuthenticated &&
    !STEPS.find((step) => step.route === pathname.replace(/\/$/, ''))
  ) {
    return <Navigate to={{ pathname: routes.auth.signIn }} replace />;
  }
  if (
    isAuthenticated &&
    !isImpersonation &&
    !user?.active &&
    !allowedSuspendedUserRoutes &&
    !STEPS.find((step) => step.route === pathname.replace(/\/$/, ''))
  ) {
    return (
      <Navigate to={{ pathname: routes.main.account.dashboard }} replace />
    );
  }

  return <Outlet />;
};
