import React from 'react';
import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';

import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';

type BookingDetails = {
  details: EventProps;
  bookEvent: (bookUrl: string) => void;
  isLoading: boolean;
};

const BookingDetails = ({ details, bookEvent, isLoading }: BookingDetails) => {
  const theme = useTheme();
  const { user } = useAuth();
  const from = moment(details.startDate);
  const to = moment(details.endDate);

  return (
    <>
      <Box padding="34px 31px 32px 31px" display="flex" alignItems="center">
        <CheckCircleIcon
          color="secondary"
          sx={{
            width: '24px',
            height: '24px',
            marginRight: '8px',
          }}
        />
        <Typography variant="h2">Confirm booking</Typography>
      </Box>
      <Divider />
      <Box padding="28px 32px 36px 32px">
        <Typography variant="h4" fontWeight="fontWeightBold" marginBottom="8px">
          Summary
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography width="230px" lineHeight="18px">
            {details.title}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography fontWeight="fontWeightBold" lineHeight="18px">
            £{details.price.toFixed(2)}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          marginTop="32px"
          marginBottom="8px"
        >
          Details
        </Typography>
        <Typography marginBottom="12px" lineHeight="18px">
          {details.address}
        </Typography>
        <Typography marginBottom="12px" lineHeight="18px">
          {from.format('dddd Do MMMM YYYY')}
        </Typography>
        <Typography marginBottom="44px" lineHeight="18px">
          {`${from.format('HH:mm')} - ${to.format('HH:mm')}`}
        </Typography>
        <Divider
          sx={{
            marginBottom: '24px',
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="24px"
        >
          <Typography variant="h4" fontWeight="fontWeightBold">
            Total (inc VAT):
          </Typography>
          <Typography variant="h2"> £{details.price.toFixed(2)}</Typography>
        </Box>
        <Tooltip
          title={
            user?.canMakeBookings
              ? null
              : 'You cannot make bookings. Contact with your Team Leader.'
          }
        >
          <span>
            <LoadingButton
              isLoading={isLoading}
              variant="contained"
              disabled={!user?.canPurchaseEvents}
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: '200px',
                height: '48px',
                padding: '12px 34px',
              }}
              onClick={() => bookEvent(details.bookUrl)}
            >
              Confirm booking
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

export default BookingDetails;
