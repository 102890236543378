import { IconButton, Box } from '@mui/material';
import { motion } from 'framer-motion';
import FavouriteIcon from 'assets/icons/FavoriteIcon';
import { getItem, KEYS } from 'utils/cache';

type FavouriteProps = {
  id: number;
  favourites: number[];
  setFavourites: React.Dispatch<React.SetStateAction<number[]>>;
};

const variants = {
  hide: { opacity: 0, scale: 0, display: 'none' },
  show: { opacity: 1, scale: 1, display: 'block' },
};

const MotionBox = motion(Box);

const Favourite = ({ id, favourites, setFavourites }: FavouriteProps) => {
  const isFavourite = favourites?.includes(id);

  const handleClick = () => {
    const value = getItem(KEYS.FAVOURITES);
    if (value !== null) setFavourites(JSON.parse(value));

    if (isFavourite) {
      setFavourites((prevFavourites) =>
        prevFavourites.filter((el) => el !== id),
      );
    }

    if (!isFavourite && favourites?.length < 3) {
      setFavourites((prevFavourites) => [...prevFavourites, id]);
    }
  };

  return (
    <MotionBox
      variants={variants}
      initial="hide"
      animate="show"
      exit="hide"
      transition={{ type: 'spring', stiffness: 100 }}
      key={isFavourite.toString()}
    >
      <IconButton
        onClick={() => handleClick()}
        aria-label="add to favorites"
        color="primary"
        sx={{ width: '40px', height: '40px', fontSize: '40px' }}
      >
        <FavouriteIcon isFavorite={isFavourite} />
      </IconButton>
    </MotionBox>
  );
};

export default Favourite;
