import { Modal, Box } from '@mui/material';

const CustomModal = ({
  content,
  closeModal,
}: {
  content: any;
  closeModal: any;
}) => (
  <Modal
    open
    onClose={() => closeModal()}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    closeAfterTransition
    sx={{ outline: 'none' }}
  >
    <Box>{content}</Box>
  </Modal>
);

export default CustomModal;
