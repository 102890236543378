import Lottie from 'react-lottie';
import * as animationData from 'assets/WL_Login_Animation.json';
import { VideoWrapper } from './style';

const AnimatedBackground = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <VideoWrapper>
      <Lottie options={defaultOptions} />
    </VideoWrapper>
  );
};

export default AnimatedBackground;
