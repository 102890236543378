/* eslint-disable no-nested-ternary */
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ScrollTopButton from 'components/Common/ScrollTopButton';

export type LoadMoreButtonProps = {
  hasNextPage?: boolean;
  onClick: () => void;
  marginTop?: string;
  isFetching?: boolean;
  hideScrollToTopButton?: boolean;
};

const LoadMoreButton = ({
  hasNextPage,
  onClick,
  marginTop,
  isFetching,
  hideScrollToTopButton,
}: LoadMoreButtonProps) => {
  const theme = useTheme();

  return (
    <Box textAlign="center" marginTop={marginTop}>
      {hasNextPage ? (
        isFetching ? (
          <CircularProgress />
        ) : (
          <Button
            variant="text"
            onClick={onClick}
            sx={{
              flexDirection: 'column',
            }}
          >
            <Typography variant="h1">👇</Typography>
            <Typography
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
              color={theme.palette.text.content}
            >
              Load more
            </Typography>
          </Button>
        )
      ) : (
        !hideScrollToTopButton && <ScrollTopButton />
      )}
    </Box>
  );
};
LoadMoreButton.defaultProps = {
  hasNextPage: false,
  marginTop: '50px',
  isFetching: false,
  hideScrollToTopButton: false,
};

export default LoadMoreButton;
