import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { Controller, useFormContext } from 'react-hook-form';
import {
  Button,
  Typography,
  Box,
  Grid,
  FormHelperText,
  useTheme,
} from '@mui/material';

import TrashIcon from 'assets/icons/Trash';
import UploadIcon from 'assets/icons/Upload';
import { IMAGE_PICKER_CONFIG } from 'constants/help';

const CompanyForm = () => {
  const theme = useTheme();

  const [updateError, setUpdateError] = useState<string>();

  const {
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const onDropAccepted = (acceptedFiles: File[]) => {
    setUpdateError('');
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setValue('avatar', e.target.result, {
          shouldDirty: true,
          shouldValidate: true,
        });
      };

      reader.readAsDataURL(file);
      return file;
    });
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorCode = fileRejections[0]?.errors[0]?.code;
    if (!errorCode) return;
    if (errorCode === 'file-too-large') {
      setUpdateError(IMAGE_PICKER_CONFIG.errors['file-too-large']);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    maxSize: 10485760,
    accept: {
      'image/jpg': [],
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const removeFile = () => {
    setValue('avatar', '', { shouldDirty: true, shouldValidate: true });
  };

  return (
    <Grid item xs={12} lg={6}>
      <Box position="relative">
        <Box
          height="180px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ backgroundColor: '#F1F1F1' }}
          border="1px solid #D9DFEF"
          borderRadius="8px"
          py="30px"
        >
          <Controller
            name="avatar"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={() => (
              <div {...getRootProps()} style={{ textAlign: 'center' }}>
                <input {...getInputProps()} />
                <UploadIcon style={{ fontSize: 18 }} />
                <Typography>Drag and drop file to upload</Typography>
                <Typography fontWeight={700}>or</Typography>
                <Button
                  onClick={open}
                  variant="contained"
                  sx={{
                    justifyContent: 'center',
                    backgroundColor: 'primary.main',
                    width: '144px',
                    height: '44px',
                    mt: '10px',
                  }}
                >
                  Select file
                </Button>
              </div>
            )}
          />
          {updateError && <FormHelperText error>{updateError}</FormHelperText>}
        </Box>
        {getValues('avatar') && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            border="1px solid #D9DFEF"
            borderRadius="8px"
            sx={{ background: 'white' }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="180px"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  '& .delete-hover': {
                    opacity: 1,
                    transition: 'opacity 0.3s',
                  },
                },
              }}
            >
              <img
                src={getValues('avatar')}
                alt=""
                style={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  border: 'none',
                }}
              />
              <Box
                position="absolute"
                className="delete-hover"
                sx={{
                  opacity: 0,
                  backgroundColor: 'rgba(29, 25, 84, 0.3)',
                  borderRadius: '8px',
                  transition: 'opacity 0.3s',
                }}
                top="0"
                width="100%"
                height="180px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => removeFile()}
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.main',
                  }}
                  endIcon={
                    <TrashIcon
                      sx={{
                        width: '22px',
                        height: '21px',
                        margin: '0 10px',
                      }}
                    />
                  }
                >
                  Remove
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {dirtyFields.avatar && errors.avatar && (
        <FormHelperText error>No file selected</FormHelperText>
      )}
      <Typography fontSize={theme.typography.smallFontSize} mt="8px">
        Max upload: 10mb, JPEG or PNG
      </Typography>
    </Grid>
  );
};

export default CompanyForm;
