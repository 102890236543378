import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'rgba(69, 56, 206, 0.16)',
        backdropFilter: 'blur(2px)',
        zIndex: theme.zIndex.modal,
      },
    },
  },
});
