import { Typography, Box, useTheme } from '@mui/material';
import moment from 'moment';

import PhoneCallIcon from 'assets/icons/PhoneCallIcon';
import { SUPPORT_CONTACT } from 'constants/help';
import { MessageBox } from './style';

const ContactBox = () => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" gap="11px" marginTop="11px">
      <PhoneCallIcon />
      <Typography fontWeight={theme.typography.fontWeightBold}>
        {SUPPORT_CONTACT}
      </Typography>
    </Box>
  );
};

const Message = ({ welcome, text, photo, ts, actor }: Message) => {
  const today = moment();
  const date = moment(ts);
  const olderThanWeek = today.diff(date, 'days') > 7;
  const parsedDate = date.isSame(today, 'day')
    ? date.format('HH:mm')
    : date.format(olderThanWeek ? 'DD/MM/YY, HH:mm' : 'ddd - HH:mm');

  const isExternal = actor === 'support';

  return (
    <MessageBox isExternal={isExternal}>
      <Box>
        {photo && <Box component="img" width="100%" src={photo} alt={ts} />}
        {text}
        {welcome && <ContactBox />}
      </Box>
      <Typography>{parsedDate}</Typography>
    </MessageBox>
  );
};

export default Message;
