import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import LoadingButton from 'components/Common/LoadingButton';
import routes from 'utils/routes';

import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';

const CancelBookingButton = ({ eventId }: { eventId: number }) => {
  const navigate = useNavigate();
  const { snack, setSnack } = useToast();
  const queryClient = useQueryClient();

  const { mutate: cancelEvent, isLoading } = useMutation(
    () => API.delete(APIRoutes.events.delete(eventId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events-booked');
        navigate(routes.main.events);
      },
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error ? 'Unable to cancel booking' : 'Booking canceled',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <LoadingButton
      isLoading={isLoading}
      variant="contained"
      color="secondary"
      sx={{
        width: '168px',
        height: '44px',
        justifyContent: 'center',
      }}
      onClick={() => cancelEvent()}
    >
      Cancel booking
    </LoadingButton>
  );
};

export default CancelBookingButton;
