/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const ImpersonateIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="inherit"
    height="24px"
    width="24px"
    version="1.1"
    viewBox="0 0 413.988 413.988"
    stroke="currentColor"
    strokeWidth="24.425292"
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="inherit"
      strokeWidth="1.6559519999999999"
    />
    <g id="SVGRepo_iconCarrier">
      <path d="M333.598,231.344c-4.083-34.865-14.409-95.779-38.456-124.271c-7.853-11.084-29.643-28.275-56.985-15.232 c-3.548,1.691-7.011,3.881-10.359,5.998c-7.001,4.426-13.614,8.607-20.804,8.607c-7.19,0-13.805-4.182-20.807-8.607 c-3.349-2.117-6.812-4.307-10.359-5.998c-27.341-13.045-49.133,4.15-56.984,15.232c-24.046,28.494-34.373,89.41-38.456,124.275 c-2.562,0.482-5.08,0.98-7.516,1.488C39.628,239.762,0,252.497,0,273.835c0,19.697,34.263,31.973,63.006,38.803 c38.519,9.152,89.654,14.193,143.987,14.193c54.332,0,105.468-5.041,143.987-14.193c28.744-6.83,63.008-19.105,63.008-38.803 C413.988,251.426,371.136,238.43,333.598,231.344z M92.471,216.735c20.514,6.002,64.664,9.898,114.523,9.898 c49.853,0,94.004-3.896,114.521-9.9c2.882,19.693,4.096,35.252,4.407,39.693c-1.744,1.213-9.143,4.971-36.751,8.25 c-22.575,2.682-51.76,4.158-82.178,4.158s-59.604-1.477-82.18-4.158c-27.606-3.279-35.007-7.035-36.751-8.25 C88.374,251.985,89.589,236.426,92.471,216.735z M126.613,113.372c0.097-0.113,19.274-24.912,44.907-12.506 c3.006,1.453,6.078,3.375,9.323,5.426c7.529,4.76,16.063,10.154,26.149,10.154c10.085,0,18.618-5.395,26.147-10.154 c3.245-2.051,6.316-3.975,9.321-5.426c25.118-12.137,44.807,12.385,44.909,12.506c17.3,20.354,27.166,60.574,32.593,93.402 c-18.274,5.869-63.247,9.859-112.971,9.859c-49.729,0-94.7-3.99-112.971-9.859C99.448,173.946,109.314,133.725,126.613,113.372z M348.669,302.909c-37.784,8.977-88.1,13.922-141.676,13.922c-53.577,0-103.892-4.945-141.676-13.922 C24.532,293.217,10,281.536,10,273.835c0-8.158,17-21.227,64.909-31.209c1.424-0.297,2.881-0.588,4.354-0.877 c-0.86,8.717-1.278,15.613-1.278,15.664c0,5.809,4.926,11.836,40.664,16.568c23.633,3.131,55.008,4.854,88.344,4.854 c33.336,0,64.709-1.723,88.342-4.854c35.737-4.732,40.664-10.76,40.664-16.568c0-0.051-0.418-6.945-1.277-15.662 c50.951,9.992,69.267,23.387,69.267,32.084C403.988,281.536,389.456,293.217,348.669,302.909z" />{' '}
    </g>
  </SvgIcon>
);

export default ImpersonateIcon;
