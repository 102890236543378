const palette = {
  primary: {
    main: '#4538CE',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FABE79',
    contrastText: '#1D1954',
  },
  background: {
    main: '#4538CE',
    content: '#F9F8F7',
    card: '#FFFFFF',
  },
  success: {
    main: '#68CA3D',
    light: '#D3E4CC',
  },
  warning: {
    main: '#FABE79',
  },
  error: {
    main: '#F76666',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#1D1954',
    main: '#FFFFFF',
    content: '#1D1954',
  },
  section: {
    main: '#F1F1F1',
  },
  accent: '#1D1954',
  mainBorderColor: '#1D1954',
  blue: {
    600: '#4538CE',
    700: '#392CC3',
    800: '#292376',
    900: '#1D1954',
  },
  orange: {
    500: '#FFF0D2',
    600: '#FEDA8F',
    700: '#F8C285',
    800: '#FABE79',
  },
};

export default palette;
