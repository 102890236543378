/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const CameraIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="20"
    fill="none"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 17a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2v11Z"
      stroke="#FABE79"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
    <path
      d="M12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="#FABE79"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CameraIcon;
