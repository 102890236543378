import React, { useEffect } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, FormHelperText, useTheme, Button } from '@mui/material';
import TextInput from 'components/Form/TextInput';

type FormData = {
  discountCode: string;
};

type DiscountForm = {
  discountCode: DiscountCode | undefined;
  setDiscount: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  disabled: boolean;
};

const schema = yup.object({
  discountCode: yup.string().min(1, 'Must be at least 1 character').trim(),
});

const DiscountForm = ({
  setDiscount,
  discountCode,
  disabled,
}: DiscountForm) => {
  const theme = useTheme();
  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { discountCode: '' },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setDiscount(data.discountCode);
  };

  useEffect(() => {
    if (discountCode) {
      reset({ discountCode: discountCode?.code });
    }
  }, [discountCode]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" gap="10px" justifyContent="flex-end">
          <Box display="flex" flexDirection="column">
            <TextInput
              label="Discount code"
              name="discountCode"
              type="text"
              disabled={disabled}
            />
            {!discountCode?.code &&
              discountCode?.discountMessage &&
              !isDirty && (
                <FormHelperText error>
                  {discountCode.discountMessage}
                </FormHelperText>
              )}
            {discountCode?.code && discountCode?.description && (
              <FormHelperText sx={{ color: theme.palette.success.main }}>
                {discountCode.description}
              </FormHelperText>
            )}
          </Box>

          <Button
            sx={{
              width: 'fit-content',
              mb: '8px',
              borderRadius: '10px',
              padding: '13px 15px',
              maxHeight: '56px',
              backgroundColor: theme.palette.primary.main,
            }}
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid || disabled}
          >
            Apply
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default DiscountForm;
