import React from 'react';
import { Box } from '@mui/material';
import { FormWrapper } from 'components/PublicLayout/style';

type PublicLayoutProps = {
  children: React.ReactNode;
};

const PublicLayout = ({ children }: PublicLayoutProps) => (
  <Box position="relative" height="100%">
    <Box
      sx={{
        position: 'absolute',
        top: '40px',
        right: '35px',
      }}
    >
      <img src="/logo.svg" alt="logo" width="280px" height="35px" />
    </Box>
    <FormWrapper maxWidth="xl">{children}</FormWrapper>
  </Box>
);

export default PublicLayout;
