import { useMutation } from 'react-query';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ButtonContainer from 'components/ButtonContainer';

import BasketIcon from 'assets/icons/BasketIcon';

import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';

export type ButtonAddToBasketProps = {
  details: EventProps;
};

const ButtonAddToBasket = ({ details }: ButtonAddToBasketProps) => {
  const theme = useTheme();
  const { snack, setSnack } = useToast();

  const { mutate: addProduct } = useMutation(
    () =>
      API.post(APIRoutes.basket.add(details.id), {
        quantity: 1,
        regularCharge: true,
      }),
    {
      onSettled: (_, error) => {
        setSnack({
          ...snack,
          message: error
            ? 'Unable to add item to basket'
            : 'Item added to basket',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
    },
  );

  return (
    <ButtonContainer>
      <Button
        variant="contained"
        disabled={!details.bookable || !details.bookUrl}
        sx={{
          backgroundColor: theme.palette.primary.main,
          justifyContent: 'space-between',
          width: '200px',
          height: '44px',
          my: 1,
        }}
        endIcon={
          <BasketIcon
            sx={{
              width: '22px',
              height: '21px',
              margin: '0 10px',
            }}
          />
        }
        type="submit"
        onClick={() => addProduct()}
      >
        Add to basket
      </Button>
    </ButtonContainer>
  );
};

export default ButtonAddToBasket;
