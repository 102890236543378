import { keyframes, styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const pulse = keyframes`
from {
  box-shadow: 0 0 0 0px rgba(247, 102, 102, 0.2);
}
to {
  box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
}
`;

export const ChipLive = styled(Chip, {
  shouldForwardProp: (prop: string) => !['isAnimated'].includes(prop as string),
})<{ isAnimated?: boolean }>(({ isAnimated = false, theme }) => ({
  animation: isAnimated ? `${pulse} 2s infinite` : 'unset',
  width: '62px',
  height: '20px',
  fontSize: theme.typography.smallFontSize,
  color: 'white',
}));
