/* eslint-disable import/extensions */
import React, { useState, useCallback, ReactNode } from 'react';
import { SwiperSlide, SwiperProps } from 'swiper/react';
import { useTheme } from '@mui/material/styles';
import SwiperCore from 'swiper';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useAuth } from 'context/AuthContext';
import NavigationIcon from 'assets/icons/NavigationIcon';
import { Title } from 'components/Title';
import { Link } from 'components/Link';

import { StyledSwiper, NavigationButton } from './style';

import 'swiper/css';

type SliderProps = SwiperProps & {
  id: string;
  items: any;
  title?: ReactNode;
  emptyComponent?: boolean;
  type?: string;
  hideViewAll?: boolean;
};

const Slider = ({
  id,
  items = [],
  title,
  type,
  emptyComponent,
  hideViewAll = false,
}: SliderProps) => {
  const theme = useTheme();
  const { user, currentSpaceId } = useAuth();

  const [swiperRef, setSwiperRef] = useState<SwiperCore>();

  const showPagination = items.length > 3;

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  return (
    <Box key={id}>
      <Box
        display="flex"
        alignItems="center"
        mb="48px"
        justifyContent="space-between"
      >
        {title && <Title variant="h2">{title}</Title>}
        {showPagination && (
          <Box display="flex" alignItems="center">
            {!hideViewAll && (
              <Box mr={2}>
                <Link
                  to={
                    type === 'bookings'
                      ? `/bookings/gallery?space=${
                          currentSpaceId === 0
                            ? user?.spaceId.toString()
                            : currentSpaceId
                        }`
                      : `/${type}`
                  }
                  color={theme.palette.accent}
                  fontWeight={theme.typography.fontWeightBold}
                >
                  View all
                </Link>
              </Box>
            )}
            <NavigationButton
              onClick={handleLeftClick}
              sx={{ marginRight: '10px' }}
            >
              <NavigationIcon
                sx={{
                  padding: '7px',
                }}
              />
            </NavigationButton>
            <NavigationButton onClick={handleRightClick}>
              <NavigationIcon
                sx={{
                  padding: '7px',
                  transform: 'rotate(180deg)',
                }}
              />
            </NavigationButton>
          </Box>
        )}
      </Box>
      {items.length > 0 && (
        <StyledSwiper
          onSwiper={setSwiperRef}
          slidesPerView={1.5}
          shortSwipes={false}
          threshold={10}
          breakpoints={{
            520: {
              slidesPerView: 1.5,
            },
            840: {
              slidesPerView: 3,
            },
            1230: {
              slidesPerView: 3.5,
            },
            1480: {
              slidesPerView: 4,
            },
          }}
          spaceBetween={16}
        >
          {items.map((item: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${id}${index}`}>{item}</SwiperSlide>
          ))}
        </StyledSwiper>
      )}
      {isEmpty(items) && emptyComponent && (
        <Box sx={{ opacity: 0.6, textAlign: 'center' }}>
          No {type} available
        </Box>
      )}
    </Box>
  );
};

Slider.defaultProps = {
  title: '',
  type: 'dashboard',
  emptyComponent: false,
  hideViewAll: false,
};

export default Slider;
