import { useState } from 'react';
import { Box, Divider } from '@mui/material';

import VisitorsTable from 'components/Visitors/VisitorsTable';
import EmptySection from 'components/Common/EmptySection';
import AddVisitorModal from 'components/Visitors/AddVisitorModal';
import { useAppContext } from 'context/AppContext';
import ScrollTopButton from 'components/Common/ScrollTopButton';

const Visitors = () => {
  const [hasExpectedVisitors, setHasExpectedVisitors] = useState(true);
  const [hasPastVisitors, setHasPastVisitors] = useState(true);
  const { openModal } = useAppContext();

  if (!hasExpectedVisitors && !hasPastVisitors) {
    return (
      <EmptySection
        onClick={() => openModal(<AddVisitorModal />)}
        missing="you have no visitors due"
        helperText="Add a new visitor"
        label="Add new visitor"
        icon={<img src="/assets/images/NoVisitors.svg" alt="No visitors" />}
      />
    );
  }

  return (
    <Box>
      {hasExpectedVisitors && (
        <VisitorsTable setHasVisitors={setHasExpectedVisitors} />
      )}

      {hasExpectedVisitors && hasPastVisitors && (
        <Divider
          sx={{
            marginY: '32px',
          }}
        />
      )}
      {hasPastVisitors && (
        <VisitorsTable setHasVisitors={setHasPastVisitors} pastVisitors />
      )}
      <Box textAlign="center">
        <ScrollTopButton />
      </Box>
    </Box>
  );
};

export default Visitors;
