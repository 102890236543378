import React from 'react';
import { isEmpty } from 'lodash-es';

import BookingConfirmation from 'components/Events/Booking/BookingConfirmation';
import CancelBookingButton from 'components/Events/Booking/CancelBookingButton';
import ButtonAddToBasket from 'components/Common/ButtonAddToBasket';

type showButtonProps = {
  event: EventProps;
  hasPrice: boolean | undefined;
  bookedEvent: EventProps | null | undefined;
};

const ShowButton = ({ event, hasPrice, bookedEvent }: showButtonProps) => {
  if (!isEmpty(bookedEvent))
    return <CancelBookingButton eventId={bookedEvent.id} />;
  if (hasPrice) return <ButtonAddToBasket details={event} />;
  return <BookingConfirmation details={event} />;
};

export default ShowButton;
