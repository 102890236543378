import { Button, Typography, Box } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import ArrowIcon from 'assets/icons/ArrowIcon';
import PinIcon from 'assets/icons/PinIcon';
import SearchIcon from 'assets/icons/SearchIcon';
import SunIcon from 'assets/icons/SunIcon';
import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';
import { DEFAULT_VALUES, FORM_OPTIONS } from 'constants/desks';
import routes from 'utils/routes';
import { useAuth } from 'context/AuthContext';
import SlidersIcon from 'assets/icons/SlidersIcon';
import { getItem, KEYS } from 'utils/cache';
import ScrollTopButton from 'components/Common/ScrollTopButton';
import SelectableSection from './SelectableSection';

const SkipButton = () => (
  <Button
    component={Link}
    to={routes.main.desks.charts}
    variant="contained"
    color="secondary"
    sx={{
      justifyContent: 'space-between',
      width: '168px',
      height: '44px',
      my: 1,
      '&:hover': {
        color: 'inherit',
      },
    }}
    endIcon={
      <ArrowIcon
        sx={{
          width: '22px',
          height: '21px',
          margin: '0 10px',
        }}
      />
    }
  >
    Skip
  </Button>
);

type FormData = {
  deskAvailabilityFilter: string;
};

const DesksForm = () => {
  const navigate = useNavigate();
  const { isFromLondon } = useAuth();
  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (!isFromLondon) {
      navigate(routes.main.desks.charts);
    }
  }, []);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const filter = data?.deskAvailabilityFilter;
    navigate({
      pathname: routes.main.desks.charts,
      search: filter ? `?filter=${filter}` : '',
    });
  };

  const favourites = JSON.parse(getItem(KEYS.FAVOURITES) as string);

  return (
    <>
      <SectionHeader
        title="Desk availability"
        hideBackButton
        elements={<SkipButton />}
      />
      <Section>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SelectableSection
              icon={SunIcon}
              title="Atmosphere"
              options={FORM_OPTIONS.atmosphere}
              name="deskAvailabilityFilter"
              disableRest
            />
            <SelectableSection
              icon={PinIcon}
              title="Location"
              options={FORM_OPTIONS.location}
              name="deskAvailabilityFilter"
              disableRest
            />
            {!isEmpty(favourites) && (
              <SelectableSection
                icon={SlidersIcon}
                title="Type"
                options={FORM_OPTIONS.type}
                name="deskAvailabilityFilter"
                disableRest
              />
            )}
            <Typography
              variant="h4"
              marginBottom="32px"
              fontWeight="fontWeightRegular"
            >
              Find your workspace
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'primary.main',
                justifyContent: 'space-between',
                width: '168px',
                height: '44px',
                my: 1,
                marginRight: '8px',
              }}
              endIcon={
                <SearchIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    margin: '0 10px',
                  }}
                />
              }
              type="submit"
              disabled={!isDirty}
            >
              Search
            </Button>
            <SkipButton />
          </form>
        </FormProvider>
        <Box textAlign="center" mt="79px" mb="16px">
          <ScrollTopButton />
        </Box>
      </Section>
    </>
  );
};

export default DesksForm;
