import { useCallback, useEffect } from 'react';
import { Typography, useTheme, Box, AlertColor } from '@mui/material';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

import Section from 'components/Section';
import routes from 'utils/routes';
import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import LoadingButton from 'components/Common/LoadingButton';

const DirectDebit = () => {
  const location = useLocation();
  const finalize = location.hash.slice(1).split('?')[1] === 'finalize=true';
  const navigate = useNavigate();
  const { user, setUser, fetchUser } = useAuth();
  const theme = useTheme();
  const { snack, setSnack } = useToast();

  const { mutate: setUpGoCardless, isLoading } = useMutation(
    () =>
      API.post(APIRoutes.auth.goCardless, {
        redirectUri: `${window.location.origin}/account/profile#payment?finalize=true`,
        exitUri: window.location.href,
      }),
    {
      onSuccess: (data) => {
        const { data: resData } = data;
        window.location.href = resData?.url;
      },
      onError: () => {
        setSnack({
          ...snack,
          message: 'Something went wrong. Please try again.',
          open: true,
          type: 'error',
        });
      },
    },
  );

  const { mutate: goCardlessFinalSetup, isLoading: isFinalSetupLoading } =
    useMutation(
      () =>
        API.post(APIRoutes.auth.goCardlessFinalize, {
          status: 'success',
        }),
      {
        onSuccess: async () => {
          setSnack({
            ...snack,
            message: 'Direct Debit has been set up correctly',
            open: true,
            type: 'success',
          });
          setUser(await fetchUser());
        },
        onError: (error: { status: string; message: string }) => {
          setSnack({
            ...snack,
            message: error.message || 'Something went wrong. Please try again.',
            open: true,
            type: (error.status as AlertColor) || 'error',
          });
        },
      },
    );

  useEffect(() => {
    const goCardlessFinalizeSetup = async () => {
      await goCardlessFinalSetup();
    };
    if (finalize && !user?.hasGoCardlessContractNumber) {
      goCardlessFinalizeSetup();
    }
  }, [finalize, user?.hasGoCardlessContractNumber]);

  const handleClick = useCallback(() => {
    if (user?.hasGoCardlessContractNumber) {
      navigate(routes.main.help.raiseTicket);
    } else {
      setUpGoCardless();
    }
  }, [user?.hasGoCardlessContractNumber]);

  return (
    <Section title="GoCardless direct debit payments" id="payment">
      <Box display="flex" gap="32px" mb="15px" flexDirection="column">
        <Typography variant="h5">
          Direct Debit is an instruction from you to your bank or building
          society. It authorises the organisation you want to pay to collect
          varying amounts from your account – but only if you’ve been given
          advanced notice of the amounts and dates of collection.
        </Typography>
        {user?.hasGoCardlessContractNumber && (
          <Typography fontWeight={theme.typography.fontWeightBold}>
            You already have a Direct Debit agreement with us. Please get in
            touch with us if you would like to cancel or change it.
          </Typography>
        )}
      </Box>
      <LoadingButton
        isLoading={isLoading || isFinalSetupLoading}
        variant="contained"
        color="secondary"
        sx={{
          width: user?.hasGoCardlessContractNumber ? '144px' : '174px',
          height: '44px',
          justifyContent: 'center',
          marginBottom: '8px',
        }}
        onClick={handleClick}
      >
        {user?.hasGoCardlessContractNumber ? 'Contact us' : 'Set up GoCardless'}
      </LoadingButton>
    </Section>
  );
};

export default DirectDebit;
