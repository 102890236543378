import { Avatar, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useIsMutating, useQuery } from 'react-query';
import LoadingButton from 'components/Common/LoadingButton';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';

const getHost = async (id: number) => {
  const {
    data: { data },
  } = await API.get(APIRoutes.tourBoking.host(id));
  return data;
};

function Footer({ submitStep }: BookingStepFooterProps) {
  const isSubmitting = !!useIsMutating('tourBooking');

  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const spaceId = getValues('location');

  const { isFetching, data: host } = useQuery<BookingHost>(
    ['bookingHost', spaceId],
    () => getHost(spaceId),
    {
      enabled: !!spaceId,
      staleTime: 60 * 1000,
    },
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row" gap={2} alignItems="center">
        {host?.avatarUrl && (
          <Avatar
            src={host.avatarUrl}
            alt="avatar"
            sx={{ width: 72, height: 72 }}
          />
        )}
        <Typography variant="h3">
          {host?.firstName} will be
          <br />
          there to meet you
        </Typography>
      </Stack>
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={submitStep}
        disabled={!isValid}
        isLoading={isSubmitting}
        sx={{
          width: '196px',
          justifyContent: 'center',
          height: '44px',
        }}
      >
        Book me in
      </LoadingButton>
    </>
  );
}

export default Footer;
