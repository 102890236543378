import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiAlert: {
    styleOverrides: {
      root: {
        color: theme.palette.blue[900],
        minWidth: '312px',
        borderRadius: '100px',
        padding: '10px 16px',
        boxShadow: 'none',
        fontWeight: theme.typography.fontWeightBold,
      },
      filledSuccess: {
        backgroundColor: '#68ca3d4d',
      },
      message: {
        fontSize: '16px',
        padding: 0,
      },
      icon: {
        opacity: 1,
        padding: '2px 0',
        alignItems: 'center',
      },
    },
  },
});
