import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        borderRadius: '8px',
        color: theme.palette.blue[900],
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
      },
    },
  },
});
