import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CloseIcon;
