import { useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';

import { useToast } from 'context/ToastContext';
import { API, APIRoutes } from 'utils/api';
import LoadingButton from 'components/Common/LoadingButton';
import { useAuth } from 'context/AuthContext';

interface ConfirmOrderButtonProps {
  closeModal: () => void;
  discountCode: string;
  isCodeValid: boolean;
}

const ConfirmOrderButton = ({
  closeModal,
  discountCode,
  isCodeValid,
}: ConfirmOrderButtonProps) => {
  const theme = useTheme();
  const { snack, setSnack } = useToast();
  const queryClient = useQueryClient();
  const { setIsBasketEmpty } = useAuth();

  const { mutate: confirmOrder, isLoading } = useMutation(
    () =>
      API.post(APIRoutes.basket.createInvoice, {
        discountCode: isCodeValid ? discountCode : '',
      }),
    {
      onSettled: (_, error) => {
        closeModal();
        setSnack({
          ...snack,
          message: error ? 'An error has occured' : 'Order confirmed',
          open: true,
          type: error ? 'error' : 'success',
        });
      },
      onSuccess: () => {
        queryClient.setQueryData('basket', {});
        setIsBasketEmpty(true);
      },
    },
  );

  return (
    <LoadingButton
      isLoading={isLoading}
      onClick={() => confirmOrder()}
      variant="contained"
      sx={{
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        width: '200px',
        height: '48px',
      }}
    >
      Confirm order
    </LoadingButton>
  );
};

export default ConfirmOrderButton;
