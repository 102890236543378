import { Box, Typography, useTheme } from '@mui/material';

import Section from 'components/Section';
import CheckBox from 'components/Form/CheckBox';
import { NOTIFICATIONS } from 'constants/profile';

type CheckboxSectionProps = {
  title: string;
  options: {
    label: string;
    name: string;
  }[];
};

const CheckboxSection = ({ details }: { details: CheckboxSectionProps }) => {
  const theme = useTheme();
  return (
    <Box marginBottom="40px">
      <Typography
        fontWeight={theme.typography.fontWeightBold}
        marginBottom="24px"
      >
        {details.title}
      </Typography>
      {details.options.map((option) => (
        <Box key={option.name}>
          <CheckBox label={option.label} name={option.name} />
        </Box>
      ))}
    </Box>
  );
};

const NotificationsForm = () => (
  <Section title="Notifications" id="notifications">
    <CheckboxSection details={NOTIFICATIONS.firstSection} />
    <CheckboxSection details={NOTIFICATIONS.secondSection} />
  </Section>
);

export default NotificationsForm;
