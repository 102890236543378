import { Stepper, Step, StepLabel, Divider, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useIsMutating } from 'react-query';

import { STEPPER_LABELS, STEPS } from 'constants/signUp';
import LoadingButton from 'components/Common/LoadingButton';
import PublicLayout from 'components/PublicLayout/PublicLayout';
import { Title } from 'components/Title';
import { FormBanner, FormBox } from 'components/PublicLayout/style';

const SignUpLayout = ({ children }: { children: any }) => {
  const { pathname } = useLocation();
  const currentStep = STEPS.findIndex(
    (step) => step.route === pathname.replace(/\/$/, ''),
  );
  const step = STEPS[currentStep];
  const isLoading = !!useIsMutating(step.queryKey);

  return (
    <PublicLayout>
      <FormBox>
        <Stack spacing={2} mb={2}>
          <Title variant="h1">Join Work.Life</Title>
          <Divider />
        </Stack>
        {children}
      </FormBox>
      <FormBanner>
        <Stepper
          activeStep={currentStep}
          alternativeLabel
          sx={{ width: '350px' }}
        >
          {STEPPER_LABELS.map((label: string) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step.buttonLabel && (
          <LoadingButton
            isLoading={isLoading}
            variant="contained"
            color="secondary"
            sx={{
              width:
                step.buttonLabel !== 'Proceed to payment' ? '168px' : '183px',
              height: '44px',
              justifyContent: 'center',
            }}
            type="submit"
            form={`step-${currentStep + 1}`}
          >
            {step.buttonLabel}
          </LoadingButton>
        )}
      </FormBanner>
    </PublicLayout>
  );
};

export default SignUpLayout;
