import { Theme } from '@mui/material';

export default ({ theme }: { theme: Theme }) => ({
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
      InputProps: {
        disableUnderline: true,
      },
    },
    styleOverrides: {
      root: {
        height: '56px',
        borderRadius: '10px',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        ':-webkit-autofill': {
          backgroundColor: '#ffffff !important',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        left: '6px',
        color: theme.palette.blue[900],
        '&.Mui-focused': {
          color: theme.palette.blue[900],
        },
        '&.MuiInputLabel-shrink': {
          opacity: 0.7,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '100%',
        backgroundColor: '#ffffff !important',
        ':-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset',
          backgroundColor: '#ffffff !important',
        },
        '& .MuiInputBase-input': {
          padding: '24px 10px 15px 18px',
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.mainBorderColor}`,
        borderRadius: '10px',
        overflow: 'hidden',
        ':hover:not(.Mui-disabled):before': {
          border: 'none',
        },
        ':before': {
          border: 'none',
        },
        ':after': {
          border: 'none',
        },
        ':-webkit-autofill': {
          backgroundColor: '#ffffff !important',
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
        },
        '&.Mui-disabled:before': {
          borderBottomStyle: 'none',
        },
        '&.Mui-focused': {
          border: `2px solid ${theme.palette.secondary.main}`,
          borderRadius: '10px',
          backgroundColor: '#ffffff !important',
          input: {
            ':-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
              backgroundColor: '#ffffff !important',
              height: '0px',
            },
          },
        },
      },
      input: {
        ':-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset',
          backgroundColor: '#ffffff !important',
        },
      },
    },
  },
});
