import MuiUseMediaQuery from './UseMediaQuery';
import Drawer from './Drawer';
import MuiButton from './Button';
import MuiButtonBase from './ButtonBase';
import MuiBreadcrumbs from './Breadcrumbs';
import Input from './Input';
import Card from './Card';
import Menu from './Menu';
import Backdrop from './Backdrop';
import Alert from './Alert';
import Checkbox from './Checkbox';
import Tab from './Tab';
import DatePicker from './DatePicker';
import Autocomplete from './Autocomplete';

function injectTheme(props: any) {
  return (component: any) => {
    if (typeof component === 'function') {
      return component(props);
    }

    return component;
  };
}

const components = (theme: any) => {
  const inject = injectTheme({ theme });
  return {
    components: {
      ...inject(MuiButtonBase),
      ...inject(MuiUseMediaQuery),
      ...inject(MuiButton),
      ...inject(Drawer),
      ...inject(MuiBreadcrumbs),
      ...inject(Input),
      ...inject(Card),
      ...inject(Menu),
      ...inject(Backdrop),
      ...inject(Alert),
      ...inject(Checkbox),
      ...inject(Tab),
      ...inject(DatePicker),
      ...inject(Autocomplete),
    },
  };
};

export default components;
