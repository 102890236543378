import { SvgIconProps, Typography } from '@mui/material';

export type LabelWithIconProps = {
  label: string;
  icon: (props: SvgIconProps) => JSX.Element;
  isHeader?: boolean;
  capacity?: boolean;
};

const LabelWithIcon = ({
  label,
  icon,
  isHeader,
  capacity,
}: LabelWithIconProps) => {
  const Icon = icon;

  return (
    <Typography
      display="flex"
      variant={isHeader ? 'h4' : 'inherit'}
      fontWeight={isHeader ? 'fontWeightBold' : 'fontWeightRegular'}
      marginTop={capacity ? '29px' : 'inherit'}
      marginBottom={capacity ? '0px' : '16px'}
    >
      <>
        {capacity ? (
          <Icon
            sx={{
              marginTop: '-7px',
              width: '24px',
              height: '24px',
              marginRight: '8px',
              lineHeight: '18px',
              fill: 'none',
            }}
          />
        ) : (
          <Icon
            sx={{
              width: '24px',
              height: '24px',
              marginRight: '8px',
              lineHeight: '18px',
              fill: 'none',
            }}
          />
        )}
        {label}
      </>
    </Typography>
  );
};

LabelWithIcon.defaultProps = {
  isHeader: false,
  capacity: false,
};

export default LabelWithIcon;
