import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import moment from 'moment';
import { useInfiniteQuery } from 'react-query';

import { useToast } from 'context/ToastContext';

import DataTable from 'components/Common/DataTable';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';
import { API, APIRoutes } from 'utils/api';
import { LIMITS } from 'constants/common';
import Loader from 'components/Loader';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import CustomChip from 'components/Common/CustomChip';
import VisitorMenu from './VisitorMenu';

const LABELS = ['Full name', 'Company', 'Email', 'Expected at', 'When'];

const VisitorsTable = ({
  setHasVisitors,
  pastVisitors,
}: {
  setHasVisitors: Dispatch<SetStateAction<boolean>>;
  pastVisitors?: boolean;
}) => {
  const queryKey = pastVisitors ? 'past-visitors' : 'expected-visitors';
  const theme = useTheme();
  const [showToast, setShowToast] = useState<{
    type: null | 'error' | 'success';
    open: boolean;
  }>({ type: null, open: false });

  const { snack, setSnack } = useToast();

  const getVisitors = async ({ pageParam = 1 }) => {
    const { data } = await API.get(
      pastVisitors
        ? APIRoutes.visitors.past(LIMITS.VISITORS, pageParam)
        : APIRoutes.visitors.incoming(LIMITS.VISITORS, pageParam),
    );
    if (data.total === 0) {
      setHasVisitors(false);
    }
    return data;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    isSuccess,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery([queryKey], getVisitors, {
    getNextPageParam: (lastPage, pages) =>
      pages.length < lastPage?.totalPages ? pages.length + 1 : undefined,
  });

  useEffect(() => {
    if (showToast.open) {
      setSnack({
        ...snack,
        message:
          showToast.type === 'success'
            ? 'Visitor updated'
            : 'Unable to update this visitor',
        open: true,
        type: showToast.type === 'error' ? 'error' : 'success',
      });
    }
  }, [showToast]);

  if (isFetching && !isFetchingNextPage && !isRefetching) {
    return <Loader />;
  }

  if (isSuccess) {
    const visitors = data.pages.flatMap((page) => page?.data);

    return (
      <Box>
        <Typography variant="h2" marginBottom="16px">
          {pastVisitors ? 'Past visitors' : 'Expected visitors'}
        </Typography>

        {isRefetching && !isFetchingNextPage ? (
          <LoadingSpinner />
        ) : (
          <>
            <DataTable labels={pastVisitors ? [] : LABELS}>
              {visitors.map((visitor: VisitorProps) => {
                const spaceName = visitor.space.replace('Work.Life ', '');
                const today = moment();
                const date = moment(visitor.visitorArrival);

                return (
                  <TableRow
                    key={visitor.id}
                    sx={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <TableCell>{visitor.visitorName}</TableCell>
                    <TableCell>{visitor.visitorCompany}</TableCell>
                    <TableCell>{visitor.visitorEmail}</TableCell>
                    <TableCell>
                      <Chip
                        label={spaceName}
                        color="secondary"
                        variant="outlined"
                        sx={{
                          height: '20px',
                          color: theme.palette.blue[900],
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                      >
                        <Box>
                          {date.isSame(today, 'day') && (
                            <CustomChip
                              label="Today!"
                              variant="filled"
                              color="secondary"
                              sx={{
                                marginLeft: '0px',
                              }}
                            />
                          )}
                          <Typography>
                            {date.format(
                              visitor.arrived ? 'DD/MM/YY' : 'DD/MM/YY - HH:mm',
                            )}
                          </Typography>
                        </Box>
                        {visitor.arrived && (
                          <ToastSuccessIcon
                            fill={theme.palette.secondary.main}
                            sx={{
                              background: theme.palette.secondary.main,
                              padding: '2px',
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        )}
                        <VisitorMenu
                          visitor={visitor}
                          queryKey={queryKey}
                          isSuccess={isSuccess}
                          setShowToast={setShowToast}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </DataTable>
            {isFetchingNextPage ? (
              <LoadingSpinner />
            ) : (
              <LoadMoreButton
                hasNextPage={hasNextPage}
                onClick={fetchNextPage}
                marginTop="32px"
                hideScrollToTopButton
              />
            )}
          </>
        )}
      </Box>
    );
  }
  return null;
};

VisitorsTable.defaultProps = {
  pastVisitors: false,
};

export default VisitorsTable;
