import { Grid, Stack, Typography, useTheme } from '@mui/material';

import TextInput from 'components/Form/TextInput';
import Section from 'components/Section';
import SelectField from 'components/Form/SelectField';
import { countries } from 'components/Profile/helpers';

export const ADDRESS_FIELDS = [
  'address',
  'postCode',
  'countryId',
  'companyName',
  'cityName',
];

const AddressForm = ({ isSignUp }: { isSignUp?: boolean }) => {
  const theme = useTheme();

  return (
    <Section title="Address" id="address">
      <Typography
        fontWeight={theme.typography.fontWeightBold}
        marginBottom="32px"
      >
        If you are a registered company, please use your registered business
        address.
      </Typography>
      {isSignUp ? (
        <Stack spacing={2}>
          <TextInput
            label="Address"
            name={ADDRESS_FIELDS[0]}
            type="text"
            required
          />
          <TextInput
            label="Postcode"
            name={ADDRESS_FIELDS[1]}
            type="text"
            required
          />
          <SelectField
            name={ADDRESS_FIELDS[2]}
            label="Country"
            options={countries}
          />
          <TextInput
            label="Company/Org. Name"
            name={ADDRESS_FIELDS[3]}
            type="text"
          />
          <TextInput
            label="City"
            name={ADDRESS_FIELDS[4]}
            type="text"
            required
          />
        </Stack>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput label="Address" name={ADDRESS_FIELDS[0]} type="text" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput label="Postcode" name={ADDRESS_FIELDS[1]} type="text" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectField
              name={ADDRESS_FIELDS[2]}
              label="Country"
              options={countries}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Company/Org. name"
              name={ADDRESS_FIELDS[3]}
              type="text"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput label="City" name={ADDRESS_FIELDS[4]} type="text" />
          </Grid>
        </Grid>
      )}
    </Section>
  );
};

AddressForm.defaultProps = {
  isSignUp: false,
};

export default AddressForm;
