import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useMutation } from 'react-query';

import { useTheme } from '@mui/material';
import { API, APIRoutes } from 'utils/api';
import { useToast } from 'context/ToastContext';
import LoadingButton from 'components/Common/LoadingButton';
import { ActionButton } from './style';

type DownloadButtonProps = {
  id?: string;
  name: string;
};

const DownloadButton = ({ id, name }: DownloadButtonProps) => {
  const { snack, setSnack } = useToast();
  const theme = useTheme();

  const { mutate: download, isLoading } = useMutation(
    () =>
      API.get(id ? APIRoutes.invoices.byId(id) : APIRoutes.invoices.statement, {
        responseType: 'blob',
      }),
    {
      onSuccess: (response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', `${name}.pdf`);
        link.click();
        link.remove();
      },
      onError: () => {
        setSnack({
          ...snack,
          message: 'Unable to download invoice',
          open: true,
          type: 'error',
        });
      },
    },
  );

  if (id) {
    return (
      <ActionButton isLoading={isLoading} onClick={() => download()}>
        <FileDownloadOutlinedIcon />
      </ActionButton>
    );
  }

  return (
    <LoadingButton
      isLoading={isLoading}
      onClick={() => download()}
      variant="contained"
      color="primary"
      endIcon={<FileDownloadOutlinedIcon />}
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: '236px',
        height: '44px',
      }}
    >
      Download statement
    </LoadingButton>
  );
};

DownloadButton.defaultProps = {
  id: null,
};

export default DownloadButton;
