import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import MotionImage from 'components/MotionBox';

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #4538ce;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
  z-index: 10000;
`;

const Loader = () => {
  const theme = useTheme();
  return (
    <Wrapper>
      <Box display="flex" flexDirection="column" pt="100px">
        <MotionImage
          sx={{ width: '100%', height: '81px', textAlign: 'center' }}
          animate={{ y: [100, -15] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        >
          <img src="/assets/images/Smile.svg" alt="logo" />
        </MotionImage>
        <Box
          zIndex={999}
          sx={{
            backgroundColor: theme.palette.background.main,
            paddingBottom: '100px',
          }}
        >
          <img src="/logo.svg" alt="logo" width="280px" height="35px" />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Loader;
