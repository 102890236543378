import { Box, Icon, Typography, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import moment from 'moment';

import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import Loader from 'components/Loader';
import EmptySection from 'components/Common/EmptySection';
import { mapStatus } from 'components/Profile/helpers';
import InvoiceIcon from 'assets/icons/InvoiceIcon';
import { useAuth } from 'context/AuthContext';
import AccountCard from './AccountCard';

type CardInvoicesProps = {
  label: string;
};

const CardInvoices = ({ label }: CardInvoicesProps) => {
  const theme = useTheme();
  const { user } = useAuth();

  const getInvoices = async () => {
    const {
      data: { data },
    } = await API.get(APIRoutes.invoices.index);
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<InvoiceProps[]>(
    'invoices',
    () => getInvoices(),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <AccountCard
        label={label}
        value={routes.main.account.invoices}
        elements={
          <>
            {user?.renewalDate && (
              <Box display="flex" gap="5px" alignItems="center" my="17px">
                <InvoiceIcon />
                <Typography>
                  Your next invoice will be issued on{' '}
                  <Typography component="span" fontWeight="700">
                    {moment(user?.renewalDate).format('DD/MM/YY')}
                  </Typography>
                </Typography>
              </Box>
            )}
            {data.length > 0 ? (
              <Box
                display="flex"
                py="8px"
                sx={{ borderBottom: '1px solid #D9DFEF' }}
              >
                <Typography flexBasis="25%" fontSize="13px">
                  Invoice
                </Typography>
                <Typography flexBasis="25%" fontSize="13px">
                  Date
                </Typography>
                <Typography flexBasis="25%" fontSize="13px">
                  Amount
                </Typography>
                <Typography flexBasis="25%" fontSize="13px">
                  Status
                </Typography>
              </Box>
            ) : (
              <Box marginTop="-67px" marginBottom="8px">
                <EmptySection
                  missing="you have no new invoices"
                  icon={
                    <img src="/assets/images/NoInvoice.svg" alt="No invoices" />
                  }
                />
              </Box>
            )}
            <Box>
              {data.slice(0, 3).map((invoice) => {
                const status = mapStatus(invoice.status);
                return (
                  <Box key={invoice.invoiceNo} display="flex" my="20px">
                    <Typography flexBasis="25%" fontWeight="700">
                      {invoice.invoiceNo}
                    </Typography>
                    <Typography flexBasis="25%">
                      {moment(invoice.dueDate).format('DD/MM/YY')}
                    </Typography>
                    <Typography flexBasis="25%" fontWeight="700">
                      £{invoice.totalAmount.toFixed(2)}
                    </Typography>
                    <Box display="flex" flexBasis="25%">
                      <Icon
                        component={status.icon}
                        sx={{
                          height: '24px',
                          width: '24px',
                          color: theme.palette.secondary.main,
                        }}
                      />
                      <Typography
                        fontWeight="700"
                        mx="6px"
                        sx={{
                          '&:first-letter': {
                            textTransform: 'capitalize',
                          },
                        }}
                      >
                        {status.label}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        }
      />
    );
  }
  return null;
};

export default CardInvoices;
