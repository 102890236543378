import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { KEYS, removeItem } from 'utils/cache';
import { useAuth } from 'context/AuthContext';
import routes from 'utils/routes';
import LayoutBanner from './LayoutBanner';

const ImpersonationBanner = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useAuth();

  const exitImpersonationMode = () => {
    removeItem(KEYS.IMPERSONATE_TOKEN);
    navigate(routes.main.dashboard, { replace: true });
    navigate(0);
    queryClient.resetQueries();
  };

  return (
    <LayoutBanner
      bottomBanner
      onClick={exitImpersonationMode}
      text={
        <>
          Mirror mode - logged in as:&nbsp;
          <span style={{ fontWeight: 'bold' }}>
            {user?.fullName} ({user?.email}){' '}
            {!user?.active && (
              <>
                {' - '}
                <span style={{ color: 'red' }}>suspended account</span>
              </>
            )}
          </span>
        </>
      }
      buttonText="Exit mirror mode"
    />
  );
};

export default ImpersonationBanner;
