import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import SectionHeader from 'components/SectionHeader';
import Section from 'components/Section';
import routes from 'utils/routes';
import { API, APIRoutes } from 'utils/api';
import InfoIcon from 'assets/icons/InfoIcon';
import Loader from 'components/Loader';
import DetailsSection from 'components/Common/DetailsSection';
import LabelWithIcon from 'components/Common/LabelWithIcon';

const breadcrumbsConfig = ({ description }: { description: string }) => [
  { label: 'Perks', href: routes.main.promotions },
  { label: description },
];

const PerkDetail = () => {
  const theme = useTheme();
  const { id } = useParams() as { id: string };

  const getPerkDetails = async () => {
    if (!id) return [];
    const {
      data: { data },
    } = await API.get(APIRoutes.perks.indexById(id));
    return data;
  };

  const { isLoading, isSuccess, data } = useQuery<PerkProps>(['perk', id], () =>
    getPerkDetails(),
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    const date = moment(data.created).format('DD/MM/YY');
    return (
      <>
        <SectionHeader
          title={data.title}
          path={breadcrumbsConfig({
            description: data.title,
          })}
          elements={
            <>
              <Typography component={Box} fontSize="smallFontSize">
                Last updated on:{' '}
                <Box
                  display="inline"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  {date}
                </Box>{' '}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mx: '16px',
                  borderColor: theme.palette.primary.main,
                }}
              />
              <Button
                variant="contained"
                href={data.claimUrl}
                target="_blank"
                sx={{
                  justifyContent: 'center',
                  backgroundColor: theme.palette.primary.main,
                  width: '144px',
                  height: '44px',
                }}
              >
                Claim
              </Button>
            </>
          }
          backLink={routes.main.promotions}
        />
        <Section>
          <DetailsSection
            leftColumn={
              <img
                src={data.imageUrl}
                alt={`${data.title}-img`}
                width="100%"
                style={{
                  maxHeight: '384px',
                  maxWidth: '100%',
                  width: 'auto',
                  margin: '0 auto',
                  display: 'block',
                }}
              />
            }
            rightColumn={
              <>
                <LabelWithIcon label="About" icon={InfoIcon} isHeader />
                <Divider
                  sx={{
                    marginBottom: '12px',
                  }}
                />
                <Box
                  sx={{
                    lineHeight: '18px',
                    marginBottom: '12px',
                    overflowWrap: 'break-word',
                    a: {
                      color: 'inherit',
                    },
                    'p, span, a': {
                      lineHeight: 'inherit !important',
                      fontFamily: 'inherit !important',
                      color: 'inherit !important',
                      fontSize: 'inherit !important',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              </>
            }
          />
        </Section>
      </>
    );
  }
  return null;
};

export default PerkDetail;
