import { Box } from '@mui/material';
import { ReactNode } from 'react';

export type SharpenedCardProps = {
  children: ReactNode;
};

const SharpenedCard = ({ children }: SharpenedCardProps) => (
  <Box
    sx={{
      position: 'relative',
      backgroundColor: 'white',
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
      '::after': {
        content: '""',
        backgroundImage: 'url(/assets/images/triangle.svg)',
        backgroundPositionX: '-18px',
        backgroundSize: '36px 12px',
        width: '100%',
        height: '12px',
        position: 'absolute',
        bottom: '-12px',
        right: 0,
        zIndex: 2,
      },
    }}
  >
    {children}
  </Box>
);
export default SharpenedCard;
