import { SvgIcon, SvgIconProps } from '@mui/material';

const ChevronDownIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 7.5L10 12.5L15 7.5"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronDownIcon;
