/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ToastSuccessIcon from 'assets/icons/ToastSuccessIcon';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

type Snack = {
  message?: string;
  color?: string;
  autoHideDuration?: number;
  open: boolean;
  icon?: JSX.Element | boolean;
  type?: AlertColor;
};

type ToastContextType = Snack & {
  setSnack: React.Dispatch<React.SetStateAction<Snack>>;
  snack: Snack;
};

const ToastContextDefaultState: ToastContextType = {
  message: '',
  color: '',
  autoHideDuration: 1500,
  open: false,
  setSnack: (): void => {},
  icon: false,
  type: 'success',
  snack: { message: '', color: '', open: false, icon: false, type: 'success' },
};
type Props = {
  children: ReactNode;
};

const ToastContext = createContext(ToastContextDefaultState);

export const ToastContextProvider = ({ children }: Props) => {
  const [snack, setSnack] = useState<Snack>({
    message: '',
    color: '',
    autoHideDuration: 3000,
    open: false,
    icon: false,
    type: 'success',
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({ open: false });
  };

  const globalContextValue = useMemo(
    () => ({
      ...ToastContextDefaultState,
      handleClose,
      snack,
      setSnack,
    }),
    [snack, setSnack, handleClose],
  );

  return (
    <ToastContext.Provider value={globalContextValue}>
      {snack.message && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snack.open}
          autoHideDuration={snack.autoHideDuration || 3000}
          onClose={handleClose}
          sx={{
            '&.MuiSnackbar-root': {
              top: '50px',
            },
          }}
        >
          <Alert
            icon={
              snack.type === 'error' ? (
                <ErrorOutlineOutlinedIcon
                  sx={{
                    color: 'white',
                    width: '20px',
                    height: '20px',
                  }}
                />
              ) : (
                <ToastSuccessIcon
                  sx={{
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                  }}
                />
              )
            }
            severity={snack.type || 'success'}
            sx={{
              color: snack.type === 'error' ? 'white' : 'blue',
            }}
          >
            <Box
              sx={{
                a: {
                  color: 'inherit',
                },
              }}
              dangerouslySetInnerHTML={{ __html: snack.message || '' }}
            />
          </Alert>
        </Snackbar>
      )}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
