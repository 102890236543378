import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface LayoutBannerProps {
  onClick: () => void;
  text: string | React.ReactNode;
  buttonText?: string;
  bottomBanner?: boolean;
  styleOverrides?: any;
}

const LayoutBanner = ({
  onClick,
  text,
  buttonText,
  bottomBanner,
  styleOverrides,
}: LayoutBannerProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        mb: bottomBanner ? '0' : '8px',
        width: '100%',
        position: bottomBanner ? 'fixed' : '',
        bottom: bottomBanner ? '0' : '',
        zIndex: bottomBanner ? '1000' : '',
        ...styleOverrides,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: bottomBanner ? 'space-around' : 'space-between',
          flexDirection: bottomBanner ? 'row' : 'column',
          gap: '24px',
          '&:last-child': {
            padding: bottomBanner ? '20px' : '',
          },
        }}
      >
        <Box display="flex" alignItems="center" gap="12px">
          <ErrorOutlineOutlinedIcon
            color="error"
            sx={{
              width: '48px',
              height: '48px',
            }}
          />
          <Typography
            variant="h5"
            maxWidth={
              bottomBanner ? 'none' : styleOverrides.textMaxWidth || '400px'
            }
          >
            {text}
          </Typography>
        </Box>
        <Button
          onClick={onClick}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.primary.main,
            flexShrink: 0,
            width: '100%',
            maxWidth: '300px',
            justifyContent: 'center',
          }}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

LayoutBanner.defaultProps = {
  buttonText: 'I understand',
  bottomBanner: false,
  styleOverrides: {},
};

export default LayoutBanner;
