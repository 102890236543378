import { SvgIcon, SvgIconProps } from '@mui/material';

const PaidIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#68CA3D" />
    <path
      d="M17.6023 7.8L9.90234 15.5L6.40234 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <clipPath id="clip0_2908_17987">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </SvgIcon>
);

export default PaidIcon;
