/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';

const SvgComponent = (props: SvgIconProps) => (
  <SvgIcon
    width="39px"
    height="49px"
    viewBox="0 0 39 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.083 1.583H5.749a4.583 4.583 0 0 0-4.583 4.584v36.666a4.583 4.583 0 0 0 4.583 4.584h27.5a4.583 4.583 0 0 0 4.584-4.584v-27.5m-13.75-13.75 13.75 13.75m-13.75-13.75v13.75h13.75m-9.167 11.459H10.333m18.333 9.166H10.333m4.583-18.333h-4.583"
      stroke="#1D1954"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default SvgComponent;
